import { fontSizes } from '@/shared/styles';
import styled from 'styled-components';

import { NormalText, TitleText } from '@/shared/components/Text.styled';

export const LegalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  background: ${(p) => p.theme.colors.backgrounds.app};
  padding-top: 10px;
  padding-bottom: 15px;

  height: 100%;
  color: ${(p) => p.theme.colors.text.secondary};
  text-align: center;
  letter-spacing: -0.5px;
`;

export const Headline = styled(TitleText)`
  font-size: ${fontSizes.giant};
`;

export const LegalText = styled(NormalText)`
  font-size: 1rem;
`;

export const StyledLink = styled.a`
  margin-top: auto; // Float this to the bottom
  width: 100%;
  height: min-content;
`;
