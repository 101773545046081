/**
 * Reusable styles for Graphics and status pages
 */
import styled from 'styled-components';

import { fontSizes, fontWeights } from '@/shared/styles';

import SuccessGraphic from '@/shared/components/SuccessGraphic';
import { NormalText, TitleText } from '@/shared/components/Text.styled';

export const PageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const TextBox = styled.div`
  position: relative;
  top: 54.5%;
  gap: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;

export const Title = styled(TitleText)`
  width: 100%;
  text-align: center;
  font-size: ${fontSizes.giant};
  font-weight: ${fontWeights.bold};
`;

export const Text = styled(NormalText)`
  width: 100%;
  font-weight: ${fontWeights.light};
  font-size: ${fontSizes.medium};
  color: ${(p) => p.theme.colors.text.secondary};
  text-align: center;
  line-height: 18px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  left: 0;
  bottom: 15px;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ClickableIconContainer = styled.div`
  cursor: pointer;
`;

export const SuccessTextBox = styled(TextBox)`
  top: 48.5%;
  gap: 7px;
`;

export const StyledSuccessGraphic = styled(SuccessGraphic)`
  position: absolute;
`;
