/**
 * Constant values for working with reusable styles.
 */

// TODO(carter): line heights
export const fontSizes = {
  mini: '.5rem',
  small: `${10 / 16.0}rem`,
  smallMedium: `${3 / 4.0}rem`,
  medium: `${14 / 16.0}rem`,
  semiBig: `${18 / 16.0}rem`,
  big: `${20 / 16.0}rem`,
  giant: `${24 / 16.0}rem`,
  jumbo: `${40 / 16.0}rem`,
};

export const fontWeights = {
  light: `300`,
  semiBold: `400`,
  bold: `600`,
  extraBold: `800`,
};

// Accent colors
const accentTeal = '#009BB5'; // Accent-Teal
const accentMint = '#3FB79C'; // Accent-Mint
const accentOrange = '#F16E00'; // Accent-Orange
const accentYellow = '#F7C700'; // Accent-Yellow
const accentRed = '#F56E60'; // Accent-Red
const accentGold = '#AD8D53'; // Accent-Gold

// Primary colors
const primaryBlue = '#003975'; // Primary-Blue // Default primary brand color
const primaryGreyDark = '#576C82'; // Primary-Grey-Dark // Buttons, text
const primaryGreyMediumDark = '#8799AB'; // Primary-Grey-Mid-Dark // Icons for nav
const primaryGreyMediumLight = '#CBD2DB'; // Primary-Grey-Mid-Light
const primaryGreyLight = '#E4E7ED'; // Primary-Grey-Light // Borders
const primaryWhite = '#F6F6F7'; // Primary-White

// Whites
const trueWhite = '#FFFFFF'; // True-white
const trueBlack = '#000000'; // True-black

// Export all the objects in one
export const colors = {
  accentTeal,
  accentMint,
  accentOrange,
  accentYellow,
  accentRed,
  accentGold,
  primaryBlue,
  primaryGreyDark,
  primaryGreyMediumDark,
  primaryGreyMediumLight,
  primaryGreyLight,
  primaryWhite,
  trueWhite,
  trueBlack,
  textGrey: '#8799AB',
  backgroundGrey: '#F0F0F4',
};

export const gradients = {
  gold: `linear-gradient(91.86deg, ${accentOrange} 1.57%, ${accentYellow} 98.43%)`,
};
