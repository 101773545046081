export enum RewardTier {
  Basic = 'Basic',
  Premium = 'Premium',
}
export interface CashbackSummary {
  basicTierRate: number;
  cashbackThisMonth: number;
  onboardingComplete: boolean;
  onboardingTransactionsCompleted: number;
  onboardingTransactionsThreshold: number;
  premiumTierRate: number;
  premiumTierSpendThreshold: number;
  purchasesThisMonth: number;
  rewardMonth: string;
  rewardTier: RewardTier;
  rewardedCategoryDescription: string;
  rewardedSpendLimit: number;
  spendLeftTillPremium?: number;
}
