import { useAddExternalAccount } from '@/services/useExternalAccount';
import { ReactComponent as CheckImage } from '@/shared/assets/check-image.svg';
import { BankAccountForm } from '@/shared/components/BankAccountForm';
import { useNavigate } from 'react-router-dom';
import { LinkingPageContainer, Title, TitleContainer } from './LinkBank.styled';

export default function LinkBankManuallyPage() {
  const navigate = useNavigate();
  const { mutateAsync: addExternalAccount } = useAddExternalAccount();

  return (
    <LinkingPageContainer>
      <TitleContainer>
        <Title>Enter your bank account information</Title>
      </TitleContainer>
      <CheckImage />
      <BankAccountForm
        submitButtonText="Save"
        onSubmit={async ({ accountNumber, routingNumber }) => {
          await addExternalAccount({ routingNumber, accountNumber });
          navigate('/link-bank/result');
        }}
      />
    </LinkingPageContainer>
  );
}
