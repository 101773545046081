/**
 * TODO(Carter): This is a monster of a Component. We should almost 100% break this up better.
 * Additionally, I don't love how many hooks we have to use here to keep track of the entire astra
 * state.
 */
import { useWorkerProfile } from '@/services/useWorkerProfile';
import { useState } from 'react';

import { Navigate, useNavigate } from 'react-router-dom';

import { useCreateInstantTransfer, useLoadAstra } from '@/services/useAstra';
import DividedCard from '@/shared/components/DividedCard';
import RadioButton from '@/shared/components/RadioButton';
import { currencyFormatter } from '@/shared/helpers';
import { Button, Spinner } from '@checkrx/pay-component-library';

import { trackAmplitudeEvent } from '@/shared/analytics';
import { AstraCardStatuses } from '@/shared/types';
import AstraCardApprovalNotice from './AstraStatuses';

import {
  ExternalAccountLink,
  LinkedExternalAccountsList,
} from '@/shared/components/ExternalAccounts';

import {
  BottomButtonContainer,
  CenteredTitle,
  DataContent,
  DataLabel,
  DestinationContainer,
  DisclaimerText,
  InstantTransferDisabledText,
  LinkedAccountsContainer,
  LinkedAccountTextContainer,
  RadioGroupContainer,
  Row,
  SingleLinkAccountContainer,
  SpeedContainer,
  StyledLink,
  TransferContainer,
  ViewContainer,
  WithdrawSummaryContainer,
} from './WithdrawalFlow.styled';

import { LoadingScreen } from '@/app/wrappers/Containers';
import { useBankingAccount } from '@/services/useBankingAccount';
import { useExternalAccounts } from '@/services/useExternalAccount';
import { useCreateWithdrawal } from '@/services/useWithdrawal';
import { useWithdrawalRequestAmount } from '@/services/useWithdrawalRequestAmount';
import { getWorkerACHLimits, getWorkerAstraConfig } from '@/shared/withdrawals';
import { Text } from '../dashboard/withdraw/WithdrawPage.styled';
import ErrorPage from '../errors/ErrorPage';

type TransferOption = 'sameDay' | 'instant' | 'standard' | '';
const DEFAULT_SAME_DAY_ACH_FEE = 0.5;

export default function ReviewWithdrawalPage() {
  // Use hooks to get all of the data we need from react query state!
  const navigate = useNavigate();
  trackAmplitudeEvent('Astra Enabled');

  const [selectedExternalAccountId, setSelectedExternalAccountId] = useState('');
  const { data: linkedExternalBankAccounts, isLoading: isLoadingExternalAccounts } =
    useExternalAccounts((externalAccounts) => {
      if (selectedExternalAccountId === '') {
        setSelectedExternalAccountId(externalAccounts[0]?.externalAccountId || '');
      }
    });

  const { data: bankingAccount, isLoading: isLoadingBankingAccount } = useBankingAccount();
  const [transferOption, setTransferOption] = useState<TransferOption>('');

  const { moreThanDailyWithdrawalLimit, moreThanMonthlyWithdrawalLimit } =
    getWorkerACHLimits(bankingAccount);

  const handleSetTransferOption = (opt: TransferOption) => {
    if (opt === 'instant') {
      astraOpen();
    }
    setTransferOption(opt);
  };

  // Fetch Feature Flags to see if we should be showing sameDay to customers
  const { data: workerProfile, isLoading: isLoadingFeatureFlags } = useWorkerProfile();
  const sameDayAchEnabled = workerProfile?.customer?.featureFlags?.sameDayAch?.enabled;
  const sameDayFeeAmount = workerProfile?.profile?.customSameDayACHFees ?? DEFAULT_SAME_DAY_ACH_FEE;

  const {
    loading: astraLoading,
    open: astraOpen,
    authorized: astraAuthorized,
    card: astraCard,
    refetch,
    error: astraError,
  } = useLoadAstra();

  const {
    mutate: createWithdrawal,
    isLoading: withdrawalIsLoading,
    isSuccess: withdrawalIsSuccess,
  } = useCreateWithdrawal();

  const {
    mutate: createInstantTransfer,
    isLoading: isLoadingInstantTransfer,
    isSuccess: instantTransferSucceeded,
  } = useCreateInstantTransfer();

  const { withdrawalRequestAmount } = useWithdrawalRequestAmount();
  const withdrawalAmount = withdrawalRequestAmount || '';

  if (
    isLoadingExternalAccounts ||
    isLoadingFeatureFlags ||
    withdrawalIsLoading ||
    isLoadingInstantTransfer ||
    astraLoading ||
    isLoadingBankingAccount
  ) {
    return (
      <LoadingScreen>
        <Spinner />
        <Text>Loading your withdrawals...</Text>
      </LoadingScreen>
    );
  }

  // On completed withdrawal
  if (withdrawalIsSuccess || instantTransferSucceeded) {
    return <Navigate to="/withdraw/result" />;
  }

  const amount = parseFloat(withdrawalAmount);
  const areACHOptionsDisabled =
    moreThanDailyWithdrawalLimit(amount) || moreThanMonthlyWithdrawalLimit(amount);

  const handleInstantTransferClick = async () => {
    // Get or create a user intent for the worker
    handleSetTransferOption('instant');
  };

  const handleOnClick = async () => {
    const currSelectedExternalAccount = linkedExternalBankAccounts?.find(
      (ea) => ea.externalAccountId === selectedExternalAccountId
    );
    if (transferOption === 'instant') {
      createInstantTransfer(amount);
    } else {
      if (!currSelectedExternalAccount) return;
      createWithdrawal({
        amount,
        bankId: currSelectedExternalAccount.externalAccountId,
        sameDay: transferOption === 'sameDay',
      });
    }
  };

  if (!workerProfile) return <ErrorPage />;

  const { perTransferLimit: instantTransferLimit, feesRate: instantTransferFeeRate } =
    getWorkerAstraConfig(workerProfile?.profile);

  const instantTransferLimitAsDollars = instantTransferLimit / 100;

  let estimatedArrival;
  let disclaimerText;
  let feeAmount;
  let totalAmount = Number(amount);
  const instantFee = amount * instantTransferFeeRate < 1;
  const BASE_FEE = 1;
  const ASTRA_MINIMUM_WITHDRAWAL = 5;

  if (transferOption === 'sameDay') {
    // We want to display 'Tomorrow' for Same-day ACH's ETA if it is past 2:50 PM EST
    const now = new Date();
    const isPast250pm =
      now.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
        timeZone: 'America/New_York',
      }) >= '14:50';
    estimatedArrival = isPast250pm ? 'Tomorrow' : 'Today';
    totalAmount = Number(amount);
    feeAmount = sameDayFeeAmount;

    // eslint-disable-next-line max-len
    disclaimerText = `By clicking Withdraw, you authorize Checkr Pay to initiate an electronic withdrawal
            to your linked bank account. If your withdrawal is submitted by 3PM EST, the transfer
            will be initiated on the same business day. Withdrawals submitted after 3PM EST, or
            on a weekend or holiday, are initiated on the next business day. Funds from same-day
            withdrawals typically become available on the business day in which they were
            initiated. However, your financial institution determines when funds are made
            available to you. Transfers may be subject to review for the protection of your
            account. See your deposit account agreement for details.`;
  } else if (transferOption === 'instant') {
    estimatedArrival = `Immediately`;

    feeAmount =
      instantTransferFeeRate === 0 ? 0 : instantFee ? BASE_FEE : amount * instantTransferFeeRate;

    disclaimerText = `By clicking Withdraw, you authorize Checkr Pay to initiate an electronic
    withdrawal to your linked debit card. The withdrawal will initiate immediately.Transfers
    may be subject to review for the protection of your account. See your deposit account agreement
    for details.`;
  } else {
    estimatedArrival = '2 - 3 business days';
    feeAmount = 0;

    // eslint-disable-next-line max-len
    disclaimerText = `By clicking Withdraw, you authorize Checkr Pay to initiate an electronic withdrawal
            to your linked bank account. If your withdrawal is submitted by 3PM EST, the transfer
            will be initiated on the same business day. Withdrawals submitted after 3PM EST, or
            on a weekend or holiday, are initiated on the next business day. Funds typically
            become available within 2-3 business days of when the transfer was initiated. However,
            your financial institution determines when funds are made available to you.
            Transfers may be subject to review for the protection of your account. See
            your deposit account agreement for details.`;
  }

  const astraCardApproved = astraCard?.status === AstraCardStatuses.Approved;

  // NOTE(Carter): This is a confusing conditional that assigns a boolean to transferMethod?
  const transferMethod =
    transferOption === 'instant'
      ? !!astraCard && astraAuthorized && astraCardApproved
      : (linkedExternalBankAccounts || []).length > 0;

  const feeText =
    instantTransferFeeRate === 0
      ? 'Instant, Free'
      : `Instant, ${
          instantFee ? '$1 fee' : `${Math.round(instantTransferFeeRate * 10000) / 100}% fee`
        }`;

  const renderBankLinking = () => {
    if (transferOption === 'instant') {
      if (!astraCard || !astraAuthorized) return null;
      return (
        <>
          <SingleLinkAccountContainer>
            <ExternalAccountLink
              bankName={astraCard.cardCompany}
              accountNumber={astraCard.lastFourDigits}
              ownerName={`${astraCard.firstName} ${astraCard.lastName}`}
              type="card"
              onDeletion={() => {
                refetch();
                handleSetTransferOption('');
              }}
            />
          </SingleLinkAccountContainer>
          {!astraCardApproved && <AstraCardApprovalNotice astraCard={astraCard} />}
        </>
      );
    }
    return (
      <>
        <LinkedAccountsContainer>
          <LinkedExternalAccountsList
            linkedAccounts={linkedExternalBankAccounts || []}
            onSelect={(v) => setSelectedExternalAccountId(v)}
            selectedId={selectedExternalAccountId}
          />
        </LinkedAccountsContainer>
        <Button text="Link new bank account" width="100%" onClick={() => navigate('/link-bank')} />
      </>
    );
  };

  const astraDisabledText = () => {
    if (totalAmount > instantTransferLimitAsDollars) {
      return `You may only withdraw up to $${instantTransferLimitAsDollars} with instant transfer`;
    }
    if (totalAmount < ASTRA_MINIMUM_WITHDRAWAL) {
      return `You must withdraw at least $${ASTRA_MINIMUM_WITHDRAWAL} to use instant transfer`;
    }
    if (astraError) {
      return 'Instant transfer not available at this time';
    }
    return null;
  };

  const withdrawDisabled =
    (transferOption !== 'instant' && (linkedExternalBankAccounts || []).length === 0) ||
    (transferOption === 'instant' && !astraCardApproved) ||
    isLoadingInstantTransfer ||
    transferOption === '';

  return (
    <ViewContainer>
      <SpeedContainer>
        <CenteredTitle>How fast would you like your money?</CenteredTitle>
        <RadioGroupContainer>
          <TransferContainer>
            <RadioButton
              selected={transferOption === 'instant'}
              onClick={handleInstantTransferClick}
              text="Fastest"
              subtext={feeText}
              disabled={
                totalAmount > instantTransferLimitAsDollars ||
                totalAmount < ASTRA_MINIMUM_WITHDRAWAL ||
                astraError
              }
            />
            {sameDayAchEnabled && (
              <RadioButton
                selected={transferOption === 'sameDay'}
                onClick={() => {
                  handleSetTransferOption('sameDay');
                }}
                text="Faster"
                disabled={areACHOptionsDisabled}
                subtext={`Next business day, ${
                  sameDayFeeAmount === 0
                    ? 'Free'
                    : `${currencyFormatter.format(sameDayFeeAmount)} USD`
                }`}
              />
            )}
            <RadioButton
              selected={transferOption === 'standard'}
              onClick={() => {
                handleSetTransferOption('standard');
              }}
              text="Standard"
              disabled={areACHOptionsDisabled}
              subtext="2-3 business days, Free"
            />
          </TransferContainer>
          <InstantTransferDisabledText>{astraDisabledText()}</InstantTransferDisabledText>
        </RadioGroupContainer>
      </SpeedContainer>
      {transferOption && (
        <DestinationContainer>
          <CenteredTitle>Select Your Destination</CenteredTitle>
          {renderBankLinking()}
        </DestinationContainer>
      )}
      {transferOption && transferMethod && (
        <LinkedAccountTextContainer>
          To remove your linked account,{' '}
          <StyledLink
            onClick={() => {
              navigate('/support');
            }}
          >
            please contact support.
          </StyledLink>
        </LinkedAccountTextContainer>
      )}
      {transferOption && transferMethod && (
        <WithdrawSummaryContainer>
          <CenteredTitle>Confirm Your Withdrawal</CenteredTitle>
          <DividedCard
            elements={[
              <Row key="total">
                <DataLabel>Total withdrawal:</DataLabel>
                <DataContent>{currencyFormatter.format(totalAmount)}</DataContent>
              </Row>,
              <Row key="fee">
                <DataLabel>Fee:</DataLabel>
                <DataContent>{currencyFormatter.format(feeAmount)}</DataContent>
              </Row>,
              <Row key="balance">
                <DataLabel>You will receive:</DataLabel>
                <DataContent>{currencyFormatter.format(totalAmount - feeAmount)}</DataContent>
              </Row>,
              <Row key="eta">
                <DataLabel>Estimated arrival:</DataLabel>
                <DataContent>{estimatedArrival}</DataContent>
              </Row>,
            ]}
          />
        </WithdrawSummaryContainer>
      )}
      <BottomButtonContainer>
        <DisclaimerText>{disclaimerText}</DisclaimerText>
        <Button
          width="100%"
          text={`Withdraw ${currencyFormatter.format(totalAmount)}`}
          colorVariant="brand"
          sizeVariant="big"
          onClick={handleOnClick}
          disabled={withdrawDisabled}
        />
      </BottomButtonContainer>
    </ViewContainer>
  );
}
