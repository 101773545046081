import { colors, fontSizes } from '@/shared/styles';
import styled from 'styled-components';

export const NoticeButton = styled.button<{ error?: boolean }>`
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  font-size: ${fontSizes.medium};
  width: 100%;
  ${(p) =>
    p.error
      ? `
    border: 1px solid ${colors.accentRed};
    color: ${colors.accentRed};
    `
      : `
    border: 1px solid ${colors.primaryGreyLight};
    `}

  &:hover,
  &:active {
    background-color: ${colors.primaryGreyLight};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;
