import { DateString } from './types';

export enum BankingApplicationStatus {
  AwaitingDocuments = 'AwaitingDocuments',
  PendingReview = 'PendingReview',
  Pending = 'Pending',
  Denied = 'Denied',
}

export enum RequiredDocumentType {
  AddressVerification = 'AddressVerification',
  IdDocument = 'IdDocument',
  IdDocumentBackSide = 'IdDocumentBackSide',
  Passport = 'Passport',
  SocialSecurityCard = 'SocialSecurityCard',
  SelfieVerification = 'SelfieVerification',
  General = 'General',
}

export interface WorkerProfileRequiredDocument {
  documentType: RequiredDocumentType;
  status: string;
  description: string;
  id?: string;
  name?: string;
  address?: {
    street?: string;
    street2?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    country?: string;
  };
}

export interface BankingApplication {
  applicationStatus: BankingApplicationStatus;
  id: string;
  jwtSubject: string;
  requiredDocuments: WorkerProfileRequiredDocument[];
  ssn: string;
}

export enum BankingAccountStatus {
  Open = 'Open',
  Closed = 'Closed',
  Frozen = 'Frozen',
}

export enum DepositProduct {
  Checking = 'checking',
}

export type PartialBankAccount = Pick<
  BankingAccount,
  | 'accountId'
  | 'accountStatus'
  | 'available'
  | 'balance'
  | 'depositProduct'
  | 'hold'
  | 'name'
  | 'userId'
  | 'pendingDirectDepositTotal'
>;

export interface BankingAccount {
  accountId: string;
  accountStatus: BankingAccountStatus;
  userId: string;
  depositProduct: DepositProduct;
  routingNumber: string;
  accountNumber: string;
  balance: number;
  available: number;
  name: string;
  hold: number;
  pendingDirectDepositTotal?: number;
  limits?: {
    id: string;
    ach: {
      limits: {
        dailyDebit: number;
        dailyCredit: number;
        monthlyDebit: number;
        monthlyCredit: number;
        dailyDebitSoft: number;
        monthlyDebitSoft: number;
      };
      totalsDaily: {
        debits: number;
        credits: number;
      };
      totalsMonthly: {
        debits: number;
        credits: number;
      };
    };
    bookTransfer: {
      limits: {
        dailyDebit: number;
        dailyCredit: number;
        monthlyDebit: number;
        monthlyCredit: number;
      };
      totalsDaily: {
        debits: number;
        credits: number;
      };
      totalsMonthly: {
        debits: number;
        credits: number;
      };
    };
    card: {
      limits: {
        dailyWithdrawal: number;
        dailyDeposit: number;
        dailyPurchase: number;
        dailyCardTransaction: number;
      };
      totalsDaily: {
        withdrawals: number;
        deposits: number;
        purchases: number;
        cardTransactions: number;
      };
    };
    checkDeposit: {
      limits: {
        daily: number;
        monthly: number;
        dailySoft: number;
        monthlySoft: number;
      };
      totalsDaily: number;
      totalsMonthly: number;
    };
    wire: {
      limits: {
        dailyTransfer: number;
        monthlyTransfer: number;
        dailyTransferSoft: number;
        monthlyTransferSoft: number;
      };
      totalsDaily: {
        transfers: number;
      };
      totalsMonthly: {
        transfers: number;
      };
    };
    checkPayment: {
      limits: {
        dailySentSoft: number;
        monthlySentSoft: number;
      };
      totalsDaily: {
        sent: number;
      };
      totalsMonthly: {
        sent: number;
      };
    };
  };
}

export enum BankingCardStatus {
  Inactive = 'Inactive',
  Active = 'Active',
  Stolen = 'Stolen',
  Lost = 'Lost',
  Frozen = 'Frozen',
  ClosedByCustomer = 'ClosedByCustomer',
  SuspectedFraud = 'SuspectedFraud',
}

export interface BankingCard {
  lastFour: string;
  expirationDate: DateString;
  cardId: string;
  cardStatus: BankingCardStatus;
  accountId: string;
  userId: string;
}
