/**
 * Transactions Graph
 *
 * TODO(Carter):
 *  - Throw error if data doesn't match bar count
 */
import styled from 'styled-components';

import { fontWeights } from '@/shared/styles';

import FillBar from '@/shared/components/FillBar';
import { NormalText } from '@/shared/components/Text.styled';

const GraphContainer = styled.div<{ height: string }>`
  width: 100%;
  height: ${(p) => p.height};
  min-height: ${(p) => p.height};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: -1;
`;

const Bar = styled.div`
  width: fit-content;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: -1;
`;

const MonthText = styled(NormalText)`
  margin-top: 10px;
  font-weight: ${fontWeights.light};
  color: ${(p) => p.theme.colors.text.primary};
`;

export default function PayoutGraph({
  height = '130px',
  showValue = false,
  legend = [],
  primaryValues = [],
  secondaryValues = [],
}: {
  height?: string;
  showValue?: boolean;
  legend?: Array<string>;
  primaryValues?: Array<number>;
  secondaryValues?: Array<number>;
}) {
  // We'll divide by the top value
  const maxVal = Math.max(Math.max(...primaryValues), Math.max(...secondaryValues)) || 1.0;

  return (
    <GraphContainer height={height}>
      {legend.map((title, idx) => {
        const primaryValue = primaryValues[idx] ?? 0;
        const secondaryValue = secondaryValues[idx] ?? 0;
        return (
          <Bar key={`graph-bar-${title}`}>
            <FillBar
              vertical
              length="80%"
              darkFillPercent={Math.round((primaryValue / maxVal) * 100.0)}
              lightFillPercent={Math.round((secondaryValue / maxVal) * 100.0)}
            />
            <MonthText>{title}</MonthText>
            {showValue ? <MonthText>{primaryValue}</MonthText> : null}
          </Bar>
        );
      })}
    </GraphContainer>
  );
}
