/**
 * A special fallback for routing errors, which are not caught by
 * our runtime BaseErrorFallback!
 *
 * They share an error page, and most of their functionality.
 * The most important piece of functionality here is 404 not-found
 * for bad or dead links, which will not be caught by our server,
 * but instead by our router!
 */

import AuthErrorPage from '@/pages/errors/AuthErrorPage';
import * as Sentry from '@sentry/react';

export default function RoutingError() {
  Sentry.captureException(new Error('Routing Error Found'));
  return <AuthErrorPage />;
}
