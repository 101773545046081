/**
 * API queries for payout methods.
 */
import { toast } from '@/shared/components/Toaster/Toaster';
import { PayoutMethod } from '@/shared/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import serverApi from './serverApi';

export type PayoutMethodAccount = {
  externalAccountId: string;
  userId: string;
  ownerName: string;
  ownerType: string;
  bank: string;
  routingNumber: string;
  accountNumber: string;
  accountType: string;
};

export const fetchPayoutMethod = async () => {
  const response = await serverApi.get<PayoutMethodAccount>(`/profile/payment_method`);
  return response.data;
};

export const useGetPayoutMethod = () => {
  return useQuery({
    queryKey: ['payoutMethod'],
    queryFn: () => fetchPayoutMethod(),
    useErrorBoundary: false,
  });
};

export async function postPayoutMethod(req: AddPayoutMethodReq) {
  let bankAccount;
  if (req?.plaidProcessorToken) {
    bankAccount = { plaidProcessorToken: req.plaidProcessorToken };
  }
  if (req?.accountNumber || req?.routingNumber) {
    bankAccount = { accountNumber: req.accountNumber, routingNumber: req.routingNumber };
  }

  const response = await serverApi.post('/profile/payment_method', {
    ...(bankAccount && { bankAccount }),
    payoutMethod: req.payoutMethod,
  });
  return response.data;
}

type AddPayoutMethodReq = {
  payoutMethod: PayoutMethod;
  accountNumber?: string;
  routingNumber?: string;
  plaidProcessorToken?: string;
};

export const useAddPayoutMethod = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: AddPayoutMethodReq) => postPayoutMethod(req),
    onSuccess: () => {
      toast({
        type: 'info',
        message: `Payout method changed`,
        duration: 2500,
      });
      return queryClient.invalidateQueries({ queryKey: ['workerProfile'] });
    },
    onError: () => {
      return queryClient.invalidateQueries({ queryKey: ['workerProfile'] });
    },
    useErrorBoundary: false,
  });
};
