import { ReactElement } from 'react';
import { ShortCircuitProps, useBankingShortCircuiter } from './useBankingShortCircuiter';

export const BankingShortCircuiter = ({
  children,
  hideIfNotOpen,
}: {
  children: ReactElement | ((props: ShortCircuitProps) => ReactElement);
  hideIfNotOpen?: boolean;
}) => {
  const { isBankingAccountOpen, status, shortCircuitFunction } = useBankingShortCircuiter();

  if (hideIfNotOpen && !isBankingAccountOpen) return null;

  return typeof children === 'function'
    ? children({
        isBankingAccountOpen,
        status,
        shortCircuitFunction,
      })
    : children;
};
