/**
 * Parent page for our entire onboarding flow. If you hit just `onboarding/` it'll redirect you
 * to where you should be based on your worker profile state
 */
import { Navigate } from 'react-router-dom';

import { LoadingScreen } from '@/app/wrappers/Containers';
import { useWorkerProfile } from '@/services/useWorkerProfile';
import { NormalText } from '@/shared/components/Text.styled';
import { Consent, ConsentDocumentIds } from '@/shared/types';
import { Spinner } from '@checkrx/pay-component-library';

const REQUIRED_CONSENT_DOCS = [
  ConsentDocumentIds.PowerOfAttorney,
  ConsentDocumentIds.TermsOfService,
  ConsentDocumentIds.SponsorBankDisclosure,
  // TODO(Carter): I've set a reminder to enable this after we've had
  // This deployed long enough not to cause issues for people
  // activating pre-deploy. One week should be enough time
  // ConsentDocumentIds.AstraDisclosure,
];

export default function OnboardingRedirector() {
  const { data: workerProfile, isLoading } = useWorkerProfile();

  // Render a spinner if we're loading
  if (isLoading) {
    return (
      <LoadingScreen>
        <Spinner />
        <NormalText>Loading your worker data.</NormalText>
      </LoadingScreen>
    );
  }

  const consents = workerProfile?.consents;
  const profile = workerProfile?.profile;

  if (profile) {
    // Check if the worker has completed each step in reverse order.
    if (
      REQUIRED_CONSENT_DOCS.every((docId) =>
        (consents || []).map((c: Consent) => c.documentId).includes(docId)
      )
    ) {
      return <Navigate to="/onboarding/activation-status" replace={true} />;
    }
    if (profile?.hasConfirmedPII) {
      return <Navigate to="/onboarding/consents" replace={true} />;
    }
    if (profile?.completedOnboarding) {
      return <Navigate to="/onboarding/confirm" replace={true} />;
    }
    return <Navigate to="/onboarding/tutorial" replace={true} />;
  }

  throw new Error(`Unexpected Onboarding Status`);
}
