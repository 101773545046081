/**
 * usePlaid – Hooks encapsulating our Plaid integration
 *
 * This is responsible for handling our Plaid related requests in addition to
 * posting the linked account through Plaid to the pay-server backend.
 */
import { toast } from '@/shared/components/Toaster/Toaster';
import { useEffect, useState } from 'react';
import { PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';
import serverApi from './serverApi';

async function fetchPlaidLinkToken() {
  const response = await serverApi.get('/banking/plaid/link_token');
  return response.data?.linkToken;
}

async function postExchangePublicToken(publicToken: string) {
  const response = await serverApi.post('banking/plaid/exchange_link_token', {
    publicToken,
  });
  return response.data.accessToken;
}

async function postCreateProcessorToken({
  accessToken,
  accountId,
}: {
  accessToken: string;
  accountId: string;
}) {
  const response = await serverApi.post('banking/plaid/create_processor_token', {
    accessToken,
    accountId,
  });
  return response.data.processorToken;
}

/**
 * Function that gets called when a worker successfully links an item.
 * When this happens, Plaid link generates a temp publicToken, which we use
 * to exchange for an accessToken from Plaid (through our pay-server API). When we get an
 * access token we then use that token, in addition to information about the account the
 * worker is trying to link to get a processorToken from Plaid. We then pass that
 * processorToken to Unit to link the worker's bank account.
 * Read more information here: https://plaid.com/docs/auth/partnerships/unit/
 */
export const usePlaid = ({ onSuccess }: { onSuccess?: (plaidProcessorToken: string) => void }) => {
  const [linkToken, setLinkToken] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const onPlaidSuccess = async (
    publicToken: string,
    metadata: { account_id?: string } & unknown
  ) => {
    setLoading(true);
    try {
      const accountId = (metadata || {})?.account_id ?? '';
      const accessToken = await postExchangePublicToken(publicToken);
      const processorToken = await postCreateProcessorToken({ accessToken, accountId });
      onSuccess?.(processorToken);
    } catch (err) {
      toast({ type: 'error', message: 'There was an error connecting with Plaid', duration: 4500 });
    } finally {
      setLoading(false);
    }
  };

  const plaidLinkConfig = {
    onSuccess: onPlaidSuccess,
    token: linkToken,
  };

  const { open, ready } = usePlaidLink(plaidLinkConfig as PlaidLinkOptions);

  const handleOpenPlaid = async () => {
    const _linkToken = await fetchPlaidLinkToken();
    setLinkToken(_linkToken);
  };

  useEffect(() => {
    if (linkToken && ready) {
      open();
    }
  }, [ready, linkToken, open]);

  return {
    loading,
    open: handleOpenPlaid,
  };
};
