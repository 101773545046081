import LoadingDots from '@/shared/components/LoadingDots';
import { fontSizes, fontWeights } from '@/shared/styles';
import { Tooltip } from '@checkrx/pay-component-library';
import styled from 'styled-components';
import { formatCentsAsCurrency } from '../helpers';
import { SubTitleText, TooltipTriggerText } from './Text.styled';

const CenterSpinner = styled.div`
  display: flex;
  width: 100%;
  min-height: 100px;
  align-items: center;
  justify-content: center;
  height: fit-content;
  margin-top: -40px;
`;

export const BalanceAmount = styled.h1<{ colorVariant: string }>`
  color: ${(p) =>
    p.colorVariant === 'dark' ? p.theme.colors.text.primary : p.theme.colors.text.accent};
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.giant};
`;

const Bigger = styled.span`
  font-size: ${fontSizes.jumbo};
`;

export function Balance({
  amount,
  directionIndicator = '',
  colorVariant = 'dark',
}: {
  amount: string;
  directionIndicator?: '' | '+' | '-';
  colorVariant?: 'dark' | 'light';
}) {
  const [dollars, cents] = amount.split('.');
  return (
    <BalanceAmount colorVariant={colorVariant}>
      {directionIndicator && `${directionIndicator} `}$ <Bigger>{dollars}</Bigger>.{cents}
    </BalanceAmount>
  );
}

const BalanceContainer = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 10px;
`;

const PendingAuthorizations = styled.div`
  color: ${(p) => p.theme.colors.text.secondary};
  font-size: ${fontSizes.smallMedium};
`;

const PendingAuthorizationsTooltipContent = styled.div`
  white-space: break-spaces;
`;

export function BalanceDisplay({
  amount = '$0.00',
  hold = 0,
  isLoading = false,
  title = 'Available Balance',
}: {
  amount?: string;
  balances?: number;
  hold?: number;
  isLoading?: boolean;
  title?: string;
}) {
  const formattedHold = formatCentsAsCurrency(hold);
  const tip = (
    <PendingAuthorizationsTooltipContent>
      Pending transactions are deducted from the available balance. They are not final and usually
      settle in 3-5 days.
    </PendingAuthorizationsTooltipContent>
  );

  return (
    <BalanceContainer>
      <SubTitleText>{title}</SubTitleText>
      {isLoading ? (
        <CenterSpinner>
          <LoadingDots size="20px" />
        </CenterSpinner>
      ) : (
        <>
          <Balance amount={amount} />
          {hold > 0 && (
            <Tooltip content={tip} direction="top" width="350px;">
              <PendingAuthorizations>
                Pending transactions: <TooltipTriggerText>{formattedHold}</TooltipTriggerText>
              </PendingAuthorizations>
            </Tooltip>
          )}
        </>
      )}
    </BalanceContainer>
  );
}
