/**
 * An Error page with a nice graphic and refresh button.
 */

import { useNavigate } from 'react-router-dom';

import error from '@/shared/assets/error-graphic.svg';
import { Text } from '@/shared/components/Graphics.styled';
import { LogoOnlyNavBar } from '@/shared/components/PageNavBar';
import { useQueryClient } from '@tanstack/react-query';

import { AppContainer } from '@/app/wrappers/Containers';
import Legalese from '@/shared/components/Legalese';

import { ContentBox, ErrorTitle, Graphic, LegaleseContent, StyledButton } from './ErrorPage.styled';

interface ErrorPageProps {
  resetErrorBoundary?: () => void;
}

export default function ErrorPage({ resetErrorBoundary }: ErrorPageProps) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return (
    <AppContainer>
      <div>
        <LogoOnlyNavBar />
        <Graphic src={error} />
        <ContentBox>
          <ErrorTitle>There was an error</ErrorTitle>
          <Text>
            The server encountered an internal error and was not able to complete your request
          </Text>
          <StyledButton
            className="error-page-refresh-btn"
            width="110px"
            sizeVariant="small"
            colorVariant="brand"
            icon="refresh-cw"
            text="Refresh"
            onClick={async () => {
              // Invalidate all the cached queries
              await queryClient.invalidateQueries();

              if (resetErrorBoundary) {
                resetErrorBoundary();
              }
              navigate('/');
            }}
          />
          <LegaleseContent>
            <Legalese />
          </LegaleseContent>
        </ContentBox>
      </div>
    </AppContainer>
  );
}
