import styled from 'styled-components';

import { colors } from '@/shared/styles';

import { ReactComponent as CardIcon } from '@/shared/assets/credit-card.svg';
import { ReactComponent as DirectDeposit } from '@/shared/assets/direct-deposit.svg';
import { ReactComponent as WithdrawalIcon } from '@/shared/assets/money-insert.svg';
import { ReactComponent as PayoutIcon } from '@/shared/assets/money-plus-line.svg';
import { ReactComponent as RightArrow } from '@/shared/assets/right-arrow.svg';
import { TransactionDirection, TransactionType } from '@/shared/types';

const Background = styled.div<{ size: string; grey?: boolean }>`
  width: ${(p) => p.size};
  min-width: ${(p) => p.size};
  height: ${(p) => p.size};
  min-height: ${(p) => p.size};
  background: ${(p) => (p.grey ? colors.primaryGreyLight : p.theme.colors.graphics.primary)};
  color: ${(p) => (p.grey ? colors.primaryGreyMediumDark : colors.trueWhite)};
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function TransactionSymbol({
  type,
  direction,
  size = '40px',
}: {
  type: TransactionType;
  direction: TransactionDirection;
  size?: string;
}) {
  return (
    <Background size={size} grey={direction === TransactionDirection.Debit}>
      <Icon type={type} direction={direction} />
    </Background>
  );
}

function Icon({ type, direction }: { type: TransactionType; direction: TransactionDirection }) {
  switch (type) {
    case TransactionType.Ach:
      // Payout from customer or ACH transfer
      return direction === TransactionDirection.Credit ? <DirectDeposit /> : <WithdrawalIcon />;
    case TransactionType.Card:
      return <CardIcon />;
    case TransactionType.Atm:
      return <WithdrawalIcon />;
    case TransactionType.BookTransfer:
      return <PayoutIcon />;
    case TransactionType.Reward:
      return <PayoutIcon />;
    default:
      return direction === TransactionDirection.Credit ? (
        <PayoutIcon />
      ) : (
        <RightArrow width="16" height="13" />
      );
  }
}
