import styled from 'styled-components';
import { colors } from '../styles';

export const NavBarHeight = '70px';

export const StyledNavBar = styled.div`
  height: ${NavBarHeight};
  min-height: ${NavBarHeight};
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${colors.primaryGreyMediumDark};
  border-bottom: 1px solid ${colors.primaryGreyLight};
`;

export const SideContainer = styled.div`
  width: 10%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const CenterContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
`;
