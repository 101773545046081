/**
 * Higher-Order component that ensures we have a tempAuthToken.
 *
 * If Auth Changes (via the useAuth functions setAuthToken and removeAuthToken) everything
 * underneath this React Component will re-render.
 */
import { ReactNode } from 'react';

import { useAuth } from '@/services/useAuth';

export const TempAuthTokenGuard = ({ children }: { children: ReactNode }) => {
  const { tempAuthToken } = useAuth();
  if (!tempAuthToken) {
    throw new Error('Runtime Error: No Temp Auth Token Passed in');
  }
  return <>{children}</>;
};
