import serverApi from '@/services/serverApi';
import { RequiredDocumentType } from '@/shared/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export interface ManualReviewDocumentUpload {
  file: File;
  documentType: keyof typeof RequiredDocumentType;
}

async function postManualReviewDocuments(documents: ManualReviewDocumentUpload[]) {
  const requests = documents.map((document) => {
    const formData = new FormData();
    formData.set('document', document.file, document.file.name);
    formData.set('documentType', document.documentType);
    return serverApi.post('/banking/upload/manual_review_document', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  });
  return await Promise.all(requests);
}

export const useUploadManualReviewDocuments = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (documents: ManualReviewDocumentUpload[]) => postManualReviewDocuments(documents),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['banking', 'application'] });
    },
    useErrorBoundary: false,
    retry: false,
  });
};
