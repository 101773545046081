/**
 * Bank Account page
 */

import { LoadingScreen } from '@/app/wrappers/Containers';
import { useBankingAccount } from '@/services/useBankingAccount';
import { trackAmplitudeEvent } from '@/shared/analytics';
import { ReactComponent as CopyIcon } from '@/shared/assets/copy-default.svg';
import { ReactComponent as EyeIcon } from '@/shared/assets/eye.svg';
import { ReactComponent as QuestionIcon } from '@/shared/assets/question-circle.svg';
import DividedCard from '@/shared/components/DividedCard';
import { ClickableIconContainer } from '@/shared/components/Graphics.styled';
import { SubTitleText, TooltipContent } from '@/shared/components/Text.styled';
import { useCopyToClipboard } from '@/shared/hooks/useCopyToClipboard';
import { Spinner, Tooltip } from '@checkrx/pay-component-library';
import { useState } from 'react';
import { BankAccountAndPersonalInformationView } from '../SupportPage.styled';
import {
  DataContent,
  DataContentGroup,
  DataLabel,
  RoutingNumberTooltipIconContainer,
  Row,
} from './BankingInformation.styled';

export default function BankingInformation() {
  const { data: bankingData, isLoading: isBankingLoading } = useBankingAccount();
  const [_, copy] = useCopyToClipboard();
  trackAmplitudeEvent('Bank Account Information Viewed');

  const { accountNumber, accountStatus, name, routingNumber } = bankingData || {};
  const [isAccountNumberMasked, setIsAccountNumberMasked] = useState(true);

  const copyToClipboard = (value?: string) => {
    if (!value) {
      return;
    }

    trackAmplitudeEvent('Bank Account Information Copied');
    copy(value);
  };

  const accountNumberText = isAccountNumberMasked
    ? accountNumber?.replace(/\d(?=\d{4})/g, '•') // just the last 4
    : accountNumber;

  const bank = 'Thread Bank';
  const routingNumberTip = (
    <TooltipContent>
      This routing number can only be used for direct deposits and ACH transactions
    </TooltipContent>
  );

  if (isBankingLoading) {
    return (
      <LoadingScreen>
        <Spinner />
      </LoadingScreen>
    );
  }

  return (
    <BankAccountAndPersonalInformationView>
      <SubTitleText>
        Below is the information for your
        <br /> Checkr Pay bank account.
      </SubTitleText>

      <DividedCard
        elements={[
          <Row key="account-number">
            <DataLabel>Account Number</DataLabel>
            <DataContentGroup>
              <DataContent>{accountNumberText}</DataContent>
              {isAccountNumberMasked ? (
                <ClickableIconContainer title="Reveal">
                  <EyeIcon
                    onClick={() => {
                      setIsAccountNumberMasked(false);
                    }}
                  />
                </ClickableIconContainer>
              ) : (
                <ClickableIconContainer title="Copy">
                  <CopyIcon onClick={() => copyToClipboard(accountNumber)} />
                </ClickableIconContainer>
              )}
            </DataContentGroup>
          </Row>,
          <Row key="routing-number">
            <DataLabel>
              Routing Number
              <Tooltip content={routingNumberTip}>
                <RoutingNumberTooltipIconContainer>
                  <QuestionIcon />
                </RoutingNumberTooltipIconContainer>
              </Tooltip>
            </DataLabel>
            <DataContentGroup>
              <DataContent>{routingNumber}</DataContent>
              <ClickableIconContainer title="Copy">
                <CopyIcon onClick={() => copyToClipboard(routingNumber)} />
              </ClickableIconContainer>
            </DataContentGroup>
          </Row>,
          <Row key="name">
            <DataLabel>Name</DataLabel>
            <DataContentGroup>
              <DataContent>{name}</DataContent>
              <ClickableIconContainer title="Copy">
                <CopyIcon onClick={() => copyToClipboard(name)} />
              </ClickableIconContainer>
            </DataContentGroup>
          </Row>,
          <Row key="bank">
            <DataLabel>Partner Bank</DataLabel>
            <DataContentGroup>
              <DataContent>{bank}</DataContent>
            </DataContentGroup>
          </Row>,
          <Row key="status">
            <DataLabel>Status</DataLabel>
            <DataContentGroup>
              <DataContent>{accountStatus}</DataContent>
            </DataContentGroup>
          </Row>,
        ]}
      />
    </BankAccountAndPersonalInformationView>
  );
}
