/**
 * Data fetching for a workers's banking account
 */
import { BankingAccount } from '@/shared/types';
import { useMutation, useQuery } from '@tanstack/react-query';
import serverApi from './serverApi';

// Refetch the customer profile every minute (EG to auto-log out)
const REFETCH_AFTER_SECONDS = 120;

/**
 * Get the full workers deposit account information from the pay-server. This will include
 * their account and routing numbers
 */
async function fetchBankingAccount() {
  const response = await serverApi.get<{
    account: BankingAccount;
  }>('/banking/account/sensitive');
  return response.data?.account;
}

export const useBankingAccount = () => {
  return useQuery({
    queryKey: ['banking', 'account'],
    queryFn: () => fetchBankingAccount(),
    refetchInterval: 1000 * REFETCH_AFTER_SECONDS,
    refetchIntervalInBackground: true, // Refetch in background too
  });
};

async function fetchPartialBankingAccount() {
  const response = await serverApi.get('/banking/account');
  return response.data?.account;
}

export const usePartialBankingAccount = <TData = BankingAccount>(
  select?: (data: BankingAccount) => TData
) => {
  return useQuery({
    queryKey: ['banking', 'account'],
    queryFn: () => fetchPartialBankingAccount(),
    select,
  });
};

export const postReopenBankingAccount = async () => {
  const response = await serverApi.post('/banking/account/reopen');
  return response;
};

export const useReopenBankingAccount = () => {
  return useMutation({
    mutationFn: async () => postReopenBankingAccount(),
    useErrorBoundary: false,
  });
};
