import styled from 'styled-components';

import { colors, fontWeights } from '@/shared/styles';

import { MiniText, NormalText } from '@/shared/components/Text.styled';

export const SplashScreenView = styled.div`
  // Not scrollable - Blobs are bigger than the screen
  overflow: hidden;
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: ${(p) => p.theme.colors.backgrounds.app};
`;

export const FlexContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-bottom: 15px;
`;

export const CTAContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  gap: 15px;
  color: ${(p) => p.theme.colors.text.secondary};
  text-align: center;
`;

export const PoweredByText = styled(NormalText)`
  color: ${colors.primaryGreyMediumDark};
  font-weight: ${fontWeights.light};
`;

export const DisclaimerText = styled(MiniText)`
  color: ${(p) => p.theme.colors.text.secondary};
  font-weight: ${fontWeights.light};
  width: 85%;
`;
