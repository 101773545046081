import { toast } from '@/shared/components/Toaster/Toaster';
import { WorkerBankingStatus } from '@/shared/types';
import { useNavigate } from 'react-router-dom';
import { useWorkerFromProfile } from '../useWorkerProfile';

export type ShortCircuitProps = {
  isBankingAccountOpen: boolean;
  status: WorkerBankingStatus;
  shortCircuitFunction: (callback: () => void) => void;
};

export const useBankingShortCircuiter = (): ShortCircuitProps => {
  const navigate = useNavigate();
  const { data: worker } = useWorkerFromProfile();
  const isBankingAccountOpen = () => {
    if (!worker?.bankingPrimaryAccount?.status) {
      return true;
    }
    if (worker?.bankingPrimaryAccount?.status === 'Closed') {
      return false;
    }
    return true;
  };

  const shortCircuitFunction = (callback: () => void) => {
    if (!isBankingAccountOpen()) {
      toast({
        message: 'Your Checkr Pay bank account is Closed.',
        type: 'action',
        duration: 5000,
        actionText: 'Tap here to reopen.',
        onAction: () => navigate('/bank-account-information'),
      });
      return;
    }
    callback();
  };

  return {
    isBankingAccountOpen: isBankingAccountOpen(),
    status: worker?.bankingPrimaryAccount?.status ?? 'Open',
    shortCircuitFunction,
  };
};
