import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 640px;
  margin: auto;
  padding: 40px 16px;
`;

const Heading = styled.h1`
  font-size: 40px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 16px;
`;

const Text = styled.p`
  text-align: center;
  margin-bottom: 2em;
`;

const FieldGroup = styled.div`
  margin: 0 0 24px 0;
`;

const Label = styled.label`
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  font-size: 16px;
  padding: 12px 16px;
  background: whitesmoke;
  border-radius: 4px;
  border: 1px solid gray;
`;

const Table = styled.table<{ disabled?: boolean }>`
  width: 100%;
  font-size: 14px;
  text-align: left;
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  `}

  @media (min-width: 48em) {
    font-size: 16px;
  }

  th {
    font-weight: 700;
    font-size: 14px;
    padding: 4px 8px;
    border-bottom: 1px solid lightgray;

    @media (min-width: 48em) {
      padding: 4px 16px;
    }
  }

  td {
    padding: 12px 8px;
    border-bottom: 1px solid whitesmoke;
    cursor: pointer;

    @media (min-width: 48em) {
      padding: 12px 16px;
    }
  }

  tr:hover td {
    background: whitesmoke;
  }
`;

type Worker = {
  id: string;
  status: string;
  profile: {
    email: string;
  };
};

const API_HOST = new URL(process.env.REACT_APP_PAY_SERVER_CUSTOMER_URL || 'http://localhost').host;

export default function DevLoginPage() {
  const [status, setStatus] = useState<'idle' | 'submitting'>('idle');
  const [searchTerm, setSearchTerm] = useState('');

  const { data: workers, error } = useQuery({
    queryFn: () => axios.get<Worker[]>('/api/local/workers').then((r) => r.data),
    queryKey: ['dev:workers'],
    useErrorBoundary: false,
  });

  const filteredWorkers = workers?.filter((worker) =>
    worker.profile.email.includes(searchTerm.toLowerCase())
  );

  const handleSelectWorker = async (workerId: string) => {
    if (status === 'submitting') {
      return;
    }

    setStatus('submitting');

    try {
      const token = await axios
        .post<string>(`/api/local/workers/${workerId}/authenticate`)
        .then((r) => r.data);
      setStatus('idle');
      window.location.href = `/?tempAuthToken=${token}`;
    } catch (err) {
      window.alert((err as Error).message);
      setStatus('idle');
    }
  };

  return (
    <Container>
      <Heading>Sign in</Heading>
      <Text>
        Select a worker to sign in.
        <br />
        Environment:{' '}
        <strong>
          <code>{API_HOST}</code>
        </strong>
      </Text>

      {error ? (
        <Text>
          Error fetching workers. Make sure DEV_TOOLS_CUSTOMER_ACCESS_KEY and
          DEV_TOOLS_CUSTOMER_SECRET_KEY are set correctly.
          <br />
          <code>{(error as Error).message}</code>
        </Text>
      ) : null}

      <FieldGroup>
        <Label htmlFor="searchTerm">Search by email</Label>
        <Input
          id="searchTerm"
          name="searchTerm"
          type="search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          autoComplete="off"
          autoFocus
          data-1p-ignore
        />
      </FieldGroup>

      <Table disabled={status === 'submitting'}>
        <thead>
          <tr>
            <th>Email</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredWorkers?.map((worker) => (
            <tr
              key={worker.id}
              tabIndex={0}
              onClick={() => handleSelectWorker(worker.id)}
              onKeyDown={(e) => {
                if (e.code === 'Enter' || e.code === 'Space') {
                  handleSelectWorker(worker.id);
                }
              }}
            >
              <td>{worker.profile.email}</td>
              <td>{worker.status}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}
