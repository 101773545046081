/**
 * A reusable Transaction List Component that can be used to
 * render transactions in different contexts within a divided card.
 */
import styled from 'styled-components';

import { colors } from '@/shared/styles';
import { Transaction } from '@/shared/types';

import DividedCard from '@/shared/components/DividedCard';
import { SubTitleText } from '@/shared/components/Text.styled';

import TransactionListItem from './TransactionListItem';

const TransactionGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const TransactionTitle = styled(SubTitleText)`
  margin-left: 15px;
`;

const EmptyText = styled.p`
  color: ${colors.textGrey};
  margin-left: 40px;
`;

export default function TransactionList({
  transactions = [],
  title = 'Transactions',
}: {
  transactions?: Array<Transaction>;
  title?: string;
}) {
  return (
    <TransactionGroup>
      <TransactionTitle>{title}</TransactionTitle>
      {transactions.length > 0 ? (
        <DividedCard
          elements={transactions.map((txn: Transaction) => {
            return <TransactionListItem txn={txn} key={`txn-link-${txn.transactionId}`} />;
          })}
        />
      ) : (
        <EmptyText>&mdash;</EmptyText>
      )}
    </TransactionGroup>
  );
}
