import { ErrorText } from '@/shared/components/Text.styled';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ReactElement } from 'react';

export const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
`;

const ErrorContainer = styled(ErrorText)`
  padding: 20px;
`;

const CardRejectedError = () => {
  const navigate = useNavigate();
  return (
    <ErrorContainer>
      We ran into some issues adding your card. Instant withdrawals are not available at this time.
      Please click here to contact{' '}
      <StyledLink
        onClick={() => {
          navigate('/support');
        }}
      >
        Checkr Pay support
      </StyledLink>
      .
    </ErrorContainer>
  );
};

const PushToCardNotSupportedError = () => {
  return (
    <ErrorContainer>
      This card does not support instant transfers. Please use a different card.
    </ErrorContainer>
  );
};

const InvalidCardTypeError = () => {
  return (
    <ErrorContainer>
      This card type does not support instant transfers. Please ensure that this is a debit or a
      prepaid card.
    </ErrorContainer>
  );
};

const CardPendingApprovalNotice = () => {
  const navigate = useNavigate();
  return (
    <ErrorContainer>
      Thanks for linking your debit card! Our team has to review a couple of things before we can
      enable instant withdrawals. Hold tight - we will reach out if we need any additional
      information. If you have any questions, please click here to contact{' '}
      <StyledLink
        onClick={() => {
          navigate('/support');
        }}
      >
        Checkr Pay support
      </StyledLink>
      .
    </ErrorContainer>
  );
};

// Map a string to a functional component
const STATUS_COMPONENTS: { [key: string]: () => ReactElement } = {
  flagged: CardPendingApprovalNotice,
  invalid_card_type: InvalidCardTypeError,
  pending: CardPendingApprovalNotice,
  rejected: CardRejectedError,
  push_to_card_not_supported: PushToCardNotSupportedError,
};

const AstraCardApprovalNotice = ({ astraCard }: { astraCard: unknown }) => {
  // TS is stilly sometimes with external data
  const { status } = (astraCard || { status: '' }) as { status: string };
  const Component = STATUS_COMPONENTS[status];

  if (!Component) {
    return null;
  }

  return <Component />;
};

AstraCardApprovalNotice.propTypes = {
  astraCard: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default AstraCardApprovalNotice;
