export enum ExternalAccountTypes {
  Savings = 'Savings',
  Checking = 'Checking',
}

export enum ExternalAccountOwnerType {
  Business = 'Business',
  Person = 'Person',
  Unknown = 'Unknown',
}

export interface ExternalAccount {
  externalAccountId: string;
  userId: string;
  bank?: string;
  routingNumber: string;
  accountNumber: string;
  accountType: ExternalAccountTypes;
  ownerName: string;
  ownerType?: ExternalAccountOwnerType;
}
