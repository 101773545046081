import styled from 'styled-components';

import { NormalText } from '@/shared/components/Text.styled';
import { fontWeights } from '@/shared/styles';

export const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  gap: 20px;
  align-items: center;
  justify-content: start;
  width: 100%;
`;

export const SearchGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  gap: 20px;
`;

export const DateText = styled(NormalText)`
  font-weight: ${fontWeights.light};
`;

export const SummaryText = styled(NormalText)`
  font-weight: ${fontWeights.light};
  text-align: center;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
`;

export const ClearingTimeStatusContainer = styled(StatusContainer)`
  align-items: flex-start;
`;

export const StatusLabel = styled(NormalText)`
  font-weight: ${fontWeights.bold};
  color: ${(p) => p.theme.colors.text.primary};
  max-width: 70%;
`;

export const StatusText = styled(NormalText)`
  font-weight: ${fontWeights.bold};
  color: ${(p) => p.theme.colors.text.primary};
  background: ${(p) => p.theme.colors.buttons.accent};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding: 0px 15px;
`;
