/**
 * Higher-Order component that Absorbs its children, rendering them in a box.
 *
 * When you use the useLoading() hook, it'll look for the nearest LoadingGuard
 * and change the CSS in it such that its components are blurred out and it renders a
 * spinner.
 */
import { LoadingScope } from '@/shared/types';
import { Spinner } from '@checkrx/pay-component-library';
import { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  max-width: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  z-index: 1;
`;

export const LoadingGuard = ({
  children,
  loadingScope,
}: {
  children: ReactNode;
  loadingScope: LoadingScope;
}) => {
  return (
    <>
      <Container className={`content-loader-${loadingScope}`}>
        <Overlay>
          <Spinner />
        </Overlay>
      </Container>
      {children}
    </>
  );
};
