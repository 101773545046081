/**
 * The view for a user to review and confirm the PII we have on record
 */
import { trackAmplitudeEvent } from '@/shared/analytics';
import styled from 'styled-components';

import DividedCard from '@/shared/components/DividedCard';
import { NormalText, TitleText, WarningText } from '@/shared/components/Text.styled';
import { Button, Check, Spinner } from '@checkrx/pay-component-library';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { SUPPORT_UPDATE_PERSONAL_INFO_PAGE } from '@/shared/helpers';

import { useCustomerFromProfile, useWorkerProfile } from '@/services/useWorkerProfile';

import { useConfirmWorkerProfile } from '@/services/onboarding';
import {
  ConfirmProfileContainer,
  ContactSupportText,
  DataLabel,
  Group,
  ManualPIIRequestText,
  OptionalInputRow,
  PiiRow,
  SupportText,
} from '../shared/confirm-pages.styled';

import { SpinnerRow } from '@/shared/components/SpinnerRow';
import { useExternalLinkPoster } from '@/shared/hooks/useExternalLinkPoster';
import { ConfirmProfileInput } from '../shared/ConfirmProfileInput';
import { disclaimerText } from '../shared/disclaimer-text';
import { Address, AddressForm } from './AddressForm';
import { ErrorMessages } from './ErrorMessages';
import { PersonalDetails, PersonalDetailsForm } from './PersonalDetailsForm';
import { parseFullName } from './utils';
import { addressValidationSchema, personalDetailsValidationSchema } from './validations';

const AddressHelpText = styled(NormalText)`
  margin-top: 15px;
  text-align: center;
`;

export default function ConfirmPIIPage() {
  const navigate = useNavigate();
  const { data: workerProfile, isLoading: _isLoading } = useWorkerProfile();
  const sendExternalLink = useExternalLinkPoster({
    link: SUPPORT_UPDATE_PERSONAL_INFO_PAGE,
    prependText: 'Update Personal Information',
  });
  const {
    contactEmail = '',
    contactPhone = '',
    contactAddress,
    checkrCorePII,
    status: workerProfileStatus,
  } = workerProfile?.profile || {};
  const {
    firstName = '',
    middleName = '',
    lastName = '',
    dateOfBirth,
    taxpayerIdentificationNumber = '',
  } = checkrCorePII || {};

  const [personalDetails, setPersonaDetails] = useState<PersonalDetails>({
    fullName:
      firstName && firstName.length > 0 && lastName && lastName.length > 0
        ? `${firstName}${middleName ? ' ' + middleName + ' ' : ' '}${lastName}`
        : '',
    dob: dateOfBirth ? dayjs(dateOfBirth).format('YYYY-MM-DD') : '',
    email: contactEmail ?? '',
    ssn: taxpayerIdentificationNumber ?? '',
  });

  const [physicalAddress, setPhysicalAddress] = useState<Address>(contactAddress as Address);
  const [mailingAddress, setMailingAddress] = useState<Address>({
    street: '',
    street2: '',
    city: '',
    state: '',
    postalCode: '',
  });
  const [preferredName, setPreferredName] = useState<string>('');
  const [hasMailingAddress, setHasMailingAddress] = useState(false);
  const [hasPreferredName, setHasPreferredName] = useState(false);

  const {
    mutate: runPostConfirmWorkerProfile,
    isError,
    isLoading,
    isSuccess,
  } = useConfirmWorkerProfile();

  const { data: customer } = useCustomerFromProfile();
  const jitEnabled = customer?.featureFlags?.justInTimeActivation?.enabled;

  const hasMultiplePayoutMethodsEnabled =
    workerProfile?.profile?.additionalPayoutMethods?.achDirectDeposit?.enabled ||
    workerProfile?.profile?.additionalPayoutMethods?.pushToCardDirectDeposit?.enabled ||
    customer?.payoutMethods?.achDirectDeposit?.enabled ||
    customer?.payoutMethods?.pushToCardDirectDeposit?.enabled;

  const handleOnClickContinue = () => {
    const formattedDob = dayjs(personalDetails.dob).format('MM-DD-YYYY');
    const { firstName, middleName, lastName } = parseFullName(personalDetails.fullName);
    const personalDetailsCopy = { ...personalDetailsValidation.value };
    delete personalDetailsCopy.fullName;

    if (contactEmail) {
      delete personalDetailsCopy.email;
    }
    if (taxpayerIdentificationNumber) {
      delete personalDetailsCopy.ssn;
      delete personalDetailsCopy.taxpayerIdentificationNumber;
    }

    const profileUpdate = {
      ...personalDetailsCopy,
      dob: formattedDob,
      firstName,
      ...(middleName !== '' ? { middleName } : {}),
      lastName,
      ...(hasPreferredName ? { preferredName } : {}),
      ...(hasMailingAddress ? { mailingAddress: { ...mailingAddress, country: 'US' } } : {}),
      address: { ...physicalAddressValidation.value, country: 'US' },
      workerType: 'individual',
    };

    runPostConfirmWorkerProfile(profileUpdate);
  };

  if (isSuccess) {
    trackAmplitudeEvent('Personal Details Confirmed');
    return <Navigate to="/onboarding/consents" />;
  }

  const physicalAddressValidation = addressValidationSchema.validate(physicalAddress, {
    abortEarly: false,
  });
  const mailingAddressValidation = addressValidationSchema.validate(mailingAddress, {
    abortEarly: false,
  });

  const transformedPersonalDetails = () => {
    if (taxpayerIdentificationNumber) {
      // destructure to create object without ssn
      //eslint-disable-next-line
      const { ssn, ...rest } = personalDetails;
      const personalDetailsWithoutSsn = rest;
      return { ...personalDetailsWithoutSsn, taxpayerIdentificationNumber };
    }
    return personalDetails;
  };

  const personalDetailsValidation = personalDetailsValidationSchema.validate(
    transformedPersonalDetails(),
    {
      abortEarly: false,
    }
  );

  const getDisableContinue = () => {
    const isPhysicalInvalid =
      !physicalAddressValidation.value ||
      (physicalAddressValidation?.error?.details?.length ?? 0) > 0;

    const isMailingInvalid = hasMailingAddress
      ? !mailingAddressValidation.value ||
        (mailingAddressValidation?.error?.details?.length ?? 0) > 0
      : false;

    const isPreferredNameInvalid = hasPreferredName && preferredName === '';

    const isPersonalDetailsInvalid = (personalDetailsValidation?.error?.details?.length ?? 0) > 0;

    return (
      isPhysicalInvalid || isMailingInvalid || isPersonalDetailsInvalid || isPreferredNameInvalid
    );
  };

  const pageContent = (
    <DividedCard
      elements={[
        <Group key="profile-contact-info">
          <OptionalInputRow>
            <Check
              checked={hasPreferredName}
              onClick={() => {
                setHasPreferredName(!hasPreferredName);
              }}
            />
            <DataLabel> I have a preferred name.</DataLabel>
          </OptionalInputRow>
          {hasPreferredName ? (
            <PiiRow>
              <DataLabel>Preferred name</DataLabel>
              <ConfirmProfileInput
                value={preferredName}
                onChange={(v) => setPreferredName(v)}
                placeholder="Preferred name"
                type="text"
              />
            </PiiRow>
          ) : null}
          <PersonalDetailsForm
            value={personalDetails}
            onChange={(v) => setPersonaDetails(v)}
            contactPhone={contactPhone}
            taxPayerId={taxpayerIdentificationNumber}
          />
        </Group>,
        <Group key="physical-address">
          <AddressForm value={physicalAddress} onChange={setPhysicalAddress} />
          <PiiRow key="address-help">
            <AddressHelpText>
              Your Checkr Pay card will be sent to the address above unless you add an additional
              mailing address.
              <br />
              <WarningText>
                P.O. Boxes are not allowed as primary addresses and must be added as a mailing
                address.
              </WarningText>
            </AddressHelpText>
          </PiiRow>
          <SupportText>
            The above information was provided by your gig platform. Something look incorrect?{' '}
            <ContactSupportText onClick={sendExternalLink}>Contact support.</ContactSupportText>
          </SupportText>
        </Group>,
        <Group key="mailing-address">
          <OptionalInputRow>
            <Check
              checked={hasMailingAddress}
              onClick={() => {
                setHasMailingAddress(!hasMailingAddress);
              }}
            />
            <DataLabel>
              I have a mailing address that is different from my primary address.
            </DataLabel>
          </OptionalInputRow>
          {hasMailingAddress ? (
            <AddressForm value={mailingAddress} onChange={setMailingAddress} />
          ) : (
            <div style={{ display: 'none' }} />
          )}
        </Group>,
      ]}
    />
  );

  return (
    <ConfirmProfileContainer>
      <TitleText>Activate Checkr Pay</TitleText>
      {workerProfileStatus === 'require_pii' ? (
        <ManualPIIRequestText>
          <NormalText>
            Looks like we need some information from you in order to activate your account
          </NormalText>
        </ManualPIIRequestText>
      ) : null}
      {hasMultiplePayoutMethodsEnabled && jitEnabled && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 12,
            marginBottom: 16,
            textAlign: 'center',
          }}
        >
          <NormalText>
            Are you a business with a Federal EIN? Create your account as a business below.
          </NormalText>
          <Button
            text="Continue as a business"
            width="100%"
            onClick={() => navigate('/onboarding/confirm-business')}
          />
        </div>
      )}
      {pageContent}
      {isError && <ErrorMessages />}
      {disclaimerText}
      <SpinnerRow>
        <Button
          className="profile-confirm-btn"
          width="100%"
          colorVariant="brand"
          text="Continue"
          sizeVariant="big"
          onClick={handleOnClickContinue}
          disabled={getDisableContinue()}
        />
        {isLoading ? (
          <div
            style={{
              marginRight: '-30px',
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          >
            <Spinner size="30px" />
          </div>
        ) : (
          <div />
        )}
      </SpinnerRow>
    </ConfirmProfileContainer>
  );
}
