/**
 * Worker-Profile-related DataFetching
 *
 * TODO(Carter): The biggest piece of tech debt here is that we fetch everything we need
 * (multiple sub-types) in one endpoint. A High-leverage tech debt fix up here will be to
 * break this into multiple smaller API calls with their own reactQuery Hooks!
 */
import {
  Consent,
  Customer,
  FeatureFlagMap,
  ProfileStatus,
  Worker,
  WorkerProfile,
} from '@/shared/types';
import { useQuery } from '@tanstack/react-query';
import serverApi from './serverApi';

// Refetch the customer profile every minute (EG to auto-log out)
const REFETCH_AFTER_SECONDS = 120;

async function fetchWorkerProfile() {
  const response = await serverApi.get<WorkerProfile>('/profile');
  return response.data;
}

export const useWorkerProfile = <TData = WorkerProfile>({
  select,
  useErrorBoundary = true,
}: {
  select?: (data: WorkerProfile) => TData;
  useErrorBoundary?: boolean;
} = {}) => {
  return useQuery({
    queryKey: ['workerProfile'],
    queryFn: () => fetchWorkerProfile(),
    refetchInterval: 1000 * REFETCH_AFTER_SECONDS,
    refetchIntervalInBackground: true, // Refetch in background too
    useErrorBoundary,
    select,
  });
};

// NOTE(Carter): Selectors on WorkerProfile will be important while we don't have
// this API call broken up yet.
const selectWorkerProfileIsVerified = (profile: WorkerProfile): boolean => profile.verified;
export const useWorkerProfileIsVerified = () =>
  useWorkerProfile({ select: selectWorkerProfileIsVerified });

const selectWorkerProfileIsActive = (profile: WorkerProfile): boolean =>
  profile?.profile?.status === ProfileStatus.Active ||
  profile?.profile?.status === ProfileStatus.Onboarded ||
  profile?.profile.status === ProfileStatus.PendingClosure;
export const useWorkerProfileIsActive = () =>
  useWorkerProfile({ select: selectWorkerProfileIsActive });

const selectWorkerProfileIsSampleAccount = (profile: WorkerProfile): boolean =>
  profile?.profile?.status === ProfileStatus.Onboarded;
export const useWorkerProfileIsSampleAccount = () =>
  useWorkerProfile({ select: selectWorkerProfileIsSampleAccount });

const selectWorkersCustomer = (profile: WorkerProfile): Customer | undefined => profile?.customer;
export const useCustomerFromProfile = () => useWorkerProfile({ select: selectWorkersCustomer });

const selectWorkerFromProfile = (profile: WorkerProfile): Worker | undefined => profile?.worker;
export const useWorkerFromProfile = () => useWorkerProfile({ select: selectWorkerFromProfile });

const selectConsentsFromProfile = (profile: WorkerProfile): Array<Consent> | undefined =>
  profile?.consents;
export const useConsentsFromProfile = () => useWorkerProfile({ select: selectConsentsFromProfile });

const selectFeatureFlagsFromProfile = (
  profile: WorkerProfile
): { customerFlags?: FeatureFlagMap; workerFlags?: FeatureFlagMap } => {
  return {
    workerFlags: profile?.worker?.featureFlags,
    customerFlags: profile?.customer?.featureFlags,
  };
};

export const useFeatureFlagsFromProfile = () =>
  useWorkerProfile({ select: selectFeatureFlagsFromProfile });
