import styled from 'styled-components';
import { colors, fontSizes } from '../styles';

export const Badge = styled.div<{ dark?: boolean }>`
  font-size: ${fontSizes.smallMedium};
  background: ${(props) => (props.dark ? colors.trueBlack : colors.primaryGreyLight)};
  padding: 2px 5px;
  color: ${(props) => (props.dark ? colors.trueWhite : colors.trueBlack)};
  border-radius: 2px;
`;
