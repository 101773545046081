import LoadingDots from '@/shared/components/LoadingDots';
import PayoutGraph from '@/shared/components/PayoutGraph';
import { TitleText } from '@/shared/components/Text.styled';
import { formatCentsAsCurrency } from '@/shared/helpers';
import { groupTransactionsByMonth } from '@/shared/lib/transaction-helpers';
import { fontSizes } from '@/shared/styles';
import { Transaction } from '@/shared/types';
import { Dropdown } from '@checkrx/pay-component-library';
import styled from 'styled-components';

const CenterSpinner = styled.div`
  display: flex;
  width: 100%;
  min-height: 100px;
  align-items: center;
  justify-content: center;
  height: fit-content;
`;

const ControlsContainer = styled.div`
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
`;

const GraphContainer = styled.div<{ width: string }>`
  padding: 0px 15px;
  width: ${(p) => p.width};
  min-height: 130px;
  margin: auto;
`;

const TotalText = styled(TitleText)`
  font-size: ${fontSizes.big};
`;

interface PayoutGraphWidgetProps {
  allPayouts: Transaction[];
  isPendingTransactions: boolean;
  monthsBack: number;
  setMonthsBack: (monthsBack: number) => void;
}

export default function PayoutGraphWidget({
  allPayouts,
  isPendingTransactions,
  monthsBack,
  setMonthsBack,
}: PayoutGraphWidgetProps) {
  const customerPayouts = allPayouts.filter(({ summary }) =>
    // TODO(Carter): Is this right??? No shot, right?
    summary.includes('Gigbolt')
  );

  const customerPayoutsByMonth = groupTransactionsByMonth(customerPayouts, monthsBack).reverse();
  const allCreditsByMonth = groupTransactionsByMonth(allPayouts, monthsBack).reverse();

  const payoutsTotal = allCreditsByMonth
    .map(({ transactions }) => transactions.reduce((prev, { amount }) => prev + amount, 0))
    .reduce((prev, cur) => prev + cur, 0);

  return (
    <>
      <ControlsContainer>
        <Dropdown
          options={[
            { label: 'Last 6 Months', value: '6' },
            { label: 'Last 3 Months', value: '3' },
            { label: 'Last 2 Months', value: '2' },
            { label: 'This Month', value: '1' },
          ]}
          onSelect={(option) => setMonthsBack(Number(option.value))}
        />
        <TotalText>+ {formatCentsAsCurrency(payoutsTotal)}</TotalText>
      </ControlsContainer>
      <GraphContainer width={`${(monthsBack / 6.0) * 100}%`}>
        {isPendingTransactions ? (
          <CenterSpinner>
            <LoadingDots size="10px" />
          </CenterSpinner>
        ) : (
          <PayoutGraph
            legend={customerPayoutsByMonth.map(({ month }) => month)}
            primaryValues={customerPayoutsByMonth.map(({ transactions }) =>
              transactions.reduce((prev, { amount }) => prev + amount, 0)
            )}
            secondaryValues={allCreditsByMonth.map(({ transactions }) =>
              transactions.reduce((prev, { amount }) => prev + amount, 0)
            )}
          />
        )}
      </GraphContainer>
    </>
  );
}
