// Brand logo, load from Customer preferences if available
import { useCustomerTheme } from '@/services/useCustomerTheme';
import { ReactComponent as CheckrPayLogo } from '@/shared/assets/checkr-wallet-color.svg';
import styled from 'styled-components';

const CustomerLogo = styled.img`
  width: ${(p) => p.theme.images?.logo?.width || '100%'};
  height: ${(p) => p.theme.images?.logo?.height || 'auto'};
`;

function BrandLogo({ className }: { className?: string }) {
  const { theme } = useCustomerTheme();
  const logoSrc = theme.images?.logo?.url;

  if (logoSrc) {
    return <CustomerLogo className={className} src={logoSrc} alt="Brand logo" />;
  } else {
    return <CheckrPayLogo className={className} />;
  }
}

export default BrandLogo;
