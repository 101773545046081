import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { SmallMediumText, TitleText } from '@/shared/components/Text.styled';
import TransactionSymbol from '@/shared/components/TransactionSymbol';
import { formatCentsAsCurrency } from '@/shared/helpers';
import { colors, fontSizes } from '@/shared/styles';
import { PayoutMethod, Transaction, TransactionDirection, TransactionStatus } from '@/shared/types';

const TransactionItemButton = styled.button`
  /* Size */
  width: 100%;
  height: fit-content;
  min-height: 40px;
  padding-block: unset;
  padding-inline: unset;

  /* Container properties */
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 15px;
  cursor: pointer;
  background-color: transparent;
  color: ${colors.primaryGreyMediumDark};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const LinkDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 5px;
`;

const AmountContainer = styled.div`
  width: fit-content;
  min-width: fit-content;
`;

const TransactionText = styled(TitleText)`
  font-size: ${fontSizes.medium};
`;

const DateText = styled(SmallMediumText)`
  color: ${(p) => p.theme.colors.text.secondary};
`;

const Description = styled(TransactionText)`
  overflow: hidden;
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
`;

const StatusMessage = styled.div`
  font-size: ${fontSizes.smallMedium};
  background: ${colors.primaryGreyLight};
  border-radius: 5px;
  padding: 2px 5px;
  color: ${colors.trueBlack};
  display: inline-block;
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FeeBadge = styled.div`
  background-color: #ffd700;
  color: black;
  padding: 2px 5px;
  border-radius: 4px;
  font-size: ${fontSizes.smallMedium};
`;

function getTransactionStatusMessage(transaction: Transaction) {
  switch (transaction.transactionStatus) {
    case TransactionStatus.Authorized:
      return 'Card Authorization Hold';
    case TransactionStatus.Pending:
      if (transaction.metadata?.payoutMethod === PayoutMethod.ACHDirectDeposit) {
        return 'Pending - Direct Deposit';
      } else {
        return 'Pending';
      }
    case TransactionStatus.Returned:
      return 'Returned';
  }
}

interface TransactionListItemProps {
  className?: string;
  txn: Transaction;
}

export default function TransactionListItem({ className, txn }: TransactionListItemProps) {
  const navigate = useNavigate();

  const payoutMethodFee = Number(txn.metadata?.payoutMethodFeeCents ?? 0);
  const formattedAmount = formatCentsAsCurrency(txn.amount - payoutMethodFee);
  const amountDirection = txn.direction === TransactionDirection.Credit ? '+' : '-';
  const statusMessage = getTransactionStatusMessage(txn);
  const text =
    (txn?.metadata?.annotation || '').length > 0 ? txn?.metadata?.annotation : txn.summary;

  return (
    <TransactionItemButton
      className={className}
      type="button"
      onClick={() => {
        navigate(`/transactions/details/${txn.transactionId}`);
      }}
    >
      <TransactionSymbol type={txn.transactionType} direction={txn.direction} />
      <ContentContainer>
        <LinkDescription>
          <TitleRow>
            <Description title={text}>{text}</Description>
          </TitleRow>
          <AmountContainer>
            <TransactionText>
              {amountDirection} {formattedAmount}
            </TransactionText>
          </AmountContainer>
        </LinkDescription>
        <BottomRow>
          <DateText>{dayjs(txn.createdAt).format('dddd, MMM D')}</DateText>
          {payoutMethodFee > 0 ? (
            <FeeBadge>{formatCentsAsCurrency(payoutMethodFee)} fee applied</FeeBadge>
          ) : (
            <div />
          )}
        </BottomRow>
        {!!statusMessage && <StatusMessage>{statusMessage}</StatusMessage>}
      </ContentContainer>
    </TransactionItemButton>
  );
}
