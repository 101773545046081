import { TabWrapper } from '@/app/wrappers/Containers';
import PageNavBar from '@/shared/components/PageNavBar';
import { Outlet } from 'react-router-dom';

export default function PayStubsLayout() {
  return (
    <TabWrapper>
      <PageNavBar title="View or download pay stub" />
      <Outlet />
    </TabWrapper>
  );
}
