import { useCustomerTheme } from '@/services/useCustomerTheme';
import { useWorkerProfile } from '@/services/useWorkerProfile';
import { trackAmplitudeEvent } from '@/shared/analytics';
import { ReactComponent as CheckrPayLogo } from '@/shared/assets/checkr-wallet-color.svg';
import { ReactComponent as CheckrWallet } from '@/shared/assets/checkr-wallet-greyscale.svg';
import { Bold } from '@/shared/components/Text.styled';
import { getWorkerContactMethods, getWorkerContacts } from '@/shared/helpers';
import { MfaMethods } from '@/shared/types';
import { Button } from '@checkrx/pay-component-library';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  CTAContainer,
  DisclaimerText,
  FlexContainer,
  PoweredByText,
  SplashScreenView,
} from './SplashScreen.styled';

const CustomerSplashScreenImg = styled.img`
  padding: 0 32px;
  width: ${(p) => p.theme.images?.onboarding?.splashScreen?.width || '100%'};
  height: ${(p) => p.theme.images?.onboarding?.splashScreen?.height || 'auto'};
`;

export default function SplashScreen() {
  const {
    data: workerProfile,
    isLoading: _profileIsLoading,
    isSuccess: _profileIsLoaded,
  } = useWorkerProfile();

  const navigate = useNavigate();

  const customer = workerProfile?.customer;
  const profile = workerProfile?.profile;

  const mfaMethod = customer?.preferences?.mfaMethod ?? MfaMethods.Email;
  const customerName = customer?.humanReadableName;

  const phone = profile?.contactPhone ?? '';
  const email = profile?.contactEmail ?? '';

  const { primaryContact: workerPrimaryContact } = getWorkerContacts(mfaMethod, phone, email);
  const { primaryContactMethod } = getWorkerContactMethods(mfaMethod);

  trackAmplitudeEvent('Begin Setup Page Viewed');
  const onClickForward: () => void = () => {
    navigate('/verification');
  };

  return (
    <SplashScreenView>
      <FlexContainer>
        <div></div>
        <SplashScreenImage />

        <CTAContainer>
          <PoweredByText>Powered by</PoweredByText>
          <CheckrWallet />
          <DisclaimerText>
            By clicking Begin Setup, you agree to receive
            {mfaMethod === 'sms' ? ' SMS' : ' email'} messages from Checkr Pay about your account at
            the {primaryContactMethod} you provided when applying to {customerName} (
            <Bold>{workerPrimaryContact}</Bold>).
          </DisclaimerText>
          <Button
            className="splash-screen-begin-setup-btn"
            text="Begin Setup"
            iconRight
            icon="arrow-right"
            width="100%"
            colorVariant="brand"
            sizeVariant="big"
            onClick={onClickForward}
          />
        </CTAContainer>
      </FlexContainer>
    </SplashScreenView>
  );
}

function SplashScreenImage() {
  const { theme } = useCustomerTheme();
  const splashScreenImageSrc = theme.images?.onboarding?.splashScreen?.url;

  if (splashScreenImageSrc) {
    return <CustomerSplashScreenImg src={splashScreenImageSrc} alt="Brand logo" />;
  } else {
    return <CheckrPayLogo />;
  }
}
