import { DateString } from '@/shared/types';
import { useState } from 'react';
import { DataContent, DataLabel, PiiRow } from '../shared/confirm-pages.styled';
import { ConfirmProfileInput, ConfirmProfileInputProps } from '../shared/ConfirmProfileInput';
import { businessDetailsValidationSchema } from './validations';

type InputProps = ConfirmProfileInputProps & {
  isValue?: boolean;
  label: string;
};

export type BusinessDetails = {
  businessName: string;
  formationDate: DateString;
  ein: string;
  entityType: string;
};

interface BusinessDetailsProps {
  value: BusinessDetails;
  onChange: (businessDetails: BusinessDetails) => void;
}

export function BusinessDetails({ value, onChange }: BusinessDetailsProps) {
  const [businessDetails, setBusinessDetails] = useState(value);

  const handleChange = (key: keyof BusinessDetails, v: string) => {
    const businessDetailsCopy = { ...businessDetails };
    businessDetailsCopy[key] = v;
    setBusinessDetails(businessDetailsCopy);
    onChange(businessDetailsCopy);
  };

  const validations = businessDetailsValidationSchema.validate(value, {
    abortEarly: false,
  });

  const getErrorState = (key: keyof typeof businessDetails) => {
    const inputErrorIdx = validations?.error?.details.findIndex((d) => key === d.path[0]) as number;
    if (inputErrorIdx === -1) return {};

    return {
      validation: () => !validations.error?.details[inputErrorIdx],
      errorText: validations.error?.details[inputErrorIdx]?.message,
    };
  };

  const inputs: InputProps[] = [
    {
      label: 'Business name:',
      value: businessDetails.businessName,
      onChange: (v: string) => handleChange('businessName', v),
      placeholder: 'Business name',
      ...getErrorState('businessName'),
      className: 'business-confirm-businessName',
      fullviewCaptureEnabled: true,
      type: 'text',
      key: 'businessName',
    },
    {
      label: 'Formation Date:',
      value: businessDetails.formationDate,
      onChange: (v: string) => handleChange('formationDate', v),
      placeholder: 'Formation date',
      ...getErrorState('formationDate'),
      className: 'business-confirm-formationDate',
      fullviewCaptureEnabled: true,
      type: 'date',
      key: 'formationDate',
    },
    {
      label: 'EIN:',
      value: businessDetails.ein,
      onChange: (v: string) => handleChange('ein', v),
      placeholder: 'Federal EIN',
      maxLength: 10,
      fullviewCaptureEnabled: false,
      format: (v) => {
        let val = v.replace(/\D/g, '');
        val = val.replace(/^(\d{2})(\d{1,2})/, '$1-$2');
        val = val.replace(/^(\d{2})-(\d{7})(.+)/, '$1-$2');
        v = val.substring(0, 10);
        return val;
      },
      ...getErrorState('ein'),
      className: 'business-confirm-ein',
      type: 'text',
      key: 'ein',
    },
    {
      label: 'Entity type:',
      value: businessDetails.entityType,
      onChange: (v: string) => handleChange('entityType', v),
      placeholder: 'Search Entity Type',
      type: 'dropdown',
      ...getErrorState('entityType'),
      className: 'business-confirm-entityType',
      fullviewCaptureEnabled: true,
      options: [
        { label: 'Sole Prop', value: 'sole_proprietorship' },
        { label: 'Partnership', value: 'partnership' },
        { label: 'Corporation', value: 'corporation' },
        { label: 'S Corp', value: 's_corporation' },
        { label: 'LLC', value: 'llc' },
        { label: 'Nonprofit', value: 'nonprofit' },
        { label: 'Cooperative', value: 'cooperative' },
      ],
      key: 'entityType',
    },
  ];

  return (
    <>
      {inputs.map((input) => {
        if (input.isValue) {
          return (
            <PiiRow key={input.label}>
              <DataLabel>{input.label}</DataLabel>
              <DataContent className={input.fullviewCaptureEnabled ? undefined : 'fullview-hide'}>
                {input.value}
              </DataContent>
            </PiiRow>
          );
        }
        return (
          <PiiRow key={input.label}>
            <DataLabel>{input.label}</DataLabel>
            <ConfirmProfileInput {...(input as ConfirmProfileInputProps)} />
          </PiiRow>
        );
      })}
    </>
  );
}
