import { MfaMethods, WorkerContactMethods } from '@/shared/types';

/**
 * Utility helper function to format numbers nicely as currency with the appropriate
 * currency symbol, comma's and rounding (if applicable).
 *
 * In the future of we want to use the system locale for the currency, we can replace
 * 'en-US' with 'undefined'. See further explanation here:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#locale_identification_and_negotiation
 *
 * If we want to round to whole numbers, we can use these options:
 * minimumFractionDigits: 0, (will print 2500.10 as $2,500.1)
 * maximumFractionDigits: 0, (causes 2500.99 to be printed as $2,501)
 */
export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

interface FormatCentsAsCurrencyOptions {
  showCents?: boolean;
  skipCurrencySymbol?: boolean;
}

export const formatCentsAsCurrency = (
  amountCents?: number,
  options?: FormatCentsAsCurrencyOptions
) => {
  // Explicit check instead of falsy check to allow for 0
  if (amountCents === undefined) {
    return '$0.00';
  }

  const showCents = options?.showCents === undefined ? true : options.showCents;
  const amountDollars = amountCents / TO_DOLLAR_DIVISOR;
  let formatter;

  if (showCents) {
    formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else {
    formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  let value = formatter.format(amountDollars);

  if (options?.skipCurrencySymbol) {
    value = value.replace('$', '').trim();
  }

  return value;
};

export const formatFractionAsPercent = (number: number) => {
  return `${number * 100.0}%`;
};

/**
 * Date formatter utility function. This helps format dates from looking
 * like: "2022-10-31T00:26:26.684Z" to "Mon, Oct 31 2022". Eventually we can pass in the options
 * as parameters in case we want to display dates differently in different parts of the aplication
 * @param dateString - zero hour offset date string
 * @returns {string} - formatted UTC date
 */
export const dateFormatter = (dateString: string) => {
  return new Date(dateString).toLocaleString('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

export const getWorkerContactMethods = (customerMfaPref: MfaMethods) => {
  let primaryContactMethod = 'Primary Factor';
  let secondaryContactMethod = 'Secondary Factor';

  if (customerMfaPref === MfaMethods.Sms) {
    primaryContactMethod = WorkerContactMethods.PhoneNumber;
    secondaryContactMethod = WorkerContactMethods.Email;
  } else if (customerMfaPref === MfaMethods.Email) {
    primaryContactMethod = WorkerContactMethods.Email;
    secondaryContactMethod = WorkerContactMethods.PhoneNumber;
  }
  return { primaryContactMethod, secondaryContactMethod };
};

/**
 * Helper function to count the number of decimals in a number
 */
export const countDecimal = (num: number) => {
  return num % 1 === 0 ? 0 : `${num}`.length - `${num}`.lastIndexOf('.') - 1;
};

export const getWorkerContacts = (
  customerMfaPref: MfaMethods,
  phoneNumber: string,
  email: string
) => {
  const primaryContact = customerMfaPref === MfaMethods.Sms ? phoneNumber : email;
  const secondaryContact = customerMfaPref === MfaMethods.Sms ? email : phoneNumber;

  return { primaryContact, secondaryContact };
};

export const maskContact = (contact: string, type: WorkerContactMethods) => {
  if (type === WorkerContactMethods.Email) {
    return contact.replace(/^(.)(.*)(.@.*)$/, (_, a, b, c) => a + b.replace(/./g, '*') + c);
  }

  if (type === WorkerContactMethods.PhoneNumber) {
    // Format the number as xxx-xxx-xxxx
    let formattedPhoneNumber;
    let last4OfPhoneNumber;
    // wrap in a try catch in case anything goes wrong with trying to split or slice the
    //  phoneNumber. We can just return the actual number rather than failing
    try {
      formattedPhoneNumber = contact.slice(2).replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      last4OfPhoneNumber = formattedPhoneNumber.split('-').slice(-1);
      return `xxx-xxx-${last4OfPhoneNumber}`;
    } catch (e) {
      return contact;
    }
  }
  return contact;
};

/**
 * @deprecated Prefer a formatting function like `formatCentsAsCurrency()`.
 */
export const TO_DOLLAR_DIVISOR = 100;

/**
 * Helper function to check if app is running in WebView or regular browser
 */
export const isUiWebView = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return (
    /(iPhone|iPod|iPad).*AppleWebKit(?!.*Version)/i.test(userAgent) ||
    /android.*webkit/i.test(userAgent)
  );
};

// This isn't very accurate, but it can help us detect the very basic cases of an address being a
// PO Boxes and allow us to warn a worker
export const addressMightBePoBox = (streetAddress: string) => {
  // eslint-disable-next-line max-len
  return /^ *((#\d+)|((box|bin)[-. /\\]?\d+)|(.*p[ .]? ?(o|0)[-. /\\]? *-?((box|bin)|b|(#|n|num|number)?\d+))|(p(ost|ostal)? *(o(ff(ice)?)?)? *((box|bin)|b)? *(#|n|num|number)*\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(#|n|num|number)? *\d+|(#|n|num|number) *\d+)/i.test(
    streetAddress
  );
};

// Direct links to support pages
export const SUPPORT_HOME_PAGE = 'https://support.checkrpay.com';
export const SUPPORT_PAGE_SUBMIT_REQUEST = 'https://checkrpay.zendesk.com/hc/en-us/requests/new';
export const SUPPORT_CARD_MANAGEMENT_PAGE =
  'https://checkrpay.zendesk.com/hc/en-us/sections/10911050080148-Card-Management';
export const SUPPORT_TAX_PAGE =
  'https://checkrpay.zendesk.com/hc/en-us/categories/10864232803988-Taxes';
export const SUPPORT_UPDATE_PERSONAL_INFO_PAGE =
  'https://checkrpay.zendesk.com/hc/en-us/articles/10713135921812-How-can-I-update-my-personal-information-including-address-contact-information-';
export const SUPPORT_LEGAL_CENTER_PAGE =
  'https://checkrpay.zendesk.com/hc/en-us/articles/13166782299796-Checkr-Pay-Legal-Center';
export const SUPPORT_APPLE_WALLET_PAGE =
  'https://support.checkrpay.com/hc/en-us/articles/10712762986132-Can-I-add-my-Visa-Business-Debit-Card-to-Apple-or-Google-Wallet-';
export const SUPPORT_REWARDS_PAGE =
  'https://support.checkrpay.com/hc/en-us/articles/17914256324116-Does-my-card-have-rewards-';
export const WITHDRAWAL_PAGE =
  'https://support.checkrpay.com/hc/en-us/articles/10713043890324-When-will-my-withdrawal-process-';
export const KEEPER_TAX_PARTNERSHIP_LINK = 'https://www.keepertax.com/partner/checkr-pay';
