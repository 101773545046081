import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { trackAmplitudeEvent } from '@/shared/analytics';
import { ReactComponent as VerificationGraphic } from '@/shared/assets/verification-graphic.svg';
import NumberInput from '@/shared/components/NumberInput';
import { getWorkerContactMethods, getWorkerContacts } from '@/shared/helpers';
import { fontWeights } from '@/shared/styles';
import { Button, Spinner } from '@checkrx/pay-component-library';

import { useCustomerTheme } from '@/services/useCustomerTheme';
import { useCheckVerificationCode, useRequestVerification } from '@/services/useVerification';
import { useWorkerProfile } from '@/services/useWorkerProfile';
import { SpinnerRow } from '@/shared/components/SpinnerRow';
import { ErrorText, TitleText } from '@/shared/components/Text.styled';
import { MfaMethods, ProfileStatus } from '@/shared/types';
import {
  CodeControls,
  DisabledLink,
  MfaButtonContainer,
  MfaVerificationContainer,
  MfaViewContainer,
  NumberRow,
  StyledLink,
  Text,
} from './VerificationPage.styled';

const CustomerVerificationGraphic = styled.img`
  flex-shrink: 0;
  width: 236px;
  height: 236px;
  object-fit: contain;
`;

const Bold = styled.span`
  ${Text};
  display: inline;
  font-weight: ${fontWeights.extraBold};
  letter-spacing: -0.5px;
`;

const ConjunctionText = styled.span``;

export default function VerificationPage() {
  const [digits, setDigits] = useState('');
  const [resendTimeout, setResendTimeout] = useState(false);

  const {
    data: workerProfile,
    isLoading: _profileIsLoading,
    isSuccess: _profileIsLoaded,
  } = useWorkerProfile();

  const {
    mutate: checkVerificationCode,
    isLoading: isCheckVerificationLoading,
    isSuccess: isCheckVerificationSuccess,
    isError: isCheckVerificationError,
  } = useCheckVerificationCode();

  const { refetch: requestOtpCode, isRefetching: isSendingOtp } = useRequestVerification({
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const navigate = useNavigate();

  const { theme } = useCustomerTheme();
  const customerVerificationGraphic = theme.images?.verificationGraphic?.url;

  const customer = workerProfile?.customer;
  const profile = workerProfile?.profile;

  const mfaMethod = customer?.preferences?.mfaMethod ?? MfaMethods.Email;

  const phone = profile?.contactPhone ?? '';
  const email = profile?.contactEmail ?? '';

  const { primaryContact: workerPrimaryContact } = getWorkerContacts(mfaMethod, phone, email);
  const { primaryContactMethod } = getWorkerContactMethods(mfaMethod);

  // Set a timer so we cant spam resend codes.
  const FORTY_FIVE_SECONDS = 45000;
  useEffect(() => {
    if (resendTimeout) {
      setTimeout(() => {
        setResendTimeout(false);
      }, FORTY_FIVE_SECONDS);
    }
  }, [resendTimeout]);

  const resendOtp = async () => {
    requestOtpCode();
    setResendTimeout(true);
    trackAmplitudeEvent('Authentication OTP Re-sent');
  };

  const onMfaSubmit = async () => {
    trackAmplitudeEvent('Authentication Succeeded');
    checkVerificationCode(digits);
  };

  // eslint-disable-next-line consistent-return
  const resetContactInstructionText = () => {
    if (
      !!profile?.status &&
      ![ProfileStatus.Active, ProfileStatus.PendingClosure, ProfileStatus.Closed].includes(
        profile?.status
      )
    ) {
      return (
        <>
          <StyledLink
            onClick={() => {
              navigate('/verification/reset-request');
            }}
          >
            {' '}
            Reset {primaryContactMethod}{' '}
          </StyledLink>
          <ConjunctionText>or</ConjunctionText>
        </>
      );
    }
  };

  // If we've succeeded in verifying (with a 200), go home to usher us
  // to the right place based on user state.
  if (isCheckVerificationSuccess) {
    return <Navigate to="/" />;
  }

  return (
    <MfaViewContainer>
      <MfaVerificationContainer>
        <TitleText>Verify your code</TitleText>
        {customerVerificationGraphic ? (
          <CustomerVerificationGraphic
            src={customerVerificationGraphic}
            alt="Customer verification graphic"
          />
        ) : (
          <VerificationGraphic />
        )}
        <Text>
          Enter the verification code sent to <Bold>{workerPrimaryContact}</Bold>
        </Text>
        <NumberRow>
          <NumberInput
            className="otp-verification-code"
            invalid={isCheckVerificationError}
            placeholder="Verification code"
            value={digits}
            centered
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDigits(e.target.value)}
          />
        </NumberRow>

        <CodeControls>
          {isCheckVerificationError ? (
            <ErrorText>The verification code is incorrect or expired!</ErrorText>
          ) : null}
          <SpinnerRow>
            <Text>
              Didn&apos;t receive a code?{' '}
              {!resendTimeout ? (
                <StyledLink onClick={resendOtp}>Resend</StyledLink>
              ) : (
                <DisabledLink>Resend</DisabledLink>
              )}
            </Text>
            {isSendingOtp ? (
              <div
                style={{
                  marginRight: '-20px',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                }}
              >
                <Spinner size="20px" />
              </div>
            ) : (
              <div />
            )}
          </SpinnerRow>
        </CodeControls>
      </MfaVerificationContainer>
      <MfaButtonContainer>
        <Text>
          Trouble logging in?
          {resetContactInstructionText()}
          <StyledLink
            onClick={() => {
              navigate('/support');
            }}
          >
            {' '}
            Contact support.{' '}
          </StyledLink>
        </Text>
        <SpinnerRow>
          <Button
            className="mfa-submit-btn"
            width="100%"
            text="Verify"
            colorVariant="brand"
            sizeVariant="big"
            disabled={digits.length < 6 || isCheckVerificationLoading}
            onClick={onMfaSubmit}
          />
          {isCheckVerificationLoading ? (
            <div
              style={{
                marginRight: '-30px',
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            >
              <Spinner size="30px" />
            </div>
          ) : (
            <div />
          )}
        </SpinnerRow>
      </MfaButtonContainer>
    </MfaViewContainer>
  );
}
