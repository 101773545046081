import { LoadingScreen } from '@/app/wrappers/Containers';
import { useWorkerStatements } from '@/services/useStatements';
import { NormalText } from '@/shared/components/Text.styled';
import { Spinner } from '@checkrx/pay-component-library';
import { StatementList } from './StatementsList';

export default function StatementsPage() {
  const { data: allStatements, isLoading } = useWorkerStatements();

  return (
    <>
      {isLoading ? (
        <LoadingScreen>
          <Spinner />
          <NormalText>Loading your statements</NormalText>
        </LoadingScreen>
      ) : (
        <StatementList statements={allStatements} />
      )}
    </>
  );
}
