import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { fontSizes, fontWeights } from '../styles';

export const PrimaryLink = styled(Link)`
  align-items: center;
  color: ${(p) => p.theme.colors.buttons.primary};
  display: flex;
  font-weight: ${fontWeights.bold};
  gap: 5px;
  justify-content: flex-end;
  svg {
    height: ${fontSizes.smallMedium};
  }

  &:hover,
  &:active,
  &:focus {
    color: ${(p) => p.theme.colors.buttons.primary};
    font-weight: ${fontWeights.bold};
    filter: brightness(90%);
  }
`;
