import { useConfirmWorkerProfile, useZDBusinessConfirm } from '@/services/onboarding';
import { useWorkerProfile } from '@/services/useWorkerProfile';
import DividedCard from '@/shared/components/DividedCard';
import { SpinnerRow } from '@/shared/components/SpinnerRow';
import { NormalText, TitleText } from '@/shared/components/Text.styled';
import { colors, fontSizes } from '@/shared/styles';
import { Button, Spinner } from '@checkrx/pay-component-library';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Address, AddressForm } from '../confirm-pii/AddressForm';
import { parseFullName } from '../confirm-pii/utils';
import { Group } from '../shared/confirm-pages.styled';
import { disclaimerText } from '../shared/disclaimer-text';
import { BusinessDetails } from './BusinessDetails';
import { PersonalDetails } from './PersonalDetails';
import {
  addressValidationSchema,
  businessDetailsValidationSchema,
  personalDetailsValidation,
} from './validations';

export const BusinessConfirmContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const ActivateCheckrPayContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const BusinessAsText = styled.div`
  font-size: ${fontSizes.medium};
  color: ${colors.primaryGreyMediumDark};
`;

const ContinueAsPersonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
  text-align: center;
  width: 100%;
`;

const SpinnerContainer = styled.div`
  margin-right: -30px;
  margin-top: auto;
  margin-bottom: auto;
`;

export const BusinessConfirm = () => {
  const navigate = useNavigate();
  const { data: workerProfile, isLoading: _isLoading } = useWorkerProfile();
  const { contactEmail, contactPhone, contactAddress, checkrCorePII } =
    workerProfile?.profile || {};
  const { firstName, middleName, lastName } = checkrCorePII || {};

  const [personalDetails, setPersonaDetails] = useState<Omit<PersonalDetails, 'dob'>>({
    fullName:
      firstName && firstName.length > 0 && lastName && lastName.length > 0
        ? `${firstName}${middleName ? ' ' + middleName + ' ' : ' '}${lastName}`
        : '',
    email: contactEmail ?? '',
  });

  const [businessDetails, setBusinessDetails] = useState<BusinessDetails>({
    businessName: '',
    formationDate: '',
    ein: '',
    entityType: '',
  });

  const [businessAddress, setBusinessAddress] = useState<Address>({
    street: contactAddress?.street ?? '',
    street2: contactAddress?.street2 ?? '',
    city: contactAddress?.city ?? '',
    state: contactAddress?.state ?? '',
    postalCode: contactAddress?.postalCode ?? '',
  });

  const addressValidation = addressValidationSchema.validate(businessAddress, {
    abortEarly: false,
  });

  const personalsValidation = personalDetailsValidation.validate(personalDetails, {
    abortEarly: false,
  });

  const businessDetailsValidation = businessDetailsValidationSchema.validate(businessDetails, {
    abortEarly: false,
  });

  const getDisableContinue = () => {
    const isAddressInvalid =
      !addressValidation.value || (addressValidation?.error?.details?.length ?? 0) > 0;

    const isPersonalDetailsInvalid = (personalsValidation?.error?.details?.length ?? 0) > 0;
    const isBusinessDetailsInvalid = (businessDetailsValidation?.error?.details?.length ?? 0) > 0;

    return isAddressInvalid || isBusinessDetailsInvalid || isPersonalDetailsInvalid;
  };

  const { mutateAsync: runPostConfirmWorkerProfile, isLoading } = useConfirmWorkerProfile();
  const { mutateAsync: sendZDBusinessConfirm } = useZDBusinessConfirm();
  const handleOnClickContinue = async () => {
    const { firstName, middleName, lastName } = parseFullName(personalDetails.fullName);
    const personalDetailsCopy = { ...personalsValidation.value };
    delete personalDetailsCopy.fullName;
    if (contactEmail) {
      delete personalDetailsCopy.email;
    }

    const profileUpdate = {
      ...personalDetailsCopy,
      firstName,
      ...(middleName !== '' ? { middleName } : {}),
      lastName,
      address: { ...addressValidation.value, country: 'US' },
      workerType: 'business',
      workerBusinessInformation: {
        ...businessDetails,
      },
    };

    await runPostConfirmWorkerProfile(profileUpdate);
    await sendZDBusinessConfirm(businessDetails);
    navigate('/onboarding/consents');
  };

  return (
    <BusinessConfirmContainer>
      <ActivateCheckrPayContainer>
        <TitleText>Activate Checkr Pay</TitleText>
        <BusinessAsText>as a business</BusinessAsText>
      </ActivateCheckrPayContainer>
      <ContinueAsPersonContainer>
        <NormalText>Are you an individual? Create your account as an individual below.</NormalText>
        <Button
          text="Continue as an individual"
          width="100%"
          onClick={() => navigate('/onboarding/confirm')}
        />
      </ContinueAsPersonContainer>
      <DividedCard
        elements={[
          <Group key="personal-details">
            <PersonalDetails
              value={personalDetails}
              onChange={(v) => setPersonaDetails(v)}
              contactPhone={contactPhone ?? ''}
              hiddenFields={['dob']}
            />
          </Group>,
          <Group key="business-details">
            <BusinessDetails value={businessDetails} onChange={(v) => setBusinessDetails(v)} />
          </Group>,
          <Group key="business-address">
            <AddressForm value={businessAddress} onChange={(v) => setBusinessAddress(v)} />
          </Group>,
        ]}
      />
      {disclaimerText}
      <SpinnerRow>
        <Button
          className="profile-confirm-btn"
          width="100%"
          colorVariant="brand"
          text="Continue"
          sizeVariant="big"
          onClick={handleOnClickContinue}
          disabled={getDisableContinue()}
        />
        {isLoading ? (
          <SpinnerContainer>
            <Spinner size="30px" />
          </SpinnerContainer>
        ) : (
          <div />
        )}
      </SpinnerRow>
    </BusinessConfirmContainer>
  );
};
