import { ReactComponent as CardIllustration } from '@/shared/assets/card_illustration.svg';
import dayjs from 'dayjs';
import { Emphasized } from '../MessageContent';

import { SUPPORT_APPLE_WALLET_PAGE } from '@/shared/helpers';
import { useExternalLinkPoster } from '@/shared/hooks/useExternalLinkPoster';
import { FC } from 'react';
import { ActionPrompt, ContentBold, ContentText, MessageContentProps } from '../MessageContent';

export const CardPending: FC<MessageContentProps> = ({ expiresAt, setWasOpened, wasOpened }) => {
  const sendDeviceWalletPage = useExternalLinkPoster({
    link: SUPPORT_APPLE_WALLET_PAGE,
    prependText: 'Link Apple or Google Wallet',
  });
  const today = dayjs();
  const arrivalEstimate = dayjs(expiresAt).format('dddd, MMM D');
  const remainingDays = today.diff(arrivalEstimate, 'days');
  if (wasOpened)
    return (
      <>
        <ContentBold align="center">Your card is on the way, but you can spend today!</ContentBold>
        <CardIllustration height={150} />
        <ContentText>
          Your physical card is being shipped to you in the mail. After you sign up for Checkr Pay,
          it takes <Emphasized>about 14 days</Emphasized> to get to you in the mail.
        </ContentText>
        <ContentText>
          By our estimates, your card should arrive on or around{' '}
          <Emphasized>{arrivalEstimate}</Emphasized>. But if you go to your card page, you can get
          your virtual card number and access your money now.
        </ContentText>
        <ContentText>
          You can even add your virtual card to your Apple Wallet or Google Wallet
        </ContentText>
        <ActionPrompt onClick={sendDeviceWalletPage}>
          Learn how to add your card to your device&apos;s wallet
        </ActionPrompt>
      </>
    );

  return (
    <>
      <ContentBold align="center">🎉 Your Checkr Pay debit card is arriving soon! 🎉</ContentBold>
      {remainingDays < 0 && (
        <ContentText>
          In the mean time, you can use your virtual card, or spend with Apple Wallet or Google
          Pay...
        </ContentText>
      )}
      {remainingDays <= 7 && (
        <ContentText>
          Your Checkr Pay debit card is almost there, but you can access your money now! You can
          spend with your virtual card, or even hook your debit card up to Apple Wallet and Google
          Pay...
        </ContentText>
      )}
      {remainingDays > 7 && (
        <ContentText>
          Your Checkr Pay debit card is on its way, but you can access your money now! You can spend
          with your virtual card, or even hook your debit card up to Apple Wallet and Google Pay...
        </ContentText>
      )}
      <ActionPrompt onClick={() => setWasOpened(true)}>Learn more</ActionPrompt>
    </>
  );
};
