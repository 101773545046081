import { useTransactions } from '@/services/useTransactions';
import { HorizontalRadioGroup } from '@/shared/components/HorizontalRadioGroup';
import LoadingDots from '@/shared/components/LoadingDots';
import TransactionList from '@/shared/components/TransactionList';
import { groupTransactionsByMonth } from '@/shared/lib/transaction-helpers';
import {
  Transaction,
  TransactionDirection,
  TransactionStatus,
  TransactionType,
} from '@/shared/types';
import { TextInput } from '@checkrx/pay-component-library';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PayoutGraphWidget from './PayoutGraphWidget';
import { SearchGroup, ViewContainer } from './TransactionPages.styled';

type Filter = 'all' | 'deposits' | 'expenses' | 'cashback';

const filterFns: Record<Filter, (t: Transaction) => boolean> = {
  deposits: (transaction) => transaction.direction === TransactionDirection.Credit,
  expenses: (transaction) => transaction.direction === TransactionDirection.Debit,
  cashback: (transaction) => transaction.transactionType === TransactionType.Reward,
  all: () => true,
};

const useTransactionHistory = ({
  monthsBack,
  query,
  filter,
}: {
  monthsBack: number;
  query: string;
  filter: Filter;
}) => {
  const { data: transactions = [], isLoading: isLoadingTransactions } = useTransactions({
    startDate: dayjs()
      .startOf('month')
      .subtract(monthsBack - 1, 'months')
      .toDate(),
    searchString: query,
    direction:
      filter === 'expenses'
        ? TransactionDirection.Debit
        : filter === 'deposits'
          ? TransactionDirection.Credit
          : undefined,
    filterFn: (t) => filterFns[filter](t),
  });

  const payoutTransactions = (transactions || []).filter(
    ({ direction, transactionStatus }) =>
      direction === TransactionDirection.Credit && transactionStatus !== TransactionStatus.Returned
  );

  const transactionsGroupedByMonth = useMemo(
    () => groupTransactionsByMonth(transactions, monthsBack),
    [transactions, monthsBack]
  );

  return {
    isLoadingTransactions,
    transactions,
    transactionsGroupedByMonth,
    payoutTransactions,
  };
};

export default function TransactionHistoryPage() {
  const [params, setParams] = useSearchParams();
  const filter = (params.get('filter') as Filter) ?? 'all';
  const [query, setQuery] = useState('');
  const [monthsBack, setMonthsBack] = useState(6);

  const { isLoadingTransactions, transactions, transactionsGroupedByMonth, payoutTransactions } =
    useTransactionHistory({
      monthsBack,
      query,
      filter,
    });

  return (
    <ViewContainer>
      <PayoutGraphWidget
        monthsBack={monthsBack}
        setMonthsBack={setMonthsBack}
        allPayouts={payoutTransactions}
        isPendingTransactions={isLoadingTransactions}
      />

      <SearchGroup>
        <TextInput
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search transactions"
        />
      </SearchGroup>

      <HorizontalRadioGroup
        name="filter"
        value={filter}
        onChange={(filter) => setParams({ filter }, { replace: true })}
        options={[
          {
            label: 'All',
            value: 'all',
          },
          {
            label: 'Deposits',
            value: 'deposits',
          },
          {
            label: 'Expenses',
            value: 'expenses',
          },
          {
            label: 'Cashback',
            value: 'cashback',
          },
        ]}
      />

      {isLoadingTransactions ? (
        <LoadingDots size="10px" />
      ) : query?.length > 0 ? (
        <TransactionList title="Search results" transactions={transactions} />
      ) : (
        transactionsGroupedByMonth.map(({ month, longMonth, transactions }) => (
          <TransactionList
            key={`transaction-list-${month}`}
            title={longMonth}
            transactions={transactions}
          />
        ))
      )}
    </ViewContainer>
  );
}
