import { useAddExternalAccount } from '@/services/useExternalAccount';
import { LinkWithPlaid } from '@/shared/components/LinkWithPlaid';
import { useNavigate } from 'react-router-dom';

const WithdrawalLinkWithPlaid = () => {
  const navigate = useNavigate();
  const { mutateAsync: addExternalAccount } = useAddExternalAccount();

  return (
    <LinkWithPlaid
      onLinkManually={() => navigate('/link-bank/manual')}
      onSuccessfulPlaidConnection={async (plaidProcessorToken) => {
        addExternalAccount({
          plaidProcessorToken,
        });
        navigate('/link-bank/result');
      }}
    />
  );
};

export default WithdrawalLinkWithPlaid;
