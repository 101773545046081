import { TO_DOLLAR_DIVISOR } from './helpers';
import { BankingAccount, Profile } from './types';

/*
  Helpers for withdrawals
*/

/*
  Astra helpers
*/
const DEFAULT_INSTANT_TRANSFER_LIMIT = 100000;
const DEFAULT_INSTANT_TRANSFER_FEE_RATE = 0.022;

export const getWorkerAstraConfig = (profile: Profile) => {
  return {
    feesRate: profile?.astra?.customFeesRate ?? DEFAULT_INSTANT_TRANSFER_FEE_RATE,
    perTransferLimit: profile?.astra?.customPerTransferLimit ?? DEFAULT_INSTANT_TRANSFER_LIMIT,
  };
};

/*
  ACH helpers
*/
const WITHDRAWAL_MINIMUM = 1;

// TODO: refactor out the use of TO_DOLLAR_DIVISOR,
// this page shows bad values when the cents are a multiple of 10
// e.g "Your current balance is: $30.1"
export const getWorkerACHLimits = (bankingAccount?: BankingAccount) => {
  const { available: accountAvailableBalance, limits: accountLimits } = bankingAccount || {};

  const currentBalanceDollars = (accountAvailableBalance || 0) / TO_DOLLAR_DIVISOR;
  const dailyWithdrawalLimitDollars =
    (accountLimits?.ach?.limits?.dailyCredit || 0) / TO_DOLLAR_DIVISOR;
  const monthlyWithdrawalLimitDollars =
    (accountLimits?.ach?.limits?.monthlyCredit || 0) / TO_DOLLAR_DIVISOR;
  const totalWithdrawnForTheDayDollars =
    (accountLimits?.ach?.totalsDaily?.credits || 0) / TO_DOLLAR_DIVISOR;
  const totalWithdrawnForTheMonthDollars =
    (accountLimits?.ach?.totalsMonthly?.credits || 0) / TO_DOLLAR_DIVISOR;

  const moreThanCurrentBal = (value: number) => value > currentBalanceDollars;
  const moreThanDailyWithdrawalLimit = (value: number) =>
    value + totalWithdrawnForTheDayDollars > dailyWithdrawalLimitDollars;
  const moreThanMonthlyWithdrawalLimit = (value: number) =>
    value + totalWithdrawnForTheMonthDollars > monthlyWithdrawalLimitDollars;
  const lessThanWithdrawalMinimum = (value: number) => value < WITHDRAWAL_MINIMUM;

  return {
    accountAvailableBalance,
    // limits
    dailyWithdrawalLimitDollars,
    monthlyWithdrawalLimitDollars,
    totalWithdrawnForTheDayDollars,
    totalWithdrawnForTheMonthDollars,
    withdrawalMinimum: WITHDRAWAL_MINIMUM,

    // threshold conditions
    moreThanCurrentBal,
    moreThanDailyWithdrawalLimit,
    moreThanMonthlyWithdrawalLimit,
    lessThanWithdrawalMinimum,
  };
};
