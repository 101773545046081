/**
 * This is a context for storing a piece of client-side state – the withdrawal request amount.
 *
 * This is because we want to remember how much money the earner is requesting, even if
 * they leave to go link a bank account first.
 */
import { ReactNode, useState } from 'react';

import { createContext, useContext, useMemo } from 'react';

// Set up a Context for interacting with authentication tokens
interface IWithdrawalAmountContext {
  withdrawalRequestAmount: string | null;
  setWithdrawalRequestAmount?: (amount: string | null) => void;
}
const defaultState = {
  withdrawalRequestAmount: null,
};
const WithdrawalAmountContext = createContext<IWithdrawalAmountContext>(defaultState);

export const WithdrawalAmountProvider = ({ children }: { children: ReactNode }) => {
  const [amount, setAmount] = useState<string | null>(null);

  const setWithdrawalRequestAmount = async (data: string | null) => {
    setAmount(data);
  };

  const value = useMemo(
    () => ({
      withdrawalRequestAmount: amount,
      setWithdrawalRequestAmount,
    }),
    [amount]
  );
  return (
    <WithdrawalAmountContext.Provider value={value}>{children}</WithdrawalAmountContext.Provider>
  );
};

export const useWithdrawalRequestAmount = () => {
  return useContext(WithdrawalAmountContext);
};
