/**
 * Helpful, reusable containers for things like layouts that wrap the application visually
 */
import styled from 'styled-components';

export const AppContainer = styled.main`
  --max-content-width: 400px;

  position: relative;
  display: grid;
  grid-template-columns: minmax(0, var(--max-content-width));
  max-width: var(--max-content-width);
  min-height: 100%;
  margin: 0 auto;
  padding: 0 10px;
`;

// The wrapper for a standalone loading page that you can render in-lieu of component
// or scope-related Loading approaches.
export const LoadingScreen = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 30px;
`;

// A top-level wrapper for column-focused Pages (instead of the default row)
export const TabWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;
