import { Customer } from './customer.types';
import { Payout, PayoutMethod } from './payout.types';
import { DateString } from './types';
import { WorkerProfile } from './worker-profile.types';
import { Worker } from './worker.types';

export enum TransactionType {
  Ach = 'Ach',
  Atm = 'Atm',
  BookTransfer = 'BookTransfer',
  Card = 'Card',
  Check = 'Check',
  Dispute = 'Dispute',
  Fee = 'Fee',
  Interest = 'Interest',
  Other = 'Other',
  Reward = 'Reward',
  Wire = 'Wire',
}

export enum TransactionDirection {
  Credit = 'Credit',
  Debit = 'Debit',
}

export enum TransactionStatus {
  Pending = 'Pending',
  PendingReview = 'PendingReview',
  Clearing = 'Clearing',
  Returned = 'Returned',
  Sent = 'Sent',
  Rejected = 'Rejected',
  Canceled = 'Canceled',
  Authorized = 'Authorized',
}

type TransactionMetadata = {
  annotation?: string;
  customerFee: string;
  payoutMethod?: PayoutMethod;
  payoutMethodFeeCents?: string;
  customer?: Customer['_id'];
  queuedPayoutId?: string;
  worker?: Worker['_id'];
  payoutId?: Payout['id'];
  queuedPayoutIdInternal?: string;
  profile?: WorkerProfile['profile']['_id'];
  isPayout?: boolean;
};

export interface Transaction {
  transactionId: string;
  transactionType: TransactionType;
  userId: string;
  accountId: string;
  amount: number;
  summary: string;
  description?: string;
  direction: TransactionDirection;
  createdAt: DateString;
  transactionStatus: TransactionStatus;
  expectedCompletionDate?: DateString;
  metadata?: TransactionMetadata;
}
