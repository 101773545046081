/**
 * A reusable radio button component
 */

import { ReactComponent as CheckIcon } from '@/shared/assets/white-check.svg';
import {
  CheckIconContainer,
  RadioButtonSubtext,
  RadioButtonText,
  StyledRadioButton,
} from '@/shared/components/RadioButton.styled';
import React from 'react';

const noop = (e: React.MouseEvent | React.KeyboardEvent) => {
  e.preventDefault();
};

export default function RadioButton({
  className = '',
  text = '',
  subtext = '',
  selected = false,
  disabled = false,
  onClick = noop,
}: {
  className?: string;
  text?: string;
  subtext?: string;
  selected?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent | React.KeyboardEvent) => void;
}) {
  return (
    <StyledRadioButton
      className={className}
      onClick={disabled ? noop : onClick}
      selected={selected}
      disabled={disabled}
    >
      <CheckIconContainer selected={selected}>{selected && <CheckIcon />}</CheckIconContainer>
      <RadioButtonText>{text}</RadioButtonText>
      <RadioButtonSubtext>{subtext}</RadioButtonSubtext>
    </StyledRadioButton>
  );
}
