import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useTransactions } from '@/services/useTransactions';
import TransactionListItem from '@/shared/components/TransactionListItem';
import { colors } from '@/shared/styles';
import { Icon, Spinner } from '@checkrx/pay-component-library';

const TransactionsPanel = styled.div`
  border: 1px solid ${colors.primaryGreyLight};
  border-radius: 10px;
  padding: 0;
  display: flex;
  flex-direction: column;
`;

const CenterSpinner = styled.div`
  display: flex;
  width: 100%;
  min-height: 100px;
  align-items: center;
  justify-content: center;
  height: fit-content;
`;

const SeeAllTransactionsButton = styled.button`
  width: 100%;
  padding: 9px 15px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.trueWhite};
  color: ${colors.primaryBlue};

  &:hover,
  &:active {
    background-color: ${colors.primaryGreyLight};
  }
`;

const TransactionsList = styled.div`
  width: 100%;
  padding: 9px 0;
`;

const TransactionItem = styled(TransactionListItem)`
  /* Size */
  width: 100%;
  height: fit-content;
  min-height: 40px;
  color: ${colors.primaryGreyMediumDark};
  padding: 10px 15px;

  /* Container properties */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 15px;
  cursor: pointer;
  background-color: transparent;
  border-bottom: 1px solid ${colors.primaryGreyLight};

  &:last-child {
    border-bottom: none;
  }

  &:hover,
  &:active {
    background-color: ${colors.primaryGreyLight};
  }
`;

const useLatestTransactions = () => {
  const { data: allTransactions, isLoading: isLoadingTransactions } = useTransactions();

  const transactions = (allTransactions || []).slice(0, 3);

  return {
    transactions,
    isLoadingTransactions,
  };
};

export default function LatestTransactionsWidget() {
  const navigate = useNavigate();
  const { transactions, isLoadingTransactions } = useLatestTransactions();

  if (isLoadingTransactions) {
    return (
      <TransactionsPanel>
        <CenterSpinner>
          <Spinner />
        </CenterSpinner>
      </TransactionsPanel>
    );
  }

  if (!transactions.length) {
    return null;
  }

  return (
    <TransactionsPanel>
      <SeeAllTransactionsButton type="button" onClick={() => navigate('/transactions')}>
        <span>See all transactions</span>
        <span>
          <Icon name="chevron-right" />
        </span>
      </SeeAllTransactionsButton>
      <TransactionsList>
        {transactions.map((txn) => (
          <TransactionItem key={txn.transactionId} txn={txn} />
        ))}
      </TransactionsList>
    </TransactionsPanel>
  );
}
