import { useState } from 'react';
import { DataContent, DataLabel, PiiRow } from '../shared/confirm-pages.styled';
import { ConfirmProfileInput, ConfirmProfileInputProps } from '../shared/ConfirmProfileInput';
import { personalDetailsValidation } from './validations';

type InputProps = ConfirmProfileInputProps & {
  isValue?: boolean;
  label: string;
};

export type PersonalDetails = {
  fullName: string;
  email: string;
  dob?: string;
};

interface PersonalDetailsProps {
  value: PersonalDetails;
  onChange: (personalDetails: PersonalDetails) => void;
  contactPhone: string;
  hiddenFields?: (keyof PersonalDetails)[];
}

export function PersonalDetails({
  value,
  onChange,
  contactPhone,
  hiddenFields = [],
}: PersonalDetailsProps) {
  const [personalDetails, setPersonalDetails] = useState(value);

  const handleChange = (key: keyof PersonalDetails, v: string) => {
    const personalDetailsCopy = { ...personalDetails };
    personalDetailsCopy[key] = v;
    setPersonalDetails(personalDetailsCopy);
    onChange(personalDetailsCopy);
  };

  const validations = personalDetailsValidation.validate(value, {
    abortEarly: false,
  });

  const getErrorState = (key: keyof typeof personalDetails) => {
    const inputErrorIdx = validations?.error?.details.findIndex((d) => key === d.path[0]) as number;
    if (inputErrorIdx === -1) return {};

    return {
      validation: () => !validations.error?.details[inputErrorIdx],
      errorText: validations.error?.details[inputErrorIdx]?.message,
    };
  };

  const inputs: InputProps[] = [
    {
      label: 'Full legal name:',
      value: personalDetails.fullName,
      onChange: (v: string) => handleChange('fullName', v),
      placeholder: 'Full name',
      ...getErrorState('fullName'),
      className: 'confirm-pii-full-name',
      fullviewCaptureEnabled: true,
      type: 'text',
      key: 'fullName',
    },
    {
      label: 'Email:',
      value: personalDetails.email,
      onChange: (v: string) => handleChange('email', v),
      placeholder: 'Email',
      ...getErrorState('email'),
      className: 'confirm-pii-email',
      fullviewCaptureEnabled: true,
      type: 'text',
      key: 'email',
    },
    {
      label: 'Date of birth:',
      value: personalDetails.dob,
      onChange: (v: string) => handleChange('dob', v),
      placeholder: 'Date of birth',
      type: 'date',
      ...getErrorState('dob'),
      className: 'confirm-pii-dob',
      fullviewCaptureEnabled: true,
      key: 'dob',
    },
    {
      label: 'Phone number:',
      value: contactPhone,
      onChange: () => undefined,
      isValue: true,
      placeholder: 'Phone number',
      className: 'confirm-pii-phone-number',
      fullviewCaptureEnabled: true,
      type: 'text',
      key: 'phoneNumber',
    },
  ].filter((i) => !hiddenFields.includes(i.key as keyof PersonalDetails)) as InputProps[];

  return (
    <>
      {inputs.map((input) => {
        if (input.isValue) {
          return (
            <PiiRow key={input.label}>
              <DataLabel>{input.label}</DataLabel>
              <DataContent className={input.fullviewCaptureEnabled ? undefined : 'fullview-hide'}>
                {input.value}
              </DataContent>
            </PiiRow>
          );
        }
        return (
          <PiiRow key={input.label}>
            <DataLabel>{input.label}</DataLabel>
            <ConfirmProfileInput {...(input as ConfirmProfileInputProps)} />
          </PiiRow>
        );
      })}
    </>
  );
}
