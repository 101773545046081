/**
 * Bank Account Information page styles
 */

import { fontSizes, fontWeights } from '@/shared/styles';
import styled from 'styled-components';

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DataLabel = styled.div`
  font-weight: ${fontWeights.light};
  font-size: ${fontSizes.medium};
  color: ${(p) => p.theme.colors.text.secondary};
`;

export const DataContentGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DataContent = styled.div`
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.bold};
  color: ${(p) => p.theme.colors.text.primary};
  text-transform: capitalize;
  margin-right: 10px;
`;

export const RoutingNumberTooltipIconContainer = styled.div`
  margin-left: 10px;
  svg {
    height: 0.8rem;
  }
`;
