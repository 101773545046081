import { useWorkerProfile } from '@/services/useWorkerProfile';
import pending from '@/shared/assets/pending-graphic.svg';
import {
  ButtonContainer,
  PageContainer,
  StyledSuccessGraphic,
  SuccessTextBox,
  Text,
  Title,
} from '@/shared/components/Graphics.styled';
import { fontSizes, fontWeights } from '@/shared/styles';
import { Button } from '@checkrx/pay-component-library';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const FailedGraphic = styled.img`
  margin: 20px auto;
`;

const VirtualCardText = styled.p`
  width: 100%;
  margin-top: 16px;
  font-weight: ${fontWeights.semiBold};
  font-size: ${fontSizes.big};
  color: ${(p) => p.theme.colors.text.secondary};
  text-align: center;
`;

export const ManualReviewTextBox = styled.div`
  padding-top: 50%;
  gap: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;

export const ManualReviewButtonContainer = styled.div`
  padding-top: 20px;
  width: 100%;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export function PendingStatus() {
  const navigate = useNavigate();

  return (
    <PageContainer>
      <FailedGraphic src={pending} width="100" height="100" alt="" />

      <Title>Activation Started!</Title>
      <Text>
        We&apos;re activating your account! This usually only takes a few minutes, so feel free to
        refresh and check back in a few. We&apos;ll also email you with any updates on your account
        status. If you have any questions, feel free to reach out to support.
      </Text>

      <ButtonContainer>
        <Button
          width="100%"
          sizeVariant="big"
          colorVariant="brand"
          text="Contact Support"
          icon="help-circle"
          onClick={() => navigate('/support')}
        />
      </ButtonContainer>
    </PageContainer>
  );
}

export function FailedStatus() {
  const navigate = useNavigate();

  return (
    <PageContainer>
      <FailedGraphic src={pending} width="100" height="100" alt="" />

      <Title>Pending Review</Title>
      <Text>
        Thank you for signing up for Checkr Pay. Our team has to double check a few things before we
        can activate your account. Please contact support for more information, or check your email
        for an update within 1 business day
      </Text>

      <ButtonContainer>
        <Button
          width="100%"
          sizeVariant="big"
          colorVariant="brand"
          text="Contact Support"
          icon="help-circle"
          onClick={() => navigate('/support')}
        />
      </ButtonContainer>
    </PageContainer>
  );
}

export function SuccessStatus() {
  const navigate = useNavigate();

  const { data: workerProfile } = useWorkerProfile();
  const humanReadableName = workerProfile?.customer?.humanReadableName;
  const preferredName = workerProfile?.profile?.preferredName;
  const firstName = workerProfile?.profile?.checkrCorePII?.firstName;

  return (
    <PageContainer>
      <StyledSuccessGraphic className="success-graphic" />
      <SuccessTextBox>
        <Title>Welcome to Checkr Pay, {preferredName || firstName}!</Title>
        <Text>
          You’ve successfully activated your Checkr Pay account. Now you can view your earnings,
          {/* eslint-disable-next-line max-len */}
          access your Visa Business Debit card, plan for taxes, and more - all without leaving the{' '}
          {humanReadableName} App.
        </Text>
        <VirtualCardText>
          Spend online now with your virtual card while you wait for your physical card to arrive in
          the mail.
        </VirtualCardText>
      </SuccessTextBox>
      <ButtonContainer>
        <Button
          className="go-to-dashboard-btn"
          width="100%"
          sizeVariant="big"
          colorVariant="brand"
          text="Go to my dashboard"
          iconRight
          icon="arrow-right"
          onClick={() => navigate('/dashboard')}
        />
      </ButtonContainer>
    </PageContainer>
  );
}
