import { Button, Spinner } from '@checkrx/pay-component-library';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import NumberInput from '@/shared/components/NumberInput';
import { countDecimal, currencyFormatter, TO_DOLLAR_DIVISOR } from '@/shared/helpers';
import { fontSizes } from '@/shared/styles';

import { LoadingScreen } from '@/app/wrappers/Containers';
import { useBankingAccount } from '@/services/useBankingAccount';
import { useWithdrawalRequestAmount } from '@/services/useWithdrawalRequestAmount';
import { useWorkerProfile } from '@/services/useWorkerProfile';
import { trackAmplitudeEvent } from '@/shared/analytics';
import { Banner } from '@/shared/components/Banner/Banner';
import {
  ErrorText,
  NormalText,
  SmallText,
  TitleText,
  WarningText,
} from '@/shared/components/Text.styled';
import { getWorkerACHLimits, getWorkerAstraConfig } from '@/shared/withdrawals';
import { Text } from '../dashboard/withdraw/WithdrawPage.styled';
import ErrorPage from '../errors/ErrorPage';

const ViewContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 20px 0 10px 0;
  gap: 25px;
`;

const AmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Title = styled(TitleText)`
  font-size: ${fontSizes.giant};
  padding-bottom: 15px;
  padding-top: 15px;
`;

const AcctLinkingExplanation = styled.div`
  padding-bottom: 10px;
`;

const BottomButtonContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  gap: 10px;
`;

export default function RequestWithdrawalPage() {
  const [amount, setAmount] = useState('');
  const [touched, setTouched] = useState(false);
  const navigate = useNavigate();
  const { withdrawalRequestAmount, setWithdrawalRequestAmount } = useWithdrawalRequestAmount();
  const { data: workerProfile, isLoading: isLoadingTransferLimits } = useWorkerProfile();

  if (!workerProfile) {
    return <ErrorPage />;
  }

  const { perTransferLimit: instantTransferLimit } = getWorkerAstraConfig(workerProfile.profile);

  trackAmplitudeEvent('Astra Enabled');

  const { data: bankingAccount, isLoading: isLoadingBankingAccount } = useBankingAccount();

  // If we have a previously stored withdrawal request amount, initialize with that.
  useEffect(() => {
    if (withdrawalRequestAmount) {
      setAmount(withdrawalRequestAmount);
    }
  }, [withdrawalRequestAmount]);

  if (isLoadingBankingAccount || isLoadingTransferLimits) {
    return (
      <LoadingScreen>
        <Spinner />
        <Text>Loading your withdrawals...</Text>
      </LoadingScreen>
    );
  }

  const {
    accountAvailableBalance,
    // limits
    dailyWithdrawalLimitDollars,
    monthlyWithdrawalLimitDollars,
    withdrawalMinimum,
    // threshold conditions
    moreThanCurrentBal,
    moreThanDailyWithdrawalLimit,
    moreThanMonthlyWithdrawalLimit,
    lessThanWithdrawalMinimum,
  } = getWorkerACHLimits(bankingAccount);

  const moreThanInstantTransferLimit = (value: number) => value > instantTransferLimit;
  const currentBalanceDollars = (accountAvailableBalance || 0) / TO_DOLLAR_DIVISOR;

  const amountNumber = parseFloat(amount);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTouched(true);
    setAmount(e.target.value);
  };

  const handleOnClick = () => {
    // Set the withdrawal request amount in client-side state
    if (setWithdrawalRequestAmount) {
      setWithdrawalRequestAmount(amount);
    }
    navigate(`/withdraw/review`);
  };

  const handleWithdrawEntireBalance = () => {
    const entireBalance = currentBalanceDollars.toFixed(2);
    setAmount(entireBalance);
    setTouched(true);
    if (setWithdrawalRequestAmount) {
      setWithdrawalRequestAmount(entireBalance);
    }
    navigate(`/withdraw/review`);
  };

  /**
   * Return true or false to determine if the 'Continue' button should be disabled, depending
   * on the amount entered
   */
  const handleDisableContinueBtn = () => {
    if (
      amountNumber <= 0 ||
      lessThanWithdrawalMinimum(amountNumber) ||
      countDecimal(amountNumber) > 2
    ) {
      return true;
    }
    return !(amount && amount.length > 0)
      ? true
      : !!(amountNumber && moreThanCurrentBal(amountNumber));
  };

  const displayAccountLinkingText = () => {
    if (currentBalanceDollars >= withdrawalMinimum) {
      return (
        <AcctLinkingExplanation>
          <SmallText>You can link your external account on the next page</SmallText>
        </AcctLinkingExplanation>
      );
    }
    return (
      <AcctLinkingExplanation>
        <WarningText>Note, you need a positive balance to link your external account</WarningText>
      </AcctLinkingExplanation>
    );
  };

  return (
    <ViewContainer>
      <Banner bannerKey="spend-and-earn" />

      <TitleContainer>
        <Title>How much would you like to withdraw?</Title>
        {displayAccountLinkingText()}
        <NormalText> Minimum allowed: ${withdrawalMinimum}</NormalText>
        <NormalText> Your current balance is: ${currentBalanceDollars}</NormalText>
      </TitleContainer>
      <AmountContainer>
        <NumberInput
          invalid={
            (!amount ||
              moreThanCurrentBal(amountNumber) ||
              lessThanWithdrawalMinimum(amountNumber)) &&
            touched
          }
          prependSymbol="$"
          value={amount}
          onChange={(e) => handleChange(e)}
        />
        {(!amount || moreThanCurrentBal(amountNumber)) && touched && (
          <ErrorText>
            {!amount
              ? `Required`
              : `You can't withdraw more than your current available balance of
                  ${currentBalanceDollars.toFixed(2)}.`}
          </ErrorText>
        )}
        {moreThanDailyWithdrawalLimit(amountNumber) && touched && (
          <ErrorText>
            {`${
              moreThanInstantTransferLimit(amountNumber)
                ? `You can't withdraw more than your daily limit of ${currencyFormatter.format(
                    dailyWithdrawalLimitDollars
                  )}.`
                : 'This is over your ACH limit, you can only use instant withdrawal for this.'
            }`}
          </ErrorText>
        )}
        {moreThanMonthlyWithdrawalLimit(amountNumber) && touched && (
          <ErrorText>
            {`${
              moreThanInstantTransferLimit(amountNumber)
                ? `You can't withdraw more than your monthly limit of ${currencyFormatter.format(
                    monthlyWithdrawalLimitDollars
                  )}.`
                : 'This is over your ACH limit, you can only use instant withdrawal for this.'
            }`}
          </ErrorText>
        )}
        {lessThanWithdrawalMinimum(amountNumber) && touched && (
          <ErrorText>{`You can't withdraw less than $${withdrawalMinimum}.`}</ErrorText>
        )}
        {moreThanInstantTransferLimit(amountNumber) && touched && (
          <NormalText>{`Your withdrawal amount of ${currencyFormatter.format(
            amountNumber
          )} exceeds the limit for instant transfers (${currencyFormatter.format(
            instantTransferLimit
          )} per transfer).
          If you would like to withdraw an amount greater than ${currencyFormatter.format(
            instantTransferLimit
          )},
          you will need to wait 1-2 business days for your funds to arrive.`}</NormalText>
        )}
      </AmountContainer>
      <BottomButtonContainer>
        {currentBalanceDollars > 0 && (
          <Button
            width="100%"
            text={`Withdraw entire balance (${currencyFormatter.format(currentBalanceDollars)})`}
            colorVariant="light"
            sizeVariant="big"
            onClick={handleWithdrawEntireBalance}
          />
        )}
        <Button
          width="100%"
          iconRight
          icon="arrow-right"
          text="Continue"
          colorVariant="brand"
          sizeVariant="big"
          disabled={handleDisableContinueBtn()}
          onClick={handleOnClick}
        />
      </BottomButtonContainer>
    </ViewContainer>
  );
}
