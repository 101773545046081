/**
 * An ActivationStatus view for the onboarding flow
 */
import { LoadingScreen } from '@/app/wrappers/Containers';
import {
  FailedStatus,
  PendingStatus,
  SuccessStatus,
} from '@/pages/onboarding/activation-status/StatusComponents';
import { ManualReview } from '@/pages/onboarding/manual-review/ManualReview';
import { useBankingApplication } from '@/services/useBankingApplication';
import { useWorkerProfile } from '@/services/useWorkerProfile';
import { ProfileStatus } from '@/shared/types';
import { Spinner } from '@checkrx/pay-component-library';
import { Navigate } from 'react-router-dom';

export default function ActivationStatusPage() {
  const { data: workerProfile, isLoading: isLoadingProfile } = useWorkerProfile();
  const { isLoading: isLoadingBankingApplication } = useBankingApplication();
  const activationStatus = workerProfile?.profile?.status;

  if (isLoadingProfile || isLoadingBankingApplication) {
    return (
      <LoadingScreen>
        <Spinner />
      </LoadingScreen>
    );
  }

  if (activationStatus === ProfileStatus.Failed) {
    return <FailedStatus />;
  }

  if (activationStatus === ProfileStatus.ManualReview) {
    return <ManualReview />;
  }

  if (activationStatus === ProfileStatus.Active || activationStatus === ProfileStatus.Onboarded) {
    return <SuccessStatus />;
  }

  if (activationStatus === ProfileStatus.Pending) {
    return <PendingStatus />;
  }

  // In cases of pending review or other weird states, the home page navigator has got it!
  return <Navigate to="/" />;
}
