import {
  GeneralDocumentForm,
  ManualReviewDocumentFormValues,
  RequiredDocumentsForm,
} from '@/pages/onboarding/manual-review/ManualReviewDocumentForms';
import { useBankingApplication } from '@/services/useBankingApplication';
import {
  ManualReviewDocumentUpload,
  useUploadManualReviewDocuments,
} from '@/services/useManualReview';
import { useWorkerProfile } from '@/services/useWorkerProfile';
import pendingGraphicSrc from '@/shared/assets/pending-graphic.svg';
import { Title } from '@/shared/components/Graphics.styled';
import { Bold, NormalText, SmallMediumText, SubTitleText } from '@/shared/components/Text.styled';
import { toast } from '@/shared/components/Toaster/Toaster';
import useZendeskChat from '@/shared/hooks/useZendeskChat';
import { colors, fontSizes } from '@/shared/styles';
import { BankingApplicationStatus } from '@/shared/types';
import { Button } from '@checkrx/pay-component-library';
import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Page = styled.div`
  position: relative;
  height: 100%;
  text-align: center;
  padding: 40px 0;
`;

const HeroGraphic = styled.img`
  margin: 0 auto 16px;
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 100%;
  margin-bottom: 24px;
`;

const Badge = styled.span`
  font-size: ${fontSizes.smallMedium};
  font-weight: 500;
  color: ${colors.primaryBlue};
  padding: 4px 8px;
  background: ${colors.accentYellow};
  border-radius: 16px;
`;

const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
`;

const Section = styled.section`
  margin-top: 48px;
`;

const Heading = styled(Title)`
  font-size: 1.2rem;
`;

const SuccessPageLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const PageResetButtonContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

type VerticalStackProps = {
  gap?: string;
};
const VerticalStack = styled.div<VerticalStackProps>`
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.gap};
`;

const MANUAL_REVIEW_SUPPORT_ARTICLE =
  'https://support.checkrpay.com/hc/en-us/articles/20953723011476-Why-are-you-requesting-documentation-';

const applicationStatusDisplay = (status?: BankingApplicationStatus) => {
  if (!status) {
    return null;
  }

  if (status === BankingApplicationStatus.AwaitingDocuments) {
    return 'Awaiting More Documents';
  } else {
    return 'Pending Review';
  }
};

export function ManualReview() {
  const navigate = useNavigate();
  const { data: workerProfile } = useWorkerProfile();
  const { data: bankingApplication } = useBankingApplication();
  const { mutateAsync: uploadManualReviewDocuments } = useUploadManualReviewDocuments();
  const [didCompleteUpload, setDidCompleteUpload] = useState(false);
  const [_, setIsZendeskChatOpen] = useZendeskChat();

  const preferredName = workerProfile?.profile?.preferredName;
  const firstName = workerProfile?.profile?.checkrCorePII?.firstName;
  const emailAddress = workerProfile?.profile?.contactEmail;

  const handleSubmitManualReviewDocuments = async (formValues: ManualReviewDocumentFormValues) => {
    const documentUploads: ManualReviewDocumentUpload[] = [];
    for (const [documentType, file] of Object.entries(formValues)) {
      documentUploads.push({
        documentType: documentType as keyof ManualReviewDocumentFormValues,
        file,
      });
    }

    try {
      await uploadManualReviewDocuments(documentUploads);
      setDidCompleteUpload(true);
    } catch (err) {
      toast({ message: 'Oops, something went wrong', type: 'error', duration: 5000 });
      Sentry.captureException(err);
    }
  };

  if (didCompleteUpload) {
    return (
      <Page>
        <SuccessPageLayout>
          <Header>
            <Title>Upload complete</Title>
            <SubTitleText>Thanks for sending in your documents!</SubTitleText>
          </Header>

          <VerticalStack gap="16px">
            <NormalText>We&apos;ll contact you via email soon with next steps.</NormalText>
            <NormalText>
              If you return to the app before you hear from us, please don&apos;t re-upload your
              document. We&apos;ll let you know if we need anything else.
            </NormalText>

            <VerticalStack gap="4px">
              <NormalText>
                <Bold>Email address:</Bold> {emailAddress}
              </NormalText>
              <NormalText>
                <Bold>Estimated response time:</Bold> 1-2 business days
              </NormalText>
            </VerticalStack>
          </VerticalStack>

          <PageResetButtonContainer>
            <Button
              icon="arrow-left"
              text="Done"
              colorVariant="brand"
              sizeVariant="big"
              onClick={() => setDidCompleteUpload(false)}
              width="100%"
            />
          </PageResetButtonContainer>
        </SuccessPageLayout>
      </Page>
    );
  }

  return (
    <Page>
      <HeroGraphic src={pendingGraphicSrc} width="80" height="80" alt="" />

      <Header>
        <Title>Almost there, {preferredName || firstName}</Title>
        <SubTitleText>
          We need a few more details <br />
          to help keep you and your money safe.
        </SubTitleText>
      </Header>

      <VerticalStack gap="16px">
        <NormalText>
          Application status:{' '}
          <Badge>{applicationStatusDisplay(bankingApplication?.applicationStatus)}</Badge>
        </NormalText>

        {bankingApplication?.applicationStatus === BankingApplicationStatus.AwaitingDocuments ? (
          <>
            <NormalText>
              Gather the required document(s) and <Bold>upload a clear photo for verification</Bold>
              . Here&apos;s what we need.
            </NormalText>

            <RequiredDocumentsForm
              onSubmit={handleSubmitManualReviewDocuments}
              requiredDocuments={bankingApplication?.requiredDocuments}
            />

            <SmallMediumText>
              {/* eslint-disable-next-line max-len */}
              We don&apos;t check your credit. We just want to make sure you&apos;re really you.{' '}
              <StyledLink
                href={MANUAL_REVIEW_SUPPORT_ARTICLE}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </StyledLink>
            </SmallMediumText>
          </>
        ) : (
          <NormalText>
            Our team has to double check a few things before we can activate your account. Keep an
            eye on your email for an update <Bold>within 1 business day.</Bold>
          </NormalText>
        )}
      </VerticalStack>

      {bankingApplication?.applicationStatus === BankingApplicationStatus.Denied ? (
        <Section>
          <VerticalStack gap="16px">
            <Heading>Additional documents</Heading>
            <NormalText>
              Use the form below to upload additional documents if requested by our support team.
            </NormalText>

            <GeneralDocumentForm onSubmit={handleSubmitManualReviewDocuments} />
          </VerticalStack>
        </Section>
      ) : null}

      <Section>
        <VerticalStack gap="16px">
          <Heading>Need help?</Heading>
          <NormalText>
            If you have questions or issues you can reach out to our support team. Live chat is
            available Monday through Friday, from 8AM to 8PM Eastern time.
          </NormalText>

          <Button
            width="100%"
            colorVariant="accent"
            text="Chat with a Support Agent"
            icon="help-circle"
            onClick={() => setIsZendeskChatOpen(true)}
          />
          <Button
            width="100%"
            colorVariant="light"
            text="Contact Support"
            onClick={() => navigate('/support')}
          />
        </VerticalStack>
      </Section>
    </Page>
  );
}
