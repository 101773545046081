import { trackAmplitudeEvent } from '@/shared/analytics';
import { ReactComponent as NumberOneIcon } from '@/shared/assets/number-1-digit.svg';
import { ReactComponent as NumberTwoIcon } from '@/shared/assets/number-2-digit.svg';
import { ReactComponent as NumberThreeIcon } from '@/shared/assets/number-3-digit.svg';
import { ErrorText, NormalText, TitleText } from '@/shared/components/Text.styled';
import { getWorkerContactMethods } from '@/shared/helpers';
import { Button, Spinner } from '@checkrx/pay-component-library';
import { Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Group } from '@/pages/onboarding/shared/confirm-pages.styled';
import DividedCard from '@/shared/components/DividedCard';
import {
  MfaVerificationContainer,
  MfaViewContainer,
  ResetMfaVerificationSteps,
  StyledLink,
  Text,
} from './VerificationPage.styled';

import { useResetOtp } from '@/services/useVerification';
import { useCustomerFromProfile } from '@/services/useWorkerProfile';
import { SpinnerRow } from '@/shared/components/SpinnerRow';
import { MfaMethods } from '@/shared/types';

const StepsRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export default function ResetRequestPage() {
  const navigate = useNavigate();
  const { refetch: resetOtp, isLoading, isSuccess, isError } = useResetOtp();

  const { data: customer } = useCustomerFromProfile();
  const { primaryContactMethod, secondaryContactMethod } = getWorkerContactMethods(
    customer?.preferences?.mfaMethod ?? MfaMethods.Email
  );

  const handleResetPhoneNumberBtn = async () => {
    trackAmplitudeEvent('Two factor Reset - Requested');
    resetOtp();
  };

  if (isSuccess) {
    return <Navigate to="/verification/verify-second-contact" />;
  }
  const errorMessage = isError ? (
    <ErrorText>
      Account is not eligible for a {primaryContactMethod} reset at this time. Please contact
      <StyledLink
        onClick={() => {
          navigate('/support');
        }}
      >
        {' '}
        Checkr Pay support.{' '}
      </StyledLink>
    </ErrorText>
  ) : (
    <></>
  );

  return (
    <MfaViewContainer>
      <MfaVerificationContainer>
        <TitleText>Reset {primaryContactMethod}</TitleText>
        <Text>
          Since you&apos;re having trouble logging in, we can help you reset your{' '}
          {primaryContactMethod}. Follow the steps below to change your {primaryContactMethod}
        </Text>

        <ResetMfaVerificationSteps>
          <DividedCard
            elements={[
              <Group key="mfa-reset-request">
                <StepsRow>
                  <NumberOneIcon />
                  <NormalText>
                    Verify your identity by confirming a one-time code we send to your{' '}
                    {secondaryContactMethod}
                  </NormalText>
                </StepsRow>
                <StepsRow>
                  <NumberTwoIcon />
                  <NormalText>
                    Enter the new {primaryContactMethod} you would like on your account
                  </NormalText>
                </StepsRow>
                <StepsRow>
                  <NumberThreeIcon />
                  <NormalText>
                    Verify the new {primaryContactMethod} using a one-time passcode
                  </NormalText>
                </StepsRow>
              </Group>,
            ]}
          />
        </ResetMfaVerificationSteps>
      </MfaVerificationContainer>

      {isError && errorMessage}

      <Text>
        Have questions?
        <StyledLink
          onClick={() => {
            navigate('/support');
          }}
        >
          {' '}
          Contact support{' '}
        </StyledLink>
      </Text>

      <SpinnerRow>
        <Button
          className="request-rest-mfa-btn"
          width="100%"
          colorVariant="brand"
          text={`Reset ${primaryContactMethod || 'Two Factor'}`}
          sizeVariant="big"
          disabled={isLoading}
          onClick={handleResetPhoneNumberBtn}
        />
        {isLoading ? (
          <div
            style={{
              marginRight: '-30px',
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          >
            <Spinner size="30px" />
          </div>
        ) : (
          <div />
        )}
      </SpinnerRow>
    </MfaViewContainer>
  );
}
