import { TabWrapper } from '@/app/wrappers/Containers';
import PageNavBar from '@/shared/components/PageNavBar';
import PayoutMethodPanel from '@/shared/components/PaymentMethodPanel/PaymentMethodPanel';
import styled from 'styled-components';

const PayoutMethodView = styled.div`
  margin-top: 20px;
`;

export default function ManagePayoutMethodPage() {
  return (
    <TabWrapper>
      <PageNavBar title="Manage Payout Methods" />
      <PayoutMethodView>
        <PayoutMethodPanel hideTitle />
      </PayoutMethodView>
    </TabWrapper>
  );
}
