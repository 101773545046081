import { LoadingScreen } from '@/app/wrappers/Containers';
import { useGetPayoutMethod } from '@/services/usePayoutMethod';
import { useWorkerProfile } from '@/services/useWorkerProfile';

import { useLoadAstra } from '@/services/useAstra';
import { useBankingAccount } from '@/services/useBankingAccount';
import {
  PaymentMethodsChoices,
  PaymentMethodsContainer,
} from '@/shared/components/PaymentMethodPanel/PaymentMethodPanel.styled';
import { Bold, ErrorText, TitleText } from '@/shared/components/Text.styled';
import { BankingAccountStatus } from '@/shared/types';
import { Spinner } from '@checkrx/pay-component-library';
import { CheckrWalletChoice } from './PaymentMethodChoices/CheckrWalletChoice';
import { DirectDepositChoice } from './PaymentMethodChoices/DirectDepositChoice';
import { InstantDirectDepositChoice } from './PaymentMethodChoices/InstantDirectDepositChoice';
import { WalletClosedChoice } from './PaymentMethodChoices/WalletClosedChoice';

export default function PayoutMethodPanel({
  message,
  hideTitle = false,
  hideDirectDepositActions = false,
}: {
  message?: JSX.Element;
  hideTitle?: boolean;
  hideDirectDepositActions?: boolean;
}) {
  const { data: workerProfile, isLoading: isLoadingWorkerProfile } = useWorkerProfile();
  const { data: bankingData, isLoading: isLoadingBankingData } = useBankingAccount();
  const { data: payoutMethodAccount } = useGetPayoutMethod();

  const instantDirectDepositEnabled = () => {
    // Worker specific enabled
    // customer enabled
    // not a business worker
    return (
      (workerProfile?.profile?.additionalPayoutMethods?.pushToCardDirectDeposit?.enabled ||
        workerProfile?.customer?.payoutMethods?.pushToCardDirectDeposit?.enabled) &&
      workerProfile.profile.workerType !== 'business'
    );
  };

  const {
    open: openAstra,
    removeCard,
    card: astraCard,
    error: astraError,
  } = useLoadAstra(instantDirectDepositEnabled());

  const isBankingAccountClosed = bankingData?.accountStatus === BankingAccountStatus.Closed;

  if (isLoadingBankingData || isLoadingWorkerProfile) {
    return (
      <LoadingScreen>
        <Spinner />
      </LoadingScreen>
    );
  }

  if (!workerProfile || !bankingData) {
    return (
      <div>
        <ErrorText> Failed to load page. Try again!</ErrorText>
      </div>
    );
  }

  return (
    <>
      {!hideTitle && <TitleText>Payout Methods</TitleText>}
      <PaymentMethodsContainer>
        {message || (
          <>
            Speed up your next payout. Use <Bold>Checkr Wallet</Bold> for instant payouts.
          </>
        )}
        <PaymentMethodsChoices>
          {isBankingAccountClosed ? (
            <WalletClosedChoice />
          ) : (
            <CheckrWalletChoice workerProfile={workerProfile} />
          )}
          <DirectDepositChoice
            workerProfile={workerProfile}
            payoutMethodAccount={payoutMethodAccount}
            hideDirectDepositActions={hideDirectDepositActions}
          />
          {instantDirectDepositEnabled() && (
            <InstantDirectDepositChoice
              astraError={astraError}
              workerProfile={workerProfile}
              removeCard={removeCard}
              openAstra={openAstra}
              card={astraCard}
            />
          )}
        </PaymentMethodsChoices>
      </PaymentMethodsContainer>
    </>
  );
}
