import { ReactComponent as CameraIcon } from '@/shared/assets/camera-plus.svg';
import { ReactComponent as CloseIcon } from '@/shared/assets/close-ex.svg';
import pdfIconSrc from '@/shared/assets/pdf.svg';
import DividedCard from '@/shared/components/DividedCard';
import { NormalText, SmallText } from '@/shared/components/Text.styled';
import { colors, fontSizes, fontWeights } from '@/shared/styles';
import { RequiredDocumentType, WorkerProfileRequiredDocument } from '@/shared/types';
import { Button, Spinner } from '@checkrx/pay-component-library';
import { ComponentProps, useEffect, useReducer, useRef, useState } from 'react';
import styled from 'styled-components';

const DocumentRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const DocumentType = styled.p`
  font-size: 16px;
  font-weight: ${fontWeights.bold};
  color: ${(p) => p.theme.colors.text.primary};
  line-height: 1.3;
  text-wrap: pretty;
  margin-bottom: 4px;
`;

const AttentionBanner = styled.p`
  font-size: ${fontSizes.smallMedium};
  font-weight: 500;
  color: ${colors.primaryBlue};
  margin: 8px 0 0;
  padding: 4px 8px;
  background: ${colors.backgroundGrey};
  border: 1px solid ${colors.accentYellow};
  border-radius: 2px;
`;

const DocumentInputWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 88px);
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  text-align: center;
`;

const DocumentInputLabel = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: ${fontSizes.small};
  color: ${colors.primaryBlue};
  line-height: 1.3;
  padding: 4px;
  background: ${colors.primaryGreyLight};
  border: 1px dashed ${colors.primaryBlue};
  border-radius: 4px;
  aspect-ratio: 1;
  transition: filter 0.1s ease-in-out;

  &:hover {
    filter: brightness(75%);
  }

  &:focus-within {
    outline: 5px auto -webkit-focus-ring-color;
  }

  input[type='file'] {
    position: absolute;
    left: -99999px;
    width: 1px;
    height: 1px;
    opacity: 0;
  }
`;

const StyledPreviewImage = styled(PreviewImage)`
  width: auto;
  height: 80px;
  max-height: 100%;
  object-fit: contain;
`;

const RemovePreviewButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  color: ${colors.trueWhite};
  padding: 2px;
  background: ${colors.accentRed};
  border-radius: 50%;
  z-index: 1;
  transition: filter 0.1s ease-in-out;

  &:hover {
    filter: brightness(75%);
  }
`;

type VerticalStackProps = {
  gap?: string;
};
const VerticalStack = styled.div<VerticalStackProps>`
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.gap};
`;

// Create a larger clickable area without affecting layout
const TouchTargetExpander = styled.span`
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: max(100%, 40px);
  height: max(100%, 40px);
  transform: translate(-50%, -50%);
`;

const documentTypeDisplay = (documentType: RequiredDocumentType) => {
  switch (documentType) {
    case RequiredDocumentType.AddressVerification:
      return 'Address Verification';
    case RequiredDocumentType.IdDocument:
      return 'Unexpired Government Issued Photo ID';
    case RequiredDocumentType.Passport:
      return 'Passport';
    case RequiredDocumentType.SocialSecurityCard:
      return 'Social Security Card';
    case RequiredDocumentType.SelfieVerification:
      return 'Selfie Verification';
    default:
      return null;
  }
};

export type ManualReviewDocumentFormValues = {
  [K in keyof typeof RequiredDocumentType]?: File;
};

type RequiredDocumentsFormProps = {
  onSubmit: (formValues: ManualReviewDocumentFormValues) => Promise<void>;
  requiredDocuments?: WorkerProfileRequiredDocument[];
};

/**
 * A dynamic form for accepting 1 or more required documents
 */
export function RequiredDocumentsForm({ onSubmit, requiredDocuments }: RequiredDocumentsFormProps) {
  const [formState, setFormState] = useState<'idle' | 'submitting'>('idle');
  const [formValues, setFormValues] = useReducer(
    (prev: ManualReviewDocumentFormValues, change: ManualReviewDocumentFormValues) => {
      return { ...prev, ...change };
    },
    {}
  );

  // The current ZD-only flow means we don't have visibility into what the worker
  // actually has to upload (they could be coming back and re-uploading a single doc).
  // All we do here is make sure at least one doc has been selected, including
  // the custom IdDocumentBackSide type.
  const isValid =
    requiredDocuments?.some((doc) => Boolean(formValues[doc.documentType])) ||
    Boolean(formValues[RequiredDocumentType.IdDocumentBackSide]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormState('submitting');
    await onSubmit(formValues);
    setFormState('idle');
  };

  return (
    <form onSubmit={handleSubmit}>
      <VerticalStack gap="32px">
        <DividedCard
          elements={requiredDocuments?.map((reqDoc) => (
            <DocumentRow key={reqDoc.id}>
              <DocumentInputWrapper>
                <DocumentInput
                  id={reqDoc.documentType}
                  name={reqDoc.documentType}
                  label={
                    reqDoc.documentType === RequiredDocumentType.IdDocument
                      ? 'Front of ID'
                      : 'Add photo'
                  }
                  file={formValues[reqDoc.documentType]}
                  onSelect={(file) => setFormValues({ [reqDoc.documentType]: file })}
                />

                {reqDoc.documentType === RequiredDocumentType.IdDocument ? (
                  <DocumentInput
                    id={RequiredDocumentType.IdDocumentBackSide}
                    name={RequiredDocumentType.IdDocumentBackSide}
                    label="Back of ID"
                    file={formValues[RequiredDocumentType.IdDocumentBackSide]}
                    onSelect={(file) =>
                      setFormValues({ [RequiredDocumentType.IdDocumentBackSide]: file })
                    }
                  />
                ) : null}
              </DocumentInputWrapper>

              <div>
                <SmallText>{reqDoc.status}</SmallText>
                <DocumentType>{documentTypeDisplay(reqDoc.documentType)}</DocumentType>
                <NormalText>{reqDoc.description}</NormalText>
                {reqDoc.documentType === RequiredDocumentType.IdDocument ? (
                  <AttentionBanner>
                    If uploading a drivers license you must include a photo of both the front and
                    back.
                  </AttentionBanner>
                ) : null}
              </div>
            </DocumentRow>
          ))}
        />

        <Button
          text={
            formState === 'submitting'
              ? ((<Spinner size="24px" />) as unknown as string)
              : 'Upload Selected Documents'
          }
          colorVariant="brand"
          sizeVariant="big"
          width="100%"
          // Necessary to prevent using the default click handler, which calls preventDefault
          onClick={() => null}
          disabled={!isValid || formState === 'submitting'}
        />
      </VerticalStack>
    </form>
  );
}

type GeneralDocumentFormProps = {
  onSubmit: (formValues: ManualReviewDocumentFormValues) => Promise<void>;
};

/**
 * A static, single-input form for accepting a `General` file upload
 */
export function GeneralDocumentForm({ onSubmit }: GeneralDocumentFormProps) {
  const [formState, setFormState] = useState<'idle' | 'submitting'>('idle');
  const [formValues, setFormValues] = useReducer(
    (prev: ManualReviewDocumentFormValues, change: ManualReviewDocumentFormValues) => {
      return { ...prev, ...change };
    },
    {}
  );

  const isValid = Boolean(formValues[RequiredDocumentType.General]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setFormState('submitting');
    await onSubmit(formValues);
    setFormState('idle');
  };

  return (
    <form onSubmit={handleSubmit}>
      <VerticalStack gap="16px">
        <DocumentInputWrapper>
          <DocumentInput
            label="Add photo"
            file={formValues[RequiredDocumentType.General]}
            onSelect={(file) =>
              setFormValues({
                [RequiredDocumentType.General]: file,
              })
            }
          />
        </DocumentInputWrapper>

        <Button
          text={
            formState === 'submitting'
              ? ((<Spinner size="16px" />) as unknown as string)
              : 'Upload Document'
          }
          colorVariant="brand"
          width="100%"
          // Necessary to prevent using the default click handler, which calls preventDefault
          onClick={() => null}
          disabled={!isValid || formState === 'submitting'}
        />
      </VerticalStack>
    </form>
  );
}

type DocumentInputProps = Omit<ComponentProps<'input'>, 'onChange' | 'onSelect' | 'value'> & {
  file?: File;
  label?: string;
  onSelect: (file?: File) => void;
};

export function DocumentInput({ file, label, onSelect, ...rest }: DocumentInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  // File inputs can't be fully controlled (you can't set a value),
  // but we can clear it's value imperatively
  useEffect(() => {
    if (!file && inputRef.current) {
      inputRef.current.value = '';
    }
  }, [file]);

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <DocumentInputLabel>
        <input
          ref={inputRef}
          type="file"
          accept="image/png, image/jpg, .pdf"
          aria-label="Select a file"
          onChange={(e) => {
            const selectedFile = e.target.files?.[0];
            onSelect(selectedFile);
          }}
          {...rest}
        />

        {file ? (
          <StyledPreviewImage
            image={file}
            width="80"
            height="80"
            alt="Preview of your uploaded document"
          />
        ) : (
          <>
            <CameraIcon />
            {label}
          </>
        )}
      </DocumentInputLabel>

      {file ? (
        <RemovePreviewButton type="button" aria-label="Remove document" onClick={() => onSelect()}>
          <CloseIcon width="16" height="16" />
          <TouchTargetExpander aria-hidden="true" />
        </RemovePreviewButton>
      ) : null}
    </div>
  );
}

type PreviewImageProps = Omit<ComponentProps<'img'>, 'src'> & {
  image?: File;
};

function PreviewImage({ image, ...rest }: PreviewImageProps) {
  const [previewSrc, setPreviewSrc] = useState<string | undefined>();

  useEffect(() => {
    if (!image) {
      return;
    }

    if (image.type === 'application/pdf') {
      setPreviewSrc(pdfIconSrc);
      return;
    }

    const objectUrl = URL.createObjectURL(image);
    setPreviewSrc(objectUrl);
    return () => {
      URL.revokeObjectURL(objectUrl);
    };
  }, [image]);

  return <img src={previewSrc} {...rest} />;
}
