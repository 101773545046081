import { ActionPrompt } from '@/shared/components/InAppMessages/MessageContent';
import { SmallMediumText } from '@/shared/components/Text.styled';
import { WITHDRAWAL_PAGE } from '@/shared/helpers';
import { useExternalLinkPoster } from '@/shared/hooks/useExternalLinkPoster';
import { PayoutMethod, Transaction, TransactionStatus } from '@/shared/types';
import dayjs from 'dayjs';
import { AttributeLabel, AttributeValue, AttributeWrapper } from './TransactionDetailPage';
import { ClearingTimeStatusContainer, StatusText, StatusLabel } from './TransactionPages.styled';

type Props = {
  transaction: Transaction;
};

const payoutMethodDescriptions = {
  [PayoutMethod.ACHDirectDeposit]: 'Direct Deposit',
  [PayoutMethod.CheckrPayBankAccount]: 'Instant Transfer to Pay Account',
  [PayoutMethod.PushToCardDirectDeposit]: 'Instant Direct Deposit',
};

export default function PayoutTransactionDetailSection({ transaction }: Props) {
  const sendExternalLink = useExternalLinkPoster({
    link: WITHDRAWAL_PAGE, // TODO: change this to page about direct deposits
  });

  const { expectedCompletionDate, transactionStatus } = transaction;
  let expectedCompletionDateMessage;

  if (transactionStatus === TransactionStatus.Pending) {
    if (expectedCompletionDate) {
      expectedCompletionDateMessage = dayjs(expectedCompletionDate).format('MMM D, YYYY');
    } else if (dayjs().isAfter(dayjs(expectedCompletionDate))) {
      expectedCompletionDateMessage = 'Any time today';
    }
  }

  return (
    <>
      <AttributeWrapper>
        <AttributeLabel>Payout Method</AttributeLabel>
        <AttributeValue>
          {
            payoutMethodDescriptions[
              transaction.metadata?.payoutMethod ?? PayoutMethod.CheckrPayBankAccount
            ]
          }
        </AttributeValue>
      </AttributeWrapper>
      {expectedCompletionDateMessage && (
        <ClearingTimeStatusContainer>
          <StatusLabel>
            Approximate Clearing Time
            <SmallMediumText>
              Your bank may take additional time to release funds.{' '}
              <ActionPrompt onClick={sendExternalLink}>Learn more about it here</ActionPrompt>.
            </SmallMediumText>
          </StatusLabel>
          <StatusText>{expectedCompletionDateMessage}</StatusText>
        </ClearingTimeStatusContainer>
      )}
    </>
  );
}
