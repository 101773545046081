import { useCashbackSummary } from '@/services/useCashback';
import { useFeatureFlagsFromProfile } from '@/services/useWorkerProfile';
import LoadingDots from '@/shared/components/LoadingDots';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { formatCentsAsCurrency, formatFractionAsPercent, SUPPORT_REWARDS_PAGE } from '../helpers';
import { useExternalLinkPoster } from '../hooks/useExternalLinkPoster';
import { CashbackSummary, RewardTier } from '../types';
import {
  CashbackAmount,
  CashbackContainer,
  CashbackHeadingContainer,
  CashbackInfoPanel,
  CashbackProgressPanel,
  CashbackTierBadge,
  CashbackTierCompletionProgressBar,
  CenterSpinner,
  DollarPart,
  ProgressBarMarker,
} from './CashbackSummaryPanel.styled';
import { Bold, ExternalLink, NormalText } from './Text.styled';

export default function CashbackSummaryPanel() {
  const { data: featureFlags } = useFeatureFlagsFromProfile();
  const customerFlags = featureFlags?.customerFlags;
  const cashbackRewardEnabled = customerFlags?.cashbackRewards?.enabled;

  if (!cashbackRewardEnabled) {
    return <></>;
  }

  const { data: cashbackSummary, isLoading } = useCashbackSummary();

  if (isLoading || !cashbackSummary) {
    return (
      <CenterSpinner>
        <LoadingDots size="10px" />
      </CenterSpinner>
    );
  }

  return (
    <CashbackContainer>
      <CashbackHeader cashbackSummary={cashbackSummary} />
      <CashbackInfoSection cashbackSummary={cashbackSummary} />
      <CashbackProgress cashbackSummary={cashbackSummary} />
    </CashbackContainer>
  );
}

interface CashbackComponentProps {
  cashbackSummary: CashbackSummary;
}

function CashbackHeader({ cashbackSummary }: CashbackComponentProps) {
  const { cashbackThisMonth, rewardMonth, rewardTier } = cashbackSummary;
  const [dollars, cents] = (cashbackThisMonth / 100.0).toFixed(2).split('.');
  const formattedRewardMonth = dayjs(rewardMonth).format('MMMM YYYY');

  return (
    <CashbackHeadingContainer>
      <div>
        <span>Cashback earned</span>
        <CashbackAmount>
          $ <DollarPart>{dollars}</DollarPart>.{cents}
        </CashbackAmount>
        <NormalText>In {formattedRewardMonth}</NormalText>
      </div>
      <CashbackTierBadge rewardTier={rewardTier}>{rewardTier} tier</CashbackTierBadge>
    </CashbackHeadingContainer>
  );
}

function CashbackInfoSection({ cashbackSummary }: CashbackComponentProps) {
  const sendExternalLink = useExternalLinkPoster({
    link: SUPPORT_REWARDS_PAGE,
    prependText: 'Learn more',
  });

  const {
    basicTierRate,
    onboardingComplete,
    onboardingTransactionsThreshold,
    premiumTierRate,
    premiumTierSpendThreshold,
    rewardedCategoryDescription,
    rewardTier,
    spendLeftTillPremium,
  } = cashbackSummary;

  if (!onboardingComplete) {
    return (
      <CashbackInfoPanel>
        <Bold>
          Earn {formatFractionAsPercent(premiumTierRate)} cashback on first{' '}
          {onboardingTransactionsThreshold} {rewardedCategoryDescription} purchases.{' '}
        </Bold>
        Keep your premium tier by spending{' '}
        {formatCentsAsCurrency(premiumTierSpendThreshold, { showCents: false })} anywhere every
        month.
        <br />
        <ExternalLink onClick={sendExternalLink}>Learn more</ExternalLink>.
      </CashbackInfoPanel>
    );
  } else if (rewardTier === RewardTier.Premium) {
    return (
      <CashbackInfoPanel>
        <Bold>
          You are earning {formatFractionAsPercent(premiumTierRate)} cashback on{' '}
          {rewardedCategoryDescription} purchases.
        </Bold>{' '}
        Keep your premium tier by spending{' '}
        {formatCentsAsCurrency(premiumTierSpendThreshold, { showCents: false })} anywhere every
        month.
        <br />
        <Link to="/transactions?filter=cashback" style={{ textDecoration: 'underline' }}>
          View your rewards
        </Link>{' '}
        or <ExternalLink onClick={sendExternalLink}>learn more</ExternalLink>.
      </CashbackInfoPanel>
    );
  } else {
    // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
    const spendLeft = formatCentsAsCurrency(spendLeftTillPremium!, {
      showCents: false,
    });
    return (
      <CashbackInfoPanel>
        You are earning {formatFractionAsPercent(basicTierRate)} cashback on{' '}
        {rewardedCategoryDescription} purchases. Spend <Bold>{spendLeft}</Bold> more on any category
        to start earning {formatFractionAsPercent(premiumTierRate)} cashback on{' '}
        {rewardedCategoryDescription}
        .
        <br />
        <ExternalLink onClick={sendExternalLink}>Learn more</ExternalLink>.
      </CashbackInfoPanel>
    );
  }
}

function CashbackProgress({ cashbackSummary }: CashbackComponentProps) {
  const {
    onboardingComplete,
    onboardingTransactionsCompleted,
    onboardingTransactionsThreshold,
    premiumTierSpendThreshold,
    premiumTierRate,
    purchasesThisMonth,
    rewardTier,
  } = cashbackSummary;

  if (!onboardingComplete) {
    const progressPercent =
      (onboardingTransactionsCompleted / onboardingTransactionsThreshold) * 100.0;

    return (
      <CashbackProgressPanel>
        <div>
          <Bold>
            {onboardingTransactionsCompleted} / {onboardingTransactionsThreshold}
          </Bold>{' '}
          purchases made for {formatFractionAsPercent(premiumTierRate)} cashback
        </div>
        <CashbackTierCompletionProgressBar rewardTier={rewardTier}>
          <div style={{ width: `${progressPercent}%` }}></div>
        </CashbackTierCompletionProgressBar>
      </CashbackProgressPanel>
    );
  } else if (rewardTier === RewardTier.Basic) {
    const progressPercent = (purchasesThisMonth / premiumTierSpendThreshold) * 100.0;
    return (
      <CashbackProgressPanel>
        <ProgressBarMarker>${premiumTierSpendThreshold / 100.0}</ProgressBarMarker>
        <CashbackTierCompletionProgressBar rewardTier={rewardTier}>
          <div style={{ width: `${progressPercent}%` }}></div>
        </CashbackTierCompletionProgressBar>
      </CashbackProgressPanel>
    );
  } else {
    return <></>;
  }
}
