import { fontSizes, fontWeights } from '@/shared/styles';
import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { MessageContentProps } from '../MessageContent';

const NewFeatureAnnouncementContainer = styled.div`
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.light};
  margin-bottom: 15px;
  text-align: left;

  p {
    margin-top: 10px;
  }
  ul {
    list-style: outside !important;
    margin-left: 10px;
  }
  li {
    list-style-type: disc;
  }
`;

const Heading = styled.div`
  text-align: center;
  display: block;
  font-weight: ${fontWeights.bold};
  margin-bottom: 10px;
`;

export const NewFeatureAnnouncement: FC<MessageContentProps> = (props: MessageContentProps) => {
  const heading = (props.messageData?.heading || '') as string;
  const message = (props.messageData?.message || '') as string;

  return (
    <NewFeatureAnnouncementContainer>
      <Heading>{heading}</Heading>
      <ReactMarkdown linkTarget="_blank">{message}</ReactMarkdown>
    </NewFeatureAnnouncementContainer>
  );
};
