/**
 * Component for a virtual card display
 */
import { useCustomerTheme } from '@/services/useCustomerTheme';
import { useWorkerProfile } from '@/services/useWorkerProfile';
import circlesBackgroundSrc from '@/shared/assets/card-circle-background.svg';
import visaLogoSrc from '@/shared/assets/visa-logo-white.svg';
import checkrPayLogoSrc from '@/shared/assets/white-logo.svg';
import { TitleText } from '@/shared/components/Text.styled';
import { colors } from '@/shared/styles';
import styled from 'styled-components';
import InjectedCardData from './InjectedCardData';

const CardContainer = styled.div<{ isFrozen: boolean }>`
  position: relative;
  width: 100%;
  aspect-ratio: 338 / 183;
  padding: 20px;
  border-radius: 25px;
  background-color: ${(p) => p.theme.colors.backgrounds.virtualCard};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${colors.primaryWhite};
  ${(p) => p.isFrozen && `filter: blur(2px);`}
`;

const CirclesBackground = styled.img`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-items: center;
  gap: 20px;
`;

const Name = styled(TitleText)`
  flex: 1;
  color: ${colors.primaryWhite};
  overflow-wrap: break-word;
`;

const CustomerLogo = styled.img`
  flex-shrink: 0;
  width: auto;
  max-width: 100px;
  height: auto;
  max-height: 80px;
`;

const VisaLogo = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 55px;
  height: auto;
  transform-origin: bottom right;
  transform: translate(-20px, -20px);
`;

export default function VirtualCard({
  showSensitive = false,
  isFrozen = false,
}: {
  showSensitive?: boolean;
  isFrozen?: boolean;
}) {
  const { data: workerProfile, isLoading: _isLoading } = useWorkerProfile();
  const { checkrCorePII, preferredName } = workerProfile?.profile || {};
  const { firstName, lastName } = checkrCorePII || {};
  const { theme } = useCustomerTheme();
  const customerLogoSrc = theme.images?.virtualCardLogo?.url;

  return (
    <CardContainer isFrozen={isFrozen}>
      {!customerLogoSrc && (
        <CirclesBackground src={circlesBackgroundSrc} width="338" height="183" alt="" />
      )}

      <TopRow>
        <Name>{`${preferredName || firstName} ${lastName}`}</Name>
        <CustomerLogo
          src={customerLogoSrc || checkrPayLogoSrc}
          width="100"
          height="80"
          alt="Company logo"
        />
      </TopRow>

      <InjectedCardData showSensitive={showSensitive} />

      <VisaLogo src={visaLogoSrc} width="18" height="55" alt="Visa logo" />
    </CardContainer>
  );
}
