/* eslint-disable no-console */
/**
 * Our Global Error Fallback. All errors except Routing Errors (client-side 404s) hit this Boundary
 * unless they're caught earlier.
 *
 * TODOs(Carter):
 * - Dev React Error Overlay View?
 */
import AuthErrorPage from '@/pages/errors/AuthErrorPage';
import ErrorPage from '@/pages/errors/ErrorPage';
import * as Sentry from '@sentry/react';
import { isAxiosError } from 'axios';
import { FallbackProps } from 'react-error-boundary';

export default function BaseErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  // Only record non-401/404 errors
  if (isAxiosError(error)) {
    const { response } = error;
    const statusCode = response?.data?.http_code || 500;

    if (statusCode >= 500) {
      Sentry.captureException(error);
    }

    switch (statusCode) {
      case 401:
        return <AuthErrorPage />;
      case 404:
        return <AuthErrorPage />;
      default:
        return <ErrorPage resetErrorBoundary={resetErrorBoundary} />;
    }
  } else {
    Sentry.captureException(error);
    console.log('Found a non-server-side error: ');
    console.log(error);
  }
  return <ErrorPage resetErrorBoundary={resetErrorBoundary} />;
}
