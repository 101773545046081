// Set up a Context for interacting with themes
import { ReactNode, useEffect, useState } from 'react';

import { defaultTheme } from '@/app/defaultTheme';
import { useWorkerProfile } from '@/services/useWorkerProfile';
import { setStyledComponentsTheme } from '@checkrx/pay-component-library';
import deepmerge from 'deepmerge';
import { createContext, useContext, useMemo } from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';

interface ThemePreferenceContext {
  theme: DefaultTheme;
}

const defaultContext: ThemePreferenceContext = {
  theme: defaultTheme,
};

const ThemePreferenceContext = createContext<ThemePreferenceContext>(defaultContext);

/**
 * WARNING:
 * The current error boundary relies on the theme, so this component cannot throw
 */
export const ThemePreferenceProvider = ({ children }: { children: ReactNode }) => {
  const { data: workerProfile } = useWorkerProfile({ useErrorBoundary: false });
  const [theme, setTheme] = useState(defaultTheme);

  useEffect(() => {
    const customerPreferences = workerProfile?.customer?.preferences;
    if (customerPreferences) {
      const merged = deepmerge(defaultTheme, customerPreferences);
      setStyledComponentsTheme(merged);
      setTheme(merged);
    }
  }, [workerProfile?.customer?.preferences]);

  const context = useMemo(() => ({ theme }), [theme]);

  return (
    <ThemePreferenceContext.Provider value={context}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemePreferenceContext.Provider>
  );
};

export const useCustomerTheme = () => {
  return useContext(ThemePreferenceContext);
};
