import { colors, fontWeights } from '@/shared/styles';
import React from 'react';
import styled from 'styled-components';

const RadioGroup = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 10px;
  position: relative;
  width: 100%;
  background: ${colors.primaryGreyLight};
  padding: 2px;
  border-radius: 10px;

  &:focus-within {
    outline: 1px solid ${(p) => p.theme.colors.text.primary};
  }

  input {
    position: absolute;
    left: -9999px;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  label {
    height: 100%;
    color: ${(p) => p.theme.colors.text.primary};
    font-weight: ${fontWeights.bold};
    font-size: 12px;
    text-align: center;
    padding: 8px 0px;
    border-radius: 8px;
  }

  & input:checked + label {
    color: ${colors.trueWhite};
    background: ${(p) => p.theme.colors.buttons.primary};
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  }
`;

interface RadioGroupProps<Value> {
  name: string;
  value: Value;
  options: Array<{ label: string; value: Value }>;
  onChange?: (val: Value) => void;
}

export function HorizontalRadioGroup<Value extends string>({
  name,
  value,
  options,
  onChange,
}: RadioGroupProps<Value>) {
  return (
    <RadioGroup>
      {options.map((option) => (
        <React.Fragment key={option.value}>
          <input
            type="radio"
            name={name}
            id={option.value}
            value={option.value}
            checked={value === option.value}
            onChange={(e) => {
              onChange?.(e.target.value as Value);
            }}
          />
          <label htmlFor={option.value}>{option.label}</label>
        </React.Fragment>
      ))}
    </RadioGroup>
  );
}
