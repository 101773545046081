import { SUPPORT_HOME_PAGE } from '@/shared/helpers';
import { useExternalLinkPoster } from '@/shared/hooks/useExternalLinkPoster';
import { FC } from 'react';
import { ActionPrompt, ContentBold, ContentText, MessageContentProps } from '../MessageContent';

export const TaxesAreComing: FC<MessageContentProps> = () => {
  const sendSupportHomePage = useExternalLinkPoster({
    link: SUPPORT_HOME_PAGE,
    prependText: 'Checkr Pay Support',
  });
  return (
    <>
      <ContentBold align="center">💵 Tax season is coming! 💵</ContentBold>
      <ContentText>
        As tax season arrives, please verify your email address connected with Checkr Pay is
        accurate. If you require assistance, please reach out to support.
      </ContentText>
      <ActionPrompt onClick={sendSupportHomePage}>Get support</ActionPrompt>
    </>
  );
};
