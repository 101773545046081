import { ReactComponent as CardIcon } from '@/shared/assets/credit-card.svg';
import { ReactComponent as MoneyIcon } from '@/shared/assets/money-clip.svg';
import { ReactComponent as TrashIcon } from '@/shared/assets/trashcan.svg';
import { colors, fontSizes, fontWeights } from '@/shared/styles';
import styled from 'styled-components';

import { useRemoveLinkedCard } from '@/services/useAstra';
import { NormalText, TitleText } from '@/shared/components/Text.styled';
import { Spinner } from '@checkrx/pay-component-library';
import { FC, ReactElement } from 'react';
import { ExternalAccount } from '../types';
import { toast } from './Toaster/Toaster';

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.primaryGreyLight};
`;

const DividedAccountsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const LinkContainer = styled.div<{ selected: boolean; canSelect: boolean }>`
  /* Size */
  width: 100%;
  height: 36px;

  /* Container properties */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${(p) => p.selected && `background: ${colors.primaryGreyLight}`};
  border-radius: 6px;
  padding: 0px 8px;
  ${(p) =>
    p.canSelect &&
    `
    cursor: pointer;
    &:hover {
      background: ${colors.backgroundGrey};
    }`}
`;

const BankContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const AccountContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const TextDescription = styled(TitleText)`
  font-size: ${fontSizes.medium};
`;

const BankAccountNumber = styled(NormalText)`
  color: ${(p) => p.theme.colors.text.secondary};
  font-weight: ${fontWeights.light};
  font-size: ${fontSizes.medium};
`;

const DeleteContainer = styled.div<{ disabled: boolean }>`
  color: red;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
`;

type Props = {
  accountNumber: string;
  type?: 'bank' | 'card';
  bankName?: string;
  ownerName?: string;
  onDeletion?: (() => void) | null;
  selected?: boolean;
  onSelect?: (() => void) | null;
};

export const ExternalAccountLink: FC<Props> = ({
  accountNumber,
  type = 'bank',
  bankName = '',
  ownerName = '',
  onDeletion = null,
  selected = false,
  onSelect = null,
}) => {
  const { mutate: removeLinkedCard, isLoading } = useRemoveLinkedCard({
    onSuccess: () => {
      toast({
        duration: 3000,
        message: `Your linked card ending in ${accountNumber} has been removed.`,
        type: 'success',
      });
      onDeletion?.();
    },
  });
  return (
    <LinkContainer
      key={accountNumber}
      selected={selected}
      onClick={() => onSelect?.()}
      canSelect={!!onSelect}
    >
      <BankContainer>
        {type === 'bank' ? <MoneyIcon /> : <CardIcon />}
        <TextDescription>{bankName || ownerName || 'Linked External account'}</TextDescription>
      </BankContainer>
      <AccountContainer>
        <BankAccountNumber>
          {type === 'bank' ? '*******' : '**** **** ****'} {accountNumber?.slice(-4) || ''}
        </BankAccountNumber>
        {!!onDeletion && (
          <DeleteContainer
            onClick={() => {
              if (isLoading) return;
              removeLinkedCard();
            }}
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : <TrashIcon />}
          </DeleteContainer>
        )}
      </AccountContainer>
    </LinkContainer>
  );
};

/**
 * Renders a list of all linked accounts in a DividedCard component
 */
export const LinkedExternalAccountsList = ({
  linkedAccounts = [],
  onSelect = () => undefined,
  selectedId = '',
}: {
  linkedAccounts: Array<ExternalAccount>;
  onSelect?: (id: ExternalAccount['externalAccountId']) => void;
  selectedId?: ExternalAccount['externalAccountId'];
}) => {
  const elements = linkedAccounts.map((externalAccount) => (
    <ExternalAccountLink
      onSelect={() => onSelect(externalAccount?.externalAccountId)}
      key={externalAccount?.accountNumber}
      accountNumber={externalAccount?.accountNumber}
      bankName={externalAccount?.bank}
      ownerName={externalAccount?.ownerName}
      selected={selectedId === externalAccount?.externalAccountId}
    />
  ));

  const dividedElements = elements.reduce((acc: Array<ReactElement>, curr: ReactElement) => {
    if (acc.length > 0) {
      acc.push(<Divider key={`divider-${curr?.key}`} />);
    }
    acc.push(curr);
    return acc;
  }, []);
  return <DividedAccountsContainer>{dividedElements}</DividedAccountsContainer>;
};
