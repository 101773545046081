import { ErrorText } from '@/shared/components/Text.styled';
import { SUPPORT_UPDATE_PERSONAL_INFO_PAGE } from '@/shared/helpers';
import { useExternalLinkPoster } from '@/shared/hooks/useExternalLinkPoster';
import { ContactSupportText, ManualPIIRequestText } from '../shared/confirm-pages.styled';

export function ErrorMessages() {
  const sendExternalLink = useExternalLinkPoster({
    link: SUPPORT_UPDATE_PERSONAL_INFO_PAGE,
    prependText: 'Update Personal Information',
  });
  return (
    <ManualPIIRequestText>
      <ErrorText>
        We were unable to update your profile with the information provided.{' '}
        <ErrorText>
          You might already have an account with us. Please{' '}
          <ContactSupportText onClick={sendExternalLink}>Contact support.</ContactSupportText> if
          you believe this is an error
        </ErrorText>
      </ErrorText>
    </ManualPIIRequestText>
  );
}
