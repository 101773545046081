import { colors } from '@/shared/styles';
import { DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
  colors: {
    buttons: {
      primary: colors.primaryBlue,
      secondary: colors.primaryGreyDark,
      accent: colors.accentTeal,
    },
    text: {
      primary: colors.primaryBlue,
      secondary: colors.primaryGreyDark,
      accent: colors.accentTeal,
    },
    graphics: {
      primary: colors.primaryBlue,
      secondary: '#95CEDC',
      accent: colors.accentTeal,
    },
    backgrounds: {
      virtualCard: colors.accentTeal,
      app: colors.trueWhite,
    },
  },
};
