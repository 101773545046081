/*
/**
 * Higher-Order component that ensures we our current worker profile is Active
 *
 * If Auth Changes (via the useAuth functions setAuthToken and removeAuthToken) everything
 * underneath this React Component will re-render.
 */
import { ReactNode, useEffect } from 'react';

import { useWorkerProfileIsActive } from '@/services/useWorkerProfile';
import useLoading from '@/shared/hooks/useLoading';
import { LoadingScope } from '@/shared/types';
import { Navigate } from 'react-router-dom';

export const WorkerIsActiveGuard = ({ children }: { children: ReactNode }) => {
  const { data: isActive, isLoading, isSuccess } = useWorkerProfileIsActive();
  const setLoading = useLoading(isLoading, LoadingScope.global);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  if (isSuccess) {
    if (!isActive) {
      return <Navigate to="/" />;
    }
  }
  return <>{children}</>;
};
