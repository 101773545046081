import serverApi from '@/services/serverApi';
import { useMutation } from '@tanstack/react-query';

interface BankInfo {
  address: string;
  isACHSupported: boolean;
  isWireSupported: boolean;
  name: string;
  routingNumber: string;
}

async function getBankInfo(routingNumber: string) {
  const res = await serverApi.get<{ institution: BankInfo }>('/banking/bank_info', {
    params: {
      routingNumber,
    },
  });
  return res.data.institution;
}

export const useFetchBankInfo = () => {
  return useMutation({
    mutationFn: async (routingNumber: string) => getBankInfo(routingNumber),
    useErrorBoundary: false,
  });
};
