/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * useAstra – Hooks encapsulating our Astra integration
 */
import { AstraCard } from '@/shared/types';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import serverApi from './serverApi';

// Set up for Astra Integration
export const initAstra = async () => {
  const script = document.createElement('script');
  script.src = process.env.REACT_APP_ASTRA_SDK_URL || '';

  return new Promise((resolve, _reject) => {
    script.onload = () => {
      resolve({ script });
    };
    document.head.appendChild(script);
  });
};

// Tell the global interface that we expect there to be an Astra namespace
declare global {
  interface Window {
    Astra: any;
  }
}

export enum AstraActionType {
  CollectAuthorization = 'COLLECT_AUTHORIZATION',
  VerifyProfile = 'VERIFY_PROFILE',
  SubmitDocument = 'SUBMIT_DOCUMENT',
  LinkAccount = 'LINK_ACCOUNT',
  LinkCard = 'LINK_CARD',
}

export const astraHandler = ({
  actionType,
  userIntentId,
  sessionToken,
  business,
  phone,
  phoneReadOnly,
  bypassConnect,
  state,
  onAuth,
  onError,
}: {
  actionType: AstraActionType;
  userIntentId: string;
  sessionToken?: string;
  business?: string;
  phone?: string;
  phoneReadOnly?: string;
  bypassConnect?: any;
  state?: any;
  onAuth?: any;
  onError: any;
}) => {
  return window.Astra.create({
    clientId: process.env.REACT_APP_ASTRA_CLIENT_ID,
    redirectUri: process.env.REACT_APP_ASTRA_REDIRECT_URI,
    actionType,
    userIntentId,
    sessionToken,
    business,
    phone,
    phoneReadOnly,
    bypassConnect,
    debitDirect: true,
    state,
    onAuth,
    onError,
  });
};

// Astra Queries and Mutations
async function fetchAccessToken(): Promise<{
  accessToken?: string;
  userIntentId?: string;
  sessionToken?: string;
}> {
  const response = await serverApi.get('/banking/astra/api_auth');
  return {
    accessToken: response.data?.accessToken,
    userIntentId: response.data?.userIntentId,
    sessionToken: response.data?.sessionToken,
  };
}
export const useGetAccessToken = (options?: UseQueryOptions) => {
  return useQuery({
    queryKey: ['astra', 'accessToken'],
    queryFn: () => fetchAccessToken(),
    // Custom Logic for Astra onboarding failures
    useErrorBoundary: (error: unknown) => (error as AxiosError)?.response?.status !== 403,
    ...options,
  });
};

async function fetchDebitCard() {
  const response = await serverApi.get('/banking/astra/card');
  return response.data?.card || null;
}
export const useDebitCard = (options?: UseQueryOptions) => {
  return useQuery({
    queryKey: ['astra', 'debitCard'],
    queryFn: () => fetchDebitCard(),
    // Custom Logic for Re-authenticating on a 403 (expired AccessToken)
    useErrorBoundary: (error: unknown) => (error as AxiosError)?.response?.status !== 403,
    ...options,
  });
};

export const useLoadAstra = (enabled = true) => {
  // load necessary data for astra
  const {
    isLoading: authLoading,
    isError: authError,
    data,
  } = useGetAccessToken({
    enabled: enabled,
  });
  const { accessToken, userIntentId, sessionToken } =
    (data as { accessToken?: string; userIntentId?: string; sessionToken?: string }) || {};

  const {
    data: card,
    isFetching: cardLoading,
    refetch: refetchCard,
  } = useDebitCard({ enabled: enabled && !authLoading && !authError && !!sessionToken });

  const { mutateAsync: removeLinkedCard } = useRemoveLinkedCard();

  // add a card instance
  const handleAddCard = async () => {
    const addCardInstance = await astraHandler({
      actionType: AstraActionType.LinkCard,
      userIntentId: userIntentId as string,
      sessionToken: sessionToken as string,
      onError: async (error: Error) => {
        throw error;
      },
    });
    addCardInstance.open();
  };

  // check if we are authorized,
  // if so we check for card. otherwise authorize then check for card
  const handleInitAstra = async () => {
    await initAstra();
    if (sessionToken && !card) {
      handleAddCard();
    }
  };

  useEffect(() => {
    if (!enabled) return;
    // we are watching to see if the astra instance has closed, if so we need to reload their card
    const obs = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        for (const el of mutation.removedNodes as any) {
          if (el.id === 'astra-iframe-container') {
            refetchCard();
          }
        }
      }
    });
    obs.observe(document.body, {
      childList: true,
    });
    return () => obs.disconnect();
  }, [enabled]);

  return {
    loading: authLoading || cardLoading,
    open: handleInitAstra,
    removeCard: removeLinkedCard,
    authorized: !!accessToken,
    card: card as AstraCard,
    refetch: refetchCard,
    error: authError,
  };
};

async function postInstantTransfer(amount: number) {
  const response = await serverApi.post('/banking/astra/instant_transfer', {
    amount: Math.round(amount * 100),
  });
  return response.data;
}
export const useCreateInstantTransfer = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (amount: number) => postInstantTransfer(amount),
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: ['banking', 'account'],
      });
    },
    onError: () => {
      return queryClient.invalidateQueries({
        queryKey: ['banking', 'account'],
      });
    },
    useErrorBoundary: true,
  });
};

const removeLinkedCard = async () => {
  const resp = await serverApi.get('/banking/astra/remove_card');
  return resp;
};

export const useRemoveLinkedCard = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => removeLinkedCard(),
    ...options,
    onSuccess: (...all) => {
      queryClient.setQueryData(['astra', 'debitCard'], null);
      options?.onSuccess?.(...all);
      return queryClient.invalidateQueries({
        queryKey: ['astra', 'debitCard'],
      });
    },
    onError: (...all) => {
      options?.onError?.(...all);
      return queryClient.invalidateQueries({
        queryKey: ['astra', 'debitCard'],
      });
    },
    useErrorBoundary: true,
  });
};
