import { Button } from '@checkrx/pay-component-library';
import styled from 'styled-components';

import { colors, fontSizes, fontWeights } from '@/shared/styles';

export const LegaleseContent = styled.div`
  margin-top: 40px;
`;

export const ContentBox = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 10px;
  margin-top: -80px;
  margin-left: auto;
  margin-right: auto;
`;

export const ErrorTitle = styled.h2`
  color: ${colors.accentRed};
  width: 100%;
  text-align: center;
  font-size: ${fontSizes.giant};
  font-weight: ${fontWeights.bold};
`;

export const StyledButton = styled(Button)`
  margin-top: 10px;
  margin-bottom: -20px; // Pull Legalese closer than it wants to be
`;

export const Graphic = styled.img`
  width: 100%;
  max-height: 50vh;
`;
