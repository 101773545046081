import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { currencyFormatter } from '../helpers';
import { Bold } from './Text.styled';

const SubHeading = styled.div`
  margin-bottom: 12px;
`;

export function DirectDepositSelectionSubHeading({
  pendingDirectDepositTotal,
  disableSignupMessage,
}: {
  pendingDirectDepositTotal: number;
  disableSignupMessage?: boolean;
}) {
  return (
    <>
      <SubHeading>
        {pendingDirectDepositTotal > 0.0 ? (
          <>
            You currently have{' '}
            <Link to="/transactions">
              {currencyFormatter.format(pendingDirectDepositTotal / 100)} in pending direct deposit
              payouts
            </Link>
            .{' '}
            {!disableSignupMessage && (
              <>
                Speed up your next payout by using <Bold>Checkr Wallet</Bold>.
              </>
            )}
          </>
        ) : (
          <>
            Payouts will automatically be sent to your externally linked bank account on pay day via
            a direct deposit.{' '}
            {!disableSignupMessage && (
              <>
                Use <Bold>Checkr Wallet</Bold> for faster payouts.
              </>
            )}
          </>
        )}{' '}
      </SubHeading>
    </>
  );
}
