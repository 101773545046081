/**
 * Bank Account page
 */

import { TabWrapper } from '@/app/wrappers/Containers';
import { useBankingShortCircuiter } from '@/services/bankingShortCircuit/useBankingShortCircuiter';
import PageNavBar from '@/shared/components/PageNavBar';
import BankAccountInformation from './BankingInformation';
import { ClosedBankAccount } from './ClosedBankAccount';

export default function BankingInformationPage() {
  const { isBankingAccountOpen } = useBankingShortCircuiter();
  return (
    <TabWrapper>
      <PageNavBar title="Bank Account" />
      {isBankingAccountOpen ? <BankAccountInformation /> : <ClosedBankAccount />}
    </TabWrapper>
  );
}
