import { useState } from 'react';

export const useLocalStorage = (keyName: string, defaultValue: string | null) => {
  const localStorageAvailable = typeof window !== 'undefined' && !!window?.localStorage;
  const [storedValue, setStoredValue] = useState(
    localStorageAvailable
      ? () => {
          try {
            const value = window.localStorage.getItem(keyName);
            if (value) {
              return JSON.parse(value);
            } else {
              window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
              return defaultValue;
            }
          } catch (err) {
            return defaultValue;
          }
        }
      : () => {
          try {
            const value = window.sessionStorage.getItem(keyName);
            if (value) {
              return JSON.parse(value);
            } else {
              window.sessionStorage.setItem(keyName, JSON.stringify(defaultValue));
              return defaultValue;
            }
          } catch (err) {
            return defaultValue;
          }
        }
  );
  const setValue = localStorageAvailable
    ? (newValue: string | null) => {
        window.localStorage.setItem(keyName, JSON.stringify(newValue));
        setStoredValue(newValue);
      }
    : (newValue: string | null) => {
        window.sessionStorage.setItem(keyName, JSON.stringify(newValue));
        setStoredValue(newValue);
      };
  return [storedValue, setValue];
};
