import { TabWrapper } from '@/app/wrappers/Containers';
import PageNavBar from '@/shared/components/PageNavBar';
import { Outlet } from 'react-router-dom';

export default function StatementsLayout() {
  return (
    <TabWrapper>
      <PageNavBar title="Statements" />
      <Outlet />
    </TabWrapper>
  );
}
