/**
 * React-router v6 doesn't let us access history anymore.
 * In order to get our animations right, we gotta do this ourselves.
 */
import { createContext, ReactNode, useContext, useState } from 'react';

interface IDashboardHistoryContext {
  dashboardHistory: Array<string>;
  push: (token: string) => void;
}

const DashboardHistoryContext = createContext<IDashboardHistoryContext>({
  dashboardHistory: [],
  push: (_s: string) => {
    /* noop */
  },
});

export function DashboardHistoryProvider({ children }: { children: ReactNode }) {
  const [dashboardHistory, setDashboardHistory] = useState<Array<string>>([]);

  const push = (location: string) => setDashboardHistory([...dashboardHistory, location]);

  return (
    <DashboardHistoryContext.Provider value={{ dashboardHistory, push }}>
      {children}
    </DashboardHistoryContext.Provider>
  );
}

export const useDashboardHistory = () => useContext(DashboardHistoryContext);
