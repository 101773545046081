/**
 * Component for a virtual card display
 *
 * TODOs:
 *  - Expiration Date:
 *    - Not currently treating expiration date as sensitive and no copy
 */
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useBankingCardAndToken } from '@/services/useBankingCard';
import copyIcon from '@/shared/assets/copy.svg';
import checkIcon from '@/shared/assets/white-check.svg';
import { NormalText, SmallText } from '@/shared/components/Text.styled';
import useLoading from '@/shared/hooks/useLoading';
import { colors, fontWeights } from '@/shared/styles';
import { LoadingScope } from '@/shared/types';
// import {
//   toggleLoadingSensitiveData,
//   toggleCopiedNumberToClipboard,
//   toggleCopiedCvvToClipboard,
// } from '../cardSlice';

const CardDataCol = styled.div`
  /* Box Settings */
  width: fit-content;
  /* Container Settings */
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: space-between;
  gap: 9px;
`;

const CardDataRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CardDataLabel = styled(SmallText)`
  color: ${colors.primaryWhite};
  margin-bottom: -3px;
`;

const CardDataText = styled(NormalText)`
  font-weight: ${fontWeights.bold};
  height: 15px;
  color: ${colors.primaryWhite};
`;

const SensitiveIframe = styled.div<{ width: string }>`
  width: ${(p) => p.width};
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`;

const CardData = styled.div<{ showSensitive?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: fit-content;
  margin-bottom: ${(p) => (p.showSensitive ? '-6px' : '0px')};
`;

const CopyButton = styled.span<{ copied?: boolean }>`
  height: 14px;
  width: 14px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Hover */

  &:hover {
    filter: brightness(75%);
    transition: 0.2s;
  }

  iframe {
    width: 100%;
    height: 100%;
    background-image: ${(p) => (p.copied ? `url(${checkIcon})` : `url(${copyIcon})`)};
    background-position: right;
    background-repeat: no-repeat;
    cursor: pointer;
  }
`;

// Typescript approach of announcing an externally-inserted variable
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let VGSShow: any;
async function showSensitiveCardData(
  cardId: string,
  bankToken: string,
  setLoadingSensitiveData: (val: boolean) => void,
  setCopiedCvvToClipboard: (val: boolean) => void,
  setCopiedNumberToClipboard: (val: boolean) => void
) {
  return new Promise<void>((resolve, reject) => {
    try {
      const show = VGSShow.create(
        process.env.REACT_APP_UNIT_VGS_VAULT_ID,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (state: { 'data-text': any }) => {
          const { 'data-text': dataText } = state;
          if (dataText?.revealed) {
            // Don't toggle loading until the data is revealed
            setLoadingSensitiveData(false);
          }
        }
      );

      // Fetch Iframes from VGS
      const cardNumberIframe = show.request({
        name: 'data-text',
        method: 'GET',
        path: `/cards/${cardId}/secure-data/pan`,
        headers: {
          Authorization: `Bearer ${bankToken}`,
        },
        htmlWrapper: 'text',
        jsonPathSelector: 'data.attributes.pan',
        // This serialization will separate card number with spaces
        // 4111111111111111 -> 4111 1111 1111 1111
        serializers: [show.SERIALIZERS.replace('(\\d{4})(\\d{4})(\\d{4})(\\d{4})', '$1 $2 $3 $4')],
      });
      const cvv2Iframe = show.request({
        name: 'data-text',
        method: 'GET',
        path: `/cards/${cardId}/secure-data/cvv2`,
        headers: {
          Authorization: `Bearer ${bankToken}`,
        },
        htmlWrapper: 'text',
        jsonPathSelector: 'data.attributes.cvv2',
      });

      const iframeCss = {
        padding: '0px',
        margin: '0px',
        'font-size': '14px',
        'font-weight': '400',
        background: 'none',
        color: `${colors.trueWhite}`,
        overflow: 'hidden',
        '@font-face': {
          'font-family': 'Outfit',
          'font-style': 'normal',
          'font-weight': '400',
          src: `url(https://fonts.gstatic.com/s/outfit/v6/QGYyz_MVcBeNP4NjuGObqx1XmO1I4TC1O4a0EwItq6fNIg.woff2) format('woff2')`,
          'unicode-range':
            'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, ' +
            'U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
        },
        'font-family': '"Outfit"',
      };

      cardNumberIframe.render('#cardNumber', iframeCss);

      // const cvvDiv = document.querySelector('#cvv');
      cvv2Iframe.render('#cvv', iframeCss);
      resolve();

      // Copy data using VGS' copyFrom function
      const copyCardNumberButton = show.copyFrom(
        cardNumberIframe,
        { text: '' },
        (status: string) => {
          if (status === 'success') {
            setCopiedCvvToClipboard(false);
            setCopiedNumberToClipboard(true);
          }
        }
      );
      const copyCvvButton = show.copyFrom(cvv2Iframe, { text: '' }, (status: string) => {
        if (status === 'success') {
          setCopiedNumberToClipboard(false);
          setCopiedCvvToClipboard(true);
        }
      });

      const buttonCss = {
        backgroundSize: 'cover, contain',
        color: `${colors.primaryWhite}`,
        padding: '0px',
        margin: '0px',
      };
      copyCardNumberButton.render('#copyCardNum', buttonCss);
      copyCvvButton.render('#copyCardCvv', buttonCss);
    } catch (vgsErr) {
      reject(vgsErr);
    }
  });
}

const SensitiveRow = styled.div`
  height: 21px;
  display: flex;
  flex-direction: row;
  align-items: start;
`;

export default function InjectedCardData({ showSensitive = false }: { showSensitive?: boolean }) {
  const [copiedCvvToClipboard, setCopiedCvvToClipboard] = useState(false);
  const [copiedNumberToClipboard, setCopiedNumberToClipboard] = useState(false);
  const setLoading = useLoading(false, LoadingScope.card);

  const { data: cardAndToken, isLoading: _isLoadingCardData } = useBankingCardAndToken();
  const card = cardAndToken?.card;
  const bankToken = cardAndToken?.bankToken ?? '';
  const cardId = card?.cardId ?? '';
  const lastFour = card?.lastFour ?? '';
  const expirationDate = card?.expirationDate ?? '';
  /*
      lastFour: string;
  expirationDate: Date;
  cardId: string;
  cardStatus: BankingCardStatus;
  accountId: string;
    */

  // Fetch Non-sensitive Data for the card on initial render
  useEffect(() => {
    async function getSensitiveData() {
      setLoading(true);
      await showSensitiveCardData(
        cardId,
        bankToken,
        setLoading,
        setCopiedCvvToClipboard,
        setCopiedNumberToClipboard
      );
    }

    if (showSensitive) {
      getSensitiveData();
    }

    return () => {
      setCopiedNumberToClipboard(false);
      setCopiedCvvToClipboard(false);
    };
  }, [showSensitive, bankToken]);

  return (
    <CardDataCol>
      <CardData showSensitive={showSensitive}>
        <CardDataLabel>Card Number</CardDataLabel>
        {showSensitive ? (
          <SensitiveRow>
            <SensitiveIframe width="140px" id="cardNumber" />
            <CopyButton id="copyCardNum" copied={copiedNumberToClipboard} />
          </SensitiveRow>
        ) : (
          <CardDataText>{`•••• •••• •••• ${lastFour}`}</CardDataText>
        )}
      </CardData>
      <CardDataRow>
        <CardData showSensitive={showSensitive}>
          <CardDataLabel>Expiry date</CardDataLabel>
          {showSensitive ? (
            <CardDataText>{`${expirationDate.split('-')[1]}/${
              expirationDate.split('-')[0]
            }`}</CardDataText>
          ) : (
            <CardDataText>••/••</CardDataText>
          )}
        </CardData>
        <CardData showSensitive={showSensitive}>
          <CardDataLabel>CVV</CardDataLabel>
          {showSensitive ? (
            <SensitiveRow>
              <SensitiveIframe width="25px" id="cvv" />
              <CopyButton id="copyCardCvv" copied={copiedCvvToClipboard} />
            </SensitiveRow>
          ) : (
            <CardDataText>•••</CardDataText>
          )}
        </CardData>
      </CardDataRow>
    </CardDataCol>
  );
}
