import { SUPPORT_HOME_PAGE } from '@/shared/helpers';
import { useExternalLinkPoster } from '@/shared/hooks/useExternalLinkPoster';
import { FC } from 'react';
import { ActionPrompt, ContentBold, ContentText, MessageContentProps } from '../MessageContent';

export const UpcomingOutage: FC<MessageContentProps> = () => {
  const sendSupportHomePage = useExternalLinkPoster({
    link: SUPPORT_HOME_PAGE,
    prependText: 'Checkr Pay Support',
  });
  const outageDate = 'November 5th';

  return (
    <>
      <ContentBold align="center">Scheduled maintenance alert for {outageDate}</ContentBold>
      <ContentText>
        During this brief period, transactions may be temporarily suspended, ACH withdrawals
        initiated during this maintenance window may be held, and transactions may be declined...
      </ContentText>
      <ActionPrompt onClick={sendSupportHomePage}>Get support</ActionPrompt>
    </>
  );
};
