import { WarningText } from '@/shared/components/Text.styled';
import { addressMightBePoBox } from '@/shared/helpers';
import styled from 'styled-components';
import { DataContent, DataLabel, Group, PiiRow } from '../shared/confirm-pages.styled';
import { ConfirmProfileInput, ConfirmProfileInputProps } from '../shared/ConfirmProfileInput';

import { AutocompleteAddress } from './AutocompleteAddress';
import { addressValidationSchema } from './validations';

const PoBoxWarningMessage = styled.div`
  text-align: center;
`;

export type Address = {
  street: string;
  street2?: string;
  city: string;
  state: string;
  postalCode: string;
};

interface AddressProps {
  value: Address;
  onChange: (address: Address) => void;
}

export function AddressForm({ value, onChange }: AddressProps) {
  const country = 'US';

  const handleChange = (key: keyof Address, v: string) => {
    const addressCopy = { ...(value || {}) };
    addressCopy[key] = v;
    onChange(addressCopy);
    onChange(addressCopy);
  };

  const addressValidation = addressValidationSchema.validate(value || {}, {
    abortEarly: false,
  });

  const getErrorState = (key: keyof typeof value) => {
    const inputErrorIdx = addressValidation?.error?.details.findIndex(
      (d) => key === d.path[0]
    ) as number;
    if (inputErrorIdx === -1) return {};

    return {
      validation: () => !addressValidation.error?.details[inputErrorIdx],
      errorText: addressValidation.error?.details[inputErrorIdx]?.message,
    };
  };

  const inputs: (ConfirmProfileInputProps & { label: string })[] = [
    {
      label: 'Address line 2',
      // Street 2 may be undefined coming from the backend
      value: value?.street2,
      onChange: (v) => handleChange('street2', v),
      placeholder: 'Address line 2',
      ...getErrorState('street2'),
      type: 'text',
      key: 'street2',
    },
    {
      label: 'City:',
      value: value?.city,
      onChange: (v) => handleChange('city', v),
      placeholder: 'City',
      ...getErrorState('city'),
      type: 'text',
      key: 'city',
    },
    {
      label: 'State:',
      value: value?.state,
      onChange: (v) => handleChange('state', v),
      placeholder: 'State',
      ...getErrorState('state'),
      type: 'text',
      key: 'state',
    },
    {
      label: 'Postal code:',
      value: value?.postalCode,
      onChange: (v) => handleChange('postalCode', v),
      placeholder: 'Postal codes',
      maxLength: 5,
      ...getErrorState('postalCode'),
      type: 'text',
      key: 'postalCode',
    },
  ];

  return (
    <Group key="profile-address">
      {value?.street && value?.street?.length > 0 && addressMightBePoBox(value?.street) && (
        <PoBoxWarningMessage>
          <WarningText>
            Please note; PO Boxes are currently not supported as a primary address. Please enter
            this as the mailing address.
          </WarningText>
        </PoBoxWarningMessage>
      )}
      <AutocompleteAddress
        onSelect={(s) => onChange(s)}
        label="Address"
        placeholder="Address"
        value={value || {}}
      />
      {inputs.map((i) => (
        <PiiRow key={i.label}>
          <DataLabel>{i.label}</DataLabel>
          <ConfirmProfileInput {...(i as ConfirmProfileInputProps)} />
        </PiiRow>
      ))}
      <PiiRow>
        <DataLabel>Country:</DataLabel>
        <DataContent>{country}</DataContent>
      </PiiRow>
    </Group>
  );
}
