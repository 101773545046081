import { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { trackAmplitudeEvent } from '@/shared/analytics';
import { ReactComponent as CircledEmailLetter } from '@/shared/assets/circled-email-letter.svg';
import { ReactComponent as CircledPhone } from '@/shared/assets/circled-phone.svg';
import { ReactComponent as KeyIcon } from '@/shared/assets/key.svg';
import NumberInput from '@/shared/components/NumberInput';
import { Bold, ErrorText, TitleText } from '@/shared/components/Text.styled';
import { Button, Spinner } from '@checkrx/pay-component-library';

import { useSendOtpToNewContact, useVerifyOtpToNewContact } from '@/services/useVerification';
import { useCustomerFromProfile } from '@/services/useWorkerProfile';
import { SpinnerRow } from '@/shared/components/SpinnerRow';
import { getWorkerContactMethods } from '@/shared/helpers';
import { MfaMethods } from '@/shared/types';
import { AxiosError } from 'axios';
import {
  CodeControls,
  MfaVerificationContainer,
  MfaViewContainer,
  NumberRow,
  StyledLink,
  Text,
} from './VerificationPage.styled';

export default function VerifyNewContactPage() {
  const [digits, setDigits] = useState('');

  const navigate = useNavigate();
  // get the new contact from the page that navigated here
  const {
    state: { contact: newWorkerContact, contactType },
  } = useLocation();

  const { data: customer } = useCustomerFromProfile();
  const {
    mutate: resendOtp,
    isError: isResendError,
    error: resendError,
  } = useSendOtpToNewContact();
  const {
    mutate: verifyOtp,
    isError: isVerifyError,
    isLoading,
    isSuccess,
  } = useVerifyOtpToNewContact();

  const customerMfaPref = customer?.preferences?.mfaMethod ?? MfaMethods.Email;
  const { primaryContactMethod } = getWorkerContactMethods(customerMfaPref);

  const secondaryContactIcon =
    customerMfaPref === MfaMethods.Sms ? <CircledEmailLetter /> : <CircledPhone />;

  const successText =
    `Your ${primaryContactMethod} has been updated! \n Going forward,` +
    ` you will receive one-time passcodes on your new ` +
    `${primaryContactMethod}: ${newWorkerContact}
    `;
  const onSecondaryOtpSubmit = async () => {
    trackAmplitudeEvent('Two factor Reset - Verify New Contact Success');
    verifyOtp({
      otp: digits,
      type: contactType,
      ...(contactType === 'phone' && { phone: newWorkerContact }),
      ...(contactType === 'email' && { email: newWorkerContact }),
    });
  };
  //OnSuccess

  const handleResendOtpToNewContact = async () => {
    trackAmplitudeEvent('Two factor Reset - OTP requested to new contact');
    resendOtp({
      type: contactType,
      ...(contactType === 'phone' && { phone: newWorkerContact }),
      ...(contactType === 'email' && { email: newWorkerContact }),
    });
  };

  // Compute the error message
  let errorMessage = <></>;
  if (isResendError) {
    errorMessage = (
      <ErrorText>
        Make sure your {primaryContactMethod} is formatted correctly and different from your
        existing {primaryContactMethod} on file
      </ErrorText>
    );
    const castError = (resendError as AxiosError) || {
      status: 500,
      data: null,
    };
    const errorStatus = (castError || { status: 500, data: null })?.status || 500;

    if (errorStatus >= 400 && errorStatus < 500) {
      const { data: errorData } = castError?.response || { data: null };
      if (errorData) {
        // Note(Carter): This is funky typing to say "hey, we know we can check .details on this"
        const details = (errorData as { ['details']: Array<string> })?.details;
        if (details && details.length > 0) {
          const detail = details[0];
          if (detail === 'ineligible_phone_number') {
            errorMessage = (
              <ErrorText>
                {primaryContactMethod} is not eligible for a new account at this time. Please
                contact
                <StyledLink
                  onClick={() => {
                    navigate('/support');
                  }}
                >
                  {' '}
                  Checkr Pay support.{' '}
                </StyledLink>
              </ErrorText>
            );
          }
        }
      }
    }
  } else if (isVerifyError) {
    // Only 4XXs are returned for this mutation
    errorMessage = <ErrorText>The verification code is incorrect or expired!</ErrorText>;
  }

  if (isSuccess) {
    return (
      <Navigate
        to="/verification/reset-success"
        state={{ successText, navigateTo: '/onboarding' }}
      />
    );
    //navigate('
  }

  return (
    <MfaViewContainer>
      <MfaVerificationContainer>
        {secondaryContactIcon}
        <TitleText>Verify your {primaryContactMethod}</TitleText>

        <NumberRow>
          <NumberInput
            centered
            value={digits}
            onChange={(e) => setDigits(e.target.value)}
            leftIcon={<KeyIcon />}
          />
        </NumberRow>

        <CodeControls>
          <Text>
            Verification code sent to
            <Bold> {newWorkerContact || 'the contact you entered'} </Bold>
          </Text>
          {(isResendError || isVerifyError) && errorMessage}
          <Button
            className="mfa-resend-btn"
            colorVariant="dark"
            text="Send another code"
            sizeVariant="small"
            onClick={handleResendOtpToNewContact}
          />
        </CodeControls>
      </MfaVerificationContainer>
      <SpinnerRow>
        <Button
          className="mfa-submit-btn"
          width="100%"
          text="Submit"
          colorVariant="brand"
          sizeVariant="big"
          iconRight
          icon="arrow-right"
          disabled={digits.length < 6}
          onClick={onSecondaryOtpSubmit}
        />
        {isLoading ? (
          <div
            style={{
              marginRight: '-30px',
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          >
            <Spinner size="30px" />
          </div>
        ) : (
          <div />
        )}
      </SpinnerRow>
    </MfaViewContainer>
  );
}
