/**
 * Reusable Digit Input component
 *
 * TODO(Carter): Move this into Reusable Components!
 */
import { colors, fontSizes, fontWeights } from '@/shared/styles';
import { ReactNode, useId } from 'react';
import styled from 'styled-components';

const Label = styled.label<{ invalid?: boolean }>`
  display: block;
  font-size: ${fontSizes.medium};
  color: ${(p) => (p.invalid ? colors.accentRed : p.theme.colors.text.primary)};
  margin-bottom: 4px;
`;

interface BaseInputBarProps {
  width: string;
  height: string;
  invalid?: boolean;
  prependSymbol?: string;
}
const BaseInputBar = styled.div<BaseInputBarProps>`
  // Display and dimensions
  width: ${(p) => p.width};
  height: ${(p) => p.height};
  border-radius: 10px;
  background: ${colors.primaryGreyLight};
  padding: 16px;

  // Container properties
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 8px;
  overflow: hidden;
  ${(p) =>
    p.invalid &&
    `
    outline: 1px ${colors.accentRed} solid;
  `}
  // Outline focus on the outer bar
  &:focus-within {
    outline: 1px solid ${(p) => p.theme.colors.text.primary};
  }
`;

const StyledNumber = styled.input<{ centered: boolean }>`
  flex-grow: 1;
  background: inherit;
  max-width: 100%;

  // Don't outline on focus on the inner-bar
  &:focus {
    outline: none;
  }

  // Value Text Styling
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.giant};
  color: ${colors.primaryGreyMediumDark};

  // Placeholder Text Styling
  ::placeholder {
    font-weight: ${fontWeights.semiBold};
    font-size: ${fontSizes.medium};
    color: ${colors.primaryGreyMediumDark};
    transform: translate3d(0, -3px, 0);
  }

  ${(p) =>
    p.centered &&
    `
    text-align: center;
  `}
`;
const Prepend = styled.div`
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.giant};
  color: ${colors.primaryGreyMediumDark};
`;

const IconWrapper = styled.div`
  color: ${colors.primaryGreyMediumDark};
`;

const ErrorText = styled.div`
  color: ${colors.accentRed};
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.medium};
`;

interface NumberInputProps extends React.ComponentPropsWithoutRef<'input'> {
  centered?: boolean;
  errorText?: string;
  height?: string;
  invalid?: boolean;
  label?: ReactNode;
  leftIcon?: ReactNode;
  prependSymbol?: string;
  width?: string;
}

function NumberInput({
  centered = false,
  errorText,
  height = '60px',
  invalid,
  label,
  leftIcon = null,
  prependSymbol = undefined,
  width = '100%',
  ...rest
}: NumberInputProps) {
  const errorMessageId = `error-${useId()}`;

  return (
    <div style={{ width }}>
      {label && (
        <Label htmlFor={rest.id} invalid={invalid}>
          {label}
        </Label>
      )}

      <BaseInputBar height={height} width="100%" invalid={invalid} prependSymbol={prependSymbol}>
        <IconWrapper>{leftIcon}</IconWrapper>
        {prependSymbol && <Prepend>{prependSymbol}</Prepend>}
        <StyledNumber
          type="number"
          centered={centered}
          inputMode="decimal"
          pattern="[0-9].*"
          autoComplete="one-time-code"
          aria-invalid={Boolean(invalid)}
          aria-describedby={invalid ? errorMessageId : undefined}
          {...rest}
        />
      </BaseInputBar>

      {errorText && invalid ? <ErrorText id={errorMessageId}>{errorText}</ErrorText> : null}
    </div>
  );
}

export default NumberInput;
