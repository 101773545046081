import styled from 'styled-components';

import { NormalText, TitleText } from '@/shared/components/Text.styled';
import { colors, fontSizes, fontWeights } from '@/shared/styles';

export const SettingsViewContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: ${colors.primaryGreyMediumDark};
`;

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const LinkContainer = styled.div<{ disabled?: boolean }>`
  /* Size */
  position: relative;
  width: 100%;
  height: fit-content;
  min-height: 40px;
  cursor: pointer;

  /* Container properties */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  /* Hover variant */
  ${(p) =>
    !p.disabled &&
    `&:hover {
    filter: brightness(50%);
    transition: 0.2s;
  }`}
`;

export const Title = styled(TitleText)`
  font-size: ${fontSizes.medium};
`;

export const SubTitle = styled(NormalText)`
  color: ${(p) => p.theme.colors.text.secondary};
  font-weight: ${fontWeights.light};
  font-size: ${fontSizes.small};
`;

export const ActionContainer = styled.div`
  margin-left: auto;
`;
