import { TitleText } from '@/shared/components/Text.styled';
import { colors, fontSizes } from '@/shared/styles';
import { FC } from 'react';
import styled from 'styled-components';

const LinkContainer = styled.button`
  /* Size */
  width: 100%;
  height: fit-content;
  min-height: 40px;
  color: ${colors.primaryGreyMediumDark};

  /* Remove button defaults */
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;

  /* Container properties */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  &:hover {
    filter: brightness(75%);
    transition: 0.2s;
  }
`;

const LinkDescription = styled.div`
  flex-direction: column;
  align-items: start;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 5px;
`;

const TaxesText = styled(TitleText)`
  font-size: ${fontSizes.medium};
`;

type Props = {
  year: string;
  onClick: () => void;
};

export const TaxesLink: FC<Props> = ({ year, onClick }) => {
  return (
    <LinkContainer onClick={onClick}>
      <LinkDescription>
        <TitleRow>
          <TaxesText>1099-NEC for {year}</TaxesText>
        </TitleRow>
      </LinkDescription>
    </LinkContainer>
  );
};
