import { NormalText } from '@/shared/components/Text.styled';
import { fontWeights } from '@/shared/styles';
import styled from 'styled-components';

export const MfaViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 32px 0px;
`;

export const ResetMfaVerificationSteps = styled.div`
  margin-top: 10px;
`;

export const MfaVerificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  //text-align: center;
  gap: 20px;
  margin-bottom: 10px;
`;

export const MfaButtonContainer = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const NumberRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const CodeControls = styled(MfaVerificationContainer)`
  gap: 10px;
  margin-bottom: 20px;
`;

export const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
`;

export const DisabledLink = styled.span`
  cursor: not-allowed;
  opacity: 30%;
`;

export const Text = styled(NormalText)`
  color: ${(p) => p.theme.colors.text.secondary};
  font-weight: ${fontWeights.light};
  text-align: center;
`;
