import { SUPPORT_HOME_PAGE } from '@/shared/helpers';
import { useExternalLinkPoster } from '@/shared/hooks/useExternalLinkPoster';
import { FC } from 'react';
import { ActionPrompt, ContentBold, ContentText, MessageContentProps } from '../MessageContent';

export const TaxesDocumentFiled: FC<MessageContentProps> = () => {
  const sendSupportHomePage = useExternalLinkPoster({
    link: SUPPORT_HOME_PAGE,
    prependText: 'Checkr Pay Support',
  });
  return (
    <>
      <ContentBold align="center">📄 Your tax document has been filed! 📄</ContentBold>
      <ContentText>
        Your 1099-NEC has been filed and will be emailed to you. You also have access in your
        &quot;More&quot; tab here. If you require assistance, please reach out to support.
      </ContentText>
      <ActionPrompt onClick={sendSupportHomePage}>Get support</ActionPrompt>
    </>
  );
};
