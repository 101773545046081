import { BalanceDisplay } from '@/shared/components/Balance';
import { NormalText } from '@/shared/components/Text.styled';
import { fontSizes, fontWeights } from '@/shared/styles';
import styled from 'styled-components';

export const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; // Center horizontally
  justify-content: start;
  width: 100%;
  gap: 20px;
`;

export const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 5px;
  gap: 15px;
  align-items: center;
  justify-content: start;
`;

export const MiddleRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: start;
  color: ${(p) => p.theme.colors.text.secondary};
`;

export const StyledBalance = styled(BalanceDisplay)`
  padding-bottom: 0px;
`;

export const WithdrawalLimitsWrapper = styled.div`
  margin-left: auto;
`;

export const Text = styled(NormalText)`
  color: ${(p) => p.theme.colors.text.secondary};
  font-weight: ${fontWeights.light};
  font-size: ${fontSizes.smallMedium};
`;

export const Numerator = styled.span`
  background: ${(p) => p.theme.colors.text.accent};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: ${fontWeights.bold};
`;

export const Denominator = styled.span`
  font-weight: ${fontWeights.bold};
  color: ${(p) => p.theme.colors.text.primary};
`;

export const TransferIconWrapper = styled.div`
  cursor: pointer;
`;

export const TransferIconHiddenWrapper = styled.div`
  visibility: hidden;
`;
