/**
 * TODOs:
 *   - This could use a good clean up - Carter mostly skipped it in his clean up
 *     run post Sandbox-MVP
 */
import { Button, Spinner } from '@checkrx/pay-component-library';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { LoadingScreen } from '@/app/wrappers/Containers';
import { useBankingShortCircuiter } from '@/services/bankingShortCircuit/useBankingShortCircuiter';
import {
  useBankingCard,
  useFreezeCard,
  usePinStatus,
  useUnfreezeCard,
} from '@/services/useBankingCard';
import { useTransactions } from '@/services/useTransactions';
import { useWorkerFromProfile, useWorkerProfileIsSampleAccount } from '@/services/useWorkerProfile';
import Legalese from '@/shared/components/Legalese';
import { NormalText } from '@/shared/components/Text.styled';
import { toast } from '@/shared/components/Toaster/Toaster';
import TransactionList from '@/shared/components/TransactionList';
import { BankingCardStatus } from '@/shared/types';
import { useSwipeable } from 'react-swipeable';
import VirtualCard from './VirtualCard';

// Overall Page – Card + Transaction Groups
const CardViewContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
`;

// Card Management Section of Page
const CardGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
`;

const VirtualCardWrapper = styled.div`
  position: relative;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

export default function CardPage() {
  const buttonWidth = 'calc((100% - 5px) / 2)';
  const navigate = useNavigate();
  const [showSensitive, setShowSensitive] = useState(false);
  const { data: recentTransactions, isLoading: isLoadingTransactions } = useTransactions();
  const { data: card, isLoading: isLoadingCard } = useBankingCard();
  const { data: worker } = useWorkerFromProfile();

  const { data: isSampleAccount } = useWorkerProfileIsSampleAccount();
  const {
    data: pinStatus = 'NotSet',
    isFetching: loadingPinStatus,
    isError: pinError,
  } = usePinStatus(card?.cardId ?? '', {
    enabled: !isSampleAccount && !!card?.cardId && worker?.bankingPrimaryAccount?.status === 'Open',
  });

  const { mutate: freezeCard, isLoading: isLoadingFreeze } = useFreezeCard();
  const { mutate: unfreezeCard, isLoading: isLoadingUnfreeze } = useUnfreezeCard();
  const { shortCircuitFunction } = useBankingShortCircuiter();
  const cardIsLoading = isLoadingFreeze || isLoadingUnfreeze;
  const isFrozen = card?.cardStatus === BankingCardStatus.Frozen;

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => {
      navigate('/dashboard');
    },
    onSwipedLeft: () => {
      navigate('/dashboard/withdraw');
    },
  });

  if (isLoadingTransactions || isLoadingCard || loadingPinStatus) {
    return (
      <LoadingScreen>
        <Spinner />
        <NormalText>Loading your card information...</NormalText>
      </LoadingScreen>
    );
  }

  const toastIfSampleAccount = (actionFn: () => void) =>
    isSampleAccount
      ? () =>
          toast({
            message: 'Start receiving payouts to unlock this functionality!',
            type: 'info',
            duration: 5000,
          })
      : actionFn;

  const shortCircuit = (actionFn: () => void) => {
    shortCircuitFunction(toastIfSampleAccount(actionFn));
  };

  const toggleSensitiveButton = (
    <Button
      width={buttonWidth}
      icon={showSensitive ? 'eye-off' : 'eye'}
      text={`${showSensitive ? 'Hide' : 'Show'} Card Number`}
      colorVariant="dark"
      sizeVariant="small"
      onClick={() =>
        shortCircuit(() => {
          setShowSensitive((curr) => !curr);
        })
      }
    />
  );
  // Override the showing functionality with report functionality
  // if card is currently frozen
  const replaceButton = (
    <Button
      width={buttonWidth}
      icon="alert-circle"
      text="Replace Card"
      colorVariant="dark"
      sizeVariant="small"
      onClick={() =>
        shortCircuit(() => {
          navigate('/support');
        })
      }
    />
  );

  const freezeButton = (
    <Button
      text={`${isFrozen ? 'Unfreeze' : 'Freeze'} Card`}
      width={buttonWidth}
      icon="cloud-snow"
      colorVariant="dark"
      sizeVariant="small"
      onClick={() =>
        shortCircuit(() => {
          if (isFrozen) {
            unfreezeCard();
            return;
          }
          freezeCard();
        })
      }
    />
  );

  return (
    <CardViewContainer {...swipeHandlers}>
      <CardGroup>
        <VirtualCardWrapper>
          {cardIsLoading ? (
            <SpinnerWrapper>
              <Spinner size="48px" />
            </SpinnerWrapper>
          ) : null}
          <VirtualCard showSensitive={showSensitive} isFrozen={isFrozen} />
        </VirtualCardWrapper>
        <ButtonRow>
          {isFrozen ? replaceButton : toggleSensitiveButton}
          {freezeButton}
        </ButtonRow>
        <ButtonRow>
          <Button
            width={buttonWidth}
            icon="settings"
            text={`${pinStatus === 'NotSet' ? 'Set' : 'Change'} PIN`}
            colorVariant="dark"
            sizeVariant="small"
            onClick={() =>
              shortCircuit(() => {
                navigate('/manage-card');
              })
            }
            disabled={
              card?.cardStatus !== BankingCardStatus.Active ||
              pinError ||
              worker?.bankingPrimaryAccount?.status !== 'Open'
            }
          />
          <Button
            width={buttonWidth}
            icon="pocket"
            text="View Bank Account"
            colorVariant="dark"
            sizeVariant="small"
            onClick={() =>
              shortCircuit(() => {
                navigate('/bank-account-information');
              })
            }
          />
        </ButtonRow>
      </CardGroup>

      <TransactionList transactions={recentTransactions?.slice(0, 3)} title="Recent transactions" />
      <Button
        icon="clock"
        width="100%"
        colorVariant="dark"
        sizeVariant="small"
        text="View all transactions"
        onClick={toastIfSampleAccount(() => {
          navigate('/transactions');
        })}
      />

      <Legalese />
    </CardViewContainer>
  );
}
