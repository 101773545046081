import { ReactComponent as PlaidLogo } from '@/shared/assets/plaid-logo.svg';

import { LoadingScreen } from '@/app/wrappers/Containers';
import { usePlaid } from '@/services/usePlaid';
import { Button, Spinner } from '@checkrx/pay-component-library';
import { FC } from 'react';
import styled from 'styled-components';
import { isUiWebView } from '../helpers';
import DividedCard from './DividedCard';
import { Bold, NormalText, SubTitleText, TitleText } from './Text.styled';

const LinkWithPlaidViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 25px;
  margin-top: 25px;
`;

const PlaidConnectionContainer = styled.div`
  text-align: center;
  inline-size: 300px;
  overflow-wrap: break-word;
  margin-bottom: 10px;
`;

const ValuePropContainer = styled.div`
  /* Size */
  width: 100%;
  height: fit-content;
  min-height: 40px;

  /* Container properties */
  flex-direction: row;
  justify-content: flex-start;
  text-align: center;
  gap: 10px;
  inline-size: 250px;
  overflow-wrap: break-word;
`;

/**
 * Render the value props on the Plaid Link page inside the divided card
 */
const valueProp = ({ heading, body }: { heading: string; body: string }) => {
  return (
    <ValuePropContainer key={heading.replace(/\s/g, '-')}>
      <SubTitleText>
        <Bold>{heading}</Bold>
      </SubTitleText>
      <NormalText>{body}</NormalText>
    </ValuePropContainer>
  );
};

type Props = {
  onLinkManually: () => void;
  onSuccessfulPlaidConnection: (plaidProcessorToken: string) => void;
};

export const LinkWithPlaid: FC<Props> = ({ onLinkManually, onSuccessfulPlaidConnection }) => {
  const { loading, open } = usePlaid({ onSuccess: onSuccessfulPlaidConnection });

  if (loading) {
    return (
      <LoadingScreen>
        <Spinner />
        <NormalText>Connecting your account via Plaid...</NormalText>
      </LoadingScreen>
    );
  }

  return (
    <LinkWithPlaidViewContainer>
      {isUiWebView() ? (
        <>
          <TitleText>Connect your account</TitleText>
          <DividedCard
            elements={[
              valueProp({
                heading: 'Connect manually',
                body: `Connect your bank account using your account and routing number`,
              }),
            ]}
          />
          <Button
            width="100%"
            text="Link bank manually"
            colorVariant="light"
            sizeVariant="small"
            onClick={onLinkManually}
          />
        </>
      ) : (
        <>
          <PlaidLogo />
          <PlaidConnectionContainer>
            <TitleText>Checkr Pay uses Plaid to securely connect your accounts</TitleText>
          </PlaidConnectionContainer>

          <DividedCard
            elements={[
              valueProp({
                heading: 'Connect effortlessly',
                body: 'Plaid lets you securely connect your financial accounts in seconds',
              }),
              valueProp({
                heading: 'Your data belongs to you',
                body: 'With Plaid your personal info and login credentials stay private',
              }),
            ]}
          />

          <Button
            width="100%"
            text="Continue with Plaid"
            colorVariant="brand"
            sizeVariant="big"
            onClick={() => {
              open();
            }}
          />
          <Button
            width="100%"
            text="Link bank manually"
            colorVariant="light"
            sizeVariant="small"
            onClick={onLinkManually}
          />
        </>
      )}
    </LinkWithPlaidViewContainer>
  );
};
