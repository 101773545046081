import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import serverApi from './serverApi';

type Verification = {
  type: 'phone' | 'email';
  phone?: string;
  email?: string;
};

type OTP = { otp: string };

// GET /verification - request verification code
async function requestVerificationCode() {
  const response = await serverApi.get('/verification');
  return response.data;
}

export const useRequestVerification = (options?: Partial<UseQueryOptions>) => {
  return useQuery({
    queryKey: ['verification'],
    queryFn: () => requestVerificationCode(),
    ...options,
  });
};

// POST /verification - Check Verification Code
async function verifyVerificationCode(code: string) {
  const response = await serverApi.post('/verification', {
    verificationValue: code,
  });
  return response.data;
}

export const useCheckVerificationCode = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (code: string) => verifyVerificationCode(code),
    onSuccess: () => {
      // On success, clear the worker Profile so we refetch it!
      return queryClient.invalidateQueries({ queryKey: ['workerProfile'] });
    },
    retry: 1,
    // >= 400 < 500
    useErrorBoundary: (err: AxiosError) => {
      const statusCode = err?.response?.status || 500;
      return statusCode < 400 || statusCode >= 500;
    },
  });
};

// POST /profile/reset_2fa/contact
async function postSendOtpToNewContact({ type, phone, email }: Verification) {
  await serverApi.post('/profile/reset_2fa/contact', {
    type,
    phone,
    email,
  });
}
export const useSendOtpToNewContact = () => {
  return useMutation({
    mutationFn: ({ type, phone, email }: Verification) =>
      postSendOtpToNewContact({ type, phone, email }),
    // Send all errors here to the component
    useErrorBoundary: false,
  });
};

// POST /profile/reset_2fa/verify_contact
async function postVerifyOtpToNewContact({ otp, type, phone, email }: Verification & OTP) {
  const response = await serverApi.post('/profile/reset_2fa/verify_contact', {
    verificationValue: otp,
    type,
    phone,
    email,
  });
  return response.data;
}
export const useVerifyOtpToNewContact = () => {
  return useMutation({
    mutationFn: ({ otp, type, phone, email }: Verification & OTP) =>
      postVerifyOtpToNewContact({ otp, type, phone, email }),

    // Send all errors here to the component
    useErrorBoundary: (err: AxiosError) => {
      const statusCode = err?.response?.status || 500;
      return statusCode < 400 || statusCode >= 500;
    },
  });
};

// GET /profile/reset_2fa – Request reset 2FA
async function getResetOtp() {
  const response = await serverApi.get('/profile/reset_2fa');
  return response.data;
}
export const useResetOtp = () => {
  return useQuery({
    queryKey: ['verification'],
    queryFn: () => getResetOtp(),
  });
};

// POST /profile/reset_2fa – Request reset 2FA
async function postVerifyReset2fa({ otp }: OTP) {
  const response = await serverApi.post('/profile/reset_2fa', {
    verificationValue: otp,
    verificationReason: '2fa_reset',
  });
  return response.data;
}

export const useVerifyReset2fa = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ otp }: OTP) => postVerifyReset2fa({ otp }),
    onSuccess: () => {
      // On success, clear the worker Profile so we refetch it!
      return queryClient.invalidateQueries({ queryKey: ['workerProfile'] });
    },
    // >= 400 < 500
    useErrorBoundary: (err: AxiosError) => {
      const statusCode = err?.response?.status || 500;
      return statusCode < 400 || statusCode >= 500;
    },
  });
};
