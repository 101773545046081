import { PayoutMethodAccount } from '@/services/usePayoutMethod';
import { ReactComponent as DirectDeposit } from '@/shared/assets/direct-deposit.svg';
import { colors } from '@/shared/styles';
import { PayoutMethod, WorkerProfile } from '@/shared/types';
import { Button } from '@checkrx/pay-component-library';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ExternalAccountLink } from '../../ExternalAccounts';
import { MediumWarningText } from '../../Text.styled';
import { PaymentMethodBadgeT, PaymentMethodChoice } from '../PaymentMethodChoice';
import { PayoutMethodConfirmationButtonsContainer } from '../PaymentMethodPanel.styled';

type Props = {
  workerProfile: WorkerProfile;
  payoutMethodAccount: PayoutMethodAccount | undefined;
  hideDirectDepositActions: boolean;
};

export const DirectDepositChoice: FC<Props> = ({
  workerProfile,
  payoutMethodAccount,
  hideDirectDepositActions,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();

  const handleNavigateWithState = () => {
    navigate('/setup-direct-deposit', {
      state: { payoutMethod: PayoutMethod.ACHDirectDeposit },
    });
  };

  const selectedPayoutMethod = workerProfile?.profile?.payoutMethod?.selected;

  const badges = [selectedPayoutMethod === PayoutMethod.ACHDirectDeposit ? 'active' : null].filter(
    Boolean
  ) as PaymentMethodBadgeT[];
  return (
    <PaymentMethodChoice
      title="Direct Deposit"
      titleIcon={<DirectDeposit stroke={colors.textGrey} />}
      badges={badges}
      bulletPoints={[
        'Payouts take 2-3 working days to complete',
        'Payouts sent direct to your bank account',
      ]}
    >
      {payoutMethodAccount && !hideDirectDepositActions ? (
        <>
          <ExternalAccountLink
            key={payoutMethodAccount.externalAccountId}
            accountNumber={payoutMethodAccount.accountNumber}
            bankName={payoutMethodAccount.bank}
            ownerName={payoutMethodAccount.ownerName}
          />
          {selectedPayoutMethod === PayoutMethod.ACHDirectDeposit && (
            <Button
              width="100%"
              text="Change bank account"
              colorVariant="accent"
              sizeVariant="small"
              onClick={handleNavigateWithState}
            />
          )}
        </>
      ) : null}
      {selectedPayoutMethod !== PayoutMethod.ACHDirectDeposit ? (
        <>
          {showConfirmation ? (
            <>
              <MediumWarningText>
                This will disable instant payouts via Checkr Wallet, and enable payouts via Direct
                Deposit.
              </MediumWarningText>
              <PayoutMethodConfirmationButtonsContainer>
                <Button
                  width="45%"
                  text="Confirm"
                  colorVariant="dark"
                  sizeVariant="small"
                  onClick={handleNavigateWithState}
                />
                <Button
                  width="45%"
                  text="Cancel"
                  colorVariant="light"
                  sizeVariant="small"
                  onClick={() => setShowConfirmation(false)}
                />
              </PayoutMethodConfirmationButtonsContainer>
            </>
          ) : (
            <Button
              width="100%"
              text="Setup direct deposit"
              colorVariant="light"
              sizeVariant="small"
              onClick={() => setShowConfirmation(true)}
            />
          )}
        </>
      ) : null}
    </PaymentMethodChoice>
  );
};
