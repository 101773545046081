import { ReactNode } from 'react';

import { createContext, useContext, useMemo } from 'react';
import { useLocalStorage } from './helpers';

// Set up a Context for interacting with authentication tokens
interface IAuthContext {
  tempAuthToken: string | null;
  setAuthToken?: (token: string) => void;
  removeAuthToken?: () => void;
}
const defaultAuthState = {
  tempAuthToken: null,
};
const AuthContext = createContext<IAuthContext>(defaultAuthState);

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [tempAuthToken, setTempAuthToken] = useLocalStorage('temp_auth_token', '');

  // call this function when you want to authenticate the user
  const setAuthToken = async (data: string) => {
    setTempAuthToken(data);
  };

  // call this function to sign out logged in user
  const removeAuthToken = () => {
    setTempAuthToken(null);
  };

  const value = useMemo(
    () => ({
      tempAuthToken,
      setAuthToken,
      removeAuthToken,
    }),
    [tempAuthToken]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
