import styled from 'styled-components';

import { fontSizes, fontWeights } from '@/shared/styles';

import { NormalText, TitleText } from '@/shared/components/Text.styled';

// containers
export const OnboardingView = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100%;
  background: ${(p) => p.theme.colors.backgrounds.app};
  display: flex;
  position: relative;
`;
export const ContentBox = styled.div`
  position: absolute;
  height: 51%;
  min-height: 51%;
  width: 100%;
  bottom: 0;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; // Center horizontally
  gap: 10px;
`;

export const MiddleContainer = styled.div`
  height: fit-content;
  min-height: fit-content;
  width: 100%;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const BottomContainer = styled.div`
  height: min-content;
  min-height: min-content;
  width: 100%;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 17px;
`;

export const ButtonRow = styled.div`
  width: 100%;
  height: min-content;
  min-height: min-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const Drawing = styled.img`
  width: ${(p) => p.width || '100%'};
  max-height: ${(p) => p.height || '55%'};
  object-fit: contain;
`;

// Typographic Styles
export const Headline = styled(TitleText)`
  font-style: normal;
  font-size: ${fontSizes.giant};
  margin-bottom: 8px;
  color: ${(p) => p.theme.colors.text.primary};
`;
export const OnboardingText = styled(NormalText)`
  color: ${(p) => p.theme.colors.text.secondary};
  line-height: 18px;

  strong {
    font-weight: ${fontWeights.bold};
  }
`;
