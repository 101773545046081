/**
 * Just a convenience function that I can use to put a spinner next to a button
 *
 * This is temporary and there's more to do here
 */
import styled from 'styled-components';

export const SpinnerRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-shrink: 0;
  flex-wrap: no-wrap;
  gap: 8px;
  padding-left: 10%;
  padding-right: 10%;
  align-items: center;
  justify-content: center;
`;
