import { colors, fontSizes, fontWeights } from '@/shared/styles';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Toast, ToastT, ToastWithId } from './toaster-types';

const ToasterContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 400px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px 0px;
  z-index: 1000;
  pointer-events: none;
`;

const ToastContainer = styled.div<{ type?: ToastT; duration: number }>`
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  min-height: 32px;
  white-space: initial;
  border-radius: 6px;
  text-align: center;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  animation: slide-down ${(p) => p.duration}ms ease-in;
  opacity: 0;
  font-size: ${fontSizes.smallMedium};
  font-weight: ${fontWeights.bold};
  pointer-events: auto;
  @keyframes slide-down {
    0% {
      transform: translateY(-32px);
      opacity: 0;
    }
    10%,
    90% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
      transform: translateY(-4px);
    }
  }
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);

  color: ${(p) => p.theme.colors.text.primary};
  background: ${(p) => {
    if (p.type === 'success') return colors.accentMint;
    if (p.type === 'error') return colors.accentRed;
    if (p.type === 'info') return colors.backgroundGrey;
    if (p.type === 'action') return colors.backgroundGrey;
  }};
`;

const ActionText = styled.span`
  color: ${(p) => p.theme.colors.text.primary};
  text-decoration: underline;
  cursor: pointer;
`;

const OPEN_TOAST = 'OPEN_TOAST';
declare global {
  interface WindowEventMap {
    OPEN_TOAST: CustomEvent;
  }
}

export const toast = (toast: Toast) => {
  window.dispatchEvent(new CustomEvent(OPEN_TOAST, { detail: toast }));
};

export const Toaster = () => {
  const [toasts, setToasts] = useState<ToastWithId[]>([]);
  useEffect(() => {
    const handleToast = ({ detail: toast }: { detail: Toast }) => {
      const generatedId = Date.now();
      const completedToast: ToastWithId = { ...toast, id: generatedId };
      const toastsCopy = [completedToast, ...toasts];
      setToasts(toastsCopy);
      setTimeout(() => {
        setToasts((curr) => curr.filter((t) => t.id !== completedToast.id));
      }, completedToast.duration);
    };
    window.addEventListener(OPEN_TOAST, handleToast);

    return () => window.removeEventListener(OPEN_TOAST, handleToast);
  }, [toasts]);

  return (
    <ToasterContainer>
      {toasts.map((toast: ToastWithId) => (
        <ToastContainer key={toast.id} type={toast.type} duration={toast.duration}>
          <span>{toast.message}</span>
          {toast.type === 'action' && (
            <ActionText onClick={toast.onAction}> {toast.actionText}</ActionText>
          )}
        </ToastContainer>
      ))}
    </ToasterContainer>
  );
};
