/**
 * Data Fetching for In-App Messages
 *
 * This lets us fetch any messages we have for the user. These are created dynamically
 * on the backend for things like in-app education, prompting the user to take certain actions,
 * etc.
 *
 * We only want to fetch this once per user session! This means, we don't want to expire it
 * except for on user authentication.
 */
import { InAppMessage } from '@/shared/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import serverApi from './serverApi';

interface AcknowledgeMessageParams {
  messageId: string;
  isOpened?: boolean;
}

async function fetchNextInAppMessage(): Promise<InAppMessage | null> {
  const response = await serverApi.get<{ message: InAppMessage }>('/profile/queued_message');
  return response.data?.message || null;
}

export const useInAppMessages = () => {
  return useQuery<InAppMessage | null>({
    queryKey: ['inAppMessage'],
    queryFn: fetchNextInAppMessage,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchIntervalInBackground: false,
  });
};

async function postAcknowledgeInAppMessage({ messageId, isOpened }: AcknowledgeMessageParams) {
  const response = await serverApi.post('/profile/acknowledge_queued_message', {
    messageId,
    isOpened: !!isOpened,
  });
  return response.data;
}

export const useAcknowledgeMessage = () => {
  const queryClient = useQueryClient();

  return useMutation(postAcknowledgeInAppMessage, {
    onSuccess: () => {
      queryClient.setQueryData(['inAppMessage'], null);
    },
  });
};
