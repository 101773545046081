import { colors, fontSizes, fontWeights } from '@/shared/styles';
import { InAppMessage, InAppMessageType } from '@/shared/types';
import styled from 'styled-components';
import { CardBenefitsEducation } from './messages/CardBenefitsEducation';
import { CardPending } from './messages/CardPending';
import { NewFeatureAnnouncement } from './messages/NewFeatureAnnouncement';
import { TaxesAreComing } from './messages/TaxesAreComing';
import { TaxesDocumentFiled } from './messages/TaxesDocumentFiled';
import { UpcomingOutage } from './messages/UpcomingOutage';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  ul {
    list-style: '✶ ' inside;
    padding-left: 5px;
    margin-top: 10px;
    font-weight: ${fontWeights.semiBold};
  }
  li {
    margin-bottom: 5px;
  }
`;

export const ExternalLinkText = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: ${colors.accentTeal};
  font-weight: ${fontWeights.bold};
`;

export const ContentText = styled.p<{ align?: 'center' | 'right' | 'left' }>`
  font-size: ${fontSizes.smallMedium};
  font-weight: ${fontWeights.light};
  color: ${colors.primaryBlue};
  ${(p) => `text-align: ${p.align};`}
`;

export const ContentBold = styled.p<{ align?: 'center' | 'right' | 'left' }>`
  font-size: ${fontSizes.smallMedium};
  font-weight: ${fontWeights.bold};
  ${(p) => `text-align: ${p.align};`}
`;

export const ActionPrompt = styled.span`
  font-weight: ${fontWeights.bold};
  color: ${colors.primaryGreyDark};
  text-decoration: underline;
  font-size: ${fontSizes.smallMedium};
  &:hover {
    color: ${colors.trueBlack};
    cursor: pointer;
  }
`;

export const Emphasized = styled.span`
  font-weight: ${fontWeights.bold};
`;

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export type MessageContentProps = {
  messageData?: InAppMessage['messageData'];
  messageType: InAppMessage['messageType'];
  messageId: InAppMessage['_id'];
  wasOpened: boolean;
  setWasOpened: React.Dispatch<React.SetStateAction<boolean>>;
  expiresAt: InAppMessage['expiresAt'];
};

export function MessageContent(props: MessageContentProps) {
  const { messageType, messageId } = props;
  return (
    <ContentContainer key={messageId}>
      <ContentBlock>
        {messageType === InAppMessageType.CardBenefitsEducation && (
          <CardBenefitsEducation {...props} />
        )}
        {messageType === InAppMessageType.CardPending && <CardPending {...props} />}
        {messageType === InAppMessageType.TaxesAreComing && <TaxesAreComing {...props} />}
        {messageType === InAppMessageType.TaxesDocumentFiled && <TaxesDocumentFiled {...props} />}
        {messageType === InAppMessageType.UpcomingOutage && <UpcomingOutage {...props} />}
        {messageType === InAppMessageType.NewFeatureAnnouncementMessage && (
          <NewFeatureAnnouncement {...props} />
        )}
      </ContentBlock>
    </ContentContainer>
  );
}
