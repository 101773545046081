import { useWorkerProfile } from '@/services/useWorkerProfile';
import { ReactComponent as CheckrWallet } from '@/shared/assets/checkr-wallet-greyscale.svg';
import { colors, fontSizes, fontWeights } from '@/shared/styles';
import { useState } from 'react';
import styled from 'styled-components';
import { FeatureToggles } from '../feature-toggles/FeatureToggles';

const PoweredByText = styled.p`
  font-size: ${fontSizes.medium};
  color: ${colors.primaryGreyMediumDark};
  font-weight: ${fontWeights.light};
`;

const SmallText = styled.p`
  font-weight: ${fontWeights.light};
  font-size: ${fontSizes.small};
  color: ${colors.primaryBlue};
`;

const LegaleseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  gap: 15px;
  color: ${colors.primaryGreyMediumDark};
  text-align: center;
`;

export default function Legalese() {
  const { data: workerProfile } = useWorkerProfile();
  const [show, setShow] = useState(false);

  const handleHold = () => {
    let tapCount = 0;
    let timer: NodeJS.Timeout;

    const startTaps = () => {
      if (
        workerProfile?.profile &&
        (workerProfile?.profile?.contactEmail?.endsWith('@checkr.com') ||
          workerProfile?.profile?.contactEmail?.endsWith('@checkrpay.com'))
      ) {
        tapCount++;
        if (tapCount === 1) {
          timer = setTimeout(() => {
            tapCount = 0;
          }, 4000);
        }
        if (tapCount >= 10) {
          setShow(true);
          clearTimeout(timer);
          tapCount = 0;
        }
      }
    };

    return {
      onMouseDown: startTaps,
      onTouchStart: startTaps,
    };
  };

  return (
    <>
      <FeatureToggles show={show} onClose={() => setShow(false)} />
      <LegaleseContainer {...handleHold()}>
        <SmallText>
          Checkr Pay is a financial technology company, not a bank. Banking services provided by
          Thread Bank. The Checkr Pay Visa Business Debit card is issued by Thread Bank pursuant to
          a license from Visa U.S.A. Inc. and may be used everywhere Visa Business debit cards are
          accepted.
        </SmallText>
        <PoweredByText>Powered by</PoweredByText>
        <CheckrWallet />
      </LegaleseContainer>
    </>
  );
}
