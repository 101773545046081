import styled from 'styled-components';

import { NormalText } from '@/shared/components/Text.styled';
import { colors, fontWeights } from '@/shared/styles';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  min-height: fit-content;
  gap: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  height: 100%;
`;

export const NavContainer = styled.div`
  height: 55px;
  width: 100%;

  // Container setup
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  border: 1px solid ${colors.primaryGreyLight};
  border-radius: 10px;
  cursor: pointer;
`;

export const NavOption = styled.div<{ active: boolean }>`
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  padding: 10px 15px;

  // Add the active styles
  ${(p) =>
    p.active
      ? `
      color: ${colors.trueWhite};
      background: ${p.theme.colors.buttons.primary};
      
    `
      : `
    color: ${p.theme.colors.buttons.secondary};
    background: ${p.theme.colors.backgrounds.app};
    `}

  /* Hover variant */ 
  ${(p) =>
    !p.active &&
    `&:hover {
      filter: brightness(95%);
    }
  `}
`;

export const TabTitle = styled(NormalText)`
  font-weight: ${fontWeights.bold};
  color: inherit;
`;
