import { Emphasized } from '../MessageContent';

import { SUPPORT_APPLE_WALLET_PAGE } from '@/shared/helpers';
import { useExternalLinkPoster } from '@/shared/hooks/useExternalLinkPoster';
import { FC } from 'react';
import { ActionPrompt, ContentBold, ContentText, MessageContentProps } from '../MessageContent';

export const CardBenefitsEducation: FC<MessageContentProps> = ({ wasOpened, setWasOpened }) => {
  const sendDeviceWalletPage = useExternalLinkPoster({
    link: SUPPORT_APPLE_WALLET_PAGE,
    prependText: 'Link Apple or Google Wallet',
  });

  if (wasOpened) {
    return (
      <>
        <ContentBold align="center">
          You just got some money. Let&apos;s get you some more. 🤑
        </ContentBold>
        <ContentText>
          Did you know your Checkr Pay account is a full-fledged bank account with perks and
          benefits that can help you save and even earn money?
          <ul>
            <li>Get Paid instantly after completing a job</li>
            <li>Earn cash back when you spend on gas</li>
            <li>Access to a wide network of ATMs</li>
            <li>No low-balance or maintenance fees</li>
          </ul>
        </ContentText>
        <ContentText>
          <Emphasized>Earn Cash Back</Emphasized> every time you use your Checkr Pay card.
          You&apos;re <Emphasized>already earning 1% cashback</Emphasized>, but when you spend more,
          you earn more. <Emphasized>Spend $400</Emphasized> in a month to be upgraded to the
          premium tier and
          <Emphasized> start earning 1.5% cashback</Emphasized>.
        </ContentText>
        <ContentText>
          The easiest ways to hit premium tier rewards is to build the Checkr Pay card into your
          habits. You can start today by setting up
          <Emphasized>automatic bill pay</Emphasized> or adding your Pay card to your Apple Wallet
          or Google pay.
        </ContentText>
        <ActionPrompt onClick={sendDeviceWalletPage}>
          Learn how to add your card to your device&apos;s wallet
        </ActionPrompt>
      </>
    );
  }

  return (
    <>
      <ContentBold align="center">💪 You just got paid for the first time! 💪</ContentBold>
      <ContentText>
        Your money is available now on your Checkr Pay card. Start using your card to access cash
        back and other benefits...
      </ContentText>
      <ActionPrompt onClick={() => setWasOpened(true)}>Learn more</ActionPrompt>
    </>
  );
};
