/**
 * A Component calling endpoint to post an external link and rendering
 * either error or success text as a result.
 *
 * Note: this component automatically calls the external link generating
 * mutation when rendering! Always render this component conditionally.
 */
import { useSendExternalSMSLink } from '@/services/onboarding';
import { useWorkerProfile } from '@/services/useWorkerProfile';
import { Bold, ErrorText } from '@/shared/components/Text.styled';
import { isUiWebView } from '@/shared/helpers';
import { useEffect } from 'react';

/**
 * Re-useable hook to run a post mutation link Api.
 */
export default function ExternalLinkPoster({
  link,
  prependText = '',
  onSent,
}: {
  link: string;
  prependText?: string;
  onSent: () => void;
}) {
  const { data: workerProfile } = useWorkerProfile();
  const {
    mutate: postExternalLinkSms,
    isIdle,
    isLoading,
    isSuccess,
    isError,
  } = useSendExternalSMSLink();

  const contactPhone = (workerProfile?.profile || {})?.contactPhone || 'your mobile device on file';

  const runPostExternalLinkApi = () => {
    // check the browser
    if (!isUiWebView()) {
      // If we're in a normal browser, desktop or mobile, then just
      // open it normally in a new tab
      window.open(link, '_blank', 'noreferrer');
      // Reset state after we've opened the window
      onSent();
    } else {
      // Otherwise, hit the API endpoint to request a SMS link to support
      postExternalLinkSms({ smsBody: `${prependText}: ${link}` });
    }
  };

  useEffect(() => {
    if (isIdle) {
      runPostExternalLinkApi();
    }
  }, [isIdle]);

  useEffect(() => {
    // Reset state after we've sent the link
    if (isSuccess) {
      onSent();
    }
  }, [isSuccess]);

  if (isLoading) {
    return (
      <div>
        <Bold>Sending link via text to {contactPhone}</Bold>
      </div>
    );
  }

  if (isError) {
    return (
      <div>
        <ErrorText> Failed to text support link. Try again!</ErrorText>
      </div>
    );
  }

  return (
    <div>
      <Bold>Opening Support portal in another tab</Bold>
    </div>
  );
}
