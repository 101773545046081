import { ReactComponent as CloseIcon } from '@/shared/assets/close-ex.svg';
import { PropsWithChildren, useState } from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';

const darkening = keyframes`
  from { background-color: rgba(0, 0, 0, 0.0); }
  to { background-color: rgba(0, 0, 0, 0.6); }
`;

const drawer = keyframes`
  from { bottom: -100px }
  to { bottom: 0; }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  animation: ${darkening} 0.3s;
`;

const DrawerContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  border-radius: 10px 10px 0px 0px;
  padding: 20px 34px 34px;
  background: white;
  animation: ${drawer} 0.3s;
  overflow: auto;
`;

const DrawerContent = styled.div`
  overflow: auto;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  cursor: pointer;
`;

// Create a larger clickable area without affecting layout
const CloseButtonTouchTargetExpander = styled.span`
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: max(100%, 40px);
  height: max(100%, 40px);
  transform: translate(-50%, -50%);
`;

export function useSlideUpDrawer() {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };

  return {
    SlideUpDrawer: ({ children }: PropsWithChildren) => {
      if (!isOpen) {
        return null;
      }

      return ReactDOM.createPortal(
        <>
          <Backdrop onClick={handleClose} />
          <DrawerContainer>
            <DrawerContent>{children}</DrawerContent>
            <CloseButton type="button" aria-label="Close Drawer" onClick={handleClose}>
              <CloseIcon width="16" height="16" />
              <CloseButtonTouchTargetExpander />
            </CloseButton>
          </DrawerContainer>
        </>,
        document.body
      );
    },
    openDrawer: () => {
      setIsOpen(true);
    },
  };
}
