/**
 * A reusable Fill Bar Component for progress bars and graphs
 */
import { colors } from '@/shared/styles';
import styled, { useTheme } from 'styled-components';

const fillBorderRadius = '5px';

const FillBarContainer = styled.div<{ vertical: boolean; length: string }>`
  /* Size */
  width: ${(p) => (p.vertical ? '20px' : p.length)};
  height: ${(p) => (p.vertical ? p.length : '20px')};

  // Relative position so we can use absolute position in children
  position: relative;
`;

const FillBarPartial = styled.div<{
  vertical: boolean;
  length: string;
  fillColor: string;
  ordering: number;
}>`
  /* Size, order, and color */
  width: ${(p) => (p.vertical ? '100%' : p.length)};
  height: ${(p) => (p.vertical ? p.length : '100%')};
  background: ${(p) => p.fillColor};
  z-index: ${(p) => p.ordering};
  border-radius: ${fillBorderRadius};

  /* Start bar at bottom left of parent container */
  position: absolute; // Absolute elements contained by first relative parent
  left: 0;
  bottom: 0;
`;

export default function FillBar({
  vertical = false,
  length = '100%',
  lightFillPercent = 100,
  darkFillPercent = 0,
  brightFillPercent = 0,
}: {
  vertical?: boolean;
  length?: string;
  lightFillPercent?: number;
  darkFillPercent?: number;
  brightFillPercent?: number;
}) {
  const theme = useTheme();
  // Parse values into user-friendly strings
  return (
    <FillBarContainer vertical={vertical} length={length}>
      {lightFillPercent > 0 && (
        <FillBarPartial
          vertical={vertical}
          length={`${lightFillPercent}%`}
          fillColor={colors.primaryGreyLight}
          ordering={1}
        />
      )}
      {brightFillPercent > 0 && (
        <FillBarPartial
          vertical={vertical}
          length={`${brightFillPercent}%`}
          fillColor={theme.colors.graphics.accent}
          ordering={2}
        />
      )}
      {darkFillPercent > 0 && (
        <FillBarPartial
          vertical={vertical}
          length={`${darkFillPercent}%`}
          fillColor={theme.colors.graphics.secondary}
          ordering={3}
        />
      )}
    </FillBarContainer>
  );
}
