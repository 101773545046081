import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import serverApi from './serverApi';

const fetchWorkerStatements = async () => {
  const res = await serverApi.get('/banking/statements');
  return res.data?.data;
};
export const useWorkerStatements = () => {
  return useQuery({
    queryKey: ['banking', 'statements'],
    queryFn: () => fetchWorkerStatements(),
  });
};

const fetchWorkerStatementPdf = async (statementId: string) => {
  const res = await serverApi.get(`/banking/statements/${statementId}/pdf`, {
    responseType: 'blob',
  });
  return res.data;
};

export const useWorkerStatementPdf = (statementId: string, options?: UseQueryOptions) => {
  return useQuery({
    queryKey: ['banking', 'statements', statementId],
    queryFn: () => fetchWorkerStatementPdf(statementId),
    ...options,
  });
};

const postWorkerStatementNotification = async ({
  statementId,
  period,
}: {
  statementId: string;
  period: string;
}) => {
  const res = await serverApi.post(`/notify/statements`, {
    statementId,
    period,
  });
  return res.data;
};

export const useSendStatementNotification = (
  options?: Partial<UseMutationOptions<unknown, unknown, unknown>>
) => {
  return useMutation({
    mutationFn: ({ statementId, period }: { statementId: string; period: string }) =>
      postWorkerStatementNotification({ statementId, period }),
    useErrorBoundary: false,
    ...options,
  });
};
