import styled from 'styled-components';

import { colors } from '@/shared/styles';
import { ReactElement } from 'react';

const CardContainer = styled.div<{ width: string; height: string }>`
  // Configurable dimensions
  height: ${(p) => p.height};
  width: ${(p) => p.width};

  // Container setup
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 20px 15px;
  gap: 15px;

  // Border styling
  border: 1px solid ${colors.primaryGreyLight};
  border-radius: 10px;
  background: ${(p) => p.theme.colors.backgrounds.app};
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.primaryGreyLight};
`;

export default function DividedCard({
  elements = [],
  height = 'auto',
  width = '100%',
}: {
  elements?: Array<ReactElement>;
  height?: string;
  width?: string;
}) {
  // Add Dividers between the elements
  const dividedElements = elements.reduce((acc: Array<ReactElement>, curr: ReactElement) => {
    if (acc.length > 0) {
      // Add a divider
      acc.push(<Divider key={`divider-${curr?.key}`} />);
    }
    acc.push(curr);
    return acc;
  }, []);

  return (
    <CardContainer width={width} height={height}>
      {dividedElements}
    </CardContainer>
  );
}
