/**
 * Layout for the Transactions Pages
 */
import { Outlet, useLocation } from 'react-router-dom';

import { LoadingScreen, TabWrapper } from '@/app/wrappers/Containers';
import { useTransactions } from '@/services/useTransactions';
import PageNavBar from '@/shared/components/PageNavBar';
import { NormalText } from '@/shared/components/Text.styled';
import { Spinner } from '@checkrx/pay-component-library';

export default function TransactionsLayout() {
  // Pre-load transactions for any Transactions page
  const { isLoading: isLoadingTransactions } = useTransactions();

  // Figure out page title based on path
  const location = useLocation();
  const path = location.pathname;
  let title = 'Transaction History';
  if (path === '/transactions/withdrawals') {
    title = 'Withdrawals';
  }

  // Pre-fetch our Transactions for all transaction pages, and render loading page if we have to
  return (
    <TabWrapper>
      <PageNavBar title={title} />
      {isLoadingTransactions ? (
        <LoadingScreen>
          <Spinner />
          <NormalText>Loading your recent transactions...</NormalText>
        </LoadingScreen>
      ) : (
        <Outlet />
      )}
    </TabWrapper>
  );
}
