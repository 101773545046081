import { Consent } from './consent.types';
import { Customer } from './customer.types';
import { PayoutMethod } from './payout.types';
import { TaxesT } from './taxes.types';
import { DateString, WorkerBusinessInformation, WorkerType } from './types';
import { Worker } from './worker.types';

export enum ProfileStatus {
  RequirePII = 'require_pii', // require user to enter their own PII. Only supported on batch files
  Pending = 'pending', // Waiting for user to connect accounts
  Onboarded = 'onboarded', // User is signed up, but hasn't gotten paid. Not activated
  ManualReview = 'manual_review', // Waiting on BaaS to review accounts
  Denied = 'denied', // Could not open accounts. Action needed
  Active = 'active', // Profile is activated and can receive payouts
  PendingClosure = 'pending_closure', // Worker has been notified of impending account closure
  // and cannot receive payouts, but can still login to withdraw their funds
  Closed = 'closed', // Profile and related accounts have been closed and cannot receive payouts
  Failed = 'failed', // Something went wrong during the account creation process
}

export interface Profile {
  // These are all potentially for the limited profile request (unverified)
  _id: string;
  publicUid: string;
  status: ProfileStatus;
  contactPhone?: string;
  contactEmail?: string;
  bankingApplicationId?: string;

  // Fields Only Returned with a verified profile
  // Package from backend appropriately
  activatedAt?: DateString;
  completedOnboarding?: boolean;
  hasConfirmedPII?: boolean;
  onboardedAt?: DateString;
  createdAt?: DateString;
  updatedAt?: DateString;
  backgroundCheckId?: string;
  customerMetadata?: string;
  customSameDayACHFees?: number;
  worker?: Worker['_id'];
  astra?: {
    customPerTransferLimit?: number;
    customFeesRate?: number;
  };
  contactAddress?: {
    street?: string;
    street2?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    country?: string;
  };
  mailingAddress?: {
    street?: string;
    street2?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    country?: string;
  };
  checkrCorePII: {
    firstName?: string;
    middleName?: string;
    lastName?: string;
    dateOfBirth?: string;
    taxpayerIdentificationNumber?: string;
  };
  preferredName?: string;
  accountType?: 'BASIC' | 'PLUS';
  tempStytchUserId?: string;
  aboundW9DocumentId?: string;
  notifications?: {
    activationReminders: {
      started?: boolean;
      count?: number;
      exhausted: boolean;
      next?: DateString;
    };
  };
  '1099nec'?: Record<number, TaxesT>;
  payoutMethod?: {
    selected: PayoutMethod;
  };
  additionalPayoutMethods?: {
    achDirectDeposit?: {
      enabled?: boolean;
    };
    checkrPayBankAccount?: {
      enabled?: boolean;
    };
    pushToCardDirectDeposit?: {
      enabled?: boolean;
    };
  };
  workerType?: WorkerType;
  workerBusinessInformation?: WorkerBusinessInformation;
}

export interface WorkerProfile {
  // These are optional - they depend on if the worker profile is verified
  authenticated: boolean;
  verified: boolean;
  customer?: Customer;
  consents?: Consent[];
  worker?: Worker;
  profile: Profile;
}
