import { Break, SupportView } from '@/pages/support/SupportPage.styled';
import { Button } from '@checkrx/pay-component-library';
import { FC } from 'react';
import { useExternalLinkPoster } from '../hooks/useExternalLinkPoster';
import { SupportText } from './Taxes.styled';
import { Bold } from './Text.styled';

type Props = {
  supportPage: string;
  prependText: string;
};

export const SupportBlock: FC<Props> = ({ supportPage, prependText }) => {
  const sendExternalLink = useExternalLinkPoster({
    link: supportPage,
    prependText: prependText,
  });

  return (
    <SupportView>
      <SupportText>
        Have questions? Tap below to launch our support helpdesk. You will be redirected to
        <Bold> support.checkrpay.com</Bold>. <Break />
      </SupportText>
      <Button
        text="Launch Help Center"
        width="100%"
        colorVariant="accent"
        sizeVariant="big"
        onClick={sendExternalLink}
      />
    </SupportView>
  );
};
