import { colors, fontSizes, fontWeights } from '@/shared/styles';
import styled from 'styled-components';

import { MiniText, TitleText } from '@/shared/components/Text.styled';

export const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding: 25px 0 10px 0;
`;

export const DisabledWarningMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-align: center;
  gap: 12px;
`;

export const InstantTransferDisabledText = styled.span`
  color: ${(p) => p.theme.colors.text.secondary};
  font-size: ${fontSizes.smallMedium};
`;

export const SpeedContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
`;

export const DestinationContainer = styled(SpeedContainer)`
  padding-top: 25px;
`;

export const LinkedAccountsContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

export const SingleLinkAccountContainer = styled.div`
  // Container setup
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 20px 15px;
  margin-bottom: 10px;
  width: 100%;

  // Border styling
  border: 1px solid ${colors.primaryGreyLight};
  border-radius: 10px;
  background: ${(p) => p.theme.colors.backgrounds.app};
`;

export const DestinationLabel = styled(TitleText)`
  font-size: ${fontSizes.medium};
`;

export const StyledLink = styled.a`
  color: ${(p) => p.theme.colors.text.accent};
  cursor: pointer;
`;

export const LinkedAccountTextContainer = styled.p`
  font-size: ${fontSizes.small};
  color: ${(p) => p.theme.colors.text.secondary};

  width: 100%;
  justify-content: left;
  padding: 8px 0px;
`;

export const TransferContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  padding: 0px 10px;
`;

export const LinkContainer = styled.div`
  /* Size */
  position: relative;
  width: 100%;
  height: fit-content;
  min-height: 40px;

  /* Container properties */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(TitleText)`
  font-size: ${fontSizes.giant};
  padding-bottom: 15px;
`;

export const CenteredTitle = styled(Title)`
  align-self: center;
  text-align: center;
`;

export const ActionContainer = styled.div`
  margin-left: auto;
`;

export const WithdrawSummaryContainer = styled.div`
  width: 100%;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center; // Center Vertically
  justify-content: space-between;
`;

export const DataLabel = styled.label`
  font-weight: ${fontWeights.light};
  font-size: ${fontSizes.medium};
  color: ${(p) => p.theme.colors.text.secondary};
`;

export const DataContent = styled.p`
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.bold};
  color: ${(p) => p.theme.colors.text.primary};
`;

export const BottomButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

export const DisclaimerText = styled(MiniText)`
  display: flex;
  flex-direction: row;
  color: ${(p) => p.theme.colors.text.secondary};
  width: 95%;
  text-align: center;
  padding-top: 20px;
  // this is smaller than mini which is .5rem
  font-size: 0.7rem;
`;
