/**
 * A page for users who already have accounts to sign in (and be
 * able to see non-sensitive info and such)
 */
import { LoadingScreen, TabWrapper } from '@/app/wrappers/Containers';
import { usePartialBankingAccount } from '@/services/useBankingAccount';
import { useUnfulfilledPayouts } from '@/services/usePayouts';
import { useWorkerProfile } from '@/services/useWorkerProfile';
import { BalanceDisplay } from '@/shared/components/Balance';
import DividedCard from '@/shared/components/DividedCard';
import PageNavBar from '@/shared/components/PageNavBar';
import PayoutNotices from '@/shared/components/PayoutNotices/PayoutNotices';
import { NormalText, TitleText } from '@/shared/components/Text.styled';
import { currencyFormatter, TO_DOLLAR_DIVISOR } from '@/shared/helpers';
import { Button, Spinner } from '@checkrx/pay-component-library';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const SigninWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-around;
`;

const SigninInstructionText = styled(NormalText)`
  margin-bottom: 15px;
  text-align: center;
`;

const BalancesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

export default function SigninPage() {
  const navigate = useNavigate();

  const { data: account, isLoading: isBankingAccountLoading } = usePartialBankingAccount();
  const { data: workerProfile, isLoading: isWorkerProfileLoading } = useWorkerProfile();
  const { data: payouts, isLoading: isPayoutsLoading } = useUnfulfilledPayouts();
  const isLoading = isBankingAccountLoading || isPayoutsLoading || isWorkerProfileLoading;

  if (isLoading) {
    return (
      <LoadingScreen>
        <Spinner />
        <NormalText>Fetching your account information</NormalText>
      </LoadingScreen>
    );
  }
  const accountBalance = currencyFormatter
    .format((account?.available || 0) / TO_DOLLAR_DIVISOR)
    .slice(1);
  const pendingDirectDeposit = currencyFormatter
    .format((account?.pendingDirectDepositTotal || 0) / TO_DOLLAR_DIVISOR)
    .slice(1);

  const preferredName = workerProfile?.profile?.preferredName;
  const firstName = workerProfile?.profile?.checkrCorePII?.firstName;

  return (
    <TabWrapper>
      <PageNavBar title="Sign In" showBack={false} />
      <SigninWrapper>
        <TitleText> Welcome back, {preferredName || firstName || 'earner'}! </TitleText>
        <BalancesWrapper>
          <DividedCard
            elements={[
              <div key={account?.name || 'balance'}>
                <BalanceDisplay amount={accountBalance} />
              </div>,
              ...((account?.pendingDirectDepositTotal ?? 0) > 0
                ? [
                    <div key={account?.name || 'pending-direct-deposit'}>
                      <BalanceDisplay
                        title="Pending Direct Deposit"
                        amount={pendingDirectDeposit}
                      />
                    </div>,
                  ]
                : []),
            ]}
          />
          {payouts && <PayoutNotices payouts={payouts} />}
        </BalancesWrapper>

        <div>
          <SigninInstructionText>
            To access your wallet, withdraw money, or view your Visa Business Debit Card,
            you&apos;ll need to sign in. This information is hidden for the protection of your
            account.
          </SigninInstructionText>
          <Button
            className="signin-btn"
            colorVariant="brand"
            text="Sign in"
            sizeVariant="big"
            width="100%"
            iconRight
            icon="arrow-right"
            onClick={() => {
              navigate('/verification');
            }}
          />
        </div>
      </SigninWrapper>
    </TabWrapper>
  );
}
