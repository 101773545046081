/**
 * Home Page of the Dashboard
 */

import { BankingShortCircuiter } from '@/services/bankingShortCircuit/BankingShortCircuiter';
import { useBankingAccount } from '@/services/useBankingAccount';
import { useTransactions } from '@/services/useTransactions';
import { useWorkerProfile } from '@/services/useWorkerProfile';
import { trackRefinerEvent } from '@/shared/analytics';
import { BalanceDisplay } from '@/shared/components/Balance';
import CashbackSummaryPanel from '@/shared/components/CashbackSummaryPanel';
import { DirectDepositActivePanel } from '@/shared/components/DirectDepositActivePanel';
import Legalese from '@/shared/components/Legalese';
import SetupPayoutMethodPanel from '@/shared/components/SetupPayoutMethodPanel';

import { useGetFeatureToggle } from '@/shared/feature-toggles/feature-toggles';
import { formatCentsAsCurrency } from '@/shared/helpers';
import { PayoutMethod, TransactionDirection, TransactionStatus } from '@/shared/types';

import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components';

import { useUnfulfilledPayouts } from '@/services/usePayouts';
import PayoutNotices from '@/shared/components/PayoutNotices/PayoutNotices';
import LatestTransactionsWidget from './LatestTransactionsWidget';

const HomeViewContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
`;

export default function HomePage() {
  const navigate = useNavigate();

  const hideAvailableBalanceToggle = useGetFeatureToggle('hide available balance');

  const { data: bankingData, isLoading: isLoadingAvailableBalance } = useBankingAccount();
  const { data: allTransactions } = useTransactions();
  const { data: payouts } = useUnfulfilledPayouts();
  const { data: workerProfile } = useWorkerProfile();
  const amountString = formatCentsAsCurrency(bankingData?.available, { skipCurrencySymbol: true });

  const allPayoutTransactions = (allTransactions || []).filter(
    ({ direction, transactionStatus }) =>
      direction === TransactionDirection.Credit && transactionStatus !== TransactionStatus.Returned
  );

  // hide certain components like balances, cashback if there is only direct deposit activity
  const selectedPayoutMethod = workerProfile?.profile?.payoutMethod?.selected;
  const onlyDirectDepositActivity =
    allPayoutTransactions.every(
      (payout) =>
        payout.metadata?.payoutMethod === PayoutMethod.ACHDirectDeposit ||
        payout.metadata?.payoutMethod === PayoutMethod.PushToCardDirectDeposit
    ) &&
    (selectedPayoutMethod === PayoutMethod.ACHDirectDeposit ||
      selectedPayoutMethod === PayoutMethod.PushToCardDirectDeposit);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      navigate('/dashboard/card');
    },
  });

  React.useEffect(() => {
    if (allPayoutTransactions.length > 0) {
      trackRefinerEvent('Customer has payouts');
    }
  }, [allPayoutTransactions]);

  return (
    <HomeViewContainer {...swipeHandlers}>
      {!onlyDirectDepositActivity && !hideAvailableBalanceToggle && (
        <BalanceDisplay
          amount={amountString}
          hold={bankingData?.hold}
          isLoading={isLoadingAvailableBalance}
        />
      )}
      <SetupPayoutMethodPanel />
      {!!workerProfile && (
        <DirectDepositActivePanel payouts={allPayoutTransactions} workerProfile={workerProfile} />
      )}
      {payouts && <PayoutNotices payouts={payouts} />}
      <LatestTransactionsWidget />
      {!onlyDirectDepositActivity && (
        <BankingShortCircuiter hideIfNotOpen>
          <CashbackSummaryPanel />
        </BankingShortCircuiter>
      )}
      <Legalese />
    </HomeViewContainer>
  );
}
