import styled from 'styled-components';

import { useCustomerFromProfile } from '@/services/useWorkerProfile';
import questionsDrawing from '@/shared/assets/undraw-questions.svg';

// This SVG is used on the placeholder pages for features not yet completed.
// We use an SVG loaded from customer preferences.

const Drawing = styled.img`
  width: ${(p) => p.theme.images?.supportPage?.width};
  height: ${(p) => p.theme.images?.supportPage?.height};
`;

export default function SupportPageDrawing({ className }: { className: string }) {
  const { data: customer } = useCustomerFromProfile();
  const supportPage = customer?.preferences?.images?.supportPage;

  return (
    <Drawing
      className={className}
      src={supportPage?.url || questionsDrawing}
      alt="support graphic"
    />
  );
}
