/**
 * The Container for the withdrawal flow
 */
import PageNavBar from '@/shared/components/PageNavBar';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const WithdrawContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; // Center horizontally
  justify-content: start;
  height: 100%;
  width: 100%;
`;

export default function WithdrawalFlowLayout() {
  return (
    <WithdrawContainer>
      <PageNavBar title="Withdraw" navigateBackTo="/dashboard/withdraw" />
      <Outlet />
    </WithdrawContainer>
  );
}
