import { DropdownInput, TextInput } from '@checkrx/pay-component-library';
import { InputHTMLAttributes, useState } from 'react';

type CommonProps = {
  value?: string;
  onChange: (s: string) => void;
  placeholder: string;
  validation?: (v: string) => boolean;
  errorText?: string;
  fullviewCaptureEnabled?: boolean;
  maxLength?: number;
  width?: string;
  height?: string;
  format?: (v: string) => string;
  key?: string;
};

type TextProps = CommonProps & {
  type: 'text';
};

type DateProps = CommonProps & {
  type: 'date';
};

type DropdownProps = CommonProps & {
  type: 'dropdown';
  options: { label: string; value: string }[];
};

type BaseProps = TextProps | DateProps | DropdownProps;

export type ConfirmProfileInputProps = BaseProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, keyof BaseProps>;

export function ConfirmProfileInput(props: ConfirmProfileInputProps) {
  const { validation = () => true, value = '', type } = props;
  const [touched, setTouched] = useState(false);
  const [dropdownFocus, setDropdownFocus] = useState(false);
  const valid = validation(value);

  if (type === 'dropdown') {
    const {
      value = '',
      onChange,
      placeholder,
      errorText,
      fullviewCaptureEnabled = true,
      options,
    } = props;
    return (
      <span
        style={{ width: '50%' }}
        className={fullviewCaptureEnabled ? undefined : 'fullview-hide'}
      >
        <DropdownInput
          onChange={() => undefined}
          options={options}
          value={value}
          placeholder={placeholder}
          onSelect={(v) => {
            setDropdownFocus(true);
            onChange(v);
          }}
          onClick={() => setDropdownFocus(true)}
          invalid={touched && !valid}
          onClickOutside={() => {
            if (!dropdownFocus) return;
            setDropdownFocus(false);
            setTouched(true);
          }}
          errorText={errorText}
        />
      </span>
    );
  }

  const {
    onChange,
    placeholder,
    errorText,
    fullviewCaptureEnabled = true,
    format,
    width: _,
    ...rest
  } = props;
  return (
    <span style={{ width: '50%' }} className={fullviewCaptureEnabled ? undefined : 'fullview-hide'}>
      <TextInput
        value={value}
        placeholder={placeholder}
        onChange={(e) => {
          const formattedValue =
            format !== undefined ? format(e.target.value) : e.target.value ?? '';
          onChange(formattedValue.trimStart());
        }}
        invalid={touched && !valid}
        onBlur={() => {
          setTouched(true);
        }}
        errorText={errorText}
        {...rest}
      />
    </span>
  );
}
