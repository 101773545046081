import { useFetchBankInfo } from '@/services/useBankInfo';
import { useWorkerProfile } from '@/services/useWorkerProfile';
import { ReactComponent as CircleWarning } from '@/shared/assets/circle-warning.svg';
import NumberInput from '@/shared/components/NumberInput';
import { Bold, ErrorText, SmallMediumText } from '@/shared/components/Text.styled';
import { colors, fontSizes, fontWeights } from '@/shared/styles';
import { Button, Spinner } from '@checkrx/pay-component-library';
import Joi from 'joi';
import { ReactNode, useState } from 'react';
import styled from 'styled-components';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  margin-bottom: 40px;
`;

const AttentionBanner = styled.p`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: ${fontSizes.medium};
  color: ${(p) => p.theme.colors.text.secondary};
  margin-top: 10px;
  padding: 10px;
  border: 1px solid ${colors.primaryGreyLight};
  border-radius: 10px;
  background: ${(p) => p.theme.colors.backgrounds.app};

  svg {
    flex: 0 0 auto;
    width: 20px;
    height: auto;
  }
`;

const FakeLabel = styled.p`
  font-size: ${fontSizes.medium};
  color: ${(p) => p.theme.colors.text.primary};
  margin-bottom: 4px;
`;

const FakeInput = styled.p`
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.big};
  color: ${colors.primaryGreyMediumDark};
  padding-left: 15px;
`;

const HorizontalStack = styled.div`
  display: flex;
  gap: 10px;
`;

type Fields = {
  routingNumber: string;
  accountNumber: string;
};

type FormErrors = Partial<Record<keyof Fields, string>> & { FORM?: string };

const formSchema = Joi.object({
  routingNumber: Joi.string().length(9).required().messages({
    'string.empty': 'Routing number must be 9 digits',
    'string.length': 'Routing number must be 9 digits',
    'any.required': 'Required',
  }),
  accountNumber: Joi.string().required().messages({
    'string.empty': 'Required',
    'any.required': 'Required',
  }),
});

interface BankAccountFormProps {
  submitButtonText: ReactNode;
  onSubmit: (formValues: Partial<Fields>) => Promise<void>;
}

export function BankAccountForm({ submitButtonText, onSubmit }: BankAccountFormProps) {
  const { data: workerProfile } = useWorkerProfile();

  const [formState, setFormState] = useState<'idle' | 'submitting'>('idle');
  const [formValues, setFormValues] = useState<Partial<Fields>>({
    routingNumber: '',
    accountNumber: '',
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const {
    mutate: fetchBankInfo,
    data: bankInfo,
    isLoading: isLoadingBankInfo,
    reset: resetBankInfo,
  } = useFetchBankInfo();

  const firstName = workerProfile?.profile?.checkrCorePII?.firstName;
  const lastName = workerProfile?.profile?.checkrCorePII?.lastName;

  const handleFieldChange = <Field extends keyof Fields>(
    fieldName: Field,
    value: Fields[Field]
  ) => {
    setFormValues((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleBlurRoutingNumber = () => {
    const validationResult = formSchema.extract('routingNumber').validate(formValues.routingNumber);

    if (validationResult.error) {
      resetBankInfo();
    } else {
      fetchBankInfo(formValues.routingNumber as string);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formState === 'submitting') {
      return;
    }

    const validationResult = formSchema.validate(formValues, { abortEarly: false });
    if (validationResult.error) {
      const errors: Partial<Record<keyof Fields, string>> = {};
      validationResult.error.details.forEach((detail) => {
        errors[detail.path[0] as keyof Fields] = detail.message;
      });
      setFormErrors(errors);
      setTimeout(() => {
        const errorField = document.querySelector<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >('[aria-invalid="true"]');
        if (errorField) {
          errorField.focus();
          errorField.scrollIntoView();
        }
      }, 0);
      return;
    }

    setFormErrors({});
    setFormState('submitting');
    try {
      await onSubmit(formValues);
    } catch (err) {
      setFormErrors({
        // eslint-disable-next-line max-len
        FORM: 'Encountered an error trying to link account. Please try again or contact Checkr Pay Support',
      });
    }
    setFormState('idle');
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <AttentionBanner>
        <CircleWarning />
        <span>
          <Bold>Important:</Bold> The legal name on the account must match the name on your Checkr
          Pay account.
        </span>
      </AttentionBanner>

      <div>
        <FakeLabel>Name on the account</FakeLabel>
        <FakeInput>
          {firstName} {lastName}
        </FakeInput>
      </div>

      <div>
        <NumberInput
          label={
            <HorizontalStack>
              Routing Number {isLoadingBankInfo && <Spinner size="10px" />}
            </HorizontalStack>
          }
          id="routingNumber"
          name="routingNumber"
          value={formValues.routingNumber}
          onChange={(e) => handleFieldChange('routingNumber', e.target.value)}
          invalid={Boolean(formErrors.routingNumber)}
          errorText={formErrors.routingNumber}
          autoComplete="off"
          disabled={formState === 'submitting'}
          placeholder="Routing number"
          onBlur={handleBlurRoutingNumber}
        />
        {bankInfo && (
          <SmallMediumText style={{ position: 'absolute' }}>
            Bank name: {bankInfo.name}
          </SmallMediumText>
        )}
      </div>

      <NumberInput
        label="Account Number"
        id="accountNumber"
        name="accountNumber"
        value={formValues.accountNumber}
        onChange={(e) => handleFieldChange('accountNumber', e.target.value)}
        invalid={Boolean(formErrors.accountNumber)}
        errorText={formErrors.accountNumber}
        autoComplete="off"
        disabled={formState === 'submitting'}
        placeholder="Account number"
      />

      {formErrors.FORM && (
        <ErrorText>
          Encountered an error trying to link account. Please try again or contact Checkr Pay
          Support
        </ErrorText>
      )}

      <Button
        width="100%"
        text={
          formState === 'submitting'
            ? ((<Spinner size="30px" />) as unknown as string)
            : (submitButtonText as unknown as string)
        }
        colorVariant="brand"
        sizeVariant="big"
        disabled={formState === 'submitting'}
        // <Button> adds a default click handler that calls preventDefault()...
        onClick={() => null}
      />
    </StyledForm>
  );
}
