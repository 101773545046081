import styled from 'styled-components';

import { fontSizes, fontWeights } from '@/shared/styles';

import { MiniText, SmallText } from '@/shared/components/Text.styled';

// Containers and positioning
export const ConfirmProfileContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center; // Center horizontally
  justify-content: start;
  gap: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;
export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center; // Center Vertically
  justify-content: space-between;
`;

// Display
export const DataContent = styled.p`
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.bold};
  color: ${(p) => p.theme.colors.text.primary};
`;
export const DataLabel = styled.label`
  font-weight: ${fontWeights.light};
  font-size: ${fontSizes.medium};
  color: ${(p) => p.theme.colors.text.secondary};
`;

// Trypography
export const SupportText = styled(SmallText)`
  width: 100%;
  text-align: center;
  color: ${(p) => p.theme.colors.text.primary};
`;
export const DisclaimerText = styled(MiniText)`
  width: 95%;
  margin-top: 5px;
  padding: 0px 10px;
  text-align: center;
  line-height: 13px;
  color: ${(p) => p.theme.colors.text.secondary};
`;

export const StyledLink = styled.a`
  text-decoration: underline;
`;

export const OptionalInputRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center; // Center Vertically
  gap: 10px;
`;

export const ContactSupportText = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export const ManualPIIRequestText = styled.div`
  text-align: center;
`;

export const PiiRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start; // Center Vertically
  justify-content: space-between;
`;
