import { TabWrapper } from '@/app/wrappers/Containers';
import PageNavBar from '@/shared/components/PageNavBar';
import { Outlet } from 'react-router-dom';

export default function LinkBankLayout() {
  return (
    <TabWrapper>
      <PageNavBar title="Link Bank" navigateBackTo="/withdraw" />
      <Outlet />
    </TabWrapper>
  );
}
