/**
 * TransactionDetail – show the details of a single transaction for a page.
 *
 * The Transaction Views don't fetch transaction data – instead the TransactionsPage
 * which renders them does so that we don't re-pull data every time we navigate
 * to a sub-page.
 */
import TransactionSymbol from '@/shared/components/TransactionSymbol';
import { dateFormatter, formatCentsAsCurrency, WITHDRAWAL_PAGE } from '@/shared/helpers';
import { Button } from '@checkrx/pay-component-library';
import { useNavigate, useParams } from 'react-router-dom';

import { useTransactions } from '@/services/useTransactions';
import { ActionPrompt } from '@/shared/components/InAppMessages/MessageContent';
import { SmallMediumText } from '@/shared/components/Text.styled';
import { useExternalLinkPoster } from '@/shared/hooks/useExternalLinkPoster';
import { colors, fontSizes, fontWeights } from '@/shared/styles';
import { Transaction, TransactionStatus } from '@/shared/types';
import dayjs from 'dayjs';
import styled from 'styled-components';
import PayoutTransactionDetailSection from './PayoutTransactionDetailSection';
import {
  ButtonContainer,
  ClearingTimeStatusContainer,
  Container,
  DateText,
  DetailsContainer,
  SummaryText,
} from './TransactionPages.styled';

const FeeBadge = styled.div`
  background-color: #ffd700;
  color: black;
  padding: 2px 5px;
  border-radius: 4px;
  font-size: ${fontSizes.smallMedium};
`;

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
`;

const FlexColumnContainer = styled(FlexContainer)`
  flex-direction: column;
  height: 60px;
  align-items: flex-start;
`;

const SpaceBetweenContainer = styled(FlexContainer)`
  justify-content: space-between;
`;

const Description = styled.div`
  overflow: hidden;
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${colors.primaryBlue};
  font-size: ${fontSizes.big};
  margin-right: 8px;
  max-width: 160px;
  flex: 1;
`;

const Amount = styled.div`
  overflow: hidden;
  min-width: 0;
  color: ${(p) => p.theme.colors.text.accent};
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.big};
  white-space: nowrap;
`;

export const AttributeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
`;

export const AttributeLabel = styled.div`
  color: ${colors.primaryGreyMediumDark};
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.bold};
`;

export const AttributeValue = styled.div`
  color: ${(p) => p.theme.colors.text.accent};
  font-size: ${fontSizes.medium};
`;

export default function TransactionDetailPage() {
  const { transactionId } = useParams();
  const navigate = useNavigate();

  const sendExternalLink = useExternalLinkPoster({
    link: WITHDRAWAL_PAGE,
  });

  // TODO(Carter): Add a select function to get _one_ worker transaction by ID. I'm just being lazy
  const { data: transactions = [] } = useTransactions();

  const transaction = transactions.filter((t: Transaction) => t.transactionId === transactionId)[0];
  // Short circuit for now if we don't find anything
  if (!transaction) {
    return <></>;
  }

  const isPayoutTransaction = transaction.metadata?.isPayout;
  const payoutMethodFee = Number(transaction?.metadata?.payoutMethodFeeCents ?? 0);

  const amount = formatCentsAsCurrency(transaction.amount - payoutMethodFee);
  const {
    createdAt,
    expectedCompletionDate,
    transactionType,
    summary,
    direction,
    description,
    metadata,
    transactionStatus,
  } = transaction;

  let status;
  if (transactionStatus === TransactionStatus.Authorized) {
    status = 'Pending';
  } else if (transactionStatus === TransactionStatus.Sent) {
    status = 'Completed';
  } else {
    status = transactionStatus;
  }

  let expectedCompletionDateMessage;
  if (status === TransactionStatus.Pending) {
    if (expectedCompletionDate) {
      expectedCompletionDateMessage = dayjs(expectedCompletionDate).format('MMM D, YYYY');
    } else if (dayjs().isAfter(dayjs(expectedCompletionDate))) {
      expectedCompletionDateMessage = 'Any time today';
    }
  }

  return (
    <Container>
      <DetailsContainer>
        <FlexContainer>
          <TransactionSymbol size="60px" type={transactionType} direction={direction} />
          <FlexColumnContainer>
            <SpaceBetweenContainer>
              <Description>{metadata?.annotation || description || summary}</Description>
              <Amount>{amount}</Amount>
            </SpaceBetweenContainer>
            <SpaceBetweenContainer>
              <DateText>{dateFormatter(createdAt)}</DateText>
              {payoutMethodFee > 0 ? (
                <FeeBadge>{formatCentsAsCurrency(payoutMethodFee)} fee applied</FeeBadge>
              ) : (
                <div />
              )}
            </SpaceBetweenContainer>
          </FlexColumnContainer>
        </FlexContainer>
      </DetailsContainer>
      <AttributeWrapper>
        <AttributeLabel>Description</AttributeLabel>
        <AttributeValue>{metadata?.annotation || description || summary}</AttributeValue>
      </AttributeWrapper>
      <AttributeWrapper>
        <AttributeLabel>Status</AttributeLabel>
        <AttributeValue>{status}</AttributeValue>
      </AttributeWrapper>

      {expectedCompletionDateMessage && (
        <ClearingTimeStatusContainer>
          <AttributeLabel>Approximate Clearing Time</AttributeLabel>
          <AttributeValue>
            {expectedCompletionDateMessage}
            <SmallMediumText>
              Your bank may take additional time to release funds.{' '}
              <ActionPrompt onClick={sendExternalLink}>Learn more about it here</ActionPrompt>.
            </SmallMediumText>
          </AttributeValue>
        </ClearingTimeStatusContainer>
      )}
      {isPayoutTransaction && <PayoutTransactionDetailSection transaction={transaction} />}
      <SummaryText>{summary}</SummaryText>
      <ButtonContainer>
        <Button
          icon="help-circle"
          width="100%"
          text="Contact support"
          colorVariant="light"
          sizeVariant="small"
          onClick={() => {
            navigate('/support');
          }}
        />
      </ButtonContainer>
    </Container>
  );
}
