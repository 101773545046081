export const parseFullName = (fullName: string) => {
  if (!fullName) {
    return {};
  }

  const names = fullName.trim().split(' ');
  const firstName = names[0];
  const lastName = names[names.length - 1];
  const middleName =
    names.length > 2
      ? names
          .map((name, idx) => {
            if (idx === 0 || idx === names.length - 1) return;
            return name;
          })
          .join(' ')
          .trim()
      : '';

  if (firstName?.length && lastName?.length) {
    return {
      firstName,
      middleName,
      lastName,
    };
  }

  return {};
};
