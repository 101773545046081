import { useEffect, useState } from 'react';
import { trackAmplitudeEvent } from '../analytics';

type isOpenValue = boolean;
type SetIsOpenFn = (value: boolean) => void;

const useZendeskChat = (): [isOpenValue, SetIsOpenFn] => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const zE = (window as any).zE;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!zE) {
      return;
    }

    if (isOpen) {
      zE('messenger', 'show');
      zE('messenger', 'open');
      trackAmplitudeEvent('Chat Support Viewed');

      zE('messenger:on', 'close', () => {
        setIsOpen(false);
      });
    } else {
      zE('messenger', 'close');
      zE('messenger', 'hide');
    }

    return () => {
      zE('messenger', 'close');
      zE('messenger', 'hide');
    };
  }, [zE, isOpen]);

  return [isOpen, setIsOpen];
};

export default useZendeskChat;
