import TransactionList from '@/shared/components/TransactionList';
import { TextInput } from '@checkrx/pay-component-library';
import { ChangeEvent, useState } from 'react';

import { useTransactions } from '@/services/useTransactions';
import { groupTransactionsByYear } from '@/shared/lib/transaction-helpers';
import { Transaction, TransactionDirection, TransactionType } from '@/shared/types';
import { SearchGroup, ViewContainer } from './TransactionPages.styled';

export default function WithdrawalHistoryPage() {
  const { data: allTransactions = [] } = useTransactions();
  const [query, setQuery] = useState('');

  const allWithdrawals = allTransactions.filter(
    ({ transactionType, direction }: Transaction) =>
      transactionType === TransactionType.Ach && direction === TransactionDirection.Debit
  );
  const organizedTransactions = groupTransactionsByYear(allWithdrawals);

  return (
    <ViewContainer>
      <SearchGroup>
        <TextInput
          value={query}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
          placeholder="Search transactions"
        />
      </SearchGroup>
      {query?.length > 0 && (
        <TransactionList
          title="Search results"
          transactions={allTransactions.filter(({ summary }: Transaction) =>
            summary.toLowerCase().includes(query.toLowerCase())
          )}
        />
      )}

      {organizedTransactions.map(({ year, longYear, transactions }) => (
        <TransactionList
          key={`transaction-list-${year}`}
          title={longYear}
          transactions={transactions}
        />
      ))}
    </ViewContainer>
  );
}
