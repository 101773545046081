import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import serverApi from './serverApi';

type TaxesNotificationReq = {
  taxYear: string;
};

const postWorkerTaxesNotification = async ({ taxYear }: TaxesNotificationReq) => {
  const res = await serverApi.post(`/notify/taxes`, {
    taxYear,
  });
  return res.data;
};

export const useSendTaxesNotification = (
  options?: Partial<UseMutationOptions<unknown, unknown, unknown>>
) => {
  return useMutation({
    mutationFn: ({ taxYear }: TaxesNotificationReq) => postWorkerTaxesNotification({ taxYear }),
    useErrorBoundary: false,
    ...options,
  });
};
