import styled from 'styled-components';

import { NormalText, TitleText } from '@/shared/components/Text.styled';
import { fontSizes, fontWeights } from '@/shared/styles';

export const LinkingPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 25px;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const Title = styled(TitleText)`
  font-size: ${fontSizes.giant};
  padding-bottom: 15px;
  padding-top: 15px;
`;

export const SubTitle = styled(NormalText)`
  color: ${(p) => p.theme.colors.text.secondary};
  font-weight: ${fontWeights.light};
  font-size: ${fontSizes.medium};
`;

export const BottomButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ResultTitle = styled(TitleText)`
  width: 100%;
  text-align: center;
  font-size: ${fontSizes.giant};
  font-weight: ${fontWeights.bold};
`;

export const ResultText = styled(NormalText)`
  width: 100%;
  font-weight: ${fontWeights.light};
  font-size: ${fontSizes.medium};
  color: ${(p) => p.theme.colors.text.secondary};
  text-align: center;
  line-height: 18px;
`;
