import useFeatureToggles, {
  DEFAULT_FEATURE_TOGGLES,
  ToggleKey,
  TOGGLE_KEYS,
} from './feature-toggles';

import { Button, Toggle } from '@checkrx/pay-component-library';
import { FC } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { colors } from '../styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 400px;
  height: 100%;
  background: ${colors.primaryWhite};
  opacity: 95%;
  padding: 12px;
  z-index: 1000;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 16px;
`;

const Title = styled.div`
  width: 100%;
  font-size: 22px;
  color: ${colors.primaryBlue};
  font-weight: bold;
`;

const ToggleList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  scrollbar-width: thin;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ToggleItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ToggleKeyText = styled.span`
  color: ${colors.primaryGreyMediumDark};
`;

const ToggleMiniText = styled.span`
  color: ${colors.primaryGreyMediumDark};
  font-size: 12px;
`;

const ToggleRemoveText = styled.span`
  color: ${colors.accentTeal};
  &:hover {
    filter: brightness(80%);
  }
  cursor: pointer;
`;

export const FeatureToggles: FC<{ show: boolean; onClose: () => void }> = ({ show, onClose }) => {
  const { listActiveFeatureToggles, setFeatureToggle, removeFeatureToggle } = useFeatureToggles();

  if (!show) return null;

  const toggles = listActiveFeatureToggles();
  const handleToggleChange = (key: ToggleKey, value: boolean) => setFeatureToggle({ key, value });

  const notUsedInCode = (key: ToggleKey) => !TOGGLE_KEYS.includes(key as ToggleKey);

  return createPortal(
    <Container>
      <Header>
        <Title>Toggles</Title>
        <Button text="Close" onClick={onClose} colorVariant="light" />
      </Header>
      <ToggleList>
        {Object.entries(toggles).map(([key, value]) => {
          const toggleKey = key as ToggleKey;
          return (
            <ToggleItem key={toggleKey}>
              <ToggleKeyText>
                {toggleKey}{' '}
                {notUsedInCode(toggleKey) && (
                  <ToggleMiniText className="text-secondary">
                    (not used in code){' '}
                    <ToggleRemoveText onClick={() => removeFeatureToggle({ key: toggleKey })}>
                      remove
                    </ToggleRemoveText>
                  </ToggleMiniText>
                )}
              </ToggleKeyText>
              <ToggleContainer>
                <ToggleMiniText>
                  Default: {DEFAULT_FEATURE_TOGGLES[toggleKey] ? 'On' : 'Off'}
                </ToggleMiniText>
                <Toggle
                  isOn={value}
                  onClick={() => handleToggleChange(toggleKey, !value)}
                  disabled={notUsedInCode(toggleKey)}
                />
              </ToggleContainer>
            </ToggleItem>
          );
        })}
      </ToggleList>
    </Container>,
    document.body
  );
};
