import { useSendTaxesNotification } from '@/services/useTaxes';
import { useWorkerProfile } from '@/services/useWorkerProfile';
import DividedCard from '@/shared/components/DividedCard';
import { SupportBlock } from '@/shared/components/SupportBlock';
import { Headline, SupportText, TaxesGroup, TextBox } from '@/shared/components/Taxes.styled';
import { toast } from '@/shared/components/Toaster/Toaster';
import { KEEPER_TAX_PARTNERSHIP_LINK, SUPPORT_TAX_PAGE } from '@/shared/helpers';
import { WorkerProfile } from '@/shared/types';
import { Button } from '@checkrx/pay-component-library';
import { FC, useState } from 'react';

import ExternalLinkPoster from '@/shared/components/ExternalLinkPoster';
import { TaxesLink } from './TaxesLink';

type Props = {
  taxesList: { id: string; url: string; year: string }[];
};

export const TaxesList: FC<Props> = ({ taxesList }) => {
  const [shouldSendExternalLink, setShouldSendExternalLink] = useState(false);

  const { data: profile } = useWorkerProfile();
  const workerProfile = profile as WorkerProfile;

  const { mutate: sendTaxesNotification } = useSendTaxesNotification({
    onError: () => {
      toast({
        type: 'error',
        message: 'There was an error sending your 1099-NEC',
        duration: 4500,
      });
    },
    onSuccess: () => {
      toast({
        type: 'success',
        message: `Successfully sent your 1099-NEC to ${workerProfile?.profile?.contactEmail}`,
        duration: 4500,
      });
    },
  });

  const handleGetPdf = async (year: string) => {
    toast({
      type: 'info',
      message: `Sending your 1099-NEC to ${workerProfile?.profile?.contactEmail}`,
      duration: 4500,
    });
    sendTaxesNotification({ taxYear: year });
  };

  const renderEarnerTaxFilingInfo = () => {
    return (
      <TextBox>
        <Headline>Ready to file your taxes?</Headline>
        <SupportText>
          We&apos;ve partnered with Keeper to save you time and money on taxes! Checkr Pay users get
          60% off. File for just $79, with no upsells!
        </SupportText>
        <SupportText>
          Continue to the Checkr Pay & Keeper Tax Partner Page to get your discount
        </SupportText>

        {shouldSendExternalLink && (
          <ExternalLinkPoster
            onSent={() => setShouldSendExternalLink(false)}
            link={KEEPER_TAX_PARTNERSHIP_LINK}
            prependText="Card Management"
          />
        )}

        <Button
          width="100%"
          iconRight
          icon="arrow-right"
          text="Get My Discount"
          colorVariant="accent"
          sizeVariant="big"
          onClick={() => {
            setShouldSendExternalLink(true);
          }}
        />
      </TextBox>
    );
  };

  if (taxesList.length === 0)
    return (
      <>
        <TextBox>
          <Headline>Looking for your tax documents?</Headline>
          <SupportText>Your 1099-NEC will be viewable as soon as it is created.</SupportText>
          <SupportText>We&apos;ll also send you an email when it&apos;s ready.</SupportText>
        </TextBox>

        {renderEarnerTaxFilingInfo()}
      </>
    );
  return (
    <>
      <TaxesGroup>
        <DividedCard
          elements={taxesList.map((t) => (
            <TaxesLink
              key={`${t?.id}-card-link`}
              year={t?.year}
              onClick={() => handleGetPdf(t.year)}
            />
          ))}
        />
        <SupportBlock prependText="Tax information" supportPage={SUPPORT_TAX_PAGE} />
      </TaxesGroup>
      {renderEarnerTaxFilingInfo()}
    </>
  );
};
