import { useWorkerProfile } from '@/services/useWorkerProfile';
import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import refiner from 'refiner-js';
import { WorkerProfile } from './types';

declare global {
  interface Window {
    $fvIdentity: {
      id?: string;
      name?: string;
      env?: string;
      roles?: string[];
    };
  }
}

export function Analytics() {
  const location = useLocation();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const referer = urlParams.get('referer') || 'unknown';
  const { data: workerProfile } = useWorkerProfile();

  useEffect(() => {
    if (workerProfile) {
      identifyUser(workerProfile, referer);
    }
  }, [workerProfile, referer]);

  useEffect(() => {
    identifyPage(location.pathname);
  }, [location]);

  return null;
}

export function identifyUser(workerProfile: WorkerProfile, referer: string) {
  refiner('identifyUser', {
    activatedAt: workerProfile.profile?.activatedAt,
    completedOnboarding: workerProfile.profile?.completedOnboarding,
    createdAt: workerProfile.worker?.createdAt,
    customer: workerProfile.customer?._id,
    customerName: workerProfile.customer?.humanReadableName,
    email: workerProfile.profile?.contactEmail,
    firstName: workerProfile.profile?.checkrCorePII?.firstName,
    id: workerProfile.profile?.publicUid,
    lastName: workerProfile.profile?.checkrCorePII?.lastName,
    onboardedAt: workerProfile.profile?.onboardedAt,
    status: workerProfile.profile?.status,
    type: 'worker',
    workerId: workerProfile.profile?.publicUid,
  });

  amplitude.setUserId(workerProfile.profile?.publicUid);
  amplitude.setGroup('customer', workerProfile.customer?.humanReadableName || '');

  const identifyEvent = new amplitude.Identify();
  identifyEvent.set('activatedAt', workerProfile.profile?.activatedAt?.toString() || '');
  identifyEvent.set('completedOnboarding', !!workerProfile.profile?.completedOnboarding);
  identifyEvent.set('createdAt', workerProfile.worker?.createdAt?.toString() || '');
  identifyEvent.set('email', workerProfile.profile?.contactEmail || '');
  identifyEvent.set('firstName', workerProfile.profile?.checkrCorePII?.firstName || '');
  identifyEvent.set('lastName', workerProfile.profile?.checkrCorePII?.lastName || '');
  identifyEvent.set('onboardedAt', workerProfile.profile?.onboardedAt?.toString() || '');
  identifyEvent.set('status', workerProfile.profile?.status || '');
  identifyEvent.set('referer', referer);
  identifyEvent.set('type', 'worker');
  identifyEvent.set('workerId', workerProfile.profile?.publicUid || '');
  amplitude.identify(identifyEvent);

  Sentry.setUser({
    id: workerProfile.profile?.publicUid,
    email: workerProfile.profile?.contactEmail,
  });
  Sentry.getCurrentScope().setTag('customerName', workerProfile.customer?.humanReadableName);

  window.$fvIdentity = {
    id: workerProfile.profile?.publicUid,
    name: workerProfile.profile?.contactEmail,
    env: process.env.REACT_APP_ENV,
    roles: ['Embedded Component'],
  };
}

// Because the app uses a memory router we
// need to tell some services about the current url
export function identifyPage(path: string) {
  Sentry.getCurrentScope().setTag('urlPath', path);
}

export function initAnalytics() {
  if (['production', 'sandbox'].includes(process.env.REACT_APP_ENV || '')) {
    attachFullviewScript();
  }

  if (process.env.REACT_APP_REFINER_PROJECT_ID) {
    refiner('setProject', process.env.REACT_APP_REFINER_PROJECT_ID);
  }

  if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, {
      defaultTracking: true,
    });
  }
}

export function trackAmplitudeEvent(event: string, properties?: amplitude.Types.EventOptions) {
  amplitude.track(event, properties);
}

export function trackRefinerEvent(event: string) {
  refiner('trackEvent', event);
}

function attachFullviewScript() {
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://install.fullview.io';
  script.dataset.org = '8a249d19-a210-4312-b6b6-b0816d34be5f';
  document.head.appendChild(script);
}
