import dayjs from 'dayjs';
import Joi from 'joi';
import { emailValidation, fullNameValidation } from '../shared/validations';

export const businessNameValidation = Joi.string()
  .required()
  .messages({ '*': 'Please enter a full business name' })
  .min(4);

export const formationDateValidation = Joi.date()
  .required()
  .messages({ '*': 'You must have a formation date in the past' })
  .less(dayjs().toDate());

export const entityTypeValidation = Joi.string()
  .optional()
  .lowercase()
  .messages({ '*': 'Please select an entity type' });

export const einValidation = Joi.string()
  .min(10)
  .required()
  .replace(/(\d{2})(\d{7})/, '$1-$2')
  .messages({ '*': 'Please enter a valid 9 digit EIN' });

export const personalDetailsValidation = Joi.object({
  fullName: fullNameValidation,
  email: emailValidation,
});

export const businessDetailsValidationSchema = Joi.object({
  businessName: businessNameValidation,
  formationDate: formationDateValidation,
  ein: einValidation,
  entityType: entityTypeValidation,
});

export const addressValidationSchema = Joi.object({
  street: Joi.string().required().messages({ '*': 'Please enter a valid street' }),
  street2: Joi.string()
    .optional()
    .messages({ '*': 'Please enter a valid secondary street' })
    .allow(null, ''),
  city: Joi.string().required().messages({ '*': 'Please enter a valid city' }),
  state: Joi.string().required().messages({ '*': 'Please enter a valid state' }),
  postalCode: Joi.string()
    .min(5)
    .max(5)
    .required()
    .messages({ '*': 'Please enter a valid postal code' }),
  country: Joi.equal('US'),
});
