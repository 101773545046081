import serverApi from '@/services/serverApi';
import { Payout } from '@/shared/types';
import { useQuery } from '@tanstack/react-query';

async function fetchUnfulfilledPayouts() {
  const res = await serverApi.get('/unfulfilled_payouts');
  return res.data;
}

export const useUnfulfilledPayouts = <TData = Payout[]>(select?: (data: Payout[]) => TData) => {
  return useQuery({
    queryKey: ['payouts', 'unfulfilled'],
    queryFn: () => fetchUnfulfilledPayouts(),
    select,
  });
};
