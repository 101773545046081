import App from '@/app/App';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import BaseErrorFallback from './app/wrappers/BaseErrorFallback';
import { initAnalytics } from './shared/analytics';

// Get the relevant Element
const checkrWalletElements = document.querySelectorAll('.checkr-wallet');

Sentry.init({
  allowUrls: [/checkrpay\.com/], // ignore errors from 3rd party scripts like fullview
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [new Sentry.BrowserTracing()],
  ignoreErrors: [/NetworkError/], // ignore network errors
  tracesSampleRate: 0.1,
  normalizeDepth: 10, // how deep to parse context json
});
initAnalytics();

/*
 * We'll find all the Checkr Wallet divs that a Customer might have in their app
 * and inject a new instance of the wallet app in there.
 */
checkrWalletElements.forEach(async (div) => {
  const root = ReactDOM.createRoot(div);

  // Define a mobile container for our app
  const urlPath = window.location.pathname.slice(1).toLowerCase();

  // Get the token from the URL and pass it as a prop down to the App component
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const tempAuthToken = urlParams.get('tempAuthToken');

  // const secretKey = urlParams.get('secretKey');
  // const accessKey = urlParams.get('accessKey');

  // Set up the Query Client
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // Fallback to the nearest error boundary if any query fails
        useErrorBoundary: true,
        // By default, consider data active for 30 seconds
        staleTime: 30000,
        // By default, a refresh doesn't force pulling stale data
        refetchOnMount: 'always',
        // Retry once automatically if a network request fails
        retry: 1,
        // Retry after 500ms
        retryDelay: 500,
      },
      mutations: {
        // Fallback to the nearest error boundary if a mutation fails (by default)
        useErrorBoundary: true,
      },
    },
  });
  // Janky routing because React Router doesn't allow us to nest
  // a MemoryRouter inside a WebRouter.
  // This may change: https://github.com/remix-run/react-router/pull/9112
  switch (urlPath) {
    // console.log(urlPath)
    // TODO(Carter): Add back Demo Path
    // case 'demo': {
    //   appElement = (
    //     <Demo
    //       initTempAuthToken={tempAuthToken}
    //       initAccessKey={accessKey}
    //       initSecretKey={secretKey}
    //       initialPath="/"
    //     />
    //   );
    //   break;
    // }
    // By default, return the non-demo app
    default: {
      root.render(
        <React.StrictMode>
          <ErrorBoundary FallbackComponent={BaseErrorFallback}>
            <QueryClientProvider client={queryClient}>
              <App tempAuthToken={tempAuthToken} />
              {/*
               * React Query Dev Tools – for debugging / inspection, change initialIsOpen to true.
               * NOTE: This is not bundled in production by default, so no concerns
               */}
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </ErrorBoundary>
        </React.StrictMode>
      );
    }
  }
});
