import SuccessGraphic from '@/shared/components/SuccessGraphic';
import { Button } from '@checkrx/pay-component-library';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { LinkingPageContainer, ResultText, ResultTitle } from './LinkBank.styled';

const StyledPageContainer = styled(LinkingPageContainer)`
  padding: 20px 30px;
  gap: 20px;
  width: 100%;
`;

export const TextBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: start;
`;

const BottomButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  height: 100px;
`;

const LinkBankResultView = () => {
  const navigate = useNavigate();
  return (
    <StyledPageContainer>
      <SuccessGraphic className="link-bank-success-graphic" />
      <TextBox>
        <ResultTitle>Success!</ResultTitle>
        <ResultText>
          Your external bank account has been successfully linked to Checkr Pay.
        </ResultText>
      </TextBox>
      <BottomButtonContainer>
        <Button
          width="100%"
          text="Continue to withdraw"
          iconRight
          icon="arrow-right"
          colorVariant="brand"
          sizeVariant="big"
          onClick={() => navigate('/withdraw/review')}
        />
      </BottomButtonContainer>
    </StyledPageContainer>
  );
};

export default LinkBankResultView;
