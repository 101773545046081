/**
 * A Component calling endpoint to post an external link and rendering
 * either error or success text as a result.
 *
 * Note: this component automatically calls the external link generating
 * mutation when rendering! Always render this component conditionally.
 */
import { useSendExternalSMSLink } from '@/services/onboarding';
import { useWorkerProfile } from '@/services/useWorkerProfile';
import { isUiWebView } from '@/shared/helpers';
import { toast } from '../components/Toaster/Toaster';

/**
 * Re-useable hook to run a post mutation link Api.
 */
export const useExternalLinkPoster = ({
  link,
  prependText = '',
  onSent,
}: {
  link: string;
  prependText?: string;
  onSent?: () => void;
}) => {
  const { data: workerProfile } = useWorkerProfile();
  const { mutate: postExternalLinkSms } = useSendExternalSMSLink();

  const contactPhone = workerProfile?.profile?.contactPhone || 'your mobile device on file';

  const runPostExternalLinkApi = () => {
    // check the browser
    if (!isUiWebView()) {
      toast({
        type: 'info',
        message: 'Opening link in another tab',
        duration: 2500,
      });

      // If we're in a normal browser, desktop or mobile, then just
      // open it normally in a new tab
      window.open(link, '_blank', 'noreferrer');
      // Reset state after we've opened the window
      onSent?.();
    } else {
      // Otherwise, hit the API endpoint to request a SMS link to support
      postExternalLinkSms({ smsBody: `${prependText}: ${link}` });
      toast({
        type: 'info',
        message: `Sending link via text to ${contactPhone}`,
        duration: 2500,
      });
    }
  };

  return runPostExternalLinkApi;
};
