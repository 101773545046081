import { useBankingShortCircuiter } from '@/services/bankingShortCircuit/useBankingShortCircuiter';
import { useCustomerFromProfile, useWorkerProfile } from '@/services/useWorkerProfile';
import LoadingDots from '@/shared/components/LoadingDots';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { colors, fontSizes } from '../styles';
import { PayoutMethod } from '../types';
import { CenterSpinner } from './CashbackSummaryPanel.styled';
import { StyledLink } from './Text.styled';

export const Panel = styled.div`
  background: ${colors.primaryWhite};
  font-size: ${fontSizes.medium};
  margin-top: 10px;
  padding: 10px 15px;
`;

export default function SetupPayoutMethodPanel() {
  const { status } = useBankingShortCircuiter();
  const { data: workerProfile, isLoading: isWorkerProfileLoading } = useWorkerProfile();
  const { data: customer, isLoading: isCustomerLoading } = useCustomerFromProfile();
  const navigate = useNavigate();

  const hasMultiplePayoutMethodsEnabled =
    workerProfile?.profile?.additionalPayoutMethods?.achDirectDeposit?.enabled ||
    workerProfile?.profile?.additionalPayoutMethods?.pushToCardDirectDeposit?.enabled ||
    customer?.payoutMethods?.achDirectDeposit?.enabled ||
    customer?.payoutMethods?.pushToCardDirectDeposit?.enabled;

  const payBankPayoutMethodSelected =
    workerProfile?.profile?.payoutMethod?.selected === PayoutMethod.CheckrPayBankAccount;

  if (isCustomerLoading || isWorkerProfileLoading) {
    return (
      <CenterSpinner>
        <LoadingDots size="10px" />
      </CenterSpinner>
    );
  }

  const isWorkerBusinessType = workerProfile?.profile?.workerType === 'business';
  const needsDirectDeposit =
    isWorkerBusinessType && workerProfile?.profile?.payoutMethod?.selected !== 'ACHDirectDeposit';
  // Do not show this after 10 days past onboarding.
  // The activation clause is for the non JIT customer Vitawerks, and can be removed
  // after their migration.
  const manyDaysSinceOnboarding =
    dayjs().diff(
      workerProfile?.profile?.onboardedAt ?? workerProfile?.profile?.activatedAt,
      'days'
    ) > 10;

  if (
    status === 'Closed' &&
    hasMultiplePayoutMethodsEnabled &&
    workerProfile?.profile?.payoutMethod?.selected !== 'ACHDirectDeposit'
  ) {
    return (
      <Panel>
        To ensure you get paid promptly{' '}
        <strong>
          <StyledLink onClick={() => navigate('/setup-direct-deposit')}>
            Set up Direct Deposit
          </StyledLink>
        </strong>{' '}
        as your payout method.
      </Panel>
    );
  }

  if (!hasMultiplePayoutMethodsEnabled || !payBankPayoutMethodSelected || manyDaysSinceOnboarding) {
    return <></>;
  }

  if (needsDirectDeposit) {
    return (
      <Panel>
        When using Checkr Pay as a business, you must use and{' '}
        <strong>
          <StyledLink onClick={() => navigate('/setup-direct-deposit')}>
            Set up Direct Deposit
          </StyledLink>
        </strong>{' '}
        as your payout method.
      </Panel>
    );
  }
  return (
    <Panel>
      You have selected <strong>Checkr Wallet</strong> as your payout method. This gives you instant
      access to your funds. Review additional{' '}
      <StyledLink onClick={() => navigate('/manage-payout-methods')}>
        payout methods here
      </StyledLink>
      .
    </Panel>
  );
}
