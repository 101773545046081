import SuccessGraphic from '@/shared/components/SuccessGraphic';
import { NormalText, TitleText } from '@/shared/components/Text.styled';
import { fontSizes, fontWeights } from '@/shared/styles';
import { Button } from '@checkrx/pay-component-library';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const TextBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: start;
`;

const BottomButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  height: 100px;
`;

export const ResultTitle = styled(TitleText)`
  width: 100%;
  text-align: center;
  font-size: ${fontSizes.giant};
  font-weight: ${fontWeights.bold};
`;

export const ResultText = styled(NormalText)`
  width: 100%;
  font-weight: ${fontWeights.light};
  font-size: ${fontSizes.medium};
  color: ${(p) => p.theme.colors.text.secondary};
  text-align: center;
  line-height: 18px;
`;

const LinkDirectDepositResultPage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <SuccessGraphic className="link-bank-success-graphic" />
      <TextBox>
        <ResultTitle>Success!</ResultTitle>
        <ResultText>
          Your external bank account has been successfully linked to Checkr Pay.
        </ResultText>
      </TextBox>
      <BottomButtonContainer>
        <Button
          width="100%"
          text="Continue"
          iconRight
          icon="arrow-right"
          colorVariant="brand"
          sizeVariant="big"
          onClick={() => navigate('/')}
        />
      </BottomButtonContainer>
    </div>
  );
};

export default LinkDirectDepositResultPage;
