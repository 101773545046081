import dayjs from 'dayjs';
import Joi from 'joi';

export const fullNameValidation = Joi.string()
  .required()
  .messages({ '*': 'Please enter a full name' })
  .regex(/^[a-zA-Z ,.'-]{2,40}(?:\s[a-zA-Z ,.'-])?\s[a-zA-Z ,.'-]{2,40}$/);
export const emailValidation = Joi.string()
  .optional()
  .lowercase()
  .messages({ '*': 'Please enter a valid email address' });
export const dobValidation = Joi.date()
  .required()
  .messages({ '*': 'You must be 18 years old' })
  .less(dayjs().subtract(18, 'years').toDate());
export const ssnValidation = Joi.string()
  .min(11)
  .required()
  .replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3')
  .messages({ '*': 'Please enter a valid 9 digit SSN' });
