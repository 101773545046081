import { trackAmplitudeEvent } from '@/shared/analytics';
import { ReactComponent as CircledEmailLetter } from '@/shared/assets/circled-email-letter.svg';
import { ReactComponent as CircledPhone } from '@/shared/assets/circled-phone.svg';
import { ReactComponent as KeyIcon } from '@/shared/assets/key.svg';
import { getWorkerContactMethods, getWorkerContacts, maskContact } from '@/shared/helpers';
import { MfaMethods, WorkerContactMethods } from '@/shared/types';
import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import NumberInput from '@/shared/components/NumberInput';
import { Bold, ErrorText, TitleText } from '@/shared/components/Text.styled';
import { Button, Spinner } from '@checkrx/pay-component-library';

import { useResetOtp, useVerifyReset2fa } from '@/services/useVerification';
import { useWorkerProfile } from '@/services/useWorkerProfile';
import { SpinnerRow } from '@/shared/components/SpinnerRow';
import {
  CodeControls,
  MfaVerificationContainer,
  MfaViewContainer,
  NumberRow,
  StyledLink,
  Text,
} from './VerificationPage.styled';

export default function VerifySecondaryContactPage() {
  const [digits, setDigits] = useState('');

  const navigate = useNavigate();

  const { refetch: resetOtp, isError: isResetOtpError } = useResetOtp();
  const {
    mutate: verifyOtp,
    isLoading,
    isSuccess,
    isError: isVerificationError,
  } = useVerifyReset2fa();

  const { data: workerProfile } = useWorkerProfile();

  const customerMfaPref = workerProfile?.customer?.preferences?.mfaMethod ?? MfaMethods.Email;
  const { primaryContactMethod, secondaryContactMethod } = getWorkerContactMethods(
    workerProfile?.customer?.preferences?.mfaMethod ?? MfaMethods.Email
  );
  const { secondaryContact } = getWorkerContacts(
    customerMfaPref,
    workerProfile?.profile?.contactPhone ?? '',
    workerProfile?.profile?.contactEmail ?? ''
  );

  const secondaryContactIcon =
    customerMfaPref === MfaMethods.Sms ? <CircledEmailLetter /> : <CircledPhone />;

  let errorMessage = <></>;
  if (isVerificationError) {
    errorMessage = <ErrorText>The verification code is incorrect or expired</ErrorText>;
  } else if (isResetOtpError) {
    errorMessage = (
      <ErrorText>
        Make sure your {primaryContactMethod} is formatted correctly and different from your
        existing {primaryContactMethod} on file
      </ErrorText>
    );
  }

  const onSecondaryOtpSubmit = async () => {
    trackAmplitudeEvent('Two factor Reset - Verify Secondary Contact Success');
    verifyOtp({ otp: digits });
  };

  const handleResendOtp = async () => {
    setDigits(''); // clear the input field
    resetOtp();
  };

  if (isSuccess) {
    return <Navigate to="/verification/new-contact" />;
  }

  return (
    <MfaViewContainer>
      <MfaVerificationContainer>
        {secondaryContactIcon}
        <TitleText>Check your {secondaryContactMethod}</TitleText>

        <Text>
          In order to reset your {primaryContactMethod} we need to verify your identity using the
          {` ${secondaryContactMethod}`} we have on file for you. Can&apos;t access your{' '}
          {secondaryContactMethod}?{' '}
          <StyledLink
            onClick={() => {
              navigate('/support');
            }}
          >
            {' '}
            Contact support{' '}
          </StyledLink>
        </Text>

        <NumberRow>
          <NumberInput
            centered
            value={digits}
            onChange={(e) => setDigits(e.target.value)}
            leftIcon={<KeyIcon />}
          />
        </NumberRow>

        <CodeControls>
          <Text>
            Verification code sent to
            <Bold>
              {' '}
              {maskContact(secondaryContact, secondaryContactMethod as WorkerContactMethods)}{' '}
            </Bold>
          </Text>
          {(isResetOtpError || isVerificationError) && errorMessage}
          <Button
            className="mfa-resend-btn"
            colorVariant="dark"
            text="Send another code"
            sizeVariant="small"
            onClick={handleResendOtp}
          />
        </CodeControls>
      </MfaVerificationContainer>

      <SpinnerRow>
        <Button
          className="mfa-submit-btn"
          width="100%"
          text="Submit"
          colorVariant="brand"
          sizeVariant="big"
          iconRight
          icon="arrow-right"
          disabled={digits.length < 6}
          onClick={onSecondaryOtpSubmit}
        />
        {isLoading ? (
          <div
            style={{
              marginRight: '-30px',
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          >
            <Spinner size="30px" />
          </div>
        ) : (
          <div />
        )}
      </SpinnerRow>
    </MfaViewContainer>
  );
}
