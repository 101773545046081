/**
 * If you navigate you to this page, it'll take re-direct you to the default
 * place you Should Be.
 */
import { useWorkerProfile, useWorkerProfileIsActive } from '@/services/useWorkerProfile';
import useLoading from '@/shared/hooks/useLoading';
import { LoadingScope, ProfileStatus } from '@/shared/types';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

export default function HomePageRedirector() {
  const { data: workerProfile, isLoading, isSuccess } = useWorkerProfile();
  const { data: workerProfileIsActive } = useWorkerProfileIsActive();
  const setLoading = useLoading(isLoading, LoadingScope.global);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);
  if (isLoading) {
    return <></>;
  }

  if (isSuccess) {
    const { verified, profile } = workerProfile;
    // If we have a verified user, we should send them straight to where they left off,
    // skipping verification.
    const status = profile?.status;
    if (verified) {
      if (workerProfileIsActive) {
        // If the worker is verified and active, take them to their dashboard
        return <Navigate to="/dashboard" replace={true} />;
      } else if (status === ProfileStatus.Pending || status === ProfileStatus.RequirePII) {
        // If the worker is verified but not activated, take them to the sign up flow
        return <Navigate to="/onboarding" replace={true} />;
      } else if (status === ProfileStatus.ManualReview || status === ProfileStatus.Failed) {
        // If worker is in manual review or has failed activation, take them to status page
        return <Navigate to="/onboarding/activation-status" replace={true} />;
      } else {
        // Unexpected server state - error loudly
        throw new Error(`Invalid users status: ${status}`);
      }
    }

    // Otherwise, they're not yet verified and we'll need to verify them
    if (workerProfileIsActive) {
      // Profile is active, but not verified. Direct them to sign-in
      return <Navigate to="/signin" replace={true} />;
    } else {
      // Otherwise, render the SplashScreen
      return <Navigate to="/onboarding/splash" replace={true} />;
    }
  }

  return <></>;
}
