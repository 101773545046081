/**
 * Legal Page – currently just links out to zendesk.
 */
import { TabWrapper } from '@/app/wrappers/Containers';
import ExternalLinkPoster from '@/shared/components/ExternalLinkPoster';
import PageNavBar from '@/shared/components/PageNavBar';
import SupportPageDrawing from '@/shared/components/SupportDrawing';
import { Bold } from '@/shared/components/Text.styled';
import { SUPPORT_LEGAL_CENTER_PAGE } from '@/shared/helpers';
import { Button } from '@checkrx/pay-component-library';
import { useState } from 'react';
import { Headline, LegalText, LegalView } from './LegalPage.styled';

export default function LegalPage() {
  const [shouldSendExternalLink, setShouldSendExternalLink] = useState(false);

  return (
    <TabWrapper>
      <PageNavBar title="Legal Center" />
      <LegalView>
        <SupportPageDrawing className="legal-support-page" />
        <Headline>Checkr Pay Legal Center</Headline>
        <LegalText>
          To view the legal agreements you accepted when signing up for Checkr Pay, please tap below
          to launch our legal center. You will be redirected to <Bold> support.checkrpay.com</Bold>.
        </LegalText>
        {shouldSendExternalLink && (
          <ExternalLinkPoster
            onSent={() => setShouldSendExternalLink(false)}
            link={SUPPORT_LEGAL_CENTER_PAGE}
            prependText="Legal Agreements"
          />
        )}
        <Button
          text="Review Legal Agreements"
          width="100%"
          colorVariant="accent"
          sizeVariant="big"
          onClick={() => {
            setShouldSendExternalLink(true);
          }}
        />
      </LegalView>
    </TabWrapper>
  );
}
