/**
 * Higher-Order component that requires Verification of WorkerProfile to continue.
 *
 * Navigates to 2fA flow if necessary (/verification). Otherwise renders its children
 *
 * This component also handles a few app-level functionalities that have to happen
 * after a worker is logged in and verified:
 * 1. It sets up app analytics with the verified user data
 * 2. It fetches and renders any in-app messages we have for the user.
 */
import { ReactNode, useEffect } from 'react';

import { useInAppMessages } from '@/services/useInAppMessages';
import { useWorkerProfile } from '@/services/useWorkerProfile';
import { MessageDisplay } from '@/shared/components/InAppMessages/MessageDisplay';
import useLoading from '@/shared/hooks/useLoading';
import { LoadingScope } from '@/shared/types';
import { Navigate } from 'react-router-dom';

export const WorkerVerificationGuard = ({ children }: { children: ReactNode }) => {
  const { data: workerProfile, isLoading, isSuccess } = useWorkerProfile();
  const { data: inAppMessage } = useInAppMessages();
  const setLoading = useLoading(isLoading, LoadingScope.global);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  if (isSuccess) {
    if (!workerProfile?.verified) {
      return <Navigate to="/" />;
    }
  }
  return (
    <>
      {inAppMessage && (
        <MessageDisplay
          messageData={inAppMessage.messageData}
          messageId={inAppMessage._id}
          messageType={inAppMessage.messageType}
          expiresAt={inAppMessage.expiresAt}
        />
      )}
      {children}
    </>
  );
};
