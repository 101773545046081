import { useMutation, useQueryClient } from '@tanstack/react-query';
import serverApi from './serverApi';

type WorkerWithDrawalReqBody = {
  amount: number;
  bankId: string;
  sameDay: boolean;
};

const postWorkerWithdrawal = async ({ amount, bankId, sameDay }: WorkerWithDrawalReqBody) => {
  const amountInCents = Math.round(amount * 100);
  const res = await serverApi.post('/banking/withdraw', {
    counterpartyExternalAccountId: bankId,
    amount: amountInCents,
    description: 'CheckrPay',
    sameDay,
  });
  return res.data;
};

export const useCreateWithdrawal = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ amount, bankId, sameDay }: WorkerWithDrawalReqBody) =>
      postWorkerWithdrawal({ amount, bankId, sameDay }),
    onSuccess: () => {
      return queryClient.removeQueries({ queryKey: ['banking', 'account'] });
    },
    onError: () => {
      return queryClient.removeQueries({ queryKey: ['banking', 'account'] });
    },
    useErrorBoundary: true,
  });
};
