/**
 * PII Update Page – currently just links out to zendesk.
 * Eventually, it'll have Self-service
 */
import { TabWrapper } from '@/app/wrappers/Containers';
import {
  DataContent,
  DataLabel,
  Group,
  PiiRow,
} from '@/pages/onboarding/shared/confirm-pages.styled';
import { useWorkerProfile } from '@/services/useWorkerProfile';
import { useRequestWorkerPersonalInfoUpdate } from '@/services/useWorkerSupport';
import { trackAmplitudeEvent } from '@/shared/analytics';
import DividedCard from '@/shared/components/DividedCard';
import PageNavBar from '@/shared/components/PageNavBar';
import { Bold, ErrorText, NormalText, SmallText, TitleText } from '@/shared/components/Text.styled';
import { Button, Dropdown, Modal, Spinner, TextInput } from '@checkrx/pay-component-library';
import { useState } from 'react';
import {
  BankAccountAndPersonalInformationView,
  ModalInputField,
  SectionHeader,
  UpdateInfoInstructionWrapper,
  UpdateInfoRequestCompleteMgsWrapper,
  UpdateInfoRequestDropDownWrapper,
} from './SupportPage.styled';

export default function PersonalInfoPage() {
  const { data: workerProfile, isLoading: _isLoading } = useWorkerProfile();
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateKey, setUpdateKey] = useState('firstName');
  const [updateValue, setUpdateValue] = useState('');

  const {
    mutate: requestWorkerPersonalInfoUpdate,
    isLoading: requestingUpdate,
    isSuccess: requestedUpdateSuccess,
    isError: requestedUpdateError,
  } = useRequestWorkerPersonalInfoUpdate();

  const {
    contactEmail,
    contactPhone,
    contactAddress,
    mailingAddress,
    checkrCorePII,
    preferredName,
  } = workerProfile?.profile || {};
  const { firstName, middleName, lastName, dateOfBirth, taxpayerIdentificationNumber } =
    checkrCorePII || {};
  const { street, street2, city, state, postalCode } = contactAddress || {};
  const {
    street: mailingStreet,
    street2: mailingStreet2,
    city: mailingCity,
    state: mailingState,
    postalCode: mailingPostalCode,
  } = mailingAddress || {};

  const handleOnUpdateSubmitButton = () => {
    trackAmplitudeEvent('Earner Requested Personal Info Update');
    setShowUpdateModal(false);
    setUpdateValue('');
    requestWorkerPersonalInfoUpdate({ updateKey, updateValue });
  };

  const renderPageContent = () => {
    if (requestingUpdate) {
      return (
        <UpdateInfoRequestCompleteMgsWrapper>
          <Spinner />
        </UpdateInfoRequestCompleteMgsWrapper>
      );
    }

    if (requestedUpdateSuccess) {
      return (
        <UpdateInfoRequestCompleteMgsWrapper>
          <NormalText>
            <Bold>Update request submitted for review! Changes will apply shortly.</Bold>
          </NormalText>
        </UpdateInfoRequestCompleteMgsWrapper>
      );
    }

    if (requestedUpdateError) {
      return (
        <UpdateInfoRequestCompleteMgsWrapper>
          <ErrorText>
            <Bold>Failed to submit update request. Please try again.</Bold>
          </ErrorText>
        </UpdateInfoRequestCompleteMgsWrapper>
      );
    }

    return (
      <BankAccountAndPersonalInformationView>
        <DividedCard
          elements={[
            <Group key="profile-contact-info">
              <SectionHeader>
                <TitleText> Personal Information </TitleText>
              </SectionHeader>
              <PiiRow>
                <DataLabel>Full Legal Name:</DataLabel>
                <DataContent>
                  {firstName} {middleName} {lastName}
                </DataContent>
              </PiiRow>
              {preferredName ? (
                <PiiRow>
                  <DataLabel>Preferred Name:</DataLabel>
                  <DataContent>{preferredName}</DataContent>
                </PiiRow>
              ) : null}
              <PiiRow>
                <DataLabel>Date Of Birth:</DataLabel>
                <DataContent>{dateOfBirth}</DataContent>
              </PiiRow>
              <PiiRow>
                <DataLabel>SSN (last 4):</DataLabel>
                <DataContent>{taxpayerIdentificationNumber}</DataContent>
              </PiiRow>
              <PiiRow>
                <DataLabel>Email:</DataLabel>
                <DataContent>{contactEmail}</DataContent>
              </PiiRow>
              <PiiRow>
                <DataLabel>Phone:</DataLabel>
                <DataContent>{contactPhone}</DataContent>
              </PiiRow>
            </Group>,
            <Group key="profile-address-info">
              <SectionHeader>
                <TitleText> Account Address </TitleText>
              </SectionHeader>
              <PiiRow>
                <DataLabel>Address:</DataLabel>
                <DataContent>
                  {street} {street2 || ''}
                </DataContent>
              </PiiRow>
              <PiiRow>
                <DataLabel>City:</DataLabel>
                <DataContent>{city}</DataContent>
              </PiiRow>
              <PiiRow>
                <DataLabel>State:</DataLabel>
                <DataContent>{state}</DataContent>
              </PiiRow>
              <PiiRow>
                <DataLabel>ZIP:</DataLabel>
                <DataContent>{postalCode}</DataContent>
              </PiiRow>
            </Group>,
            <Group key="profile-mailing-address-info">
              <SectionHeader>
                <TitleText> Mailing Address </TitleText>
              </SectionHeader>
              {mailingAddress ? (
                <>
                  <PiiRow>
                    <DataLabel>Address:</DataLabel>
                    <DataContent>
                      {mailingStreet} {mailingStreet2 || ''}
                    </DataContent>
                  </PiiRow>
                  <PiiRow>
                    <DataLabel>City:</DataLabel>
                    <DataContent>{mailingCity}</DataContent>
                  </PiiRow>
                  <PiiRow>
                    <DataLabel>State:</DataLabel>
                    <DataContent>{mailingState}</DataContent>
                  </PiiRow>
                  <PiiRow>
                    <DataLabel>ZIP:</DataLabel>
                    <DataContent>{mailingPostalCode}</DataContent>
                  </PiiRow>
                </>
              ) : (
                <SectionHeader>
                  <NormalText>
                    <Bold>Same as account address </Bold>
                  </NormalText>
                </SectionHeader>
              )}
            </Group>,
          ]}
        />
      </BankAccountAndPersonalInformationView>
    );
  };

  return (
    <TabWrapper>
      <PageNavBar title="Your Information" />
      {renderPageContent()}
      <Button
        text="Request Profile Update"
        width="100%"
        colorVariant="accent"
        sizeVariant="big"
        onClick={() => {
          setShowUpdateModal(true);
        }}
        disabled={requestedUpdateSuccess || requestingUpdate}
      />
      <Modal
        isOpen={showUpdateModal}
        close={() => {
          setShowUpdateModal(false);
        }}
        headerText="Update your information"
        width="85%"
        height="60%"
        modalContent={
          <TabWrapper>
            {/*{renderToast()}*/}
            <BankAccountAndPersonalInformationView>
              <UpdateInfoInstructionWrapper>
                <NormalText>
                  Select the personal information you would like to update and provide the new
                  values
                </NormalText>
              </UpdateInfoInstructionWrapper>
              <UpdateInfoRequestDropDownWrapper className="update-info-dropdown">
                <Dropdown
                  options={[
                    {
                      value: 'firstName',
                      label: 'First Name',
                    },
                    {
                      value: 'lastName',
                      label: 'Last Name',
                    },
                    {
                      value: 'phoneEmailSsn',
                      label: 'Phone / Email / SSN',
                    },
                    {
                      value: 'accountAddress',
                      label: 'Account Address',
                    },
                    {
                      value: 'shippingAddress',
                      label: 'Shipping Address',
                    },
                  ]}
                  onSelect={(option) => setUpdateKey(option.value)}
                />
              </UpdateInfoRequestDropDownWrapper>
            </BankAccountAndPersonalInformationView>

            <SmallText>
              If entering an address, please enter the full address including street, city, state,
              and zip code.
            </SmallText>
            <ModalInputField>
              <TextInput
                value={updateValue}
                type="text"
                // centered
                placeholder="Enter your new value"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setUpdateValue(e.target.value)
                }
              />
            </ModalInputField>
            <Button
              text="Submit"
              width="50%"
              colorVariant="accent"
              sizeVariant="small"
              onClick={() => {
                handleOnUpdateSubmitButton();
              }}
              disabled={updateValue.length < 2}
            />
          </TabWrapper>
        }
      />
    </TabWrapper>
  );
}
