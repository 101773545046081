import { DefaultTheme } from 'styled-components';
import { DepositProduct } from './banking.types';
import { FeatureFlagMap } from './feature-flags.types';
import { MfaMethods } from './types';

export const DEFAULT_INSTANT_DIRECT_DEPOSIT_FEE_RATE = 0.022;
export interface Customer {
  _id: string;
  orgIdentifier: string;
  humanReadableName?: string;
  // Optionally returned, depending on permissions
  maximumFloat?: number;
  floatedBalance?: number;
  preferences?: DefaultTheme & {
    accountActivationLink: {
      web: string;
      android?: string;
      ios?: string;
    };
    achWithdrawalLimits?: {
      BASIC: {
        daily: number;
        monthly: number;
      };
      PLUS?: {
        daily: number;
        monthly: number;
      };
    };
    depositProduct: DepositProduct;
    mfaMethod?: MfaMethods;
  };
  featureFlags?: FeatureFlagMap;
  payoutMethods?: {
    achDirectDeposit?: {
      canBeEnabledForSpecificWorkers?: false;
      enabled?: true;
      sameDayAchFees?: 0;
      usesSameDayAch?: false;
    };
    checkrPayBankAccount?: {
      enabled?: boolean;
    };
    pushToCardDirectDeposit?: {
      enabled?: boolean;
      customFeesRate?: number;
    };
  };
}
