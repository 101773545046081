/**
 * A Page-level nav bar. Default Behavior:
 *   - If you pass it a logo, it'll render the logo in the center
 *   - Otherwise it'll render whatever `title` you pass in.
 *
 *   - You can decide if it shows a back arrow, and where it goes.
 *   - You can decide if it shows the `help` icon.
 *
 * TODO(Carter):
 * - Add popover tooltip text option to add help text on hovering
 *   over the Help icon.
 */
import { To, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as BackIcon } from '@/shared/assets/left-arrow.svg';
import BrandLogo from './BrandLogo';
import { CenterContainer, SideContainer, StyledNavBar } from './NavBar.styled';
import { TitleText } from './Text.styled';

const StyledBrandLogo = styled(BrandLogo)`
  max-width: 180px;
  max-height: 30px;
  object-fit: contain;
`;

// TODO(carter): consolidate with other Nav Bar styles
const IconWrapper = styled.span`
  /* Hover variant */

  &:hover {
    filter: brightness(50%);
    transition: 0.1s;
  }
`;

const StickyNav = styled(StyledNavBar)`
  position: sticky;
  top: 0;
  background: ${(p) => p.theme.colors.backgrounds.app};
  z-index: 1;
`;

export default function PageNavBar({
  navigateBackTo,
  title,
  showBack = true,
}: {
  navigateBackTo?: string;
  title?: string;
  showBack?: boolean;
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  let showHelp = true;

  if (['/verification'].includes(pathname)) {
    // We don't want the top bar rendering on the base verification page
    return <></>;
  }
  if (['/transactions/withdrawals', '/transactions'].includes(pathname)) {
    showHelp = false;
  }

  return (
    <StickyNav>
      <SideContainer>
        {showBack ? (
          <IconWrapper>
            <BackIcon onClick={() => navigate((navigateBackTo ? `${navigateBackTo}` : -1) as To)} />
          </IconWrapper>
        ) : (
          <div />
        )}
      </SideContainer>
      <CenterContainer>
        {title ? <TitleText>{title}</TitleText> : <StyledBrandLogo />}
      </CenterContainer>
      {/* TODO(Carter): The Help Button */}
      <SideContainer>{showHelp ? <div /> : <div />}</SideContainer>
    </StickyNav>
  );
}

export function LogoOnlyNavBar() {
  return (
    <StickyNav>
      <CenterContainer>
        <StyledBrandLogo />
      </CenterContainer>
    </StickyNav>
  );
}
