/**
 * Data fetching for external accounts
 */
import { ExternalAccount } from '@/shared/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import serverApi from './serverApi';

const REFETCH_AFTER_SECONDS = 120;

// Fetch External Accounts for a worker
async function fetchExternalAccounts() {
  const response = await serverApi.get('/banking/linked_accounts');
  return response.data?.data;
}

type ExternalAccountReqBody = {
  accountNumber?: string;
  routingNumber?: string;
  plaidProcessorToken?: string;
};

export const useExternalAccounts = (onSuccess?: (d: ExternalAccount[]) => void) => {
  return useQuery({
    queryKey: ['externalAccounts'],
    queryFn: () => fetchExternalAccounts(),
    refetchInterval: 1000 * REFETCH_AFTER_SECONDS,
    refetchIntervalInBackground: true, // Refetch in background too
    select: (data: Array<{ externalAccount: ExternalAccount }>) => {
      return data.map((d) => d.externalAccount);
    },
    onSuccess,
  });
};

// Manually add External Bank Account for a worker
export async function postExternalAccount({
  accountNumber,
  routingNumber,
  plaidProcessorToken,
}: ExternalAccountReqBody) {
  const response = await serverApi.post('/banking/link_account', {
    accountNumber,
    routingNumber,
    plaidProcessorToken,
  });
  return response.data;
}

export const useAddExternalAccount = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ accountNumber, routingNumber, plaidProcessorToken }: ExternalAccountReqBody) =>
      postExternalAccount({
        accountNumber,
        routingNumber,
        plaidProcessorToken,
      }),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['externalAccounts'] });
    },
    onError: () => {
      return queryClient.invalidateQueries({ queryKey: ['externalAccounts'] });
    },
    // TODO(Carter): Should we manually handle _all_ errors or just some of them?
    useErrorBoundary: false,
  });
};
