import { useWorkerProfileIsVerified } from '@/services/useWorkerProfile';
import { ReactComponent as LockedMoneyIcon } from '@/shared/assets/locked-money.svg';
import { formatCentsAsCurrency } from '@/shared/helpers';
import { useSlideUpDrawer } from '@/shared/hooks/useSlideUpDrawer';
import { Payout, PayoutFailureReasons, PayoutStatus } from '@/shared/types';
import { useNavigate } from 'react-router-dom';
import { StyledLink } from '../Text.styled';
import PayoutList from './PayoutList';
import { NoticeButton, TitleContainer } from './PayoutNotices.styled';

type Props = {
  payouts: Array<Payout>;
};

export default function InvalidPushToCardDirectDepositPayoutDestinationNotice({ payouts }: Props) {
  const { SlideUpDrawer, openDrawer } = useSlideUpDrawer();
  const navigate = useNavigate();
  const { data: isWorkerProfileVerified, isLoading: isWorkerProfileLoading } =
    useWorkerProfileIsVerified();
  const failedPayouts = payouts.filter(
    (payout) =>
      payout?.status === PayoutStatus.Failed &&
      payout?.failureReasons?.includes(
        PayoutFailureReasons.InvalidPushToCardDirectDepositPayoutDestination
      )
  );

  if (failedPayouts.length === 0 || isWorkerProfileLoading) {
    return null;
  }

  const totalAmount = failedPayouts.reduce((sum, payout) => sum + payout.amountCents, 0);
  const subTitle = (
    <>
      The following payouts failed because the debit card used for Instant Direct Deposits did not
      work. Please{' '}
      {isWorkerProfileVerified ? (
        <StyledLink onClick={() => navigate('/manage-payout-methods')}>
          update the debit card
        </StyledLink>
      ) : (
        <>sign in and update the debit card</>
      )}{' '}
      or use a different payout method.
    </>
  );

  return (
    <>
      <NoticeButton type="button" onClick={openDrawer} error>
        <TitleContainer>
          <LockedMoneyIcon />
          <div>Payouts Failed - Update debit card</div>
        </TitleContainer>
        <div>{formatCentsAsCurrency(totalAmount)}</div>
      </NoticeButton>
      <SlideUpDrawer>
        <PayoutList payouts={failedPayouts} title="Failed Payouts" subTitle={subTitle} />
      </SlideUpDrawer>
    </>
  );
}
