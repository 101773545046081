import { RewardTier } from '@/shared/types';
import styled from 'styled-components';
import { colors, fontSizes, fontWeights } from '../styles';

export const CashbackAmount = styled.div`
  color: ${(p) => p.theme.colors.text.primary};
  font-weight: ${fontWeights.bold};
`;

export const CashbackContainer = styled.div`
  border: 1px solid ${colors.primaryGreyLight};
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 15px;
`;

export const CashbackHeadingContainer = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;

export const CashbackTierBadge = styled.div<{ rewardTier: RewardTier }>`
  background: ${(p) =>
    p.rewardTier === RewardTier.Premium ? colors.accentGold : p.theme.colors.graphics.primary};
  border-radius: 5px;
  padding: 7px 10px;
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.smallMedium};
  color: white;
`;

export const DollarPart = styled.span`
  font-size: ${fontSizes.giant};
`;

export const CenterSpinner = styled.div`
  align-items: center;
  border: 1px solid ${colors.primaryGreyLight};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  min-height: 12rem;
  padding-bottom: 3rem; /* offset spinner negative margin */
`;

export const CashbackInfoPanel = styled.div`
  background: ${colors.primaryWhite};
  font-size: ${fontSizes.medium};
  margin-top: 10px;
  padding: 10px 15px;
`;

export const CashbackProgressPanel = styled.div`
  font-size: ${fontSizes.medium};
  margin-top: 10px;

  strong {
    color: ${colors.accentGold};
    font-weight: ${fontWeights.extraBold};
  }
`;

export const ProgressBarMarker = styled.div`
  display: flex;
  font-size: ${fontSizes.smallMedium};
  font-weight: ${fontWeights.bold};
  margin-bottom: -10px;
  justify-content: flex-end;
`;

export const CashbackTierCompletionProgressBar = styled.div<{
  rewardTier: RewardTier;
}>`
  background: ${colors.primaryGreyLight};
  border-radius: 5px;
  height: 1rem;
  margin-top: 10px;
  overflow: hidden;
  div {
    background: ${(p) =>
      p.rewardTier === RewardTier.Premium ? colors.accentGold : p.theme.colors.graphics.primary};
    height: 1rem;
  }
`;
