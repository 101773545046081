import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@checkrx/pay-component-library';

import { useWithdrawalRequestAmount } from '@/services/useWithdrawalRequestAmount';
import { Text, Title } from '@/shared/components/Graphics.styled';
import SuccessGraphic from '@/shared/components/SuccessGraphic';
import { MiniText, NormalText } from '@/shared/components/Text.styled';
import { currencyFormatter } from '@/shared/helpers';
import { colors, fontWeights } from '@/shared/styles';

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 20px 0;
  gap: 20px;
  width: 100%;
`;

export const TextBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: start;
`;

export const WithdrawalContainer = styled.div`
  position: relative;
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: start;
  padding: 20px 15px;

  // Border styling
  border: 1px solid ${colors.primaryGreyLight};
  border-radius: 10px;
  background: ${(p) => p.theme.colors.backgrounds.app};
`;

export const BoldText = styled(NormalText)`
  font-weight: ${fontWeights.bold};
  color: ${(p) => p.theme.colors.text.primary};
  text-align: center;
`;

const BottomButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  gap: 20px;
`;

export const DisclaimerText = styled(MiniText)`
  color: ${(p) => p.theme.colors.text.secondary};
  width: 85%;
  text-align: center;
`;

export default function WithdrawalResultPage() {
  const navigate = useNavigate();
  const { withdrawalRequestAmount: amount, setWithdrawalRequestAmount } =
    useWithdrawalRequestAmount();

  const formattedWithdrawalAmount = amount
    ? currencyFormatter.format(parseFloat(amount || ''))
    : '';

  return (
    <ViewContainer>
      <SuccessGraphic className="withdrawal-success" />
      <TextBox>
        <Title>Your withdrawal is on the way!</Title>
        <Text>You successfully withdrew:</Text>
        <BoldText>{formattedWithdrawalAmount}</BoldText>
      </TextBox>

      <BottomButtonContainer>
        <DisclaimerText>
          You authorized Checkr Pay to initiate an electronic withdrawal to your linked bank
          account. Transfer speeds vary and could take up to 3 business days to complete. Transfers
          may be subject to review for the protection of your account.
        </DisclaimerText>
        <Button
          width="100%"
          text="Go to my dashboard"
          iconRight
          icon="arrow-right"
          colorVariant="brand"
          sizeVariant="big"
          onClick={() => {
            // Reset the stored withdrawal amount
            if (setWithdrawalRequestAmount) {
              setWithdrawalRequestAmount(null);
            }
            navigate('/dashboard/withdraw');
          }}
        />
      </BottomButtonContainer>
    </ViewContainer>
  );
}
