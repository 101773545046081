import { useAddPayoutMethod } from '@/services/usePayoutMethod';
import { useWorkerProfile } from '@/services/useWorkerProfile';
import { ReactComponent as CheckImage } from '@/shared/assets/check-image.svg';
import { BankAccountForm } from '@/shared/components/BankAccountForm';
import { PayoutMethod } from '@/shared/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { LinkingPageContainer, Title, TitleContainer } from './../link-bank/LinkBank.styled';
import { SetupCompletedNoticeContainer } from './LinkDirectDepositBankManuallyPage.styled';

export default function LinkDirectDepositBankManuallyPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { data: workerProfile } = useWorkerProfile();
  const { mutateAsync: addExternalAccount } = useAddPayoutMethod();

  const directDepositBankSetupAlreadyComplete =
    workerProfile?.profile?.payoutMethod?.selected === PayoutMethod.ACHDirectDeposit;

  return (
    <LinkingPageContainer>
      <TitleContainer>
        {directDepositBankSetupAlreadyComplete ? (
          <SetupCompletedNoticeContainer>
            <Title>Update Bank Details</Title>
            <p>
              You have already completed direct deposit setup. Use this page to update the bank
              details.
            </p>
          </SetupCompletedNoticeContainer>
        ) : (
          <Title>Setup Bank Details</Title>
        )}
      </TitleContainer>
      <CheckImage />

      <BankAccountForm
        submitButtonText={
          directDepositBankSetupAlreadyComplete ? 'Update Bank Details' : 'Enable Direct Deposit'
        }
        onSubmit={async ({ accountNumber, routingNumber }) => {
          await addExternalAccount({
            routingNumber,
            accountNumber,
            payoutMethod: state?.payoutMethod ?? PayoutMethod.ACHDirectDeposit,
          });
          navigate('/');
        }}
      />
    </LinkingPageContainer>
  );
}
