import {
  ButtonContainer,
  PageContainer,
  StyledSuccessGraphic,
  SuccessTextBox,
  Text,
  Title,
} from '@/shared/components/Graphics.styled';
import { Button } from '@checkrx/pay-component-library';
import { useLocation, useNavigate } from 'react-router-dom';

export default function ResetRequestSuccessPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { successTitle, successText } = location.state;

  return (
    <PageContainer>
      <StyledSuccessGraphic className="reset-success-graphic" />
      <SuccessTextBox>
        <Title>{successTitle || 'Success!'}</Title>
        <Text>{successText}</Text>
      </SuccessTextBox>
      <ButtonContainer>
        <Button
          className="go-to-dashboard-btn"
          width="100%"
          sizeVariant="big"
          colorVariant="brand"
          text="Continue to account activation"
          iconRight
          icon="arrow-right"
          onClick={() => navigate('/')}
        />
      </ButtonContainer>
    </PageContainer>
  );
}
