/**
 * TODOs:
 *   - Add Idempotency keys everywhere for post requests
 */
import * as Sentry from '@sentry/react';
import axios, { AxiosError, AxiosResponse } from 'axios';

const BASE_URL =
  process.env.REACT_APP_CHECKR_PAY_WORKER_API_URL ?? 'http://localhost:5050/worker/v0/';

const serverApi = axios.create({
  baseURL: BASE_URL,
});
serverApi.defaults.headers.common['Content-Type'] = `application/json`;

serverApi.interceptors.request.use((config) => {
  // Add token to request headers at time or request
  const tempAuthToken = localStorage
    ? localStorage['temp_auth_token']
    : sessionStorage['temp_auth_token'];
  // Type check to see if we have a temp auth token
  if (tempAuthToken) {
    config.headers['X-CHECKR-TEMPORARY-AUTH-TOKEN'] = `${JSON.parse(tempAuthToken)}`;
  }

  return config;
});

// Send errors to Sentry
serverApi.interceptors.response.use(
  (res) => res,
  (error: AxiosError) => {
    Sentry.withScope((scope) => {
      if (error.response) {
        scope.setContext('Server Api', {
          response: error.response.data,
        });
        Sentry.captureException(new ServerApiError(error.response), {
          level: error.response.status >= 500 ? 'error' : 'info',
          tags: {
            requestPath: error.response.config.url,
            requestMethod: error.response.config.method,
            responseStatus: error.response.status,
          },
          fingerprint: [
            error.response.config.url || '',
            error.response.config.method || '',
            String(error.response.status) || '',
          ],
        });
      } else {
        Sentry.captureException(error);
      }
    });

    return Promise.reject(error);
  }
);

export default serverApi;

class ServerApiError extends Error {
  constructor(response: AxiosResponse) {
    const message = `${response?.config.method?.toUpperCase()} ${
      response?.config.url
    } failed with status code ${response?.status}`;
    super(message);
    this.name = 'ServerApiError';
  }
}
