/**
 * Shared types for reuse across the Embedded Components
 */

export type DateString = string;

export enum MfaMethods {
  Sms = 'sms',
  Email = 'email',
}

export enum WorkerContactMethods {
  PhoneNumber = 'phone number',
  Email = 'email',
}

export enum DashboardPath {
  Home = '/dashboard',
  Card = '/dashboard/card',
  PayStubs = '/pay-stubs',
  HelpCenter = '/support',
  Withdraw = '/dashboard/withdraw',
  Settings = '/dashboard/settings',
}

// IDs of the various loading guards
// Having this types out forces the caller to specify the level at which
// they want to show a loading screen
export enum LoadingScope {
  global = 'global',
  authentication = 'authentication',
  dashboard = 'dashboard',
  card = 'card',
}

export type AstraCard = {
  cardCompany?: string;
  expirationDate?: string;
  firstName?: string;
  id: string;
  lastFourDigits: string;
  lastName?: string;
  status: AstraCardStatuses;
};

export enum AstraCardStatuses {
  Approved = 'approved',
  Flagged = 'flagged',
  InvalidCardType = 'invalid_card_type',
  Pending = 'pending',
  PushToCardNotSupported = 'push_to_card_not_supported',
  Rejected = 'rejected',
  Removed = 'removed',
}

export type WorkerType = 'business' | 'individual';
export type WorkerBusinessInformation = {
  businessName: string;
  formationDate: DateString;
  ein: string;
  entityType:
    | 'sole_proprietorship'
    | 'partnership'
    | 'corporation'
    | 's_corporation'
    | 'llc'
    | 'nonprofit'
    | 'cooperative';
};
