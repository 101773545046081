import { colors, fontSizes } from '@/shared/styles';
import styled from 'styled-components';

export const PaymentMethodPanel = styled.div`
  margin-top: 15px;
`;

export const PaymentMethodsContainer = styled.div`
  background-color: ${colors.primaryWhite};
  font-size: ${fontSizes.medium};
  padding: 12px 12px 0 12px;
  border-radius: 8px;
  overflow: auto;
`;

export const PaymentMethodsChoices = styled.div`
  margin-top: 8px;
`;

export const BadgeRow = styled.div`
  div {
    margin-left: 5px;
  }
`;

export const PaymentMethodsChoiceContainer = styled.div`
  background-color: white;
  padding: 10px 12px 15px;
  margin: 10px 0;
  border-radius: 10px;
  border: 1px solid ${colors.primaryGreyLight};

  ul {
    margin-bottom: 10px;
  }

  li {
    font-size: ${fontSizes.medium};
    display: flex;
    height: 30px;
    align-items: center;

    svg {
      display: inline-block;
      height: 20px;
      margin-right: 5px;
      width: 20px;
    }
  }
`;

export const ChoiceHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid ${colors.primaryGreyLight};
  display: flex;
  justify-content: space-between;
  margin: 7px 0;
  padding-bottom: 10px;
  svg {
    height: 20px;
    margin-right: 10px;
    width: 20px;
  }

  div {
    display: flex;
    align-items: center;
  }
`;

export const PayoutMethodConfirmationButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;
