import Joi from 'joi';
import {
  dobValidation,
  emailValidation,
  fullNameValidation,
  ssnValidation,
} from '../shared/validations';

const taxpayerIdentificationNumberValidation = Joi.string().min(4).required();

export const personalDetailsWithSsnValidation = Joi.object({
  fullName: fullNameValidation,
  email: emailValidation,
  dob: dobValidation,
  ssn: ssnValidation,
});

export const personalDetailsWithoutSsnValidation = Joi.object({
  fullName: fullNameValidation,
  email: emailValidation,
  dob: dobValidation,
  taxpayerIdentificationNumber: taxpayerIdentificationNumberValidation,
});

export const personalDetailsValidationSchema = Joi.alternatives().try(
  personalDetailsWithSsnValidation,
  personalDetailsWithoutSsnValidation
);

export const addressValidationSchema = Joi.object({
  street: Joi.string().required().messages({ '*': 'Please enter a valid street' }),
  street2: Joi.string()
    .optional()
    .messages({ '*': 'Please enter a valid secondary street' })
    .allow(null, ''),
  city: Joi.string().required().messages({ '*': 'Please enter a valid city' }),
  state: Joi.string()
    .length(2)
    .uppercase()
    .required()
    .valid(
      'AK',
      'AL',
      'AR',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DE',
      'FL',
      'GA',
      'HI',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'KY',
      'LA',
      'MA',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OK',
      'OR',
      'PA',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
      'DC',
      'AS', //	American Samoa
      'GU', //	Guam
      'MP', //	Northern Mariana Islands
      'PR', //	Puerto Rico
      'VI', //	U.S. Virgin IslandsAS	American Samoa
      'GU', //	Guam
      'MP', //	Northern Mariana Islands
      'PR', //	Puerto Rico
      'VI', //	U.S. Virgin Islands
    )
    .messages({ '*': 'Please enter a valid two-letter US state code' }),
  postalCode: Joi.string()
    .min(5)
    .max(5)
    .required()
    .messages({ '*': 'Please enter a valid postal code' }),
  country: Joi.equal('US'),
});
