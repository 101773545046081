import { DropdownInput } from '@checkrx/pay-component-library';
import { FC, useEffect, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { DataLabel } from '../shared/confirm-pages.styled';
import { Address } from './AddressForm';
import { addressValidationSchema } from './validations';

type Props = {
  onSelect: (v: Address) => void;
  label: string;
  placeholder: string;
  value: Address | null;
};

const STREET_NUMBER = 'street_number';
const ROUTE = 'route';
const SUBPREMISE = 'subpremise';
const LOCALITY = 'locality';
const ADMIN_LEVEL_ONE = 'administrative_area_level_1';
const POSTAL_CODE = 'postal_code';

type AddressComponentType =
  | typeof STREET_NUMBER
  | typeof ROUTE
  | typeof SUBPREMISE
  | typeof LOCALITY
  | typeof ADMIN_LEVEL_ONE
  | typeof POSTAL_CODE;

type AddressComponent = {
  long_name: string;
  short_name: string;
  types: AddressComponentType[];
};

type PlaceDetails = {
  address_components: AddressComponent[];
};

export const AutocompleteAddress: FC<Props> = ({ onSelect, label, placeholder, value }) => {
  const [touched, setTouched] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [selectedPlaceId, setSelectedPlaceId] = useState<string>('');
  const [search, setSearch] = useState('');
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
      debounce: 400,
      options: {
        componentRestrictions: { country: ['us'] },
        fields: ['address_components', 'geometry'],
        types: ['address'],
      },
    });

  const valid = !addressValidationSchema.validate(value)?.error;

  const getDefault = () => {
    if (value?.street === '') return '';
    const street2 = value?.street2 && value?.street2 !== '' ? ' ' + value?.street2 + ' ' : ' ';
    return `${value?.street}${street2}${value?.city ?? ''} ${value?.state ?? ''}`.trim();
  };

  // get initial prediction values
  useEffect(() => {
    getPlacePredictions({
      input: getDefault(),
    });
  }, []);

  // set to see if we have started getting values
  useEffect(() => {
    if (!hasLoaded && isPlacePredictionsLoading) {
      setHasLoaded(true);
    }
  }, [hasLoaded, isPlacePredictionsLoading]);

  // once we have gotten initial prediction values, decide if it was bad
  // or good and update accordingly
  useEffect(() => {
    if (
      hasLoaded &&
      placePredictions.length === 0 &&
      !isPlacePredictionsLoading &&
      value?.street !== ''
    ) {
      handleSelect('');
    }
    if (
      hasLoaded &&
      placePredictions.length > 0 &&
      !isPlacePredictionsLoading &&
      value?.street !== '' &&
      selectedPlaceId === ''
    ) {
      handleSelect(placePredictions[0].place_id);
    }
  }, [hasLoaded, placePredictions, isPlacePredictionsLoading, value, selectedPlaceId]);

  const formattedPlacesOptions = placePredictions.map((p) => ({
    label: p.description,
    value: p.place_id,
  }));

  const buildAddressFromComponents = (ac: AddressComponent[]): Address => {
    let street = '';
    let street2 = '';
    let city = '';
    let state = '';
    let postalCode = '';

    ac.forEach((c, idx) => {
      if (c.types.includes(STREET_NUMBER)) {
        street = `${c.long_name} ${ac[idx + 1]?.long_name}`;
        return;
      }
      if (c.types.includes(SUBPREMISE)) {
        street2 = c.long_name;
        return;
      }
      if (c.types.includes(LOCALITY)) {
        city = c.long_name;
      }
      if (c.types.includes(ADMIN_LEVEL_ONE)) {
        state = c.short_name;
      }
      if (c.types.includes(POSTAL_CODE)) {
        postalCode = c.short_name;
      }
    });

    return {
      street,
      street2,
      city,
      state,
      postalCode,
    };
  };

  const handleSearch = (search: string) => {
    setSearch(search);
    getPlacePredictions({ input: search });
    if (search === '') {
      handleSelect('');
    }
  };

  const handleSelect = (placeId: string) => {
    if (placeId === '') {
      onSelect({
        street: '',
        street2: '',
        city: '',
        state: '',
        postalCode: '',
      });
      getPlacePredictions({
        input: '',
      });
      return;
    }
    setSelectedPlaceId(placeId);
    placesService?.getDetails({ placeId }, (placeDetails: PlaceDetails) => {
      const addressObj = buildAddressFromComponents(placeDetails.address_components);
      onSelect(addressObj);
    });
  };

  const getValue = () => {
    if (selectedPlaceId !== '') return selectedPlaceId;
    if (placePredictions.length > 0 && search === '') return placePredictions[0].place_id;
    return '';
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
      <DataLabel>{label}</DataLabel>
      <span>
        <DropdownInput
          onChange={handleSearch}
          onSelect={(v) => handleSelect(v)}
          closeOnChange
          value={getValue()}
          options={formattedPlacesOptions}
          height="30px"
          placeholder={placeholder}
          onBlur={() => setTouched(true)}
          invalid={touched && !valid}
          disableInternalSearch
        />
      </span>
    </div>
  );
};
