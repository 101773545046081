/**
 * Styled Text Components
 */
import styled from 'styled-components';

import { colors, fontSizes, fontWeights } from '@/shared/styles';

export const SubTitleText = styled.h2`
  font-weight: ${fontWeights.semiBold};
  font-size: ${fontSizes.semiBig};
  color: ${(p) => p.theme.colors.text.primary};
`;

export const TitleText = styled.h2`
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.big};
  color: ${(p) => p.theme.colors.text.primary};
`;

export const SmallText = styled.p`
  font-weight: ${fontWeights.light};
  font-size: ${fontSizes.small};
  color: ${(p) => p.theme.colors.text.primary};
`;

export const SmallMediumText = styled.p`
  font-weight: ${fontWeights.light};
  font-size: ${fontSizes.smallMedium};
  color: ${(p) => p.theme.colors.text.primary};
`;

export const MiniText = styled.p`
  font-weight: ${fontWeights.light};
  font-size: ${fontSizes.mini};
`;

export const NormalText = styled.p`
  font-weight: ${fontWeights.light};
  font-size: ${fontSizes.medium};
  color: ${(p) => p.theme.colors.text.secondary};
`;

export const MediumWarningText = styled(NormalText)`
  color: ${colors.accentOrange};
`;

export const Bold = styled.span`
  font-weight: ${fontWeights.bold};
`;

// New Texts
export const BaseText = styled.p`
  font-weight: ${fontWeights.light};
  font-size: ${fontSizes.medium};
`;

export const BaseTitle = styled.p`
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.big};
`;

export const ErrorText = styled.span`
  color: ${colors.accentRed};
`;

export const WarningText = styled.span`
  color: ${colors.accentOrange};
  font-size: ${fontSizes.small};
`;

export const ExternalLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export const TooltipTriggerText = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export const TooltipContent = styled.span`
  white-space: break-spaces;
`;

export const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
`;
