/**
 * A Progress Bar Component with a discrete number of steps
 *
 * If you're looking for a Continuous progress bar, use
 * a FillBar component instead.
 *
 * TODOs(Carter):
 * -  move this to Reusable components
 */
import { colors } from '@/shared/styles';
import { OnOffBall } from '@checkrx/pay-component-library';
import styled from 'styled-components';

// Could with width or height, but this is the cross-axis dimension
const thickness = '30px';
const ProgBarContainer = styled.div<{ vertical: boolean; length: string }>`
  /* Size */
  width: ${(p) => (p.vertical ? thickness : p.length)};
  height: ${(p) => (p.vertical ? p.length : thickness)};

  /* Flex container */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${(p) => (p.vertical ? 'column' : 'row')};

  position: relative;
`;

const Outline = styled.div`
  height: ${thickness};
  width: ${thickness};
  background: ${colors.primaryGreyLight};
  border-radius: 50%;

  // Center On/Off ball in the outline
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const DotConnector = styled.div<{ vertical: boolean; length: string }>`
  // 1px line
  width: ${(p) => (p.vertical ? '1.5px' : p.length)};
  height: ${(p) => (p.vertical ? p.length : '1.5px')};
  z-index: 0;

  // In the middle of the cross-axis
  position: absolute;
  top: ${(p) => (p.vertical ? '0px' : '50%')};
  left: ${(p) => (p.vertical ? '50%' : '0px')};

  background: ${colors.primaryGreyLight};
`;
export default function DiscreteProgBar({
  vertical = false,
  length = '100%',
  nodes,
}: {
  vertical?: boolean;
  length?: string;
  nodes: Array<boolean>;
}) {
  // Parse values into user-friendly strings
  return (
    <ProgBarContainer vertical={vertical} length={length}>
      <DotConnector vertical={vertical} length={length} />
      {nodes
        .map((isOn, idx) => ({ isOn, idx }))
        .map(({ isOn, idx }) => (
          <Outline key={`onOff-${idx}`}>
            <OnOffBall size="18px" variant={isOn ? 'on' : 'disabled'} />
          </Outline>
        ))}
    </ProgBarContainer>
  );
}
