import { ReactComponent as LockedMoneyIcon } from '@/shared/assets/locked-money.svg';
import { formatCentsAsCurrency } from '@/shared/helpers';
import { useSlideUpDrawer } from '@/shared/hooks/useSlideUpDrawer';
import { Payout, PayoutQueuingReasons, PayoutStatus } from '@/shared/types';
import PayoutList from './PayoutList';
import { NoticeButton, TitleContainer } from './PayoutNotices.styled';

type Props = {
  payouts: Array<Payout>;
};

export default function WaitingOnWorkerAccountActivationNotice({ payouts }: Props) {
  const { SlideUpDrawer, openDrawer } = useSlideUpDrawer();

  const queuedPayouts = payouts.filter(
    (payout) =>
      payout?.status === PayoutStatus.Queued &&
      payout?.queuingReason === PayoutQueuingReasons.WaitingOnWorkerAccountActivation
  );

  if (queuedPayouts.length === 0) {
    return null;
  }

  const totalAmount = queuedPayouts.reduce((sum, payout) => sum + payout.amountCents, 0);
  const subTitle = (
    <>
      The following payouts will be accessible as soon as you finish activating your Checkr Pay
      account.
    </>
  );

  return (
    <>
      <NoticeButton type="button" onClick={openDrawer}>
        <TitleContainer>
          <LockedMoneyIcon />
          <div>Payouts waiting - Activate your account</div>
        </TitleContainer>
        <div>{formatCentsAsCurrency(totalAmount)}</div>
      </NoticeButton>
      <SlideUpDrawer>
        <PayoutList payouts={queuedPayouts} title="Payouts waiting" subTitle={subTitle} />
      </SlideUpDrawer>
    </>
  );
}
