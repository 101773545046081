/**
 * OnboardingLayout.tsx
 *
 * A Parent Layout for all Onboarding Pages
 */
import { Outlet } from 'react-router-dom';

import { TabWrapper } from '@/app/wrappers/Containers';

export default function OnboardingLayout() {
  return (
    <TabWrapper>
      <Outlet />
    </TabWrapper>
  );
}
