/**
 * Layout for the Embedded Dashboard
 */
import { Outlet, useLocation } from 'react-router-dom';

import { animated, useTransition } from 'react-spring';

import { DashboardPath, ProfileStatus } from '@/shared/types';
import { useEffect, useMemo } from 'react';
import { ContentWrapper } from './DashboardLayout.styled';

import { useWorkerProfile } from '@/services/useWorkerProfile';
import { Banner } from '@/shared/components/Banner/Banner';
import { BannerKey } from '@/shared/components/Banner/banners';
import dayjs from 'dayjs';
import styled from 'styled-components';
import DashboardTabNav from './DashboardNavBars';
import { useDashboardHistory } from './useDashboardHistory';

const getPathIndex = (path: string | undefined) => {
  if (!path) {
    return 0;
  }
  const idx = [
    DashboardPath.Home,
    DashboardPath.Card,
    DashboardPath.Withdraw,
    DashboardPath.Settings,
  ].indexOf(path as DashboardPath);
  return idx;
};

const AnimationRow = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

export default function DashboardLayout() {
  // Let's see if this works!
  const location = useLocation();

  // Custom context context for our Dashboard history for animation use!
  const { dashboardHistory, push: pushHistory } = useDashboardHistory();

  const { data: workerProfile } = useWorkerProfile();
  const today = useMemo(() => dayjs(), []);
  const fakeShippingDays = today.diff(workerProfile?.profile?.activatedAt, 'days');
  const cardIssued = !!workerProfile?.worker?.bankingPrimaryAccount?.physicalCardId;

  // If an active worker only has direct deposit setup
  const hideBankingExperiences =
    !workerProfile?.worker?.bankingUserId &&
    workerProfile?.profile?.status === ProfileStatus.Active;

  const getBanner = (): BannerKey | undefined => {
    const conditions: { key: BannerKey; condition: boolean }[] = [
      {
        key: 'business-direct-deposit',
        condition:
          workerProfile?.profile?.workerType === 'business' &&
          workerProfile.profile.payoutMethod?.selected !== 'ACHDirectDeposit',
      },
      {
        key: 'virtual-ready-to-spend',
        condition: fakeShippingDays < 7 && cardIssued,
      },
      {
        key: 'physical-almost-delivered',
        condition: fakeShippingDays >= 7 && fakeShippingDays < 12 && cardIssued,
      },
    ];

    return conditions.find(({ condition }) => condition)?.key;
  };

  // There are two transitions described by these three variables: the transition "from to enter"
  // which happens on the component mount, and the transition "enter to leave" which happens
  // on component unmount (when we navigate to another tab via swipe or tab click).
  const historyLength = dashboardHistory.length;
  const prevPath = historyLength > 1 ? dashboardHistory[historyLength - 2] : undefined;
  const curPath = historyLength > 1 ? dashboardHistory[historyLength - 1] : undefined;
  const prevTabIndex = prevPath ? getPathIndex(prevPath) : -1;
  const curTabIndex = curPath ? getPathIndex(curPath) : -1;

  // TODO(Carter): Can I figure out the leave transition? Or just leave it?
  const transitions = useTransition(curPath, {
    from: {
      opacity: 0,
      width: '100%',
      display: 'flex',
      transform: prevTabIndex < curTabIndex ? 'translate3d(100%,0,0)' : 'translate3d(-100%,0,0)',
    },
    enter: {
      opacity: 1,
      display: 'flex',
      width: '100%',
      transform: 'translate3d(0,0,0)',
    },
    // leave: {
    //   width: '100%',
    //   opacity: 0,
    //   display: 'flex',
    //   transform: prevTabIndex < curTabIndex ? 'translate3d(-100%,0,0)' : 'translate3d(100%,0,0)',
    // },
  });
  // This is the recommended approach of tracking history in React-Router-v6!
  // Additionally, if the Layout rerenders (AKA we leave the /dashboard/* path),
  // then previousPath will automatically be reset to its default value of undefined
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen#:~:text=The%20new%20React%20Router%20v6,case%20whenever%20the%20location%20changes
  useEffect(() => {
    pushHistory(location.pathname);
  }, [location.pathname]);

  return (
    <ContentWrapper>
      <DashboardTabNav
        currentPath={location.pathname}
        hideBankingExperiences={hideBankingExperiences}
      />
      <Banner bannerKey={getBanner()} />
      <AnimationRow>
        {!prevPath ? (
          <Outlet />
        ) : (
          transitions((style) => (
            <animated.div style={style}>
              <Outlet />
            </animated.div>
          ))
        )}
      </AnimationRow>
    </ContentWrapper>
  );
}
