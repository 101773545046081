import { CardAndTokenData } from '@/services/useBankingCard';
import { toast } from '@/shared/components/Toaster/Toaster';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FieldContainer, Form, FormContainer, SubmitButton } from './pin.styled';
import { fieldCss, useVGSForm, VGSCollectForm } from './use-vgs-form';

const InputGroup = styled.div`
  display: flex;
`;

type Props = {
  card: CardAndTokenData['card'];
  token: CardAndTokenData['bankToken'];
};

export const SetupPin: FC<Props> = ({ card, token }) => {
  const navigate = useNavigate();

  const initForm = useCallback((vgs_collect: VGSCollectForm) => {
    vgs_collect
      .field('#cc-pin', {
        type: 'text',
        name: 'data.attributes.pin',
        placeholder: 'PIN',
        validations: ['required', '/^([0-9]{4})$/'],
        css: fieldCss,
        maxLength: 4,
      })
      .replacePattern('/[^0-9]+/g');
    vgs_collect
      .field('#cc-pin-confirm', {
        type: 'text',
        name: 'data.attributes.confirmPin',
        placeholder: 'Confirm PIN',
        validations: [
          'required',
          '/^([0-9]{4})$/',
          {
            type: 'compareValue',
            params: {
              field: 'data.attributes.pin',
              function: 'match',
            },
          },
        ],
        css: fieldCss,
        maxLength: 4,
      })
      .replacePattern('/[^0-9]+/g');
  }, []);

  const { formState, handleFormSubmit } = useVGSForm({
    initForm,
    submitUrl: `/cards/${card?.cardId}/secure-data/pin`,
    submitOptions: {
      mapDotToObject: 'true',
      headers: {
        'Content-Type': 'application/vnd.api+json',
        Authorization: 'Bearer ' + token,
      },
    },
    onSettled: (status) => {
      if (status >= 200 && status < 300) {
        toast({ message: 'Successfully set PIN', duration: 5000, type: 'success' });

        navigate(-1);
        return;
      }
      toast({
        message: `Error setting PIN - please try again.`,
        duration: 5000,
        type: 'error',
      });
    },
  });

  return (
    <FormContainer>
      <Form id="collect-form" onSubmit={handleFormSubmit}>
        <InputGroup>
          <label>
            <span>PIN</span>
            <FieldContainer id="cc-pin" />
          </label>
          <label>
            <span>Confirm PIN</span>
            <FieldContainer id="cc-pin-confirm" />
          </label>
        </InputGroup>
        <SubmitButton
          type="submit"
          disabled={
            !formState?.['data.attributes.pin']?.isValid ||
            !formState?.['data.attributes.confirmPin']?.isValid
          }
        >
          Set PIN
        </SubmitButton>
      </Form>
    </FormContainer>
  );
};
