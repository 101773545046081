/**
 * Data fetching for a worker's banking account card
 */
import { BankingCard } from '@/shared/types';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import serverApi from './serverApi';

// Refetch the customer profile every minute (EG to auto-log out)
const REFETCH_AFTER_SECONDS = 120;

export interface CardAndTokenData {
  card: BankingCard;
  bankToken: string;
}
/**
 * Get the full workers deposit account information from the pay-server. This will include
 * their account and routing numbers
 */
async function fetchBankingCardAndToken() {
  const response = await serverApi.get('/banking/virtual_card/sensitive');
  return response.data;
}

export const useBankingCardAndToken = <TData = CardAndTokenData>(
  select?: (data: CardAndTokenData) => TData
) => {
  return useQuery({
    queryKey: ['card'],
    queryFn: () => fetchBankingCardAndToken(),
    refetchInterval: 1000 * REFETCH_AFTER_SECONDS,
    refetchIntervalInBackground: true, // Refetch in background too
    select,
  });
};

const selectBankingCard = (data: CardAndTokenData): BankingCard => data.card;
export const useBankingCard = () => useBankingCardAndToken(selectBankingCard);

const selectBankingToken = (data: CardAndTokenData): string => data.bankToken;
export const useBankingToken = () => useBankingCardAndToken(selectBankingToken);

// Mutations for Freezing
async function postFreezeCard() {
  const response = await serverApi.post('/banking/virtual_card/freeze');
  return response.data;
}
export const useFreezeCard = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => postFreezeCard(),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['card'] });
    },
    onError: () => {
      return queryClient.invalidateQueries({ queryKey: ['card'] });
    },
    useErrorBoundary: true,
  });
};

async function postUnfreezeCard() {
  const response = await serverApi.post('/banking/virtual_card/unfreeze');
  return response.data;
}
export const useUnfreezeCard = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => postUnfreezeCard(),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['card'] });
    },
    onError: () => {
      return queryClient.invalidateQueries({ queryKey: ['card'] });
    },
    useErrorBoundary: true,
  });
};

const fetchPinStatus = async ({ cardId }: { cardId: string }) => {
  const response = await serverApi.get<{ pinStatus: { status: 'NotSet' | 'Set' } }>(
    `/banking/card/${cardId}/get_pin_status`
  );
  return response.data.pinStatus.status;
};

export const usePinStatus = (
  cardId: string,
  options?: UseQueryOptions<'NotSet' | 'Set', Error>
) => {
  return useQuery({
    queryKey: ['pin_status'],
    queryFn: () => fetchPinStatus({ cardId }),
    useErrorBoundary: false,
    ...options,
  });
};
