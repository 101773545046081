import { useEffect, useState } from 'react';
import { LoadingScope } from '../types/types';

const useLoading = (init: boolean, loaderId: LoadingScope) => {
  const [isLoading, setLoading] = useState(init);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isLoading) {
      // Create a styled element and use its content override CSS styles
      const style = document.createElement('style');
      style.innerHTML = `
        ${`.content-loader-${loaderId}`} {
            display: block;
        }
      `;
      document.head.appendChild(style);

      return () => {
        // Remove the <style> element from the component when the component unmounts or isLoading
        document.head.removeChild(style);
      };
    }
  }, [isLoading]);

  return setLoading;
};

export default useLoading;
