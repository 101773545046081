import { LoadingScreen } from '@/app/wrappers/Containers';
import { useCustomerFromProfile } from '@/services/useWorkerProfile';
import { ReactComponent as LockedMoneyIcon } from '@/shared/assets/locked-money.svg';
import { formatCentsAsCurrency } from '@/shared/helpers';
import { useSlideUpDrawer } from '@/shared/hooks/useSlideUpDrawer';
import { Payout, PayoutQueuingReasons, PayoutStatus } from '@/shared/types';
import { Spinner } from '@checkrx/pay-component-library';
import PayoutList from './PayoutList';
import { NoticeButton, TitleContainer } from './PayoutNotices.styled';

type Props = {
  payouts: Array<Payout>;
};

export default function WaitingOnManualIssuanceNotice({ payouts }: Props) {
  const { SlideUpDrawer, openDrawer } = useSlideUpDrawer();

  const queuedPayouts = payouts.filter(
    (payout) =>
      payout?.status === PayoutStatus.Queued &&
      payout?.queuingReason === PayoutQueuingReasons.WaitingOnManualIssuance
  );

  if (queuedPayouts.length === 0) {
    return null;
  }

  const { data: customer } = useCustomerFromProfile();

  if (!customer) {
    return (
      <LoadingScreen>
        <Spinner />
      </LoadingScreen>
    );
  }
  const totalAmount = queuedPayouts.reduce((sum, payout) => sum + payout.amountCents, 0);
  const subTitle = (
    <>
      We are waiting on {customer.humanReadableName} to release these payouts to you. Once released,
      the funds will be transferred using your selected payout method. Please contact{' '}
      {customer.humanReadableName} if you have questions on the release date.
    </>
  );

  return (
    <>
      <NoticeButton type="button" onClick={openDrawer}>
        <TitleContainer>
          <LockedMoneyIcon />
          <div>Upcoming Payouts</div>
        </TitleContainer>
        <div>{formatCentsAsCurrency(totalAmount)}</div>
      </NoticeButton>
      <SlideUpDrawer>
        <PayoutList payouts={queuedPayouts} title="Upcoming Payouts" subTitle={subTitle} />
      </SlideUpDrawer>
    </>
  );
}
