import { DateString } from './types';

export enum InAppMessageType {
  CardPending = 'CARD_PENDING_MESSAGE',
  CardBenefitsEducation = 'CARD_BENEFITS_EDUCATION_MESSAGE',
  NewFeatureAnnouncementMessage = 'NEW_FEATURE_ANNOUNCEMENT_MESSAGE',
  UpcomingOutage = 'UPCOMING_OUTAGE_MESSAGE',
  TaxesAreComing = 'TAXES_ARE_COMING_MESSAGE',
  TaxesDocumentFiled = 'TAXES_DOCUMENT_FILED_MESSAGE',
}

// In-App Messages for a WorkerProfile
export interface InAppMessage {
  // ID for the message. TODO(Carter): Package this to messageId on the server.
  _id: string;

  // What type of message is this?
  messageType: InAppMessageType;

  // When was this Message queued?
  createdAt: DateString;

  // Won't be shown after this date, even if acknowledged
  expiresAt?: DateString;

  // General Purpose object to add relevant message data / parameters to the front end.
  messageData?: { [key: string]: unknown }; // Keep this general purpose until we have a better idea
}
