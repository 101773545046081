import { DisclaimerText } from './confirm-pages.styled';

export const disclaimerText = (
  <DisclaimerText>
    By pressing Submit, you agree that Checkr Pay may use the information you have provided above
    for identity verification purposes. We may also ask for additional information if we are unable
    to verify your identity using the information provided. To help the government fight the funding
    of terrorism and money laundering activities, federal law requires all financial institutions to
    obtain, verify, and record information that identifies each person who opens an account.
  </DisclaimerText>
);
