import serverApi from '@/services/serverApi';
import { Transaction, TransactionDirection } from '@/shared/types';
import { useQuery } from '@tanstack/react-query';

const REFETCH_AFTER_SECONDS = 60;

async function fetchTransactions({
  startDate,
  endDate,
  direction,
}: {
  startDate?: Date;
  endDate?: Date;
  direction?: TransactionDirection;
}) {
  const res = await serverApi.get<{ data: Transaction[] }>('/banking/transactions', {
    params: {
      created_after: startDate,
      created_before: endDate,
      direction:
        direction === TransactionDirection.Credit
          ? 'money_in'
          : direction === TransactionDirection.Debit
            ? 'money_out'
            : undefined,
    },
  });
  return res.data.data;
}

export const useTransactions = ({
  startDate,
  endDate,
  direction,
  searchString,
  filterFn,
}: {
  startDate?: Date;
  endDate?: Date;
  direction?: TransactionDirection;
  searchString?: string;
  filterFn?: (transaction: Transaction) => boolean;
} = {}) => {
  return useQuery({
    queryKey: ['transactions', { startDate, endDate }],
    queryFn: async () => fetchTransactions({ startDate, endDate }),
    refetchInterval: 1000 * REFETCH_AFTER_SECONDS,
    select: (transactions) => {
      transactions.sort(byCreatedAtDesc);

      return transactions.filter((transaction) => {
        return (
          doesSearchMatch(transaction, searchString) &&
          doesDirectionMatch(transaction, direction) &&
          doesCustomerFilterMatch(transaction, filterFn)
        );
      });
    },
  });
};

function byCreatedAtDesc(a: Transaction, b: Transaction) {
  if (new Date(a.createdAt) < new Date(b.createdAt)) {
    return 1;
  } else {
    return -1;
  }
}

function doesSearchMatch(transaction: Transaction, searchString?: string) {
  if (!searchString) {
    return true;
  }

  return `${transaction.metadata?.annotation}:${transaction.summary}`
    .toLowerCase()
    .includes(searchString.toLowerCase());
}

/**
 * The API's `direction` parameter can return bad data,
 * e.g. ACH withdrawals are returned when requesting credits.
 *
 * When that's fixed we can remove this function and let
 * the API own this logic instead.
 */
function doesDirectionMatch(transaction: Transaction, direction?: TransactionDirection) {
  if (!direction) {
    return true;
  }

  return transaction.direction === direction;
}

function doesCustomerFilterMatch(
  transaction: Transaction,
  filterFn?: (transaction: Transaction) => boolean
) {
  if (!filterFn) {
    return true;
  }

  return filterFn(transaction);
}
