import { useReopenBankingAccount } from '@/services/useBankingAccount';
import { useCustomerFromProfile, useWorkerProfile } from '@/services/useWorkerProfile';
import { Bold, SubTitleText, TitleText } from '@/shared/components/Text.styled';
import { toast } from '@/shared/components/Toaster/Toaster';
import { PayoutMethod } from '@/shared/types';
import { Button } from '@checkrx/pay-component-library';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  margin-bottom: 160px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 12px;
`;

export const ClosedBankAccount = () => {
  const navigate = useNavigate();
  const { data: workerProfile } = useWorkerProfile();
  const { data: customer } = useCustomerFromProfile();
  const reopenBankingAccount = useReopenBankingAccount();

  const handleReopenBankAccount = async () => {
    try {
      await reopenBankingAccount.mutateAsync();
      navigate('/dashboard');
      toast({
        type: 'success',
        duration: 12000,
        message: `Successfully reopened your banking account. 
          Please allow a few moments for all banking operations to be restored.`,
      });
    } catch (err) {
      toast({
        type: 'error',
        duration: 3500,
        message: 'Could not reopen your banking account. Please contact support.',
      });
    }
  };
  const selectedPayoutMethod = workerProfile?.profile?.payoutMethod?.selected;
  const isUsingDD = selectedPayoutMethod === PayoutMethod.ACHDirectDeposit;
  const canUseDD =
    workerProfile?.profile?.additionalPayoutMethods?.achDirectDeposit?.enabled ||
    customer?.payoutMethods?.achDirectDeposit?.enabled;

  return (
    <PageWrapper>
      <Container>
        <TitleText>Checkr Pay Bank Account Closed</TitleText>
        <SubTitleText>
          Your Checkr Pay bank account is closed temporarily due to inactivity. You may reopen your
          bank account by clicking reopen below.
        </SubTitleText>
        <SubTitleText>
          You may {isUsingDD ? 'continue to use' : 'also set up'} <Bold>direct deposit</Bold> to
          receive payouts.
        </SubTitleText>
        <Button text="Reopen" onClick={handleReopenBankAccount} width="100%" colorVariant="brand" />
        {!isUsingDD && canUseDD && (
          <Button
            text="Setup direct deposit"
            onClick={() => navigate('/setup-direct-deposit')}
            width="100%"
            colorVariant="light"
          />
        )}
      </Container>
    </PageWrapper>
  );
};
