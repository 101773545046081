/**
 * Data fetching for a workers's banking Application and all related information
 */
import serverApi from '@/services/serverApi';
import { BankingApplication } from '@/shared/types';
import { useQuery } from '@tanstack/react-query';

async function fetchBankingApplication() {
  const response = await serverApi.get<{
    application: BankingApplication;
  }>('/banking/application');

  return response.data.application;
}

export const useBankingApplication = () => {
  return useQuery({
    queryKey: ['banking', 'application'],
    queryFn: () => fetchBankingApplication(),
  });
};
