/**
 * The Dashboard gets its own special nav bar.
 */
import { DashboardPath } from '@/shared/types';
import { useNavigate } from 'react-router-dom';
import { NavContainer, NavOption, TabTitle } from './DashboardLayout.styled';

const noop = (e: React.MouseEvent | React.KeyboardEvent) => {
  e.preventDefault();
};

export default function DashboardTabNav({
  currentPath,
  hideBankingExperiences,
}: {
  currentPath: string | undefined;
  hideBankingExperiences?: boolean;
}) {
  const navigate = useNavigate();

  return (
    <NavContainer>
      <NavOption
        active={currentPath === DashboardPath.Home}
        onClick={
          currentPath === DashboardPath.Home
            ? noop
            : () => {
                navigate('/dashboard');
              }
        }
      >
        <TabTitle>Home</TabTitle>
      </NavOption>
      {hideBankingExperiences ? (
        <>
          <NavOption
            active={currentPath === DashboardPath.PayStubs}
            onClick={
              currentPath === DashboardPath.PayStubs
                ? noop
                : () => {
                    navigate(DashboardPath.PayStubs);
                  }
            }
          >
            <TabTitle>Pay Stubs</TabTitle>
          </NavOption>
          <NavOption
            active={currentPath === DashboardPath.HelpCenter}
            onClick={
              currentPath === DashboardPath.HelpCenter
                ? noop
                : () => {
                    navigate(DashboardPath.HelpCenter);
                  }
            }
          >
            <TabTitle>Help Center</TabTitle>
          </NavOption>
        </>
      ) : (
        <>
          <NavOption
            active={currentPath === DashboardPath.Card}
            onClick={
              currentPath === DashboardPath.Card
                ? noop
                : () => {
                    navigate('/dashboard/card');
                  }
            }
          >
            <TabTitle>Card</TabTitle>
          </NavOption>
          <NavOption
            active={currentPath === DashboardPath.Withdraw}
            onClick={
              currentPath === DashboardPath.Withdraw
                ? noop
                : () => {
                    navigate('/dashboard/withdraw');
                  }
            }
          >
            <TabTitle>Withdraw</TabTitle>
          </NavOption>
        </>
      )}
      <NavOption
        active={currentPath === DashboardPath.Settings}
        onClick={
          currentPath === DashboardPath.Settings
            ? noop
            : () => {
                navigate('/dashboard/settings');
              }
        }
      >
        <TabTitle className="dashboard-tab-more">More</TabTitle>
      </NavOption>
    </NavContainer>
  );
}
