import type { Transaction } from '@/shared/types';
import dayjs from 'dayjs';

export function groupTransactionsByMonth(transactions: Transaction[], numberOfMonths: number) {
  const monthlyTransactionsHashMap: { [key: number]: Transaction[] } = {};
  for (let i = 0; i < transactions.length; i++) {
    const tx = transactions[i];
    if (tx) {
      const date = dayjs(tx.createdAt);
      const monthIdx = date.month();
      const transactionsInMonth = (monthlyTransactionsHashMap[monthIdx] =
        monthlyTransactionsHashMap[monthIdx] || []);
      transactionsInMonth.push(tx);
    }
  }

  const res = [];
  for (let idx = 0; idx < numberOfMonths; idx++) {
    const date = dayjs().subtract(idx, 'month');

    let longMonth;
    if (idx === 0) {
      longMonth = 'This month';
    } else if (idx === 1) {
      longMonth = 'Last month';
    } else {
      longMonth = date.format('MMMM');
    }
    res.push({
      month: date.format('MMM'),
      longMonth,
      transactions: monthlyTransactionsHashMap[date.month()] || [],
    });
  }
  return res;
}

export function groupTransactionsByYear(transactions: Transaction[], numberOfYears = 2) {
  const yearlyTransactionsHashMap: { [key: number]: Transaction[] } = {};
  for (let i = 0; i < transactions.length; i++) {
    const tx = transactions[i];
    if (tx) {
      const date = dayjs(tx.createdAt);
      const year = date.year();
      const transactionsInYear = (yearlyTransactionsHashMap[year] =
        yearlyTransactionsHashMap[year] || []);
      transactionsInYear.push(tx);
    }
  }

  const currentYear = new Date().getFullYear();
  const res = [];
  for (let idx = 0; idx < numberOfYears; idx++) {
    const year = currentYear - idx;
    const yearStr = '' + year;
    res.push({
      year: yearStr,
      longYear: yearStr,
      transactions: yearlyTransactionsHashMap[year] || [],
    });
  }
  return res;
}
