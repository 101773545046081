import { colors, fontSizes, fontWeights } from '@/shared/styles';
import styled, { DefaultTheme } from 'styled-components';

// Color variants
function selectedButtonStyles(theme: DefaultTheme) {
  return `
    background: ${theme.colors.buttons.primary};
    color: ${colors.trueWhite};
`;
}

function unselectedButtonStyles(theme: DefaultTheme) {
  return `
    background: ${colors.primaryGreyMediumLight};
    color: ${theme.colors.buttons.primary};
`;
}

export const CheckIconContainer = styled.div<{ selected?: boolean }>`
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  background: ${(p) => (p.selected ? p.theme.colors.buttons.secondary : colors.trueWhite)};
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  border: 1px solid #e4e7ed;
`;

export const RadioButtonText = styled.h3`
  /* Text Styling */
  font-family: 'Outfit';
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.bold};
`;

export const RadioButtonSubtext = styled.h2`
  /* Text Styling */
  font-family: 'Outfit';
  font-size: ${fontSizes.small};
`;

export const StyledRadioButton = styled.button<{
  selected?: boolean;
  disabled?: boolean;
}>`
  /* Container properties */
  display: flex;
  flex-flow: row;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* Sizing */
  width: 100px;
  height: 110px;
  min-height: 100px;
  border-radius: 7px;
  gap: 6px;
  padding: 5px 9px;

  /* Coloring */
  border: none;
  ${(p) => (p.selected ? selectedButtonStyles(p.theme) : unselectedButtonStyles(p.theme))};

  /* Disabled variant */
  ${(p) =>
    p.disabled &&
    `
    opacity: 0.4;
  `}

  /* Hover variant */
  ${(p) =>
    !p.disabled &&
    `&:hover {
    filter: brightness(75%);
    transition: 0.2s;
  }`}
`;
