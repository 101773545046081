import { ReactComponent as RightArrow } from '@/shared/assets/right-arrow.svg';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { currencyFormatter } from '../helpers';
import { colors, fontSizes } from '../styles';
import { Badge } from './Badge.styled';
import { PrimaryLink } from './Link.styled';
import { Bold } from './Text.styled';

const Panel = styled.div`
  background-color: ${colors.primaryWhite};
  font-size: ${fontSizes.medium};
  padding: 12px;
  margin-top: 10px;
  border-radius: 8px;
`;

const Heading = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
`;

const SubHeading = styled.div`
  margin-bottom: 10px;
`;

export function CondensedDirectDepositSelectionNotice({
  pendingDirectDepositTotal,
}: {
  pendingDirectDepositTotal: number;
}) {
  return (
    <>
      <Panel>
        <Heading>
          <Bold>Direct deposit</Bold> <Badge>Active</Badge>
        </Heading>
        <SubHeading>
          {pendingDirectDepositTotal > 0.0 ? (
            <>
              You currently have{' '}
              <Link to="/transactions">
                {currencyFormatter.format(pendingDirectDepositTotal / 100)} in pending payouts
              </Link>
              .
            </>
          ) : (
            <>
              Payouts will automatically be sent to your externally linked bank account on pay day
              via a direct deposit.
            </>
          )}{' '}
          Use <Bold>Checkr Wallet</Bold> for faster payouts.
        </SubHeading>
        <PrimaryLink to="/manage-payout-methods">
          Enable instant payouts
          <RightArrow />
        </PrimaryLink>
      </Panel>
    </>
  );
}
