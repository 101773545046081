/**
 * Fetches cashback data
 */
import { CashbackSummary } from '@/shared/types';
import { useQuery } from '@tanstack/react-query';
import serverApi from './serverApi';

async function fetchCashbackSummary() {
  const response = await serverApi.get('/cashback/summary');
  return response.data?.cashbackSummary || null;
}

export const useCashbackSummary = <TData = CashbackSummary>(
  select?: (data: CashbackSummary) => TData
) => {
  return useQuery({
    queryKey: ['cashback', 'summary'],
    queryFn: () => fetchCashbackSummary(),
    select,
  });
};
