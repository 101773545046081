import { useAddPayoutMethod } from '@/services/usePayoutMethod';
import { LinkWithPlaid } from '@/shared/components/LinkWithPlaid';
import { PayoutMethod } from '@/shared/types';
import { useLocation, useNavigate } from 'react-router-dom';

const SelectPayoutMethodPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { mutateAsync: addPayoutMethod } = useAddPayoutMethod();

  return (
    <LinkWithPlaid
      onLinkManually={() => navigate('/setup-direct-deposit/manual', { state })}
      onSuccessfulPlaidConnection={async (plaidProcessorToken) => {
        addPayoutMethod({
          plaidProcessorToken,
          payoutMethod: state?.payoutMethod ?? PayoutMethod.ACHDirectDeposit,
        });
        navigate('/setup-direct-deposit/result');
      }}
    />
  );
};

export default SelectPayoutMethodPage;
