import { colors } from '@/shared/styles';
import styled from 'styled-components';

const DotContainer = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: end;
  gap: 10px;
`;

const BouncingBall = styled.div<{ size: string; order: number }>`
  min-width: ${(p) => p.size};
  max-width: ${(p) => p.size};
  min-height: ${(p) => p.size};
  max-height: ${(p) => p.size};
  border-radius: 100%;
  background-color: ${colors.primaryGreyDark};
  margin-bottom: -20px;

  // Define the animation
  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(13px);
    }
    60% {
      transform: translateY(-15px);
    }
    80% {
      transform: translateY(0);
    }
  }

  -webkit-animation-name: bounce;
  -webkit-animation-delay: ${(p) => `.${p.order}s`};
  -webkit-animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;

  animation-name: bounce;
  animation-delay: ${(p) => `.${p.order}s`};
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
`;

export default function LoadingDots({ size = '30px' }: { size?: string }) {
  return (
    <DotContainer>
      <BouncingBall size={size} order={0} />
      <BouncingBall size={size} order={1} />
      <BouncingBall size={size} order={2} />
    </DotContainer>
  );
}
