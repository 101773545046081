/**
 * VerificationLayout.tsx
 *
 * A Parent Layout for all Verification and MFA Pages
 */
import { Outlet } from 'react-router-dom';

import { TabWrapper } from '@/app/wrappers/Containers';
import PageNavBar from '@/shared/components/PageNavBar';

export default function VerificationLayout() {
  return (
    <TabWrapper>
      <PageNavBar navigateBackTo="/" />
      <Outlet />
    </TabWrapper>
  );
}
