import styled, { useTheme } from 'styled-components';
// This SVG is used on the success pages. The graphic colors are loaded from customer preferences.
//
// Classname is a required by styled-components in order to style
function CustomSuccessGraphic({
  primaryColor,
  secondaryColor,
  accentColor,
  className,
}: {
  primaryColor?: string;
  secondaryColor?: string;
  accentColor?: string;
  className: string;
}) {
  return (
    <svg
      className={className}
      width="315"
      height="315"
      viewBox="0 0 315 315"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="157.308" cy="160.286" r="92.5873" stroke="#E4E7ED" strokeWidth="4" />
      <circle cx="157.307" cy="158.287" r="124.547" stroke="#E4E7ED" strokeWidth="3" />
      <circle cx="157.307" cy="157.65" r="156.637" stroke="#E4E7ED" />
      <circle cx="290.792" cy="76.1272" r="3.5" fill={secondaryColor} />
      <circle cx="84.5714" cy="57.0106" r="5.39515" fill={accentColor} />
      <circle cx="209.868" cy="82.239" r="12.5" fill="#E4E7ED" />
      <circle cx="20.1944" cy="233.628" r="3.44585" fill={primaryColor} />
      <circle cx="271.552" cy="207.508" r="8.66066" fill="#E4E7ED" />
      {/* eslint-disable max-len */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M157.307 222.715C191.273 222.715 218.807 195.181 218.807 161.215C218.807 127.25 191.273 99.7153 157.307 99.7153C123.342 99.7153 95.8071 127.25 95.8071 161.215C95.8071 195.181 123.342 222.715 157.307 222.715ZM157.307 216.965C126.518 216.965 101.558 192.005 101.558 161.215C101.558 130.426 126.518 105.466 157.307 105.466C188.097 105.466 213.057 130.426 213.057 161.215C213.057 192.005 188.097 216.965 157.307 216.965ZM180.657 148.742C181.829 147.57 181.829 145.671 180.657 144.499C179.486 143.327 177.586 143.327 176.415 144.499L149.346 171.567L138.199 160.421C137.028 159.249 135.128 159.249 133.957 160.421C132.785 161.592 132.785 163.492 133.957 164.663L147.225 177.931C147.787 178.494 148.551 178.81 149.346 178.81C150.142 178.81 150.905 178.494 151.468 177.931L180.657 148.742Z"
        fill="url(#paint0_linear_0_1)"
      />
      {/* eslint-enable max-len */}
      <defs>
        <linearGradient
          id="paint0_linear_0_1"
          x1="95.8071"
          y1="165.432"
          x2="218.815"
          y2="165.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={primaryColor} />
          <stop offset="1" stopColor={accentColor} />
        </linearGradient>
      </defs>
    </svg>
  );
}

const StyledSuccessGraphic = styled(CustomSuccessGraphic)`
  width: 100%;
  max-height: 53.2%;
  top: 6%;
`;

export default function SuccessGraphic({ className }: { className: string }) {
  const theme = useTheme();
  return (
    <StyledSuccessGraphic
      className={className}
      primaryColor={theme?.colors?.graphics?.primary}
      secondaryColor={theme?.colors?.graphics?.secondary}
      accentColor={theme?.colors?.graphics?.accent}
    />
  );
}
