import { trackAmplitudeEvent } from '@/shared/analytics';
import { ReactComponent as CircledEmailLetter } from '@/shared/assets/circled-email-letter.svg';
import { ReactComponent as PhoneIcon } from '@/shared/assets/phone-input-icon.svg';
import { getWorkerContactMethods } from '@/shared/helpers';
import { Button, Spinner, TextInput } from '@checkrx/pay-component-library';
import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import NumberInput from '@/shared/components/NumberInput';
import { ErrorText, TitleText } from '@/shared/components/Text.styled';
import { MfaMethods, WorkerContactMethods } from '@/shared/types';

import { useSendOtpToNewContact } from '@/services/useVerification';
import { useCustomerFromProfile } from '@/services/useWorkerProfile';
import { SpinnerRow } from '@/shared/components/SpinnerRow';
import { AxiosError } from 'axios';
import {
  MfaVerificationContainer,
  MfaViewContainer,
  NumberRow,
  StyledLink,
  Text,
} from './VerificationPage.styled';

export default function NewContactPage() {
  const [contact, setContact] = useState('');

  const navigate = useNavigate();

  const {
    mutate: sendOtpToNewContact,
    isError,
    isLoading,
    isSuccess,
    error,
  } = useSendOtpToNewContact();
  const { data: customer } = useCustomerFromProfile();

  const customerMfaPref = customer?.preferences?.mfaMethod ?? MfaMethods.Email;
  const { primaryContactMethod } = getWorkerContactMethods(customerMfaPref);

  let errorMessage = <></>;
  if (isError) {
    errorMessage = (
      <ErrorText>Make sure your {primaryContactMethod} is formatted correctly</ErrorText>
    );
    const castError = (error as AxiosError) || { status: 500, data: null };
    const errorStatus = (castError || { status: 500, data: null })?.status || 500;

    if (errorStatus >= 400 && errorStatus < 500) {
      const { data: errorData } = castError?.response || { data: null };
      if (errorData) {
        // Note(Carter): This is funky typing to say "hey, we know we can check .details on this"
        const details = (errorData as { ['details']: Array<string> })?.details;
        if (details && details.length > 0) {
          const detail = details[0];
          if (detail === 'ineligible_phone_number') {
            errorMessage = (
              <ErrorText>
                {primaryContactMethod} is not eligible for a new account at this time. Please
                contact
                <StyledLink
                  onClick={() => {
                    navigate('/support');
                  }}
                >
                  {' '}
                  Checkr Pay support.{' '}
                </StyledLink>
              </ErrorText>
            );
          }
        }
      }
    }
  }

  // eslint-disable-next-line consistent-return
  const handleSendOtpToNewContact = async () => {
    trackAmplitudeEvent('Two factor Reset - OTP requested to new contact');
    if (primaryContactMethod === WorkerContactMethods.PhoneNumber) {
      sendOtpToNewContact({
        type: 'phone',
        phone: contact,
      });
    } else {
      sendOtpToNewContact({
        type: 'email',
        email: contact,
      });
    }
  };

  if (isSuccess) {
    return (
      <Navigate
        to="/verification/verify-new-contact"
        // Pass some navigation state around
        state={{
          contact,
          contactType:
            primaryContactMethod === WorkerContactMethods.PhoneNumber ? 'phone' : 'email',
        }}
      />
    );
  }
  return (
    <MfaViewContainer>
      <MfaVerificationContainer>
        <CircledEmailLetter />
        <TitleText>Type new {primaryContactMethod}</TitleText>

        <Text>We will send a one-time verification code to this {primaryContactMethod}</Text>

        <NumberRow>
          {primaryContactMethod === WorkerContactMethods.PhoneNumber ? (
            <NumberInput
              centered
              value={contact}
              onChange={(e) => setContact(e.target.value)}
              leftIcon={<PhoneIcon />}
            />
          ) : (
            <TextInput value={contact} onChange={(e) => setContact(e.target.value)} />
          )}
        </NumberRow>
        {isError && errorMessage}
      </MfaVerificationContainer>

      <SpinnerRow>
        <Button
          className="mfa-submit-btn"
          width="100%"
          text="Send OTP Code"
          colorVariant="brand"
          sizeVariant="big"
          iconRight
          icon="arrow-right"
          disabled={
            // Basic check for 10 digits minimum. Our extensive server DTO will catch
            // other errors and format phone number the way server wants it
            primaryContactMethod === WorkerContactMethods.PhoneNumber && contact.length < 10
          }
          onClick={handleSendOtpToNewContact}
        />
        {isLoading ? (
          <div
            style={{
              marginRight: '-30px',
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          >
            <Spinner size="30px" />
          </div>
        ) : (
          <div />
        )}
      </SpinnerRow>
    </MfaViewContainer>
  );
}
