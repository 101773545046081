import styled from 'styled-components';

import { TitleText } from '@/shared/components/Text.styled';
import { colors, fontSizes } from '@/shared/styles';

import { useSendStatementNotification, useWorkerStatementPdf } from '@/services/useStatements';
import { useWorkerProfile } from '@/services/useWorkerProfile';

import DividedCard from '@/shared/components/DividedCard';
import {
  Headline,
  StatementsGroup,
  SupportText,
  TextBox,
} from '@/shared/components/Statements.styled';
import { toast } from '@/shared/components/Toaster/Toaster';
import { isUiWebView } from '@/shared/helpers';
import { Statement } from '@/shared/types';
import { useState } from 'react';

const LinkContainer = styled.div<{ disabled?: boolean }>`
  /* Size */
  width: 100%;
  height: fit-content;
  min-height: 40px;
  color: ${colors.primaryGreyMediumDark};

  /* Container properties */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  /* Hover variant */
  ${(p) =>
    !p.disabled &&
    `&:hover {
    filter: brightness(75%);
    transition: 0.2s;
  }`}
`;

const LinkDescription = styled.div`
  flex-direction: column;
  align-items: start;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 5px;
`;

const StatementText = styled(TitleText)`
  font-size: ${fontSizes.medium};
`;

function periodToMonthYear(period: string) {
  const monthNum = parseInt(period.substring(period.length - 2));
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const monthName = monthNames[monthNum - 1];
  return `${monthName} ${period.substring(0, 4)}`;
}

export function StatementLink({
  statementId,
  onClick,
  disabled = false,
  period,
}: {
  statementId: string;
  onClick: (id: string, period: string) => void;
  disabled?: boolean;
  period: string;
}) {
  const handleStatementLinkClick = () => {
    onClick(statementId, period);
  };

  return (
    <LinkContainer
      key={`statement-${statementId}`}
      disabled={disabled}
      onClick={() => handleStatementLinkClick()}
    >
      <LinkDescription>
        <TitleRow>
          <StatementText>Statement for {periodToMonthYear(period)}</StatementText>
        </TitleRow>
      </LinkDescription>
    </LinkContainer>
  );
}

export function StatementList({ statements = [] }: { statements?: Array<Statement> }) {
  const [selectedStatementId, setSelectedStatementId] = useState<string>('');
  const [selectedPeriod, setSelectedPeriod] = useState<string>('');

  const { data: profile } = useWorkerProfile();

  const statementDownloadErrorMsg = `We've encountered an error downloading your statement. 
  Please try again or contact support.`;

  const { isLoading: _isDownloadingBlob } = useWorkerStatementPdf(selectedStatementId, {
    enabled: selectedStatementId !== '',
    onSuccess: (statementPdfBlob) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(statementPdfBlob as Blob);

      if (!isUiWebView()) {
        // Open the statement in a new tab if we are on web
        window.open(link.href);
        setSelectedStatementId('');
      } else {
        sendStatementNotification({
          statementId: selectedStatementId,
          period: selectedPeriod,
        });
      }
    },
    onError: () => {
      toast({
        type: 'error',
        message: statementDownloadErrorMsg,
        duration: 2500,
      });
    },
  });

  const handleDownloadPdf = async (statementId: string, period: string) => {
    setSelectedStatementId(statementId);
    setSelectedPeriod(period);
    if (!isUiWebView()) {
      toast({
        type: 'info',
        message: 'Opening your statement in a new window',
        duration: 2500,
      });
    } else {
      toast({
        type: 'info',
        message: `Sending your statement to ${profile?.profile?.contactEmail}`,
        duration: 2500,
      });
    }
  };

  const { mutate: sendStatementNotification } = useSendStatementNotification({
    onError: () => {
      toast({
        type: 'error',
        message: statementDownloadErrorMsg,
        duration: 2500,
      });
    },
    onSuccess: () => {
      toast({
        type: 'success',
        message: `Successfully sent your statement to ${profile?.profile?.contactEmail}`,
        duration: 2500,
      });
    },
  });

  return (
    <StatementsGroup>
      {statements.length > 0 ? (
        <DividedCard
          elements={statements.map((s: Statement) => (
            <StatementLink
              key={`${s?.statementId}-card-link`}
              statementId={s?.statementId}
              period={s?.period}
              onClick={handleDownloadPdf}
            />
          ))}
        />
      ) : (
        <></>
      )}
      {statements.length === 0 ? (
        <TextBox>
          <Headline>Looking for your statements?</Headline>
          <SupportText>
            Your first account statement - including earnings, expenses, and other transactions -
            will be available early next month.
          </SupportText>
          <SupportText>
            Check back next month to download your statement. We&apos;ll also send you an email when
            it&apos;s ready.
          </SupportText>
        </TextBox>
      ) : null}
    </StatementsGroup>
  );
}
