/**
 * An Error page stating that their session is expired
 */

import error from '@/shared/assets/error-graphic.svg';
import { Text } from '@/shared/components/Graphics.styled';
import { LogoOnlyNavBar } from '@/shared/components/PageNavBar';

import { AppContainer } from '@/app/wrappers/Containers';
import Legalese from '@/shared/components/Legalese';

import { ContentBox, ErrorTitle, Graphic, LegaleseContent } from './ErrorPage.styled';

export default function AuthErrorPage() {
  return (
    <AppContainer>
      <div>
        <LogoOnlyNavBar />
        <Graphic src={error} />
        <ContentBox>
          <ErrorTitle>Your Session Has Expired</ErrorTitle>
          <Text>
            To sign in again, try navigating away from your Wallet or browser and returning or
            refreshing/logging out of the app/browser.
          </Text>
          <LegaleseContent>
            <Legalese />
          </LegaleseContent>
        </ContentBox>
      </div>
    </AppContainer>
  );
}
