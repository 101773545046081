import { PayoutMethod, Transaction, TransactionStatus, WorkerProfile } from '../types';
import { CondensedDirectDepositSelectionNotice } from './CondensedDirectDepositSelectionNotice';
import { DirectDepositSelectionSubHeading } from './DirectDepositSelectionSubHeading';
import PayoutMethodPanel from './PaymentMethodPanel/PaymentMethodPanel';

const HARD_UPSELL_LIMIT = 3;

export function DirectDepositActivePanel({
  payouts,
  workerProfile,
}: {
  payouts: Transaction[];
  workerProfile: WorkerProfile;
}) {
  const directDepositPayouts = payouts.filter(
    (payout) => payout.metadata?.payoutMethod === PayoutMethod.ACHDirectDeposit
  );

  const pendingDirectDepositTotal = directDepositPayouts.reduce((total, payout) => {
    if (payout.transactionStatus === TransactionStatus.Pending) {
      total = total + payout.amount;
    }

    return total;
  }, 0);

  const isDirectDepositPayoutMethodSelected =
    workerProfile?.profile?.payoutMethod?.selected === PayoutMethod.ACHDirectDeposit;

  if (isDirectDepositPayoutMethodSelected) {
    if (directDepositPayouts.length <= HARD_UPSELL_LIMIT) {
      // upsell level - all in
      return (
        <PayoutMethodPanel
          message={
            <DirectDepositSelectionSubHeading
              pendingDirectDepositTotal={pendingDirectDepositTotal}
            />
          }
          hideDirectDepositActions
        />
      );
    } else {
      // upsell level - just mention
      return (
        <CondensedDirectDepositSelectionNotice
          pendingDirectDepositTotal={pendingDirectDepositTotal}
        />
      );
    }
  } else {
    if (pendingDirectDepositTotal > 0) {
      // show pending direct deposit amount message
      return (
        <DirectDepositSelectionSubHeading
          pendingDirectDepositTotal={pendingDirectDepositTotal}
          disableSignupMessage={true}
        />
      );
    } else {
      return null; // don't say anything about direct deposits
    }
  }
}
