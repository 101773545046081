import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import serverApi from './serverApi';

async function requestWorkerCallBack() {
  const response = await serverApi.post('/support/request_call_back');
  return response.data;
}

type SupportReqBody = {
  updateKey: string;
  updateValue: string;
};

async function requestWorkerPersonalInfoUpdate({ updateKey, updateValue }: SupportReqBody) {
  const response = await serverApi.post('/support/request_personal_info_update', {
    updateKey,
    updateValue,
  });
  return response.data;
}

export const useRequestWorkerCallBack = (options?: UseMutationOptions) => {
  return useMutation({
    mutationFn: () => requestWorkerCallBack(),
    ...options,
    useErrorBoundary: false,
  });
};

export const useRequestWorkerPersonalInfoUpdate = () => {
  return useMutation({
    mutationFn: ({ updateKey, updateValue }: SupportReqBody) =>
      requestWorkerPersonalInfoUpdate({
        updateKey,
        updateValue,
      }),
    useErrorBoundary: false,
  });
};
