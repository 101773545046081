import { NormalText, TitleText } from '@/shared/components/Text.styled';
import { fontSizes, fontWeights } from '@/shared/styles';
import styled from 'styled-components';

export const TextBox = styled.div`
  padding-top: 20%;
  gap: 10px;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  align-self: center;

  font-size: ${fontSizes.big};
  font-weight: ${fontWeights.semiBold};
  text-align: left;
`;

export const Headline = styled(TitleText)`
  font-size: ${fontSizes.giant};
`;

export const SupportText = styled(NormalText)`
  font-size: 1rem;
`;

export const StatementsGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 10px;
`;
